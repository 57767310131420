/* React packages  */
import React from 'react';
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';



/**
* Renders part of underwritting rules  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class UndWrittingPart2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {}
    }
  };

  render() {

    return (

      <div className="">


        {/* Form -: in this form section included  label, togglebutton and dropdown to render the data */}

        <form method="post" name="userRegistrationForm" className="formroot">

          <div className="unwrite">
            <div className="d-flex flex-row mt-3 ">

              <div className="p-2 headerbuttons">
                <div className="input-group Multiplier">
                  <p className="text2part">Multiplier <br /> Status</p>
                  <label className="switch"><input type="checkbox"  name="catMultiStatus" onChange={this.props.toggleChange.bind(this)} checked={this.props.data[0].catMultiStatus} /><div className="slider round" ></div></label>

                </div>
              </div>


              <div className="p-2 headertext">
                <p className="textposition">Company Category – Interest Rate Multiplier</p>
                <p className="textlabel">Employer / Company Category</p>
              </div>
            </div>

            <div className="d-flex flex-row mt-3 padmar ">
              <div className="p-2 underwritepart">
                <div className="form-group">
                  <label className="labelpt-8 maklabuw">Product</label>
                  {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled >{underWritingRules.productName}</label></div>)}
                </div>
              </div>


              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>CAT – A</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isCategoryAlist[i] }} name="categoryA" onChange={(e) => { this.props.handleChangecategoryA(e, i) }} placeholder={underWritingRules.categoryA} disabled={this.props.catMultiStatusdisable}>
                      <option value={underWritingRules.categoryA}>{underWritingRules.categoryA}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>
              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>CAT – B</label>
                </div>
                <div className="form-group">

                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isCategoryBlist[i] }} name="categoryB" onChange={(e) => { this.props.handleChangecategoryB(e, i) }} placeholder={underWritingRules.categoryB} disabled={this.props.catMultiStatusdisable}>
                      <option value={underWritingRules.categoryB}>{underWritingRules.categoryB}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>CAT – C</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isCategoryClist[i] }} name="categoryC" onChange={(e) => { this.props.handleChangecategoryC(e, i) }} placeholder={underWritingRules.categoryC} disabled={this.props.catMultiStatusdisable}>
                      <option value={underWritingRules.categoryC}>{underWritingRules.categoryC}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>CAT – D</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isCategoryDlist[i] }} name="categoryD" onChange={(e) => { this.props.handleChangecategoryD(e, i) }} placeholder={underWritingRules.categoryD} disabled={this.props.catMultiStatusdisable}>
                      <option value={underWritingRules.categoryD}>{underWritingRules.categoryD}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>CAT – E</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isCategoryElist[i] }} name="categoryE" onChange={(e) => { this.props.handleChangecategoryD(e, i) }} placeholder={underWritingRules.categoryE} disabled={this.props.catMultiStatusdisable}>
                      <option value={underWritingRules.categoryE}>{underWritingRules.categoryE}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>
            </div>
          </div>
        </form>


      </div>
    )

  }


}

export default UndWrittingPart2;
