import React, { useEffect, useState } from "react";
import ProcessSteps from "../../../../utilities/ProcessSteps";

import Liveness from "../../../../assets/images/uday/verified-icons/liveness.svg";
import Facematch from "../../../../assets/images/uday/verified-icons/facematch.svg";
import ocr from "../../../../assets/images/uday/verified-icons/ocr.svg";
import IdVerification from "../../../../assets/images/uday/verified-icons/id.svg";
import faceauth2 from "../../../../assets/images/faceauth2.svg";

const steps = [
  {
    title: "Soft Token Solution",
    image: faceauth2,
    className: "face-auth-img2",
  },
  { image: Liveness, description: "Liveness Verification" },
  { image: Facematch, description: "Face match" },
  { image: ocr, description: "OCR" },
  { image: IdVerification, description: "ID verification" },
];

const FaceProcessContainer = () => {
  return (
    <div className="faceauth-process-flow">
      <ProcessSteps
        steps={steps}
        arrowColor="#2988E2"
        circleBorderColor="#2988E2"
        textColor="#0A2540"
        topDescriptionClass="facetopDescription"
      />
    </div>
  );
};

export default FaceProcessContainer;
