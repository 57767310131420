import React from "react";
import risk from "../../../assets/images/risk.svg";
import bankmockup from "../../../assets/images/personal 1.webp";
import appstore from "../../../assets/images/astore.svg";
import playstore from "../../../assets/images/pstore.svg";
import highlysecure from "../../../assets/images/highlysecure.svg";
import virtualdebitcard from "../../../assets/images/virtualdebitcard.svg";
import accountfees from "../../../assets/images/accountfees.svg";

const Body = () => {
  return (
    <>
      <div className="personal-page">
        <div className="bank-body-section-1">
          <h1>Open your digital Montra account today! </h1>

          <div className="row personalb">
            <div className="col-md-6 col-sm-6">
              <div className="bank-body-section-1-1">
                <img src={accountfees} alt="" className="imgpadding" />
                <div class="lineparagrapproducts">
                  <h6>No account fees</h6>
                </div>
                <p className="enjoy">
                  Enjoy the convenience of banking on your phone with free
                  digital account.
                </p>
              </div>

              <div>
                <img src={risk} alt="" className="imgpadding" />
                <div class="lineparagrapproducts">
                  <h6>Risk-free deposits</h6>
                </div>
                <p>
                  Your money is safe with us. None of your deposits will be
                  converted in to any risky asset.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="freevirtual">
                <img src={virtualdebitcard} alt="" className="imgpadding" />
                <div class="lineparagrapproducts">
                  <h6>Free virtual debit card</h6>
                </div>
                <p>
                  Use your virtual debit card to make effortless online
                  transactions at your favorite  merchants across the world.
                </p>
              </div>

              <div>
                <img src={highlysecure} alt="" className="imgpadding" />
                <div class="lineparagrapproducts">
                  <h6>Highly secure</h6>
                </div>
                <p>
                  Your account is secured with a special Montra PIN & 2FA soft
                  token account on VERIFYED App to ensure your account is safe.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bank-body-section-2">
          <h1>Open an account in few minutes in these easy steps:</h1>
          <div className="row">
            <div className="col-md-5 col-xl-4 banking-body-image-col">
              <div className="bankfeatures">
                <img
                  src={bankmockup}
                  alt="Bank features"
                  className="openaccount"
                />
              </div>
            </div>
            <div className="col-md-7 col-xl-8 personal-child-body-col openpersonal">
              <div className="banksteps">
                <div className="personalcountimghidden">1</div>
                <div className="open-account">
                  <div className="open-acct-count personalcountimg">1</div>
                  <div className="bank-step-content">
                    <h6>Download Montra App</h6>
                    <p>
                      You can download Montra App from Google & IOS App stores.
                    </p>
                    <div className="app-download appp-bank">
                      <img src={playstore} alt="image" />
                      <img src={appstore} alt="image" />
                    </div>
                  </div>
                </div>
                <div className="personalcountimghidden twoimg">2</div>
                <div className="open-account">
                  <div className="open-acct-count personalcountimg">2</div>
                  <div className="bank-step-content">
                    <h6 className="signuptext">Sign Up</h6>
                    <p>
                      Sign up with your mobile number and create your profile
                      once your mobile number is verified.
                    </p>
                  </div>
                </div>
                <div className="personalcountimghidden">3</div>
                <div className="open-account">
                  <div className="open-acct-count personalcountimg">3</div>
                  <div className="bank-step-content">
                    <h6>Verify your details</h6>
                    <p>
                      Submit your verified liveness profile picture followed by
                      verification of your BVN or NIN.
                    </p>
                  </div>
                </div>

                <div className="personalcountimghidden">4</div>
                <div className="open-account personalappaccount">
                  <div className="open-acct-count personalcountimg">4</div>
                  <div className="bank-step-content">
                    <h6>Your Montra account is now ready!!!</h6>
                    <p>
                      You can start using your Montra account based on any KYC
                      Level. You can upgrade your KYC Level based on further
                      verification of your ID & residence address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bank-body-section-2 bank-body-section-3">
          <h1>Link your Bank Accounts on your Montra App</h1>
          <div className="row">
            <div className="col-md-7 col-xl-8 personal-child-body-col">
              <div className="banksteps1">
                <div className="personalcountimghidden">1</div>
                <div className="open-account">
                  <div className="bank-step-content">
                    <h6>
                      Select a Bank Account{" "}
                      <span>
                        you want to use to add money to your Montra Account for
                        Montra to verify your account details & consent to
                        placement of eMandate on this account.
                      </span>
                    </h6>
                  </div>
                  <div className="open-acct-count personalcountimg">1</div>
                </div>
                <div className="personalcountimghidden">2</div>
                <div className="open-account">
                  <div className="bank-step-content">
                    <h6>
                      Make a refundable payment using this Bank Account{" "}
                      <span>
                        {" "}
                        to prove the account ownership. Once the payment is
                        verified, your eMandate on the account would be
                        activated for linking on Montra App.
                      </span>{" "}
                    </h6>
                  </div>
                  <div className="open-acct-count personalcountimg">2</div>
                </div>
                <div className="personalcountimghidden">3</div>
                <div className="open-account">
                  <div className="bank-step-content">
                    <h6>
                      Get your Montra Account funded using this linked account{" "}
                      <span>to confirm the linking.</span>
                    </h6>
                  </div>

                  <div className="open-acct-count personalcountimg">3</div>
                </div>
                <div className="personalcountimghidden">4</div>
                <div className="open-account">
                  <div className="bank-step-content">
                    <h6>
                      Your Bank Account is now linked{" "}
                      <span>
                        for you to make payment request from this bank account
                        on Montra App !!!
                      </span>
                    </h6>
                  </div>

                  <div className="open-acct-count personalcountimg">4</div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-4 banking-body-image-col openbank">
              <div className="bankfeatures">
                <img
                  src={bankmockup}
                  alt="bankfeatures"
                  className="openaccountsecond"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
