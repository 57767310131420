/* React packages and components */
import React from 'react';
import { Tooltip } from "carbon-components-react";
import "carbon-components/css/carbon-components.min.css";
import NumberFormat from 'react-number-format';
import ReactTooltip from "react-tooltip";

/**
 * Renders lower section of PRODUCT-MIX in maker section
 * <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>


 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>d-flex  :- </b> it is use for manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities.</li>
 * <li> <b>Tooltip :-</b> This tooltip is a small pop up that appears when user places the mouse pointer over an element such as link or buttons to provide hint or information about the element being hovered.</li>
 *  @Author : Seema Kumari
 *  @version :1.0
 * */

class CollectionLoan extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         fields: {},
         errors: {},
         isActive: false,
         isDisabled: false,
         status: '',
         message: '',
         backerrors: [],
         errorMsg: ''
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
   }
   /**
    * Captures the pin entered by maker in product-mix section.
    *
    * @param {Event} e Event which calls this method
    */
   handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
         fields
      });
   }

   /**
    * Submits product-mix data to server.
    *
    * @param {Event} e Event which calls this method
    */
   handleSubmit = (e) => {
      e.preventDefault();
      {
         let fields = {};
         this.setState({
            message: '',
            status: '',
            backerrors: [],
            errorMsg: ''
         });
      }
      this.submitChange();
      this.removepercentile();
      let proxyurl = 'https://cors-anywhere.herokuapp.com/';
      let url = 'https://api.montra.org/moneylinkweb/api/product/saveprodmix?email=' + localStorage.getItem("email") + '&pin=' + this.state.fields.pin;
      fetch(url, {
         method: 'POST',
         body: JSON.stringify(this.props.loaddata),
         headers: {
            'Content-Type': 'application/json;charset=utf-8',
         },
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            else {
               this.setState({
                  errorMsg: "Server Error: Please Try After Sometime"
               })
            }
         })
         .then(contents => {
            if (200 == contents.status && 'SUCCESS' == contents.message) {
               this.setState({
                  isDisabled: true,
                  status: contents.status,
                  message: contents.message
               });
            } else {
               this.setState({
                  backerrors: contents.errors,
                  status: contents.status,
                  message: contents.message
               });
               this.state.backerrors.map(key => {
                  this.setState({
                     errorMsg: key.message
                  })
               })
            }
            this.props.handlestatus(this.state.status)
         })
         .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"))
   }
   removepercentile() {
      this.props.loaddata.map((productmix, i) => {
         productmix.feeline1 = parseFloat(productmix.feeline1)
         productmix.feeline2 = parseFloat(productmix.feeline2)
         productmix.lossPoolContrPercent = parseFloat(productmix.lossPoolContrPercent)
         productmix.penalChanrgesPercent = parseFloat(productmix.penalChanrgesPercent)
         productmix.baseInterestRate = parseFloat(productmix.baseInterestRate)
         productmix.baseTenors = parseFloat(productmix.baseTenors)
      })
   }
   /**
    * Changes boolean values to ON and OFF.
    */
   submitChange() {
      this.props.loaddata.map((productmix, i) => {
         if (true == productmix.activeProds) {
            productmix.activeProds = "ON"
         } else {
            productmix.activeProds = "OFF"
         }
      })
   }
   myFormat(num) {
      return num + '%';
   }
   render() {
      console.log(this.props.data)
      return (

         <div className="productmixcollection">


            <form method="post" name="userRegistrationForm" className="formroot">
               <div className="row">
                  <div className="col-sm-12 col-md-12 col-xl-4 reduce">

                     <div className="d-flex flex-row mt-3 ">
                        <div className="p-2 productformcol">
                           <div className="form-group">
                              <label className="productcodeloanmak">Product</label>
                              {this.props.loaddata.map((productmix, i) => <div key={i}><label className="form-control loanlabelmak" name="prodName" id="field1" disabled> {productmix.prodName} </label></div>)}
                           </div>
                        </div>

                        <div className="p-2 productinputcollection">
                           <div className="toolinfopro" >
                              <p className="productheader procoll">Collection for internal loans</p>
                              <Tooltip className="toolt" direction="top" align="center" >
                                 Origination OR Origination + Anniversary
                              </Tooltip>
                           </div>
                           <div className="form-group">
                              {this.props.loaddata.map((productmix, i) => <div key={i}>
                                 <div className="input-group aligncenter inputblue margn">
                                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" name="collectionForInternLoans" placeholder={productmix.collectionForInternLoans} onChange={(e) => { this.props.handleChangecollectionForInternLoans(e, i) }} disabled={this.props.productdisable[i]}>
                                       <option value={productmix.collectionForInternLoans}>{productmix.collectionForInternLoans}</option>
                                       <option value="NO">NO</option>
                                       <option value="YES">YES</option>
                                    </select>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                       Non Editable
                                    </ReactTooltip>
                                 </div>
                              </div>)}


                           </div>
                        </div>

                        <div className="p-2 productinputcollection">
                           <div className="toolinfopro" >
                              <p className="productheadermak mak4">Base Interest Rate</p>
                              <Tooltip className="toolt" direction="left" align="center" >
                                 Loans being managed on Bank / FC’s LMS
                              </Tooltip>
                           </div>
                           <div className="form-group">
                              {this.props.loaddata.map((productmix, i) => <div key={i}>
                                 <div className="inner-addon right-addon">
                                    <NumberFormat className="form-control formproduct margn"
                                       data-tip data-tip-disable={!this.props.editable} data-for="noneditable"
                                       name="baseInterestRate"
                                       // value={Number} 
                                       value={productmix.baseInterestRate}
                                       onChange={(e) => { this.props.handleChangebaseInterestRate(e, i) }}
                                       suffix={'%'}
                                       disabled={this.props.productdisable[i]}
                                    />
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                       Non Editable
                                    </ReactTooltip>
                                 </div>
                              </div>)}
                           </div>
                        </div>



                        <div className="p-2 productinputcollection">
                           <div className="toolinfopro" >
                              <p className="basetenor">Base Tenors</p>
                              <Tooltip className="toolt" direction="left" align="center" >
                                 Annualized Rate
                              </Tooltip>
                           </div>
                           <div className="form-group">

                              {this.props.loaddata.map((productmix, i) => <div key={i}>
                                 <div className="inner-addon right-addon">
                                    <NumberFormat className="form-control formproduct margn"
                                       data-tip data-tip-disable={!this.props.editable}
                                       data-for="noneditable" name="baseTenors"
                                       // value={Number} 
                                       value={productmix.baseTenors}
                                       onChange={(e) => { this.props.handleChangebaseTenors(e, i) }}
                                       suffix={'%'} disabled={this.props.productdisable[i]}
                                    />
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                       Non Editable
                                    </ReactTooltip>
                                 </div>
                              </div>)}
                           </div>
                        </div>

                     </div>
                  </div>



                  <div className="col-sm-12 col-md-12 col-xl-4 reduce">
                     <div className="d-flex flex-row mt-3 ">

                        <div className="p-2  collectionpt">
                           <div className="collectioncard">
                              {/* <form method="post" name="userRegistrationForm"> */}

                              <div className="form-group ">
                                 <label htmlFor="first">Type Your User PIN </label>

                                 <input type="password" className="form-control cardinputfield" name="pin" value={this.state.fields.message} onChange={this.handleChange} placeholder="xxxxx " />
                                 <div className="sign">
                                    <input type="submit" className="btn btn-warning cardbutton" style={{ backgroundColor: this.props.buttoncolor }} onClick={this.handleSubmit} value="Submit changes for approval" disabled={this.state.isDisabled || this.props.editable || this.props.fieldsvalid} />
                                 </div>
                              </div>
                              {(() => {
                                 switch (this.props.fieldsvalid) {
                                    case true: return <p className="errorMsg">All Fields are Mandatory</p>
                                    default: return <p></p>
                                 }
                              })()}
                              {(() => {
                                 switch (this.state.message) {
                                    case "SUCCESS": return <p className="submitted">Submitted Succesfully</p>
                                    default: return <p></p>
                                 }
                              })()}
                              {(() => {
                                 switch (this.state.message) {
                                    case "UNSUCCESS": return <div className="errorMsg"> <p className="invalidpin">{this.state.errorMsg}</p></div>
                                    default: return <p></p>
                                 }
                              })()}

                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-sm-12 col-md-3 col-xl-3 reduce">
                  </div>
               </div>
            </form>
         </div>
      );
   }
}


export default CollectionLoan;
