/* React packages and components */
import React from 'react';
import { PartnerLogin } from './PartnerLogin';
import userprofile from './userprofile';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import moneylinkLogo from "../assets/images/Moneylink-logo.svg"


/**
 * Renders partner login page of MoneyLink.
 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * @Author : Seema Kumari
 * @version :1.0 */

class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        if ('' === userprofile.getRole) {
            return (<p>hi </p>);
        }
        else {
            return (
                <div>
                    <div className='ml-background moneybgnew'>
                        <div className="main-header">
                            <div className="mneylink-log-header">
                                <img src={moneylinkLogo} />
                            </div>
                        </div>
                        <div className="">
                            <div className="container-fluid">
                                <div className="row login-2 ">
                                    <div className="col-sm-12 col-md-3 col-xl-4">
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="partnerexist">
                                            <PartnerLogin handlerole={(role) => this.project(role)} />
                                            <p className="signuptext">Sign-up if you are a New User / Issuer Partner </p>
                                            <div className="sign ">
                                                <NavLink to="/Moneylink/PartnerSignup" >  <button type="" className="btn btn-warning  montrasignup"> Sign-up </button></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-xl-4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

}
function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
};

const connectedLoginPage = connect(mapState, actionCreators)(Partner);
export { connectedLoginPage as Partner };
