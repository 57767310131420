import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
// import agencyhero1 from "../../../assets/images/agencyhero1.svg";
import agencyhero2 from "../../../assets/images/agencyhero2.webp";
import AgencyProcessContainer from "./AgencyProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="business-agency">
        <div className="row personalb">
          <div className="col-md-9 business-agency-content-col responsivebusiness-col">
            <h1>
              Earn more by opening a <span>Montra Agent Account</span>{" "}
              <span className="andfont"> & </span> start offering{" "}
              <span>Agency Banking Services</span>
            </h1>
            <p>
              You can register on Montra Business to become a Montra Agent.
              Already registered Merchants on Montra Business can also become
              Montra Agent by adding one more business account.
            </p>
            {/* <img src={agencyhero1} alt="" className="agencyhero1" /> */}
            <AgencyProcessContainer />
            <div className="app-download agencyapp">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col-md-3 business-agency-2 responsivebusiness-col">
            <img src={agencyhero2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
