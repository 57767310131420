import React from 'react';
import ReactTooltip from "react-tooltip";



/**
* Renders part of underwritting rules  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class UndWrittingPart3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {}
    }
  };

  render() {

    return (

      <div>

        {/* Form -: in this form section included  label, togglebutton and dropdown to render the data */}
        <form method="post" name="userRegistrationForm" className="formroot">

          <div className="unwrite">
            <div className="d-flex flex-row mt-3 ">

              <div className="p-2 headerbuttonsright">
                <div className="input-group Multiplier2">
                  <p className="text2part">Multiplier <br /> Status</p>
                  <label className="switch"><input type="checkbox"  name="avgSalTenorMultiplierStatus" onChange={this.props.toggleChange.bind(this)} checked={this.props.data[0].avgSalTenorMultiplierStatus} /><div className="slider round" ></div></label>
                </div>
              </div>

              <div className="p-2 headertext">
                <p className="textposition">Average Salary - Tenor Multiplier</p>
                <p className="textlabel">Average Monthly Salary Bands</p>
              </div>

            </div>

            <div className="d-flex flex-row mt-3 padmar">
              <div className="p-2 underwritepart3">
                <div className="form-group">
                  <label className="labelpt-8 maklabuw">Product</label>
                  {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled > {underWritingRules.productName} </label></div>)}
                </div>
              </div>





              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {"<"}200K</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isAvgSalaryMultLT200000list[i] }} name="avgSalaryMultLT200000" onChange={(e) => { this.props.handleChangeavgSalaryMultLT200000(e, i) }} placeholder={underWritingRules.avgSalaryMultLT200000} disabled={this.props.avgSalTenorMultiplierStatusdisable}>
                      <option value={underWritingRules.avgSalaryMultLT200000}>{underWritingRules.avgSalaryMultLT200000}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>200K – 500K</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isAvgSalaryMultBET200000n500000list[i] }} name="avgSalaryMultBET200000n500000" onChange={(e) => { this.props.handleChangeavgSalaryMultBET200000n500000(e, i) }} placeholder={underWritingRules.avgSalaryMultBET200000n500000} disabled={this.props.avgSalTenorMultiplierStatusdisable}>
                      <option value={underWritingRules.avgSalaryMultBET200000n500000}>{underWritingRules.avgSalaryMultBET200000n500000}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>500K - 1M</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isAvgSalaryMultBET500001n1000000list[i] }} name="avgSalaryMultBET500001n1000000" onChange={(e) => { this.props.handleChangeavgSalaryMultBET500001n1000000(e, i) }} placeholder={underWritingRules.avgSalaryMultBET500001n1000000} disabled={this.props.avgSalTenorMultiplierStatusdisable}>
                      <option value={underWritingRules.avgSalaryMultBET500001n1000000}>{underWritingRules.avgSalaryMultBET500001n1000000}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>1M - 2M</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isAvgSalaryMultBET1000001n2000000list[i] }} name="avgSalaryMultBET1000001n2000000" onChange={(e) => { this.props.handleChangeavgSalaryMultBET1000001n2000000(e, i) }} placeholder={underWritingRules.avgSalaryMultBET1000001n2000000} disabled={this.props.avgSalTenorMultiplierStatusdisable}>
                      <option value={underWritingRules.avgSalaryMultBET1000001n2000000}>{underWritingRules.avgSalaryMultBET1000001n2000000}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {">"}2M </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isAvgSalaryMultGT2000000list[i] }} name="avgSalaryMultGT2000000" onChange={(e) => { this.props.handleChangeavgSalaryMultGT2000000(e, i) }} placeholder={underWritingRules.avgSalaryMultGT2000000} disabled={this.props.avgSalTenorMultiplierStatusdisable}>
                      <option value={underWritingRules.avgSalaryMultGT2000000}>{underWritingRules.avgSalaryMultGT2000000}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>




              <div className="p-2 underwrite">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>Bank Staff</label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                    <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isAvgSalTenorBankStaffvaluelist[i] }} name="avgSalaryMultLT200000" onChange={(e) => { this.props.handleChangeavgSalTenorBankStaffvalue(e, i) }} placeholder={underWritingRules.avgSalTenorBankStaffvalue} disabled={this.props.avgSalTenorMultiplierStatusdisable}>
                      <option value={underWritingRules.avgSalTenorBankStaffvalue}>{underWritingRules.avgSalTenorBankStaffvalue}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ReactTooltip id="noneditable" place="top" effect="solid">
                      Non Editable
                     </ReactTooltip>
                  </div>)}
                </div>
              </div>
            </div>
          </div>


        </form>



      </div>
    )

  }


}

export default UndWrittingPart3;
