/* React packages */
import React from "react";

import Montralogo from "../../assets/paylink-images/montra/newml.svg";
import { Link, NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";

/**
 *  Header component is render top navigation in Credit-UW section.
 * <h6>Shows all sections</h6>
 * <li>Moneylink logo</li>
 * <li>User Name</li>
 * <li>User Type</li>
 * <li>Log out button</li>
 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li><b>Navbar.Brand :- </b>navbar-brand class is applied to typical Moneylink logo so we can  see in the top navigation bar.</li>
 * <li><b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li><b>NavLink:- </b> it will add styling attributes to the rendered element when it matches the current URL.</li>
 * <li><b>btn btn-outline-danger :-</b> it is used for buttons</li>
  @Author : Seema Kumari
  @version :1.0
 */

const Header = () => {
  return (
    <div className="montra-main-header">
      <Navbar
        className="navbar navbar-expand-lg navbar-light bg-light"
        bg="light"
        expand="lg"
      >
        <div className="toplogoml">
          <img className="toplogo" src={Montralogo} alt="logo" />
        </div>
        <Nav className=" mr-auto montralendingheader">
          <ul className="navbarnav">
            <li className="navbarlink">
              Name :{" "}
              <span className="titlename">{localStorage.getItem("name")} </span>
            </li>
            <li className="navbarlink">
              User Type : <span className="titlename"> {"Credit UW"} </span>
            </li>
            <li className="navbarlink2">
              Partner <br />{" "}
              <img
                className="imagepartner"
                src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Fheader-logo.png?alt=media&token=3ee60d83-df6b-496f-b691-8892172c6dba"
                alt="image"
              />
            </li>
          </ul>
          {/* <div className='montra-logout-user'>
   <Nav.Link as={NavLink} to="PartnerLogin" type="" className="btn btn-outline-danger logout">Log Out</Nav.Link>
   </div> */}
        </Nav>

        {/* logout button :- user can exist his profile after clicking the logout button */}
        <Nav className="montra-logout-user">
          <Nav.Link
            as={NavLink}
            to="PartnerLogin"
            type=""
            className="btn btn-outline-danger logout"
          >
            Log Out
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
};

export default Header;
