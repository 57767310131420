/* React packages and components */

import React from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { AiOutlineInfoCircle } from "react-icons/ai";
import "carbon-components/css/carbon-components.min.css";
import FooterAdmin from "../Footer/FooterAdmin";
import CommonHeader from "../header/CommonHeader";
import { NavLink } from "react-router-dom";
import { Tooltip } from "carbon-components-react";

/**
 * LoanApproved component is render all data for Credit-UW Loan Approved section.
 * <li> "CREDIT - UW" can Click on "Loan Approved" section, which contains all the loans which are approved by CREDIT - UW  and click on "View Details" for a particular request.  </li>

 <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>ReactReactTooltip  :-</b> This ReactTooltip  is a small pop up that appears when user places the mouse pointer over an element such as link or buttons to provide hint or information about the element being hovered.</li>

 * @Author : Seema Kumari
 * @version :1.0

 */

class LoanApproved extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [
        {
          loanId: "",
          customerName: "",
          payLinkId: "",
          loanProductName: "",
          customerType: "",
          bankAcctNumber: "",
          bankName: "",
          bankCustomerId: "",
          amountRequested: "",
          loanTenor: "",
          interestRate: "",
        }, // {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // }, {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // }, {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // }, {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // }, {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // }, {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // }, {
        //   loanId: '', customerName: '', payLinkId: '', loanProductName: '', customerType: '', bankAcctNumber: '', bankName: '', bankCustomerId: '', amountRequested: '', loanTenor: '', interestRate: '',
        // },
      ],
      errors: [],
    };
  }
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/credituw/getloanapplications?"
    );
    fetch(
      url + "email=" + localStorage.getItem("email") + "&loanstatus=approved",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert("server error");
        }
      })
      .then((contents) => {
        if (200 == contents.status && "SUCCESS" == contents.message) {
          this.setState({ data: contents.data });
        } else {
          this.setState({ errors: contents.errors });
          this.state.errors.map((key) => {});
        }
      });
  }
  render() {
    let j = 0;
    if (
      localStorage.getItem("isLoggedIn") &&
      "CREDIT-UW" == localStorage.getItem("role")
    ) {
      return (
        <div>
          <CommonHeader />

          <div id="wrapper">
            {/* header menu */}
            <Sidebar />
            <div id="maincontent" className="content">
              <section id="content-wrapper">
                <div className="backloan2">
                  <div className="container-fluid loanrequest">
                    <p className="manageheader">Loan Approved</p>
                    {this.state.data.map((credituw, i) => (
                      <div key={i} className="row loanback">
                        <div className="col-md-12 reducepaddingfirst">
                          <form
                            method="post"
                            name="userRegistrationForm"
                            className="formroot"
                          >
                            <div className="d-flex flex-row  ">
                              <div className="p-2 managesn">
                                <p className="productheadersales cu6">S. No.</p>
                                <div className="form-group loanserialno">
                                  <div className="serialcuw">
                                    <p className="serial">{++j}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <p className="productheadersales cu3">
                                  Loan ID
                                </p>
                                <div className="form-group loanid">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.loanId}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <p className="productheadersales cu5">
                                  Customer Name
                                </p>
                                <div className="form-group loangroup1">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.customerName}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <p className="productheadersales cu1">
                                  PayLink ID
                                </p>
                                <div className="form-group loangroup2">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.payLinkId}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <p className="productheadersales cu">
                                  Customer Type
                                </p>
                                <div className="form-group loangroup1">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.customerType}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <div className="toolinfo">
                                  <p className="productheadersales cu4">
                                    Bank A/C Number{" "}
                                  </p>
                                  {/* <ReactReactTooltip 
                                    className="toolt"
                                    direction="left"
                                    align="center"
                                  >
                                    <span> Salary / Primary account</span>
                                  </ReactReactTooltip > */}
                                  {/* <a id="my-anchor-element">
                                    <AiOutlineInfoCircle />
                                  </a>
                                  <ReactTooltip
                                    anchorSelect="#my-anchor-element"
                                    content="Salary / Primary account"
                                  /> */}
                                  <Tooltip
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    Salary / Primary account
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.bankAcctNumber}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <div className="toolinfo">
                                  <p className="productheadersales bankname">
                                    Bank Name{" "}
                                  </p>
                                  {/* <ReactReactTooltip 
                                    className="toolt"
                                    direction="left"
                                    align="center"
                                  >
                                    <span>
                                      {" "}
                                      Where customer’s Salary / Primary bank
                                      account{" "}
                                    </span>
                                  </ReactReactTooltip > */}
                                  {/* <a id="my-anchor-element">
                                    <AiOutlineInfoCircle />
                                  </a>
                                  <ReactTooltip
                                    anchorSelect="#my-anchor-element"
                                    content="Where customer’s Salary / Primary bank
                                      account"
                                  /> */}
                                  <Tooltip
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    Where customer’s Salary / Primary bank
                                    account
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.bankName}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="col-md-12 reducepaddingfirst">
                          <form
                            method="post"
                            name="userRegistrationForm"
                            className="formroot"
                          >
                            <div className="d-flex flex-row  ">
                              <div className="p-2 managesntwo">
                                <p className="productheadersales"></p>
                                <div className="form-group loanserialno">
                                  <div>
                                    <p className="serial margn"></p>
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <p className="productheadersales">
                                  Bank Customer ID
                                </p>
                                <div className="form-group loangroup">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.bankCustomerId}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="p-2 managecus">
                                <div className="toolinfo">
                                  <p className="productheadersales">
                                    Product Name{" "}
                                  </p>
                                  {/* <ReactReactTooltip 
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    <span> Product for loan request </span>
                                  </ReactReactTooltip > */}
                                  {/* <a id="my-anchor-element">
                                    <AiOutlineInfoCircle />
                                  </a>
                                  <ReactTooltip
                                    anchorSelect="#my-anchor-element"
                                    content="Product for loan request"
                                  /> */}
                                  <Tooltip
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    Product for loan request
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.loanProductName}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="p-2 managecus">
                                <p className="productheadersales">
                                  Amount Requested
                                </p>
                                <div className="form-group loangroupuw">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.amountRequested}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 managecus">
                                <div className="toolinfo">
                                  <p className="productheadersales">
                                    Interest Rate{" "}
                                  </p>
                                  {/* <ReactReactTooltip 
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    <span>
                                      {" "}
                                      Approved by PayLink as per RAC rules of
                                      the lender{" "}
                                    </span>
                                  </ReactReactTooltip > */}
                                  {/* <a id="my-anchor-element">
                                    <AiOutlineInfoCircle />
                                  </a>
                                  <ReactTooltip
                                    anchorSelect="#my-anchor-element"
                                    content=" Approved by PayLink as per RAC rules of
                                      the lender"
                                  /> */}
                                  <Tooltip
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    Approved by PayLink as per RAC rules of the
                                    lender
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.interestRate}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="p-2 managecus">
                                <div className="toolinfo">
                                  <p className="productheadersales loanname">
                                    Loan Tenor{" "}
                                  </p>
                                  {/* <ReactReactTooltip 
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    <span>
                                      {" "}
                                      Approved by PayLink as per RAC rules of
                                      the lender{" "}
                                    </span>
                                  </ReactReactTooltip > */}
                                  {/* <a id="my-anchor-element">
                                    <AiOutlineInfoCircle />
                                  </a>
                                  <ReactTooltip
                                    anchorSelect="#my-anchor-element"
                                    content=" Approved by PayLink as per RAC rules of
                                      the lender"
                                  /> */}
                                  <Tooltip
                                    className="toolt"
                                    direction="top"
                                    align="center"
                                  >
                                    Approved by PayLink as per RAC rules of the
                                    lender
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  <div className="inner-addon right-addon">
                                    <input
                                      type="text"
                                      className="form-control formproduct margn"
                                      name=""
                                      placeholder={credituw.loanTenor}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="p-2 manageptuw">
                                <p className="productheadersales">
                                  View Details for Decision
                                </p>
                                <div className="form-group loangroup3">
                                  <div className="inner-addon right-addon">
                                    <Link
                                      to="/ViewDetails"
                                      state={{
                                        loanId: this.state.data[i].loanId,
                                        loantype: "LoanApproved",
                                        status: false,
                                        statusapproval: false,
                                        statusdisable: true,
                                      }}
                                    >
                                      {" "}
                                      <span>
                                        <i className="far fa-file-alt"></i>
                                      </span>{" "}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* <FooterAdmin /> */}
        </div>
      );
    } else {
      return <p>Please Login AS CREDITUW</p>;
    }
  }
}

export default LoanApproved;
