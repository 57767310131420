import React from "react";
import "./style.css";
import loan1 from "../../../../assets/images/loanorig.svg";
import loanm from "../../../../assets/images/loanorigm.svg";
import loan2 from "../../../../assets/images/mobileloanapp.svg";
import loan3 from "../../../../assets/images/Automatedun.svg";
import loan4 from "../../../../assets/images/Reduce-Tasks.svg";
import loan5 from "../../../../assets/images/efficientw.svg";
import loan6 from "../../../../assets/images/CompliantD.svg";
import loan7 from "../../../../assets/images/consumer.svg";
import loanmgn1 from "../../../../assets/images/loanmanage.svg";
import loanmgn2 from "../../../../assets/images/loanmgn2.svg";
import loanmgn3 from "../../../../assets/images/loanmgn3.svg";
import loanmgn4 from "../../../../assets/images/loanmgn4.svg";
import loanmgn5 from "../../../../assets/images/loanmgn5.svg";
import loanmgn6 from "../../../../assets/images/loanmgn6.svg";
import loanorig1 from "../../../../assets/images/loanorig1.svg";
import loanorig2 from "../../../../assets/images/loanorig2.svg";
import loanorig3 from "../../../../assets/images/loanorig3.svg";
import mlportal from "../../../../assets/images/mlportal.svg";
import mlportal1 from "../../../../assets/images/mlportal1.svg";
import mlportal3 from "../../../../assets/images/mlportal3.svg";
import mlportal4 from "../../../../assets/images/mlportal4.svg";
import mlportal5 from "../../../../assets/images/mlportal5.svg";
import mlportal6 from "../../../../assets/images/mlportal6.svg";
import mlportal2 from "../../../../assets/images/mlportal2.svg";
import wml1 from "../../../../assets/images/wml1.svg";
import wml2 from "../../../../assets/images/wml2.svg";
import wml3 from "../../../../assets/images/wml3.svg";
import wml4 from "../../../../assets/images/wml4.svg";
import wml5 from "../../../../assets/images/wml5.svg";
import wml6 from "../../../../assets/images/wml6.svg";
import wml7 from "../../../../assets/images/wml7.svg";
import wml8 from "../../../../assets/images/wml8.svg";


const Body = () => {
  return (
    <>
      <div className="lm">
        <div className="lm1 loansyst">
          <div className="row personalb">
            <div className="col-xs-12 col-sm-6  col-xl-3">
              <div className="lmm">
                <div className="lineparagrap">
                  <h6>Loan Origination</h6>
                </div>
                <p>
                  Our API can help you offer a variety of loan products on Lender’s Mobile Banking
                  app to their customers. MoneyLink underwrites these loans instantly based on the
                  templatized rules that you set-up on our Portal. MoneyLink platform can instantly
                  disburse the approved loans.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="lmm">
                <div className="lineparagrap">
                  <h6>Loan Management</h6>
                </div>
                <p>
                  MoneyLink cloud-based Loan Management System manages Loan Accounts
                  for each of the disbursed loans and helps Lenders manage the loan
                  over its life-cycle.
                </p>
              </div>
            </div>
            <div className=" col-sm-6 col-xl-3">
              <div className="lmm">
                <div className="lineparagrap">
                  <h6>Loan Collection</h6>
                </div>
                <p>
                  MoneyLink undertakes the collection processes on behalf of the
                  Lenders based on the rules set-up by them or the  Portal, both for
                  Due as well as Delinquent amounts. MoneyLink platform not just
                  collects from the linked settlement accounts but also from all
                  other accounts that customer has across multiple banks.
                </p>
              </div>
            </div>
            <div className=" col-sm-6  col-xl-3">
              <div className="lmm">
                <div className="lineparagrap">
                  <h6>MoneyLink Portal</h6>
                </div>
                <p>
                  Lenders can set-up underwriting & collection rules besides accessing a variety of
                  dashboards to analyze and manage the Loan Portfolio.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lm2">
          <div className="cip-body-1">
            <h1>Next-Gen Digital Lending Platform & Ecosystem</h1>
            <div className="loanstep1">
              <div className="credit-feature loanorigstep1">
                <div>
                  <img src={loan1} alt="" />
                </div>
                <div>
                  <span className="loanorign">Loan Origination</span>
                </div>
              </div>

              <div className="row personalb">
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loan2} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Mobile Loan Applications</h6>
                    </div>
                    <p>
                      Providing a convenient loan application experience for your customers. </p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loan3} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Automated Underwriting</h6>
                    </div>
                    <p>
                      Helps you to make faster consumer loan decisions for Instant Disbursements. </p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loan4} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Reduce Tasks</h6>
                    </div>
                    <p>
                      Automated processes help your lending team spend more time with customers. </p>
                  </div>
                </div>
              </div>

              <div className="row personalb">
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loan5} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Efficient Workflows</h6>
                    </div>
                    <p>
                      Clean up the back office with efficiency-focused workflows. </p>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loan6} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Compliant Documentation</h6>
                    </div>
                    <p>
                      Digital  compliant documentation, which is easily accessible, whenever you need . </p>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loan7} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">The Complete end-to-end Consumer Loan Solution</h6>
                    </div>
                    <p>
                      A complete end-to-end solution tailored to the needs of you and your customer. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="loanstep1">
              <div className="credit-feature loanorigstep1">
                <div>
                  <img src={loanmgn1} alt="" />
                </div>
                <div>
                  <span className="loanorign">Loan Management</span>
                </div>
              </div>

              <div className="row personalb">
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanmgn2} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Loan Account Management</h6>
                    </div>
                    <p>
                      Instantly create loan accounts for disbursed loan to manage them over its life-cycle. </p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanmgn3} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Loan Account Statement</h6>
                    </div>
                    <p>
                      Easy access to loan account statement to track repayment & collection schedule. </p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanmgn4} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Manage Loan Disbursements</h6>
                    </div>
                    <p>
                      Instant loan disbursements to Digital loans and straight through disbursements to the others approved through work-flow. </p>
                  </div>
                </div>
              </div>

              <div className="row personalb">
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanmgn5} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Manage & Automate Repayments</h6>
                    </div>
                    <p>
                      Easy options for customers to make repayments on the due date or collect the same on the due date from the linked accounts. </p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanmgn6} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Reprice / Restructure Loans</h6>
                    </div>
                    <p>
                      Reprice or Restructure loan easily at individual loan or at portfolio level. </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="loanstep1">
              <div className="credit-feature loanorigstep1">
                <div>
                  <img src={loanorig1} alt="" />
                </div>
                <div>
                  <span className="loanorign">Loan Collection</span>
                </div>
              </div>
              <div className="row personalb">
                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanorig2} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Loan Portfolio Management</h6>
                    </div>
                    <p>
                      MoneyLink provides detailed dashboards for Lenders to review loan portfolio for tracking revenue & delinquency. </p>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 col-xl-4">
                  <div className="loanorigp">
                    <div className="loanchildicon">
                      <img src={loanorig3} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Collection of Delinquent Loans</h6>
                    </div>
                    <p>
                      MoneyLink helps the Lenders collect systemically the delinquent amounts from the linked settlement accounts as well as all other bank accounts in the Industry. </p>
                  </div>
                </div>
              </div>
            </div>



          </div>
          <div className="cip-body-2">
            <div className="credit-feature loanorigstep1">
              <div>
                <img src={loanm} alt="" />
              </div>
              <div>
                <span className="loanorign">MoneyLink Portal</span>
              </div>
            </div>
            <div className="row personalb">
              <div className="col-sm-6  col-xl-4">
                <div className="card-type2">
                  <div className="card-typeinside">
                    <div className="loanchildicon">
                      <img src={mlportal1} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Create Lender’s profile</h6>
                    </div>
                    <p>
                      Lenders can create profile by deciding the products that they want to lend and by creating the under-writing rules from the easy-to-follow pre-set templates. </p>
                  </div>

                  <div>
                    <div className="loanchildicon">
                      <img src={mlportal2} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Workflow Loan Underwriting</h6>
                    </div>
                    <p>
                      Underwriters profiled by Lenders can access the loans approved by MoneyLink Cloud for
                      further approval for the loans not eligible for Digital under-writing & Disbursement. </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  col-xl-4">
                <div className="card-type2">
                  <div className="card-typeinside">
                    <div className="loanchildicon">
                      <img src={mlportal3} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Set-up Loan GLs</h6>
                    </div>
                    <p>
                      Lenders can set-up Loan GLs for MoneyLink to automate the loan disbursal, Fee, Interest and other income collection, collection of charges, principal amount repayments. </p>
                  </div>

                  <div>
                    <div className="loanchildicon">
                      <img src={mlportal4} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">Checker for Instant loan disbursement</h6>
                    </div>
                    <p>
                      Loans approved by MoneyLink Cloud for instant disbursement, go through systemic checking process before the disbursement. </p>
                  </div>

                </div>
              </div>
              <div className="col-sm-6  col-xl-4">
                <div className="card-type2">
                  <div className="card-typeinside">
                    <div className="loanchildicon">
                      <img src={mlportal5} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">User Creation</h6>
                    </div>
                    <p>
                      Admin users for each Lender can create Users for various roles such as Sales, Under-writer, Finance, Product Manger etc. with a Maker & Checker approval process. </p>
                  </div>

                  <div>
                    <div className="loanchildicon">
                      <img src={mlportal6} alt="" />
                    </div>
                    <div className="lineparagrap">
                      <h6 className="loanheader">View portfolio dashboards</h6>
                    </div>
                    <p>
                      Lenders can view the loan portfolio dashboards to track revenue & loan delinquencies. </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div className="why-moneylink loanmoneywhy">
        <h1>Why MoneyLink?</h1>
        <div className="prepaid-card why-moneylink-body">
          <div className="cip-cards">
            <div className="cip-card-1">
              <div className="cip-card">
                <img src={wml1} alt="" />
                <div className="lineparagrap">
                  <h6>Cloud-Based</h6>
                </div>
                <p>
                  No need to buy or maintain servers and infrastructure. Our cloud service is fast, secure, and grows with you.
                </p>
              </div>
              <div className="cip-card">
                <img src={wml2} alt="" />
                <div className="lineparagrap">
                  <h6>Flexible</h6>
                </div>
                <p>
                  Quick and easy to set up, configure, and change. Customize at the flick of a switch.
                </p>
              </div>
              <div className="cip-card">
                <img src={wml3} alt="" />
                <div className="lineparagrap">
                  <h6>Easy to integrate</h6>
                </div>
                <p>
                  Use only the elements you need and plug in any third-party applications you want to use using our powerful APIs.

                </p>
              </div>
              <div className="cip-card">
                <img src={wml4} alt="" />
                <div className="lineparagrap">
                  <h6>Great support</h6>
                </div>
                <p>You probably won’t need it much because MoneyLink is simple and intuitive to use, but our support team is always here to help you, and help you grow.</p>
              </div>
            </div>
            <div className="cip-card-2">
              <div className="cip-card">
                <img src={wml5} alt="" />
                <div className="lineparagrap">
                  <h6>Always up-to-date</h6>
                </div>
                <p>
                  We’re constantly making improvements and adding new features. And because MoneyLink is in the cloud, you get them automatically.
                </p>
              </div>
              <div className="cip-card">
                <img src={wml6} alt="" />
                <div className="lineparagrap">
                  <h6>Real-time</h6>
                </div>
                <p>
                  Instant updates mean every transaction is immediately visible throughout the system.
                  Reassuring for your customers and powerful for your managers.
                </p>
              </div>
              <div className="cip-card">
                <img src={wml7} alt="" />
                <div className="lineparagrap">
                  <h6>Affordable</h6>
                </div>
                <p>
                  Our simple, subscription-based model means economical pricing and costs you can predict.
                </p>
              </div>
              <div className="cip-card">
                <img src={wml8} alt="" />
                <div className="lineparagrap">
                  <h6>Robust Security</h6>
                </div>
                <p>
                  MoneyLink loan management system underwrites loan with double layer of security
                  of Maker & Checker concept of underwriting done systemically to eliminate errors and reduce risk.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
