import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import appstore from "../../assets/images/appstoreblack.png";
import playstore from "../../assets/images/playstore.png";
import logo from "../../assets/images/montrafooter.svg";
import arrow1 from "../../assets/images/arrow1.svg";


const Main = () => {
  return (
    <>
      <div className="mainfooter-page">
        <section className="footer">
          {/* <div className="footer-box">
          <div className="row footer-boxes">
            <div className="col footer-box1">
              <div className="download-montra">
                <p>Download Montra</p>
                <span>Using any of your preferred app store</span>

                <div className="app-download">
                  <img src={playstore} alt="image" />
                  <img src={appstore} alt="image" />
                </div>
              </div>
            </div>
            <div className="col footer-box2">
              <p>Start Innovating with Montra as your partner today</p>
              <span>Select on preferred app device to download Montra</span>
              <button>Contact us</button>
            </div>
          </div>
        </div> */}


          <div className="footer-box">
            <div className="row footer-boxes">
              <div className="col footerdown">
                <div className="download-montra">
                  <p>Download Montra <br />
                    <span> Using any of your preferred app store</span></p>

                  <div className="app-download ">
                    <img src={playstore} alt="image" />
                    <img src={appstore} alt="image" />
                  </div>
                </div>
              </div>
              {/* <div className="col footer-box2">
              <p>Start Innovating with Montra as your partner today</p>
              <span>Select on preferred app device to download Montra</span>
              <button>Contact us</button>
            </div> */}
            </div>
          </div>
          <div className="footer-container footer-links">

            <div className="footer-first-section footer-section">
              <Link to="/" > <img src={logo} alt="image" />
              </Link>
              <p>
                <i class="bi bi-cursor-fill"></i> Montra, Inc. (United States)
              </p>
              <p>
                Montra introduces innovative products & solutions that promote
                Financial Inclusion across emerging economies towards transition
                from cash to digital payments & making accessibility to Credit
                easy for everyone
              </p>
            </div>
            <div className="footersecRow footer-section">
              <h6>Products</h6>
              <span>Personal</span>
              <ul>

                <Link to="/Products/Banking#Banking"
                  className="nav-link main-active"
                >
                  <li>Banking</li>
                </Link>
                <Link to="/Products/Payment#Payments"
                  className="nav-link main-active"
                >
                  <li>Payment</li>
                </Link>
                <Link to="/Products/Finance#Finance"
                  className="nav-link main-active"
                >
                  <li>Finance</li>
                </Link>
                <Link to="/Products/Chat#Chat"
                  className="nav-link main-active"
                >
                  <li>Chat</li>
                </Link>
                <Link to="/Products/Shop#Shopping"
                  className="nav-link main-active"
                >
                  <li>Shopping</li>
                </Link>
                <Link to="/Products/overview#Overview"
                  className="nav-link main-active"
                >
                  <li>Lending</li>
                </Link>
              </ul>

              <span>Business</span>
              <ul>
                <Link to="/Business/Payment#Payments"
                  className="nav-link main-active"
                >
                  <li>Payment</li>
                </Link>
                <Link to="/Business/Banking#Banking"
                  className="nav-link main-active"
                >
                  <li>Banking</li>
                </Link>

                <Link to="/Business/AgencyBanking#AgencyBanking"
                  className="nav-link main-active"
                >
                  <li>Agency Banking </li>
                </Link>
                {/* <Link to="/Business/Banking"
                  className="nav-link main-active"
                >
                  <li>Lending</li>
                </Link> */}
                <Link to="/Business/more/TapPay#TapPay"
                  className="nav-link main-active"
                >
                  <li>Tap & Pay </li>
                </Link>
                <Link to="/Business/more/Inventory#Inventory"
                  className="nav-link main-active"
                >
                  <li>Inventory Management</li>
                </Link>
                <Link
                  to="/Business/more/Store#Montra-Store"
                  className="nav-link main-active"
                >
                  <li>Montra Store</li>
                </Link>
                <Link
                  to="/Business/more/Gateway#PaymentGateway"
                  className="nav-link main-active"
                >
                  <li>Payment Gateway</li>
                </Link>
              </ul>
              <div className="responsivefourthfootermenu">
                <h6>Platforms</h6>
                <span>MoneyLink</span>
                <ul>
                  <Link to="/Platform/Moneylink/cip#CardIssuing" className="nav-link main-active">
                    <li>Card Issuing</li>
                  </Link>
                  <Link to="/Platform/Moneylink/merchant#merchant" className="nav-link main-active">
                    <li>Merchant Services</li>
                  </Link>
                  <Link to="/Platform/Moneylink/Accountmanagement#AccountManagement" className="nav-link main-active">
                    <li>Account Management</li>
                  </Link>
                  <Link to="/Platform/Moneylink/Loanmanagement#LoanManagementSystem" className="nav-link main-active">
                    <li>Loan Management System</li>
                  </Link>
                  <Link to="/Platform/Moneylink/Debtcollections#collection" className="nav-link main-active">
                    <li>Debt Collections</li>
                  </Link>
                </ul>
                <h6 className="businessfooterspan">Company</h6>
                <ul>
                  <Link to="/Company/Overview" className="nav-link main-active">
                    <li>About Montra</li>
                  </Link>
                  <Link to="/Company/Ourstory" className="nav-link main-active">
                    <li>Our Story</li>
                  </Link>
                </ul>

              </div>

            </div>
            <div className="footerthirdRow footer-section">
              <h6>Solutions</h6>
              <span>Duo</span>
              <ul>
                <Link
                  to="/Solutions/Duo/Dts#DTS" className="nav-link main-active">
                  <li>Dual Transaction Service - DTS</li>
                </Link>
                <Link to="/Solutions/Duo/Dcp#DCP" className="nav-link main-active">
                  <li>Duo Card Product - DCP</li>
                </Link>
                <Link to="/Solutions/Duo/Duoapp#DuoApp" className="nav-link main-active">
                  <li>DUO App</li>
                </Link>
                <Link to="/Solutions/Duo/Dwp#DuoWebPortal" className="nav-link main-active">
                  <li>DUO Web Portal</li>
                </Link>
                <Link to="/Solutions/Duo/Customeronboarding#DuoCustomerOnboarding" className="nav-link main-active">
                  <li>DUO Customer Onboarding</li>
                </Link>
                <Link to="/Solutions/Duo/Dlm#LimitManagement" className="nav-link main-active">
                  <li>Limit Management</li>
                </Link>
                <Link to="/Solutions/Duo/Collection#Collections" className="nav-link main-active">
                  <li>Collections</li>
                </Link>
              </ul>

              <span>Verified</span>
              <ul>
                <Link to="/Solution/Verified/Authapp#AuthenticatorApp" className="nav-link main-active">
                  <li>Authenticator App</li>
                </Link>
                <Link to="/Solution/Verified/Faceauth#FaceAuthentication" className="nav-link main-active">
                  <li>Face Authentication</li>
                </Link>
                <Link to="/Solution/Verified/Faceauth#FaceAuthentication" className="nav-link main-active">
                  <li>KYC ID Verification</li>
                </Link>
                <Link to="/Solution/Verified/Faceauth#FaceAuthentication" className="nav-link main-active">
                  <li>Video KYC Solution</li>
                </Link>
              </ul>

              <span>Supply Chain</span>
              <ul>
                <Link to="/Solutions/Supplychain/payment#supplypayments" className="nav-link main-active">
                  <li>Payments</li>
                </Link>
                <Link to="/Solutions/Supplychain/collection#supplycollections" className="nav-link main-active">
                  <li>Collections</li>
                </Link>
                <Link to="/Solutions/Supplychain/Sales#salespromotions" className="nav-link main-active">
                  <li>Sales Promotions</li>
                </Link>
                <Link to="/Solutions/Supplychain/Orderfulfilment#OrderFulfilment" className="nav-link main-active">
                  <li>Order Fulfilment</li>
                </Link>
                <Link to="/Solutions/Supplychain/Distributionanalytics#Distributionanalytics" className="nav-link main-active">
                  <li>Distribution Analytics</li>
                </Link>
              </ul>
            </div>
            <div className="footerFourthRow footer-section responsivefourthfooter">
              <h6>Platforms</h6>
              <span>MoneyLink</span>
              <ul>
                <Link to="/Platform/Moneylink/cip#CardIssuing" className="nav-link main-active">
                  <li>Card Issuing</li>
                </Link>
                <Link to="/Platform/Moneylink/merchant#merchant" className="nav-link main-active">
                  <li>Merchant Services</li>
                </Link>
                <Link to="/Platform/Moneylink/Accountmanagement#AccountManagement" className="nav-link main-active">
                  <li>Account Management</li>
                </Link>
                <Link to="/Platform/Moneylink/Loanmanagement#LoanManagementSystem" className="nav-link main-active">
                  <li>Loan Management System</li>
                </Link>
                <Link to="/Platform/Moneylink/Debtcollections#collection" className="nav-link main-active">
                  <li>Debt Collections</li>
                </Link>
              </ul>
              <h6 className="companyfootertext">Company</h6>
              <ul>
                <Link to="/Company/Overview" className="nav-link main-active">
                  <li>About Montra</li>
                </Link>
                <Link to="/Company/Ourstory" className="nav-link main-active">
                  <li>Our Story</li>
                </Link>
                {/* <li>Montra Press</li>
              <li>Become a Partner</li> */}
              </ul>

              {/* <h6>Resources</h6>
              <ul>
                <li>Demo Montra App</li>
                <li>Explainer Video</li>
                <li>Developers</li>
              </ul>

              <h6>Help</h6>
              <ul>
                <li>FAQ</li>
                <li>Support</li>
              </ul>

              <h6>Contact</h6>
              <ul>
                <li>hi@montra.ng</li>
              </ul> */}

            </div>
          </div>


        </section>
      </div>
    </>
  );
};

export default Main;
