/* React packages and components */
import React from 'react';
import Otp from './Otp';
import { Link, NavLink } from 'react-router-dom';
import moneylinkLogo from "../assets/images/Moneylink-logo.svg";

/**
 * Renders the signup Screen of MoneyLink.
 * <p>It is used to capture the details of user who is trying to sign up.</p>
 * <p>Validates all the fields.</p>
 * <p>By clicking on signup button it sends all data to server.</p>
 <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
 * <li> <b>select :-</b> This tag is use for element is used to create a drop-down list. </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>

 * @Author : Seema Kumari
 * @version :1.0
 */

class PartnerSignup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                officialEmail: '',
                registerpassword: '',
                password: ''
            },
            errors: {},
            status: '',
            message: '',
            backerrors: [],
            isActive: false,
            errormsg: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleShow = () => {
        this.setState({
            isActive: true
        });
    };
    /**
   *captures the values entred by user.
  * @param {Event} e event which calls this method.
*/
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    /**
    *Submits the data entered by user.
    * @param {Event} e event which calls this method.
    */
    handle2Submit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = this.state.fields;
            localStorage.setItem("email", this.state.fields.officialEmail)
            let proxyurl = 'https://cors-anywhere.herokuapp.com/';
            let url = 'https://api.montra.org/moneylinkweb/api/user/signup';
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(fields),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    else
                        return 'server error'
                })
                .then(contents => {
                    if (200 == contents.status && "SUCCESS" == contents.message) {
                        this.setState({
                            status: contents.status,
                            message: contents.message
                        });
                        localStorage.setItem("logintype", "SIGNUP");
                    } else {
                        this.setState({
                            backerrors: contents.errors,
                            status: contents.status,
                            message: contents.message
                        });
                        if (this.state.backerrors.length > 0) {
                            this.state.backerrors.map(key => {
                                this.setState({
                                    errormsg: key.message
                                })
                            })
                        }
                        else {

                            this.setState({ errormsg: "Server Error" })
                        }
                    }
                })
                .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"))

        }
    };

    /**
     * Validates the data entered by the user to sign up.
     */
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields['officialEmail']) {
            formIsValid = false;
            errors['officialEmail'] = '*Please enter your official Email Address';
        }

        if (typeof fields['officialEmail'] !== 'undefined') {
            //regular expression for officialEmail validation
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(fields['officialEmail'])) {
                formIsValid = false;
                errors['officialEmail'] = '*Please enter valid official Email Address';
            }
        }

        if (!fields['registerpassword']) {
            formIsValid = false;
            errors['registerpassword'] = '*Please enter your password';
        }

        if (typeof fields['registerpassword'] !== 'undefined') {
            if (
                !fields['registerpassword'].match(
                    /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
                )
            ) {
                formIsValid = false;
                errors['registerpassword'] =
                    '*Please enter secure and strong password! example : Acde@123';
            }
        }

        if (!fields['password']) {
            formIsValid = false;
            errors['password'] = '*Please enter your password';
        }

        if (typeof fields['password'] !== 'undefined') {
            if (
                !fields['password'].match(
                    /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
                )
            ) {
                formIsValid = false;
                errors['password'] =
                    '*Please enter secure and strong password';
            }
        }

        if (
            (fields['password'] && fields['registerpassword']) !==
            'undefined' &&
            fields['password'] !== fields['registerpassword']
        ) {
            formIsValid = false;
            errors['password'] = "*Password doesn't match!!";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    render() {
        if (this.state.status == 200 && "SUCCESS" == this.state.message) {
            return (
                <div className='ml-background moneybgnew'>
                    <div className="main-header">
                        <div className="mneylink-log-header">
                            <img src={moneylinkLogo} />
                        </div>
                    </div>
                    <div className="">
                        <div className="container-fluid">
                            <div className="row login-2 ">
                                <div className="col-sm-12 col-md-3 col-xl-4">
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-4">
                                    <div className="partnerexist">
                                        <form method="post" name="userRegistrationForm" onSubmit={this.handle2Submit} >
                                            <p className="signupara4">New User of an existing Issuer Partner......</p>
                                            <div className="form-group">
                                                <label htmlFor="first" className='signin-label'>Partner Name</label>
                                                <select name="name" className="form-control leftalign" value={this.state.fields.partnername} onChange={this.handleChange}>
                                                    <option value="Select Partner">Select Partner</option>
                                                    <option value="Acess Bank">Access Bank</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="officialEmail" className='signin-label'>Your Official Email Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control partners-input"
                                                    id="officialEmail"
                                                    name="officialEmail"
                                                    value={this.state.fields.officialEmail}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="errorMsg">{this.state.errors.officialEmail}</div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="registerpassword" className='signin-label'>Password </label>
                                                <input
                                                    type="password"
                                                    className="form-control partners-input"
                                                    id="registerpassword"
                                                    name="registerpassword"
                                                    value={this.state.fields.registerpassword}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="errorMsg">{this.state.errors.registerpassword}</div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="password" className='signin-label'> Retype Password </label>
                                                <input
                                                    type="password"
                                                    className="form-control partners-input"
                                                    id="password"
                                                    name="password"
                                                    value={this.state.fields.password}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="errorMsg">
                                                    {this.state.errors.password}
                                                </div>
                                            </div>

                                            <div className="sign">
                                                <input
                                                    type="submit"
                                                    className="btn btn-warning partnerbutton"
                                                    onClick={this.handleShow}
                                                    value="Sign-up"
                                                />
                                            </div>
                                        </form>
                                        <br />
                                        {this.state.isActive ? (
                                            <div>
                                                {/* <Otp email={this.state.fields.officialEmail} /> */}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-3 col-xl-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className='ml-background moneybgnew'>
                        <div className="main-header">
                            <div className="mneylink-log-header">
                                <img src={moneylinkLogo} />
                            </div>
                        </div>
                        <div className="">
                            <div className="container-fluid">
                                <div className="row login-2 ">
                                    <div className="col-sm-12 col-md-3 col-xl-4">
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-4">
                                        <div className="partnerexist">
                                            <form method="post" name="userRegistrationForm" onSubmit={this.handle2Submit}   >
                                                <p className="para4">New User of an existing Issuer Partner..</p>
                                                <div className="form-group">
                                                    <label htmlFor="first" className='signin-label'>Partner Name</label>
                                                    <select name="name" className="form-control leftalign" value={this.state.fields.partnername} onChange={this.handleChange}>
                                                        <option value="Select Partner">Select Partner</option>
                                                        <option value="Acess Bank">Access Bank</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="officialEmail" className='signin-label'>Your Official Email Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control partners-input"
                                                        id="officialEmail"
                                                        name="officialEmail"
                                                        value={this.state.fields.officialEmail}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="errorMsg">{this.state.errors.officialEmail}</div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="registerpassword" className='signin-label'>Password </label>
                                                    <input
                                                        type="password"
                                                        className="form-control partners-input"
                                                        id="registerpassword"
                                                        name="registerpassword"
                                                        value={this.state.fields.registerpassword}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="errorMsg">{this.state.errors.registerpassword}</div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="password" className='signin-label'> Retype Password </label>
                                                    <input
                                                        type="password"
                                                        className="form-control partners-input"
                                                        id="password"
                                                        name="password"
                                                        value={this.state.fields.password}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.password}
                                                    </div>
                                                    {(() => {
                                                        switch (this.state.message) {
                                                            case "UNSUCCESS": return <p className="errorMsg">{this.state.errormsg}</p>
                                                            default: return <p></p>
                                                        }
                                                    })()}
                                                </div>

                                                <div className="sign">
                                                    <input
                                                        type="submit"
                                                        className="btn btn-warning partnerbutton"
                                                        onClick={this.handleShow}
                                                        value="Sign-up"
                                                    />
                                                    {(() => {
                                                        switch (this.state.message) {
                                                            case "SUCCESS": return <p>Signup Success</p>;
                                                            default: return <p></p>
                                                        }
                                                    })()}
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-3 col-xl-4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default PartnerSignup;
