/* React packages and components */
import React from "react";
import { Link, Navigate } from "react-router-dom";

/**
 * Render field to enter otp.
 * <p>Captures the otp enterer during login and sends it to server for verification.</p>
 * @Author : Seema Kumari
 * @version :1.0
 */

class Otp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                verifyotp: "",
            },
            errors: {},
            email: "",
            responsestatus: {
                status: "",
                timestamp: "",
                data: {},
                message: "",
                errorMsg: "",
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     *captures the otp entered by user.
     * @param {Event} e event which calls this method.
     */

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });
    }
    /**
     *Calls server to resend the while logging.
     * @param {Event} e event which calls this method.
     */
    resendOtp = (e) => {
        e.preventDefault();
        let url = new URL(
            "https://api.montra.org/moneylinkweb/api/user//sendotp" +
            "?" +
            "email=" +
            this.props.email
        );
        fetch(url, {
            method: "GET",
            headers: { "Content-Type": "application/json;charset=utf-8" },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert("server error");
                }
            })
            .then((contents) => {
                if (200 == contents.status && "SUCCESS" == contents.message) {
                    this.setState({
                        status: contents.status,
                        otpmessage: contents.message,
                    });
                } else {
                    this.setState({
                        backerrors: contents.errors,
                        status: contents.status,
                        otpmessage: contents.message,
                    });
                    this.state.backerrors.map((key) => {
                        this.setState({
                            errorMsg: key.message,
                        });
                    });
                }
            })
            .catch(() =>
                console.log("Can’t access " + url + " response. Blocked by browser?")
            );
    };
    /**
     *capyures the new password to server entered by user during login.
     * @param {Event} e event which calls this method.
     */
    handleOtp = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            // const proxyurl = "https://cors-anywhere.herokuapp.com/";

            let url = new URL(
                "https://api.montra.org/moneylinkweb/api/user/verifyotp" +
                "?" +
                "email=" +
                this.props.email +
                "&otp=" +
                this.state.fields.verifyotp
            );
            fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json;charset=utf-8" },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        alert("server error");
                    }
                })
                .then((contents) => {
                    if (200 == contents.status && "SUCCESS" == contents.message) {
                        this.setState({
                            role: contents.data.role,
                            status: contents.status,
                            message: contents.message,
                        });
                    } else {
                        this.setState({
                            backerrors: contents.errors,
                            status: contents.status,
                            message: contents.message,
                        });
                        this.state.backerrors.map((key) => {
                            this.setState({
                                errorMsg: key.message,
                            });
                        });
                    }
                })
                .catch(() =>
                    console.log("Can’t access " + url + " response. Blocked by browser?")
                );
        }
    };
    /**
     *capyures the new password to server entered by user during signup.
     * @param {Event} e event which calls this method.
     */
    handlesignupOtp = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            let url = new URL(
                "https://api.montra.org/moneylinkweb/api/user/verifysignupotp" +
                "?" +
                "email=" +
                this.props.email +
                "&otp=" +
                this.state.fields.verifyotp
            );
            fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json;charset=utf-8" },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        alert("server error");
                    }
                })
                .then((contents) => {
                    if (200 == contents.status && "SUCCESS" == contents.message) {
                        this.setState({
                            role: contents.data.role,
                            status: contents.status,
                            message: contents.message,
                        });
                    } else {
                        this.setState({
                            backerrors: contents.errors,
                            status: contents.status,
                            message: contents.message,
                        });
                        this.state.backerrors.map((key) => {
                            this.setState({
                                errorMsg: key.message,
                            });
                        });
                    }
                })
                .catch(() =>
                    console.log("Can’t access " + url + " response. Blocked by browser?")
                );
        }
    };
    /**
     * Validates all the fields
     */
    validateForm() {
        let fields = this.state.fields;

        let errors = {};
        let formIsValid = true;

        if (!fields["verifyotp"]) {
            formIsValid = false;
            errors["verifyotp"] = "*Please enter your OTP.";
        }

        if (typeof fields["verifyotp"] !== "undefined") {
        }

        this.setState({
            errors: errors,
        });
        return formIsValid;
    }

    render() {
        if (
            200 == this.state.status &&
            "SUCCESS" == this.state.message &&
            "SIGNIN" == localStorage.getItem("logintype")
        ) {
            switch (localStorage.getItem("role")) {
                case "CHECKER":
                    return <Navigate to="/MoneyLink/AdminChecker" />;
                case "MAKER":
                    return <Navigate to="/MoneyLink/AdminMaker" />;
                case "SALES":
                    return <Navigate to="/Sales" />;
                case "SALARY PROCESSOR":
                    return <Navigate to="/SalProcessor" />;
                case "CREDIT-UW":
                    return <Navigate to="/CreditUW" />;
                case "BUSINESS":
                    return <Navigate to="/MoneylinkBusiness" />;
                case "COLLECTION":
                    return <Navigate to="/Collection" />;
                case "OPERATIONS":
                    return <Navigate to="/Operations" />;
                case "NIBSS-COLLECTION":
                    return <Navigate to="/NibssCollection" />;
                case "SALARY PROCESSOR-EXTERNAL":
                    return <Navigate to="/SalProcessorLender" />;
                // default: return <Navigate to="PartnerLogin" />
            }
        } else if (
            200 == this.state.status &&
            "SUCCESS" == this.state.message &&
            "SIGNUP" == localStorage.getItem("logintype")
        ) {
            return <Navigate to="UserPin" />;
        } else if ("SIGNUP" == localStorage.getItem("logintype")) {
            return (
                <div>
                    {/* Form -: in this form section is use for otp field after submit the password and user id */}
                    <form
                        method="post"
                        name="userOTPForm"
                        onSubmit={this.handleOtp.bind(this)}
                    >
                        <div className="form-group otpfield">
                            <label htmlFor="verifyotp" className="signin-label">
                                Verify OTP{" "}
                            </label>
                            <label htmlFor="resendotp" className="forgot">
                                <Link to="">
                                    <input
                                        type="text"
                                        className="input-resend"
                                        onClick={this.resendOtp}
                                        value="Resend OTP"
                                    />
                                </Link>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="verifyotp"
                                id="otp"
                                value={this.state.fields.verifyotp}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">{this.state.errors.verifyotp}</div>
                            {(() => {
                                switch (this.state.message) {
                                    case "UNSUCCESS":
                                        return (
                                            <div className="errorMsg">
                                                {" "}
                                                <p>{this.state.errorMsg}</p>
                                            </div>
                                        );
                                    default:
                                        return <p className="emptyparagraph"></p>;
                                }
                            })()}
                            {(() => {
                                switch (this.state.otpmessage) {
                                    case "SUCCESS":
                                        return <p>Otp Resent Successfully</p>;
                                    default:
                                        return <p></p>;
                                }
                            })()}
                        </div>
                        <div className="otp">
                            <button
                                type="submit"
                                className="btn btn-warning otpbutton"
                                onChange={this.handlesignupOtp}
                                value="Submit"
                            />
                        </div>
                    </form>
                </div>
            );
        } else {
            return (
                <div>
                    <form
                        method="post"
                        name="userOTPForm"
                        onSubmit={this.handleOtp.bind(this)}
                    >
                        <div className="form-group otpfield">
                            <label htmlFor="verifyotp" className="signin-label">
                                Verify OTP{" "}
                            </label>
                            <label htmlFor="resendotp" className="forgot">
                                <Link to="" className="input-resend" onClick={this.resendOtp}>
                                    Resend OTP{" "}
                                </Link>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="verifyotp"
                                id="otp"
                                value={this.state.fields.verifyotp}
                                onChange={this.handleChange}
                            />
                            <div className="fileuploadpin">{this.state.resendOtp}</div>
                            <div className="errorMsg">{this.state.errors.verifyotp}</div>
                            {(() => {
                                switch (this.state.message) {
                                    case "UNSUCCESS":
                                        return (
                                            <div className="errorMsg">
                                                {" "}
                                                <p>{this.state.errorMsg}</p>
                                            </div>
                                        );
                                    default:
                                        return <p className="emptyparagraph"></p>;
                                }
                            })()}
                            {(() => {
                                switch (this.state.otpmessage) {
                                    case "SUCCESS":
                                        return <p className="otpsub">Otp Resent Successfully</p>;
                                    default:
                                        return <p></p>;
                                }
                            })()}
                        </div>
                        <div className="otp">
                            <button
                                className="btn btn-warning otpbutton"
                                onClick={this.handleOtp}
                            >
                                {" "}
                                Submit{" "}
                            </button>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

export default Otp;
