/* React packages and components */
import React from 'react';
import Sidebar from '../Sidebar';
import ReactTooltip from "react-tooltip";
import MakerHeader from '../MakerHeader';
import "./ProductGL.css";


/**
 * Renders product-gl  section of bank for maker section.
 * <p>Maker is allowed make the changes</p>
 * <p>Maker can submit the changes.</p>

 <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>d-flex  :- </b> it is use for manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities.</li>
* <li> <b> Toggle Button :- </b> user can use toggle button for change the state .</li>

 @Author : Seema Kumari
 @version :1.0
 */

class ProductGL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      fieldsnotvalid: false,
      dataarray: [
        {
          prodName: '', loanGL: '', feeGL1: '', feeGL2: '', interestGL: '', otherChargesGL: '', losspoolGL: '', cliGL: '', assetInsurnceGL: '', feeGL2Required: '',
          interestGLRequired: '', otherChargesGLRequired: "", losspoolGLRequired: '', cliGLRequired: '', assetInsuranceGLRequired: '', payLinkIncomeGL: ''
        },
      ],
      status: '',
      editable: false,
      isDisabled: false,
      buttoncolor: '',
      message: '',
      errorMsg: '',
      backerrors: '',
      isAssetInsurnceGLlist: [],
      isCliGLlist: [],
      isFeeGL1list: [],
      isFeeGL2list: [],
      isInterestGLlist: [],
      isLoanGLlist: [],
      isLosspoolGLlist: [],
      isOtherChargesGLlist: [],
      isPayLinkIncomeGLlist: [],
      feeGL2Requireddisable: false,
      otherChargesGLRequireddisable: false,
      interestGLRequireddisable: false,
      feeGL1Requireddisable: false,
      cliGLRequireddisable: false,
      assetInsuranceGLRequireddisable: false,
      payLinkIncomeGLRequireddisable: false,
      errors: []
    };
  }

  /**
   *captures the pin entred by user.
   * @param {Event} e event which calls this method.
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }
  /**
    * This will validate the pin.
    */
  handleSubmitvalidateForm() {
    let errors = this.state.errors;
    let formIsValid = true;
    if (this.state.fields.pin == '' || this.state.fields.pin == null) {
      formIsValid = false;
      errors['pin'] = '*Please enter pin.';
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
    *Submits the changes to server for approval.
    * @param {Event} e event which calls this method.
    */
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      this.setState({
        errors: []
      })
      this.setState({
        backerrors: []
      });
      this.setOnAndOffsubmitvalue();

      let proxyurl = 'https://cors-anywhere.herokuapp.com/';
      let url = 'https://api.montra.org/moneylinkweb/api/product/saveprodgl?email=' + localStorage.getItem("email") + '&pin=' + this.state.fields.pin;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.state.dataarray),
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          else
            return 'server error'
        })
        .then(contents => {

          this.setonandoffstatusvalue();
          if (200 == contents.status && 'SUCCESS' == contents.message) {
            this.setState({
              isDisabled: true,
              status: contents.status,
              pinmessage: contents.message
            });
          } else {
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              pinmessage: contents.message
            });
            this.state.backerrors.map(key => {
              this.setState({
                errorMsg: key.message
              })
            })
          }
        })

    }
  }
  /**
   * For fresh editable request we set all to default values.
   */
  setNvalue() {
    this.state.dataarray.map((productgl, i) => {
      productgl.isFeeGL1 = "N"
      productgl.isAssetInsurnceGL = "N"
      productgl.isCliGL = "N"
      productgl.isFeeGL2 = "N"
      productgl.isInterestGL = "N"
      productgl.isLoanGL = "N"
      productgl.isLosspoolGL = "N"
      productgl.isOtherChargesGL = "N"
      productgl.isPayLinkIncomeGL = "N"
    })

  }
  /**
   *Set border color for already sent request from maker.
   */
  setBorderColor() {
    let isAssetInsurnceGLlist = this.state.isAssetInsurnceGLlist
    let isCliGLlist = this.state.isCliGLlist
    let isFeeGL1list = this.state.isFeeGL1list
    let isFeeGL2list = this.state.isFeeGL2list
    let isInterestGLlist = this.state.isInterestGLlist
    let isLoanGLlist = this.state.isLoanGLlist
    let isLosspoolGLlist = this.state.isLosspoolGLlist
    let isOtherChargesGLlist = this.state.isOtherChargesGLlist
    let isPayLinkIncomeGLlist = this.state.isPayLinkIncomeGLlist
    this.state.dataarray.map((productgl, i) => {
      if ("A" == productgl.isFeeGL1) {
        this.state.isFeeGL1list[i] = "#e90505"
      }
      if (productgl.isAssetInsurnceGL == "A") {
        this.state.isAssetInsurnceGLlist[i] = "#e90505"
      }
      if (productgl.isCliGL == "A") {
        this.state.isCliGLlist[i] = "#e90505"
      }
      if (productgl.isFeeGL2 == "A") {
        this.state.isFeeGL2list[i] = "#e90505"
      }
      if (productgl.isInterestGL == "A") {
        this.state.isInterestGLlist[i] = "#e90505"
      }
      if (productgl.isLoanGL == "A") {
        this.state.isLoanGLlist[i] = "#e90505"
      }
      if (productgl.isLosspoolGL == "A") {
        this.state.isLosspoolGLlist[i] = "#e90505"
      }
      if (productgl.isOtherChargesGL == "A") {
        this.state.isOtherChargesGLlist[i] = "#e90505"
      }
      if (productgl.isPayLinkIncomeGL == "A") {
        this.state.isPayLinkIncomeGLlist[i] = "#e90505"
      }
    })
    this.setState({
      isAssetInsurnceGLlist,
      isCliGLlist,
      isFeeGL1list,
      isFeeGL2list,
      isInterestGLlist,
      isLoanGLlist,
      isLosspoolGLlist,
      isOtherChargesGLlist,
      isPayLinkIncomeGLlist,
    })
  }
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    const url = 'https://api.montra.org/moneylinkweb/api/product/productgl?email=' + localStorage.getItem("email");

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Server Error');
        }
      })
      .then(contents => {
        console.log('Fetched contents:', contents); // Add logging for debugging

        if (contents.status === 200 && contents.message === 'SUCCESS') {
          this.setState({
            dataarray: contents.data.ProductGls,
            status: contents.status,
            message: contents.message,
            editable: contents.data.editable
          }, () => {
            if (this.state.editable) {
              this.setState({
                editable: false,
                buttoncolor: "#192859"
              });
            } else {
              this.setState({
                editable: true,
                buttoncolor: "#343a40"
              });
            }

            if (this.state.editable) {
              this.setBorderColor();
            } else {
              this.setNvalue();
            }
            this.setonandoffstatusvalue();
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message,
          });

          contents.errors.forEach(error => {
            console.error('Error:', error.message);
            this.setState({
              errorMsg: error.message
            });
          });
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        this.setState({
          errorMsg: 'Server Error Please Try Again'
        });
      });
  }

  /**
   * Set Boolean values for "ON and OFF" for GL's required
   */
  setonandoffstatusvalue() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((productgl, i) => {
      if ("ON" == productgl.feeGL2Required) {
        productgl.feeGL2Required = true
        this.state.feeGL2Requireddisable = false
      } else {
        productgl.feeGL2Required = false
        this.state.feeGL2Requireddisable = true
      }
      if ("ON" == productgl.loanGLRequired) {
        productgl.loanGLRequired = true
        this.state.loanGLRequireddisable = false
      } else {
        productgl.loanGLRequired = false
        this.state.loanGLRequireddisable = true
      }
      if ("ON" == productgl.feeGL1Required) {
        productgl.feeGL1Required = true
        this.state.feeGL1Requireddisable = false
      } else {
        productgl.feeGL1Required = false
        this.state.feeGL1Requireddisable = true
      }
      if ("ON" == productgl.interestGLRequired) {
        productgl.interestGLRequired = true
        this.state.interestGLRequireddisable = false
      } else {
        productgl.interestGLRequired = false
        this.state.interestGLRequireddisable = true
      }
      if ("ON" == productgl.otherChargesGLRequired) {
        productgl.otherChargesGLRequired = true
        this.state.otherChargesGLRequireddisable = false
      } else {
        productgl.otherChargesGLRequired = false
        this.state.otherChargesGLRequireddisable = true
      }
      if ("ON" == productgl.losspoolGLRequired) {
        productgl.losspoolGLRequired = true
        this.state.losspoolGLRequireddisable = false
      } else {
        productgl.losspoolGLRequired = false
        this.state.losspoolGLRequireddisable = true
      }
      if ("ON" == productgl.cliGLRequired) {
        productgl.cliGLRequired = true
        this.state.cliGLRequireddisable = false
      } else {
        productgl.cliGLRequired = false
        this.state.cliGLRequireddisable = true
      }
      if ("ON" == productgl.assetInsuranceGLRequired) {
        productgl.assetInsuranceGLRequired = true
        this.state.assetInsuranceGLRequireddisable = false
      } else {
        productgl.assetInsuranceGLRequired = false
        this.state.assetInsuranceGLRequireddisable = true
      }
      if ("ON" == productgl.payLinkIncomeGLRequired) {
        productgl.payLinkIncomeGLRequired = true
        this.state.payLinkIncomeGLRequireddisable = false
      } else {
        productgl.payLinkIncomeGLRequired = false
        this.state.payLinkIncomeGLRequireddisable = true
      }
    })
    this.setState({
      dataarray
    });
  }
  /**
   * Set "ON and OFF" based on boolean values.
   */
  setOnAndOffsubmitvalue() {
    this.state.dataarray.map((productgl, i) => {
      if (true == productgl.feeGL2Required) {
        productgl.feeGL2Required = 'ON'
      } else {
        productgl.feeGL2Required = 'OFF'
      }
      if (true == productgl.loanGLRequired) {
        productgl.loanGLRequired = 'ON'
      } else {
        productgl.loanGLRequired = 'OFF'
      }
      if (true == productgl.feeGL1Required) {
        productgl.feeGL1Required = 'ON'
      } else {
        productgl.feeGL1Required = 'OFF'
      }
      if (true == productgl.interestGLRequired) {
        productgl.interestGLRequired = 'ON'
      } else {
        productgl.interestGLRequired = 'OFF'
      }
      if (true == productgl.otherChargesGLRequired) {
        productgl.otherChargesGLRequired = 'ON'
      } else {
        productgl.otherChargesGLRequired = 'OFF'
      }
      if (true == productgl.losspoolGLRequired) {
        productgl.losspoolGLRequired = 'ON'
      } else {
        productgl.losspoolGLRequired = 'OFF'
      }
      if (true == productgl.cliGLRequired) {
        productgl.cliGLRequired = 'ON'
      } else {
        productgl.cliGLRequired = 'OFF'
      }
      if (true == productgl.assetInsuranceGLRequired) {
        productgl.assetInsuranceGLRequired = 'ON'
      } else {
        productgl.assetInsuranceGLRequired = 'OFF'
      }
      if (true == productgl.payLinkIncomeGLRequired) {
        productgl.payLinkIncomeGLRequired = 'ON'
      } else {
        productgl.payLinkIncomeGLRequired = 'OFF'
      }
    })
  }

  /**
    *captures the change made by user for GL's required.
    * @param {Event} e event which calls this method.
    */
  toggleChange(e) {
    let dataarray = this.state.dataarray;
    if ('feeGL2Required' == e.target.name) {
      this.state.dataarray[0].feeGL2Required = !this.state.dataarray[0].feeGL2Required

      if (this.state.dataarray[0].feeGL2Required) {
        this.state.feeGL2Requireddisable = false
        this.state.dataarray.map(productgl => {
          productgl.feeGL2Required = true
          productgl.isFeeGL2Required = "A"
        })
      } else {
        this.state.dataarray.map(productgl => {
          productgl.feeGL2Required = false
          productgl.isFeeGL2Required = "A"
        })
        this.state.feeGL2Requireddisable = true
      }
      this.setState({
        dataarray
      });

    }
    else if ('interestGLRequired' == e.target.name) {
      this.state.dataarray[0].interestGLRequired = !this.state.dataarray[0].interestGLRequired
      if (this.state.dataarray[0].interestGLRequired) {
        this.state.interestGLRequireddisable = false
        this.state.dataarray.map(productgl => {
          productgl.isInterestGLRequired = "A"
          productgl.interestGLRequired = true
        })
      } else {
        this.state.dataarray.map(productgl => {
          productgl.isInterestGLRequired = "A"
          productgl.interestGLRequired = false
        })
        this.state.interestGLRequireddisable = true
      }

      this.setState({
        dataarray
      });
    } else if ('otherChargesGLRequired' == e.target.name) {
      this.state.dataarray[0].otherChargesGLRequired = !this.state.dataarray[0].otherChargesGLRequired
      if (this.state.dataarray[0].otherChargesGLRequired) {
        this.state.otherChargesGLRequireddisable = false
        this.state.dataarray.map(productgl => {
          productgl.isOtherChargesGLRequired = "A"
          productgl.otherChargesGLRequired = true
        })
      } else {
        this.state.dataarray.map(productgl => {
          productgl.isOtherChargesGLRequired = "A"
          productgl.otherChargesGLRequired = false
        })
        this.state.otherChargesGLRequireddisable = true
      }
      this.setState({
        dataarray
      });

    } else if ('losspoolGLRequired' == e.target.name) {
      this.state.dataarray[0].losspoolGLRequired = !this.state.dataarray[0].losspoolGLRequired
      if (this.state.dataarray[0].losspoolGLRequired) {
        this.state.losspoolGLRequireddisable = false
        this.state.dataarray.map(productgl => {
          productgl.isLosspoolGLRequired = "A"
          productgl.losspoolGLRequired = true
        })
      } else {
        this.state.dataarray.map(productgl => {
          productgl.isLosspoolGLRequired = "A"
          productgl.losspoolGLRequired = false
        })
        this.state.losspoolGLRequireddisable = true
      }

      this.setState({
        dataarray
      });

    } else if ('cliGLRequired' == e.target.name) {
      this.state.dataarray[0].cliGLRequired = !this.state.dataarray[0].cliGLRequired
      if (this.state.dataarray[0].cliGLRequired) {
        this.state.cliGLRequireddisable = false
        this.state.dataarray.map(productgl => {
          productgl.isCliGLRequired = "A"
          productgl.cliGLRequired = true
        })
      } else {
        this.state.dataarray.map(productgl => {
          productgl.isCliGLRequired = "A"
          productgl.cliGLRequired = false
        })
        this.state.cliGLRequireddisable = true
      }
      this.setState({
        dataarray
      });

    } else if ('assetInsuranceGLRequired' == e.target.name) {
      this.state.dataarray[0].assetInsuranceGLRequired = !this.state.dataarray[0].assetInsuranceGLRequired
      if (this.state.dataarray[0].assetInsuranceGLRequired) {
        this.state.assetInsuranceGLRequireddisable = false
        this.state.dataarray.map(productgl => {
          productgl.isAssetInsuranceGLRequired = "A"
          productgl.assetInsuranceGLRequired = true
        })
      } else {
        this.state.dataarray.map(productgl => {
          productgl.isAssetInsuranceGLRequired = "A"
          productgl.assetInsuranceGLRequired = false
        })
        this.state.assetInsuranceGLRequireddisable = true
      }
      this.setState({
        dataarray
      });

    }
    else {

    }
  }
  /**
      * @typedef {productGlData} productGlData
      * @property {string} feeGL1 indicates feeGL1 of loan product type
      * @property {String} feeGL2  indicates feeGL2 of loan product type
      * @property {string} cliGL indicates cliGL of loan product type
      * @property {String} interestGL indicates interestGL of loan product type
      * @property {String} otherChargesGL indicates otherchargesGL of loan product type
      * @property {String} payLinkIncomeGL indicates payLinkIncomeGL of loan product type
      * @property {String} assetInsurnceGL indicates assetInsuranceGL of loan product type
      * @property {String} loanGL indicates loanGL of loan product type
      * @property {String} losspoolGL indicates losspoolGL of loan product type
      */
  /**
   * Capture the change made by maker,stores into data object for loangl.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeloanGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].loanGL = e.target.value
    this.state.dataarray[i].isLoanGL = "A";
    if ("" == this.state.dataarray[i].loanGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for feegl1.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangefeeGL1(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].feeGL1 = e.target.value
    this.state.dataarray[i].isFeeGL1 = "A";
    if ("" == this.state.dataarray[i].feeGL1) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Capture the change made by maker,stores into data object for feegl2.
     *
     * @param {Event} e event captures the changed value.
     * @param {productGlData} productGlData  stores it into index productGlData
     */
  handleChangefeeGL2(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].feeGL2 = e.target.value
    this.state.dataarray[i].isFeeGL2 = "A";
    if ("" == this.state.dataarray[i].feeGL2) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Capture the change made by maker,stores into data object for interestgl.
     *
     * @param {Event} e event captures the changed value.
     * @param {productGlData} productGlData  stores it into index productGlData
     */
  handleChangeinterestGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].interestGL = e.target.value
    this.state.dataarray[i].isInterestGL = "A";
    if ("" == this.state.dataarray[i].interestGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for otherchargesgl.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeotherChargesGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].otherChargesGL = e.target.value
    this.state.dataarray[i].isOtherChargesGL = "A";
    if ("" == this.state.dataarray[i].otherChargesGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Capture the change made by maker,stores into data object for losspoolgl.
     *
     * @param {Event} e event captures the changed value.
     * @param {productGlData} productGlData  stores it into index productGlData
     */
  handleChangelosspoolGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].losspoolGL = e.target.value
    this.state.dataarray[i].isLosspoolGL = "A";
    if ("" == this.state.dataarray[i].losspoolGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }/**
   * Capture the change made by maker,stores into data object for cligl.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangecliGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].cliGL = e.target.value
    this.state.dataarray[i].isCliGL = "A";
    if ("" == this.state.dataarray[i].cliGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Capture the change made by maker,stores into data object for assetinsurancegl.
     *
     * @param {Event} e event captures the changed value.
     * @param {productGlData} productGlData  stores it into index productGlData
     */
  handleChangeassetInsurnceGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].assetInsurnceGL = e.target.value
    this.state.dataarray[i].isAssetInsurnceGL = "A";
    if ("" == this.state.dataarray[i].assetInsurnceGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Capture the change made by maker,stores into data object for paylinkincomegl.
     *
     * @param {Event} e event captures the changed value.
     * @param {productGlData} productGlData  stores it into index productGlData
     */
  handleChangepayLinkIncomeGL(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].payLinkIncomeGL = e.target.value
    this.state.dataarray[i].isPayLinkIncomeGL = "A";
    if ("" == this.state.dataarray[i].payLinkIncomeGL) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }



  render() {

    if (localStorage.getItem("isLoggedIn") && "MAKER" == localStorage.getItem("role")) {
      if (this.state.dataarray.length > 0) {

        return (
          <div>
            { /* top-Header */}
            <MakerHeader />
            <div id="wrapper">

              { /* header menu */}
              <Sidebar />
              {(() => {
                if (this.state.editable) {
                  return <div className="errorMsg"> <p className="toperror">There is Change Request yet to be approved by checker. A new request can be submitted once checker approves the request</p></div>
                }
              })()}
              <div id="maincontent" className="content">
                <section id="content-wrapper">
                  <div className="backloan">
                    <div className="container-fluid contenttext">
                      <p className="Productgl">PRODUCT GL</p>
                      <p className="subtext">Account Numbers referring to GLs</p>

                      {/* Form -: in this form section included input text, label, togglebutton  to render the data */}
                      <form method="post" name="userRegistrationForm" className="formroot">

                        <div className="row">

                          <div className="col-sm-12 col-md-12 col-xl-7 reduce">
                            <div className="d-flex flex-row mt-3 ">

                              <div className="p-2 productglcoloms">
                                <div className="form-group">

                                  <label className="labelglrow2">Product</label>
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}><label className="form-control loanlabelmak" name="prodName" id="field1" disabled> {ProductGL.prodName} </label></div>)}
                                </div>
                              </div>


                              <div className="p-2 progl">
                                <div className="form-group">
                                  <label className="labelglrow"></label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Loan GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="loanGL" onChange={(e) => { this.handleChangeloanGL(e, i) }} value={ProductGL.loanGL} style={{ borderColor: this.state.isLoanGLlist[i] }} />
                                      <ReactTooltip id="noneditable" place="top" effect="solid">
                                        Non Editable
                                      </ReactTooltip>
                                    </div>
                                  </div>)}
                                </div>
                              </div>
                              <div className="p-2 progl">
                                <div className="form-group">
                                  <label className="labelglrow"></label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Fee GL  1</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="feeGL1" onChange={(e) => { this.handleChangefeeGL1(e, i) }} value={ProductGL.feeGL1} style={{ borderColor: this.state.isFeeGL1list[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>
                              <div className="p-2 progl">
                                <div className="form-group">
                                  <label className="switch productswitch"><input type="checkbox" name="feeGL2Required" onChange={this.toggleChange.bind(this)} checked={this.state.dataarray[0].feeGL2Required} /><div className="slider round" ></div></label>
                                  <label className="labelgl">GL Required </label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Fee GL  2</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="feeGL2" onChange={(e) => { this.handleChangefeeGL2(e, i) }} value={ProductGL.feeGL2} disabled={this.state.feeGL2Requireddisable} style={{ borderColor: this.state.isFeeGL2list[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>
                              <div className="p-2 progl">
                                <div className="form-group">
                                  <label className="switch productswitch"><input type="checkbox" name="interestGLRequired" onChange={this.toggleChange.bind(this)} checked={this.state.dataarray[0].interestGLRequired} /><div className="slider round" ></div></label>
                                  <label className="labelgl">GL Required </label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Interest GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="interestGL" onChange={(e) => { this.handleChangeinterestGL(e, i) }} value={ProductGL.interestGL} disabled={this.state.interestGLRequireddisable} style={{ borderColor: this.state.isInterestGLlist[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>




                              <div className="p-2 progl">
                                <div className="form-group">
                                  <label className="switch productswitch"><input type="checkbox" name="otherChargesGLRequired" onChange={this.toggleChange.bind(this)} checked={this.state.dataarray[0].otherChargesGLRequired} /><div className="slider round" ></div></label>
                                  <label className="labelgl"> GL Required </label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Other Charges GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="otherChargesGL" onChange={(e) => { this.handleChangeotherChargesGL(e, i) }} value={ProductGL.otherChargesGL} disabled={this.state.otherChargesGLRequireddisable} style={{ borderColor: this.state.isOtherChargesGLlist[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="col-sm-12 col-md-12 col-xl-5 reduce">
                            <div className="d-flex flex-row mt-3 ">

                              <div className="p-2 md-trial">
                                <div className="form-group">
                                  <label className="labelglrowgl2">Product</label>
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}><label type="text" className="form-control loanlabelmak" name="prodName" id="field1" disabled> {ProductGL.prodName}</label></div>)}
                                </div>
                              </div>

                              <div className="p-2 progl2">
                                <div className="form-group">
                                  <label className="switch productswitch"><input type="checkbox" name="losspoolGLRequired" onChange={this.toggleChange.bind(this)} checked={this.state.dataarray[0].losspoolGLRequired} /><div className="slider round" ></div></label>
                                  <label className="labelgl"> GL Required </label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Loss Pool GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="losspoolGL" onChange={(e) => { this.handleChangelosspoolGL(e, i) }} value={ProductGL.losspoolGL} disabled={this.state.losspoolGLRequireddisable} style={{ borderColor: this.state.isLosspoolGLlist[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>
                              <div className="p-2 progl2">
                                <div className="form-group">
                                  <label className="switch productswitch"><input type="checkbox" name="cliGLRequired" onChange={this.toggleChange.bind(this)} checked={this.state.dataarray[0].cliGLRequired} /><div className="slider round" ></div></label>
                                  <label className="labelgl"> GL Required </label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> CLI GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="cliGL" onChange={(e) => { this.handleChangecliGL(e, i) }} value={ProductGL.cliGL} disabled={this.state.cliGLRequireddisable} style={{ borderColor: this.state.isCliGLlist[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>
                              <div className="p-2 progl2">
                                <div className="form-group">
                                  <label className="switch productswitch"><input type="checkbox" name="assetInsuranceGLRequired" onChange={this.toggleChange.bind(this)} checked={this.state.dataarray[0].assetInsuranceGLRequired} required /><div className="slider round" ></div></label>
                                  <label className="labelgl"> GL Required </label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> Asset Insurance GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number"
                                        className="form-control formproduct margn"
                                        data-tip data-tip-disable={!this.state.editable} data-for="noneditable"
                                        name="assetInsurnceGL"
                                        onChange={(e) => { this.handleChangeassetInsurnceGL(e, i) }}
                                        value={ProductGL.assetInsurnceGL}
                                        required
                                        disabled={this.state.assetInsuranceGLRequireddisable}
                                        style={{ borderColor: this.state.isAssetInsurnceGLlist[i] }}
                                      />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>
                              <div className="p-2 progl2">
                                <div className="form-group">
                                  <label className="labelglrowgl"></label>
                                  <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> PayLink Income GL</label>
                                </div>
                                <div className="form-group">
                                  {this.state.dataarray.map((ProductGL, i) => <div key={i}>
                                    <div className="inner-addon right-addon">
                                      <input type="number" required className="form-control formproduct margn" data-tip data-tip-disable={!this.state.editable} data-for="noneditable" name="payLinkIncomeGL" onChange={(e) => { this.handleChangepayLinkIncomeGL(e, i) }} value={ProductGL.payLinkIncomeGL} style={{ borderColor: this.state.isPayLinkIncomeGLlist[i] }} />
                                    </div>
                                    <ReactTooltip id="noneditable" place="top" effect="solid">
                                      Non Editable
                                    </ReactTooltip>
                                  </div>)}
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </form>


                      {/* Type Your User PIN  :- user can submit the changes request approval after entering user pin */}

                      <div className="row">
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4 formpgl">
                          <div className="formbox">
                            <form method="post" name="" >

                              <div className="form-group ">
                                <label htmlFor="first">Type Your User PIN </label>

                                <input type="password" className="form-control cardinputfield" name="pin" onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                                <div className="sign">
                                  <input type="submit" className="btn btn-warning cardbutton" onClick={this.handleSubmit} value="Submit changes for approval" style={{ backgroundColor: this.state.buttoncolor }} disabled={this.state.isDisabled || this.state.fieldsnotvalid || this.state.editable} />
                                </div>
                              </div>
                              <div className="errorMsg">{this.state.errors.pin}</div>
                              {(() => {
                                switch (this.state.fieldsnotvalid) {
                                  case true: return <p className="errorMsg">All Fields are Mandatory</p>
                                  default: return <p></p>
                                }
                              })()}
                              {(() => {
                                switch (this.state.pinmessage) {
                                  case "SUCCESS": return <p className="submitted">Submitted Succesfully</p>
                                  default: return <p></p>
                                }
                              })()}
                              {(() => {
                                switch (this.state.pinmessage) {
                                  case "UNSUCCESS": return <div className="errorMsg"> <p className="invalidpin">{this.state.errorMsg}</p></div>
                                  default: return <p></p>
                                }
                              })()}
                            </form>
                          </div>
                        </div>

                        <div className="col-md-4">
                        </div>

                      </div>



                      {/* user can recognise the fields/columns/rows functionality based on the label information */}

                      <div className="productglbutton">
                        <input type="submit" className=" editable-button" value="Editable" disabled />
                        <input type="submit" className=" noteditable-button" value="Not Editable" disabled />
                      </div>
                    </div>
                  </div>
                </section>
              </div>

            </div>

          </div>
        )
      }
      else {
        return (
          <p>No data found</p>
        )
      }
    }
    else {
      return (<p>Please Login</p>)

    }
  }
}
export default ProductGL;
