/* React packages */
import React from "react";
import { Link } from "react-router-dom";

/**
 *   Render's manage user(normal users) for checker section.
 * <p>Allows maker to make the changes.</p>
 * <p>Maker allowed to submit the changes with the pin.</p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

class ManageUserPart1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    };
    this.addInput = this.addInput.bind(this);
  }
  addInput(e) {
    this.props.addInput(e);
  }

  render() {
    return (
      <div>
        {/* Form -: in this form section included input text, label, togglebutton  to render the data */}

        <form method="post" name="userRegistrationForm">
          <div className="d-flex flex-row  ">
            <div className="p-2 managemak1">
              <div className="">
                <label className="serial-header">S. No.</label>
              </div>

              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="heightmak">
                    <p className="serialch">{++i}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 managemak2">
              <div className="">
                <label className="usertheadermak makeruser">User Name</label>
              </div>

              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div
                    key={i}
                    className="inner-addon right-addon"
                    style={{
                      backgroundColor:
                        this.props.color[i] || this.props.activedisable[i],
                    }}
                  >
                    <textarea
                      type="text"
                      className="form-control formproduct margn desk"
                      name="userName"
                      onChange={(e) => {
                        this.props.handleChangeusername(e, i);
                      }}
                      value={manageUser.userName}
                      disabled={
                        this.props.color[i] || this.props.activedisable[i]
                      }
                      style={{
                        borderColor: this.props.isUserNamelist[i],
                        backgroundColor: this.props.backGroundColor[i],
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 managemak2">
              <div className="">
                <label className="usertheadermak designation">
                  User Designation
                </label>
              </div>

              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="inner-addon right-addon">
                    <textarea
                      type="text"
                      className="form-control formproduct margn desk"
                      name="designation"
                      onChange={(e) => {
                        this.props.handleChangeuserdesgn(e, i);
                      }}
                      value={manageUser.designation}
                      disabled={
                        this.props.color[i] || this.props.activedisable[i]
                      }
                      style={{
                        borderColor: this.props.isDesignationlist[i],
                        backgroundColor: this.props.backGroundColor[i],
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 managemak2">
              <div className="">
                <label className="usertheadermak makeruser">User Type</label>
              </div>

              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="input-group aligncenter  margn">
                    <select
                      className="form-control formselectblue"
                      onChange={(e) => {
                        this.props.handleChangeusertype(e, i);
                      }}
                      value={manageUser.userType}
                      disabled={
                        this.props.color[i] || this.props.activedisable[i]
                      }
                      style={{
                        borderColor: this.props.isUserTypelist[i],
                        backgroundColor: this.props.backGroundColor[i],
                      }}
                    >
                      <option value={manageUser.userType}>
                        {manageUser.userType}
                      </option>
                      <option value="MAKER">MAKER</option>
                      <option value="CHECKER">CHECKER</option>
                      <option value="SALES">SALES</option>
                      <option value="SALARY PROCESSOR">SALARY PROCESSOR</option>
                      <option value="BUSINESS">BUSINESS</option>
                      <option value="OPERATIONS">BUSINESS</option>
                      <option value="COLLECTION">COLLECTION</option>
                      <option value="NIBSS-COLLECTION">NIBSS-COLLECTION</option>
                      <option value="SALARY PROCESSOR-EXTERNAL">
                        SALARY PROCESSOR-EXTERNAL
                      </option>
                    </select>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 managemak2">
              <div className="">
                <label className="usertheadermak email">
                  User Official Email ID
                </label>
              </div>

              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="inner-addon right-addon">
                    <textarea
                      type="email"
                      className="form-control formproduct margn emaildesk"
                      name="officialEmail"
                      onChange={(e) => {
                        this.props.handleChangeemail(e, i);
                      }}
                      value={manageUser.officialEmail}
                      required
                      disabled={
                        this.props.color[i] ||
                        this.props.activedisable[i] ||
                        this.props.emaildisable[i]
                      }
                      style={{
                        borderColor: this.props.isEmaillist[i],
                        backgroundColor: this.props.backGroundColor[i],
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 managemak2">
              <div className="">
                <label className="usertheadermak designation mobno">
                  User Mobile Number{" "}
                </label>
              </div>

              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="inner-addon right-addon">
                    <textarea
                      type="number"
                      className="form-control formproduct margn desk"
                      name="mobile"
                      onChange={(e) => {
                        this.props.handleChangemobile(e, i);
                      }}
                      value={manageUser.mobile}
                      maxLength="10"
                      disabled={
                        this.props.color[i] || this.props.activedisable[i]
                      }
                      style={{
                        borderColor: this.props.isMobilelist[i],
                        backgroundColor: this.props.backGroundColor[i],
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2  managemak3">
              <div className="managemak3-activation">
                <label className="usertheaderch actmanage">Activation </label>
              </div>
              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="form-group togglemanagemak">
                    <label className="switch toggleswitch">
                      <input
                        type="checkbox"
                        name="active"
                        onChange={(e) => {
                          this.props.toggleChange(e, i);
                        }}
                        checked={manageUser.active}
                        disabled={this.props.color[i]}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 managemak3 rem">
              <div className="">
                <label className="usertheaderch actmanage ">Removal</label>
              </div>
              <div className="form-group">
                {this.props.data.map((manageUser, i) => (
                  <div key={i} className="form-group togglemanagemak2">
                    <button
                      className="btn btn-success removal"
                      onClick={(e) => {
                        this.props.handleChangeremoval(e, i);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Add More -: here Add More button is used toadd extra row  */}

          <div className="addbutton">
            <Link to="#">
              <input
                type="submit"
                className="btn btn-warning addbuttonmore"
                value="+ Add More"
                onClick={this.addInput.bind(this)}
              />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageUserPart1;
