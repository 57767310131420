/* React packages and components */
import React from 'react';
import Sidebar from '../Sidebar';
import ManageUserPart1 from './ManageUserPart1';
import ManageUserPart2 from './ManageUserPart2';
import * as EmailValidator from 'email-validator';
import MakerHeader from '../MakerHeader';
import "./ManageUser.css";


/**
 * Renders  data for both Users(Non-Credit-UW users and Credit-UW Users)  in maker Manage User section.
 * <p> Maker is allowed to make the user inactive/active</p>
 * <p> Maker is allowed to remove user</p>
 * <p> Maker is allowed to assign the products for credit-uw user</p>
 * <p> Maker is allowed  to change the roles of users</p>.
 * @Author : Seema Kumari
 * @version :1.0
 */

class ManageUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      isUserNamelist: [],
      isEmaillist: [],
      isUserTypelist: [],
      isDesignationlist: [],
      isMobilelist: [],
      isUserNamecredituwlist: [],
      isEmailcredituwlist: [],
      isUserTypecredituwlist: [],
      isDesignationcredituwlist: [],
      isMobilecredituwlist: [],
      buttoncolor: '',
      fieldsnotvalid: false,
      rolesAndProductsNotAssigned: {
        MissingProds: [],
        MissingRoles: []
      },
      errors: [],
      editable: false,
      emaildisable: [],
      emailcredituwdisable: [],
      activedisable: [],
      activecredituwdisable: [],
      color: [],
      credituwcolor: [],
      backGroundColor: [],
      backGroundColorcredituw: [],
      isassignedarray: [[], []],
      prodsAssignedForUWRules: [{
        CompanyId: '',
        email: '',
        isAssgined: '',
        lastUpdaDateTime: '',
        productName: '',
        requestId: '',
        status: '',
      }],
      inputs: [{
        officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [], active: '', userName: ''
      }],
      inputscredituw: [{
        officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [{
          CompanyId: '',
          email: '',
          isAssgined: '',
          lastUpdaDateTime: '',
          productName: '',
          requestId: '',
          status: ''
        }], active: '', userName: ''
      }],
      dataarraycredituw:
        [{
          officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [{
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          },
          {
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }, {
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }, {
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }], active: '', userName: ''
        }],
      dataarray:
        [{
          officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [{
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }], active: '', userName: ''
        },
        ],
      status: '',
      isDisabled: false,
      backerrors: [],
      message: '',
      errorMsg: ''
    };

  }

  /**
   * Captures the pin entered by maker before submitting the changes.
   * @param {Event} e event which calls this method.
   */

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields
    });
  }

  /**
   * @typedef {data} data
   * @property {string} userName indicates username
   * @property {String} email  indicates email of user
   * @property {string} userType indicates role of user
   * @property {String} mobile indicates mobile number of user
   * @property {String} designation indicates designation of user
   */

  /**
   * Creates one more object of data and shows the input fields to enter the details.
   *<p> For other than credit-uw users.</p>
   * @param {data} data object which is added
   * @param {Event} e event to add one more user
   */
  addInput(e) {
    let input = {
      officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [], active: true, userName: ''
    }
    let dataarray = this.state.dataarray;
    dataarray.push(input);
    this.setState({ dataarray })
  }
  /**
   * Creates one more object of data and shows the input fields to enter the details.
   *<p> For credit-uw users.</p>
   * @param {data} data object which is added
   * @param {Event} e event to add one more user
   */
  addInputcredituw(e) {
    let isassignedarray = this.state.isassignedarray;
    let dataarraycredituw = this.state.dataarraycredituw;
    let itemcredituw = {
      officialEmail: '', designation: '', userType: 'CREDIT-UW', isUserType: 'A', mobile: '', prodsAssignedForUWRules: [{
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'PayDay Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Salary Advance', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'STPL', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'CD Loans', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Personal Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Auto Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Home Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Loan Against Property', requestId: '', status: ''
      }
      ], active: true, userName: ''
    }
    dataarraycredituw.push(itemcredituw);

    this.setState({
      dataarraycredituw: dataarraycredituw,
      isassignedarray
    })
  }

  /**
   * Captures the value of designation of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwuserdesgn(e, i) {

    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].designation = e.target.value;
    this.state.dataarraycredituw[i].isDesignation = "A";
    if ("" == this.state.dataarraycredituw[i].designation) {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of username of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwusername(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].userName = e.target.value;
    this.state.dataarraycredituw[i].isUserName = "A";
    if ("" == this.state.dataarraycredituw[i].userName) {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Captures the value of product assigned of data(For Credit-UW users).
     * @param {Event} e event that calls and stores the value entered by maker
     * @param {data} data  index of data to store the value.
     */
  handleChangecredituwproducttypes(e, i, j) {
    let dataarraycredituw = this.state.dataarraycredituw;

    if ('YES' == e.target.value) {
      dataarraycredituw[i].prodsAssignedForUWRules[j].isAssgined = "A";
      dataarraycredituw[i].prodsAssignedForUWRules[j].prodAssigned = "YES";
    } else {
      dataarraycredituw[i].prodsAssignedForUWRules[j].isAssgined = "A";
      dataarraycredituw[i].prodsAssignedForUWRules[j].prodAssigned = "NO";
    }

    this.setState({
      dataarraycredituw: dataarraycredituw
    });
  }
  /**
   * Captures the value of usertype of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwusertype(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].userType = e.target.value;
    this.state.dataarraycredituw[i].isUserType = "A";
    if ("" == this.state.dataarraycredituw[i].userType) {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of email of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwemail(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].officialEmail = e.target.value;
    this.state.dataarraycredituw[i].isEmail = "A";
    if ("" == this.state.dataarraycredituw[i].officialEmail || !EmailValidator.validate(this.state.dataarraycredituw[i].officialEmail)) {
      this.state.isEmailcredituwlist[i] = "#e90505";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isEmailcredituwlist[i] = "";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of mobile of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwmobile(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].mobile = e.target.value;
    this.state.dataarraycredituw[i].isMobile = "A";
    if ("" == this.state.dataarraycredituw[i].mobile || !Number(this.state.dataarraycredituw[i].mobile) || 10 != this.state.dataarraycredituw[i].mobile.length) {
      this.state.isMobilecredituwlist[i] = "#e90505";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isMobilecredituwlist[i] = "";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }

  /**
   * Captures the value of designation of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeuserdesgn(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].designation = e.target.value;
    this.state.dataarray[i].isDesignation = "A";
    if ("" == this.state.dataarray[i].designation) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of username of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeusername(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].userName = e.target.value;
    this.state.dataarray[i].isUserName = "A";
    if ("" == this.state.dataarray[i].userName) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of usertype of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeusertype(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].userType = e.target.value;
    this.state.dataarray[i].isUserType = "A";
    if ("" == this.state.dataarray[i].userType) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of email of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeemail(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].officialEmail = e.target.value;
    this.state.dataarray[i].isEmail = "A";
    if ("" == this.state.dataarray[i].officialEmail || !EmailValidator.validate(this.state.dataarray[i].officialEmail)) {
      this.state.isEmaillist[i] = "#e90505";
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isEmaillist[i] = "";
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of mobile of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangemobile(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].mobile = e.target.value;
    this.state.dataarray[i].isMobile = "A";
    if ("" == this.state.dataarray[i].mobile || !Number(this.state.dataarray[i].mobile) || 10 != this.state.dataarray[i].mobile.length) {
      this.state.isMobilelist[i] = "#e90505";
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isMobilelist[i] = "";
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of remove of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeremoval(e, i) {
    e.preventDefault();

    let color = this.state.color;
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].isRemoved = "A";
    if (!this.state.color[i] || '' == this.state.color[i]) {
      this.state.color[i] = true;
      this.state.backGroundColor[i] = "#fdc9a9"
    }
    else {
      this.state.color[i] = false;
      this.state.backGroundColor[i] = "#dcf4fb"
    }
    this.setState({
      color,
      dataarray
    })

  }
  /**
   * Captures the value of remove of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwremoval(e, i) {
    e.preventDefault();

    let credituwcolor = this.state.credituwcolor;
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].isRemoved = "A";
    if (!this.state.credituwcolor[i] || '' == this.state.credituwcolor[i]) {
      this.state.credituwcolor[i] = true;
      this.state.backGroundColorcredituw[i] = "#fdc9a9"
    }
    else {
      this.state.credituwcolor[i] = false;
      this.state.backGroundColorcredituw[i] = "#dcf4fb"
    } this.setState({
      credituwcolor,
      dataarraycredituw
    })

  }
  /**
   * Captures the value of product assigned of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeprodsAssignedForUWRules(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].prodsAssignedForUWRules = e.target.value;
    if ("" == this.state.dataarray[i].prodsAssignedForUWRules) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * This will validate the pin.
   * No Parameters for this method.
   */
  handleSubmitvalidateForm() {
    let errors = this.state.errors;
    let formIsValid = true;
    if (this.state.fields.pin == '' || this.state.fields.pin == null) {
      formIsValid = false;
      errors['pin'] = '*Please enter pin.';
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
   *Submit the changes done by the maker to Server
   * @param {Event} e event that will check user pin and submits.
   */

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      this.setState({
        errors: []
      })
      this.setOnAndOffActivevalue();
      this.setOnAndOffActivevaluecredituw();
      let data = [];
      this.state.dataarray.map(item => {
        data.push(item)
      })

      this.state.dataarraycredituw.map(item => {
        data.push(item)
      })

      let proxyurl = 'https://cors-anywhere.herokuapp.com/';
      let url = 'https://api.montra.org/moneylinkweb/api/user/updateusers?email=' + localStorage.getItem("email") + '&pin=' + this.state.fields.pin;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          else
            return 'server error'
        })
        .then(contents => {

          if (200 == contents.status && 'SUCCESS' == contents.message) {
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message
            });
            this.setTrueOrFalseActivevalue();
            this.setTrueOrFalseActivevaluecredituw();
          } else {
            this.setTrueOrFalseActivevalue();
            this.setTrueOrFalseActivevaluecredituw();
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message,
              rolesAndProductsNotAssigned: contents.data
            });

            this.state.backerrors.map(key => {
              this.setState({
                errorMsg: key.message
              })
            })
          }
        })

    }
  }
  /**
   * set boolean based on fetch details in componentdidmount.
   */
  setTrueOrFalseActivevalue() {
    let dataarray = this.state.dataarray;
    let backgroundColor = this.state.backGroundColor;
    this.state.dataarray.map((ManageUser, i) => {
      this.state.backGroundColor[i] = "#dcf4fb"
      this.state.emaildisable[i] = true;
      if ("YES" == ManageUser.active || "yes" == ManageUser.active) {
        ManageUser.active = true
        this.state.activedisable[i] = false
        this.state.backGroundColor[i] = "#dcf4fb"
      }
      else {
        ManageUser.active = false
        this.state.activedisable[i] = true
        this.state.backGroundColor[i] = "#fafafa"
      }
    })
    this.setState({
      dataarray: dataarray,
      backgroundColor
    })
  }
  /**
   * Sets boolean values for fetched details in componentdidmount.
   */
  setTrueOrFalseActivevaluecredituw() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((ManageUser, i) => {
      this.state.emailcredituwdisable[i] = true;
      this.state.backGroundColorcredituw[i] = "#dcf4fb"
      if ("YES" == ManageUser.active || "yes" == ManageUser.active) {
        ManageUser.active = true
        this.state.activecredituwdisable[i] = false
        this.state.backGroundColor[i] = "#dcf4fb"
      }
      else {
        ManageUser.active = false
        this.state.activecredituwdisable[i] = true
        this.state.backGroundColor[i] = "#fafafa"
      }
    })
    this.setState({ dataarraycredituw: dataarraycredituw })
  }

  setOnAndOffActivevalue() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageuser, i) => {
      if (manageuser.active) {
        manageuser.active = "YES"
      }
      else {
        manageuser.active = "NO"
      }
    })
    this.setState({
      dataarray: dataarray
    })
  }
  setOnAndOffActivevaluecredituw() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((ManageUser, i) => {
      if (ManageUser.active) {
        ManageUser.active = "YES"
      }
      else {
        ManageUser.active = "NO"
      }
    })
    this.setState({ dataarraycredituw: dataarraycredituw })
  }
  /**
   * sets border color based on changed values.
   */
  setBorderColor() {
    let isUserNamelist = this.state.isUserNamelist
    let isEmaillist = this.state.isEmaillist
    let isUserTypelist = this.state.isUserTypelist
    let isDesignationlist = this.state.isDesignationlist
    let isMobilelist = this.state.isMobilelist
    let isUserNamecredituwlist = this.state.isUserNamecredituwlist
    let isEmailcredituwlist = this.state.isEmailcredituwlist
    let isUserTypecredituwlist = this.state.isUserTypecredituwlist
    let isDesignationcredituwlist = this.state.isDesignationcredituwlist
    let isMobilecredituwlist = this.state.isMobilecredituwlist
    this.state.dataarray.map((ManageUser, i) => {
      if (ManageUser.isDesignation == "A") { this.state.isDesignationlist[i] = "#e90505" }
      if (ManageUser.isEmail == "A") { this.state.isEmaillist[i] = "#e90505" }
      if (ManageUser.isMobile == "A") { this.state.isMobilelist[i] = "#e90505" }
      if (ManageUser.isUserName == "A") { this.state.isUserNamelist[i] = "#e90505" }
      if (ManageUser.isUserType == "A") { this.state.isUserTypelist[i] = "#e90505" }
    })
    this.state.dataarraycredituw.map((ManageUser, i) => {
      if (ManageUser.isDesignation == "A") { this.state.isDesignationcredituwlist[i] = "#e90505" }
      if (ManageUser.isEmail == "A") { this.state.isEmailcredituwlist[i] = "#e90505" }
      if (ManageUser.isMobile == "A") { this.state.isMobilecredituwlist[i] = "#e90505" }
      if (ManageUser.isUserName == "A") { this.state.isUserNamecredituwlist[i] = "#e90505" }
      if (ManageUser.isUserType == "A") { this.state.isUserNamecredituwlist[i] = "#e90505" }
    })
  }
  /**
   * For new editable request once the checker approved sets defauklt values.
   */
  setNvalue() {
    this.state.dataarray.map((ManageUser, i) => {
      ManageUser.isDesignation = "N"
      ManageUser.isEmail = "N"
      ManageUser.isMobile = "N"
      ManageUser.isUserName = "N"
      ManageUser.isUserType = "N"
      ManageUser.isActive = "N"
      ManageUser.isRemoved = "N"
    })
  }
  setNvaluecredituw() {
    this.state.dataarraycredituw.map((ManageUser, i) => {
      ManageUser.isDesignation = "N"
      ManageUser.isEmail = "N"
      ManageUser.isMobile = "N"
      ManageUser.isUserName = "N"
      ManageUser.isUserType = "N"
      ManageUser.isActive = "N"
      ManageUser.isRemoved = "N"
      ManageUser.prodsAssignedForUWRules.map(prodsAssignedForUWRules => {
        prodsAssignedForUWRules.isAssgined = "N"
      })
    })
  }
  setNprodsvaluecredituw() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((ManageUser, i) => {
      ManageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) => {
        prodsAssignedForUWRules.isAssgined = "N"
      })
    })
    this.setState({
      dataarraycredituw
    })
  }

  /**
   * Fetches data from server and render all fields
   */
  componentDidMount() {
    let url = new URL('https://api.montra.org/moneylinkweb/api/user/users?email=' + localStorage.getItem("email"));

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Server error');
        }
      })
      .then(contents => {
        console.log('Fetched contents:', contents); // Log fetched contents for debugging

        if (contents.status === 200 && contents.message === 'SUCCESS') {
          this.OnAndOffChangecredituwarray(contents.data.CreditUWUsers.length);
          this.setState({
            dataarray: contents.data.RegularUsers,
            dataarraycredituw: contents.data.CreditUWUsers,
            status: contents.status,
            buttoncolor: contents.data.editable ? "#192859" : "#343a40",
            editable: contents.data.editable
          }, () => {
            // Call methods that depend on the state being updated
            if (this.state.editable) {
              this.setBorderColor();
            } else {
              this.setNvalue();
            }
            this.setTrueOrFalseActivevalue();
            this.setTrueOrFalseActivevaluecredituw();
            this.setNprodsvaluecredituw();
            this.setNvaluecredituw();
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            errorMsg: contents.errors.map(error => error.message).join(', ')
          });
          console.error('Errors:', contents.errors); // Log errors for debugging
        }
      })
      .catch(error => {
        console.error('Fetch error:', error); // Log fetch error for debugging
        this.setState({
          errorMsg: 'Server Error Please Try Again'
        });
      });
  }
  /**
   * Captures the boolean value for active toggle button
   *
   * @param {Event} e  event to call this method
   * @param {data[]} data index of data array
   */

  toggleChange(e, i) {
    let dataarray = this.state.dataarray;
    let activedisable = this.state.activedisable;
    if (!this.state.activedisable[i] || '' == this.state.activedisable[i]) {
      this.state.activedisable[i] = true;
      this.state.backGroundColor[i] = "#fafafa"
    }
    else {
      this.state.activedisable[i] = false;
      this.state.backGroundColor[i] = "#dcf4fb"
    }
    this.state.dataarray[i].active = !this.state.dataarray[i].active
    this.state.dataarray[i].isActive = "A";
    this.setState({
      dataarray,
      activedisable
    });
  }
  /**
   * Captures the changed active value for Credit-UW users
   *
   * @param {Event} e captures the changed value and calls this method
   * @param {data} data index of data and stores into object
   */
  toggleChangecredituwactive(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let activecredituwdisable = this.state.activecredituwdisable;
    if (!this.state.activecredituwdisable[i] || '' == this.state.activecredituwdisable[i]) {
      this.state.activecredituwdisable[i] = true;
      this.state.backGroundColorcredituw[i] = "#fafafa"
    }
    else {
      this.state.activecredituwdisable[i] = false;
      this.state.backGroundColorcredituw[i] = "#dcf4fb"
    }
    this.state.dataarraycredituw[i].active = !this.state.dataarraycredituw[i].active
    this.state.dataarraycredituw[i].isActive = "A";
    this.setState({
      dataarraycredituw,
      activecredituwdisable
    });
  }
  OnAndOffChangecredituwarray(n) {
    let isassignedarray = this.state.isassignedarray;
    for (let i = 0; i < n; i++) {
      isassignedarray.push([]);
      this.setState({
        isassignedarray
      });
    }
  }
  render() {
    if (localStorage.getItem("isLoggedIn") && "MAKER" == localStorage.getItem("role")) {
      if (this.state.dataarray.length > 0) {
        return (

          <div>
            { /* top-Header */}
            <MakerHeader />

            <div id="wrapper">
              { /* header menu */}
              <Sidebar />
              {(() => {
                if (this.state.editable) {
                  return <div className="errorMsg"> <p className="toperror">There is Change Request yet to be approved by checker. A new request can be submitted once checker approves the request</p></div>
                }
              })()}
              <div id="maincontent" className="content">
                <section className='usersectionmange' >
                  <div className="backloan">
                    <div className="container-fluid undercontainer">
                      <p className="manageheader">MANAGE USER</p>
                      <p className="usertextmak">Users other than Credit-UW</p>

                      <div className="row">
                        <div className="col-md-12 reducepaddingfirst">
                          <ManageUserPart1 backGroundColor={this.state.backGroundColor} activedisableBackGroundColor={this.state.activedisableBackGroundColor} colorBackGroundColor={this.state.colorBackGroundColor} activedisable={this.state.activedisable} isUserNamelist={this.state.isUserNamelist} isEmaillist={this.state.isEmaillist} isUserTypelist={this.state.isUserTypelist} isDesignationlist={this.state.isDesignationlist} isMobilelist={this.state.isMobilelist}
                            emaildisable={this.state.emaildisable} handleChangeremoval={(e, i) => this.handleChangeremoval(e, i)} color={this.state.color} disablebutton={this.state.isDisabled} handleChange={(e) => this.handleChange(e)} handleChangeuserdesgn={(e, i) => this.handleChangeuserdesgn(e, i)} handleChangeusername={(e, i) => this.handleChangeusername(e, i)} handleChangeusertype={(e, i) => this.handleChangeusertype(e, i)} handleChangeemail={(e, i) => this.handleChangeemail(e, i)} handleChangemobile={(e, i) => this.handleChangemobile(e, i)} data={this.state.dataarray} addInput={(e) => this.addInput(e)} toggleChange={(e, i) => this.toggleChange(e, i)} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 reducepaddingfirst">
                          <p className="usertextmak2">Credit-UW Users</p>
                          <ManageUserPart2 backGroundColorcredituw={this.state.backGroundColorcredituw} activecredituwdisableBackGroundColor={this.state.activecredituwdisableBackGroundColor} credituwcolorBackGroundColor={this.state.credituwcolorBackGroundColor} activecredituwdisable={this.state.activecredituwdisable} isUserNamecredituwlist={this.state.isUserNamecredituwlist} isEmailcredituwlist={this.state.isEmailcredituwlist} isUserTypecredituwlist={this.state.isUserTypecredituwlist} isDesignationcredituwlist={this.state.isDesignationcredituwlist} isMobilecredituwlist={this.state.isMobilecredituwlist}
                            emailcredituwdisable={this.state.emailcredituwdisable} toggleChangecredituwactive={(e, i) => { this.toggleChangecredituwactive(e, i) }} disablebutton={this.state.isDisabled} handleChangecredituwuserdesgn={(e, i) => this.handleChangecredituwuserdesgn(e, i)} handleChangecredituwusername={(e, i) => this.handleChangecredituwusername(e, i)} handleChangecredituwusertype={(e, i) => this.handleChangecredituwusertype(e, i)} handleChangecredituwemail={(e, i) => this.handleChangecredituwemail(e, i)} handleChangecredituwmobile={(e, i) => this.handleChangecredituwmobile(e, i)}
                            isassignedarray={this.state.isassignedarray} credituwcolor={this.state.credituwcolor} handleChangecredituwremoval={(e, i) => this.handleChangecredituwremoval(e, i)} handleChangeprodsAssignedForUWRules={(e, i) => this.handleChangeprodsAssignedForUWRules(e, i)} data={this.state.dataarraycredituw} prodsAssignedForUWRules={this.state.dataarraycredituw.prodsAssignedForUWRules} addInput={(e) => this.addInputcredituw(e)} handleChangecredituwproducttypes={(e, i, j) => this.handleChangecredituwproducttypes(e, i, j)} />

                        </div>
                      </div>


                      {/* Type Your User PIN  :- user can submit the changes request approval after entering user pin */}

                      <div className="row">
                        <div className="managebox">
                          <form method="post" name="" >

                            <div className="form-group ">
                              <label htmlFor="first">Type Your User PIN </label>

                              <input type="password" className="form-control cardinputfield" name="pin" onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                              <div className="sign">
                                <input type="submit" className="btn btn-warning cardbutton" onClick={this.handleSubmit} value="Submit changes for approval" style={{ backgroundColor: this.state.buttoncolor }} disabled={this.state.isDisabled || this.state.fieldsnotvalid || this.state.editable} />
                              </div>
                            </div>
                            <div className="errorMsg">{this.state.errors.pin}</div>
                            {(() => {
                              switch (this.state.fieldsnotvalid) {
                                case true: return <p className="errorMsg">All Fields are Mandatory</p>
                                default: return <p></p>
                              }
                            })()}
                            {(() => {
                              switch (this.state.message) {
                                case "SUCCESS": return <p className="submitted">Submitted Succesfully</p>
                                default: return <p></p>
                              }
                            })()}
                            {(() => {
                              switch (this.state.message) {
                                case "UNSUCCESS": return <div>
                                  <div className="errorMsg"> <p className="invalidpin">{this.state.errorMsg}<div>{this.state.rolesAndProductsNotAssigned.MissingProds}</div><div>{this.state.rolesAndProductsNotAssigned.MissingRoles}</div></p></div>                                </div>
                                default: return <p></p>
                              }
                            })()}
                          </form>
                        </div>
                      </div>

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <p>
            No data Found
          </p>
        )
      }
    }
    else {
      return (<p>Please Login</p>)

    }
  }
}

export default ManageUser;
