import "./style.css";
import cartAdd from "../../../assets/images/cart-add.svg";
import cartSave from "../../../assets/images/cart-save.svg";
import payNow from "../../../assets/images/pay-now.svg";
import pod from "../../../assets/images/pod.svg";
import escrowPay from "../../../assets/images/escrow-pay.svg";
import qrCode from "../../../assets/images/qr-code.svg";
import montraIcon from "../../../assets/images/montra-icon.svg";
import ussd from "../../../assets/images/ussd.svg";
import sdk1 from "../../../assets/images/sdk-1.png";
import sdk2 from "../../../assets/images/sdk-2.png";
import price1 from "../../../assets/images/price-icon-1.svg";
import price2 from "../../../assets/images/price-icon-2.svg";
import price3 from "../../../assets/images/price-icon-3.svg";
import price4 from "../../../assets/images/price-icon-4.svg";
import price5 from "../../../assets/images/price-icon-5.svg";
import payStep1 from "../../../assets/images/pay-step-1.png";
import payStep2 from "../../../assets/images/pay-step-2.png";
import payStep3 from "../../../assets/images/pay-step-3.png";
import built1 from "../../../assets/images/built1.svg";
import built2 from "../../../assets/images/built2.svg";
import feature1 from "../../../assets/images/features-1.svg";
import feature2 from "../../../assets/images/features-2.svg";
import feature3 from "../../../assets/images/features-3.svg";
import feature4 from "../../../assets/images/features-4.svg";
import feature5 from "../../../assets/images/features-5.svg";
import feature6 from "../../../assets/images/features-6.svg";
import feature7 from "../../../assets/images/features-7.svg";
import feature8 from "../../../assets/images/features-8.svg";
import feature9 from "../../../assets/images/features-9.svg";
import feature10 from "../../../assets/images/features-10.svg";
import gateway1 from "../../../assets/images/gateway-1.svg";
import gateway2 from "../../../assets/images/gateway-2.svg";
import gateway3 from "../../../assets/images/gateway-3.svg";
import gateway4 from "../../../assets/images/gateway-4.svg";
import gateway5 from "../../../assets/images/gateway-5.svg";
import gateway6 from "../../../assets/images/gateway-6.svg";
import gateway7 from "../../../assets/images/gateway-7.svg";
import gateway8 from "../../../assets/images/gateway-8.svg";
import gateway9 from "../../../assets/images/gateway-9.svg";
import gateway10 from "../../../assets/images/gateway-10.svg";
import gateway11 from "../../../assets/images/gateway-11.svg";

const CardWithTriangle = ({ number, isFirstCard, isLastCard, children }) => {
  const numToWord = (num) => {
    switch (num) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
      case 6:
        return "two2";

      default:
        return "";
    }
  };
  return (
    <div className="card">
      {isLastCard ? null : (
        <div className={`triangle ${numToWord(number)}`}></div>
      )}
      {isFirstCard ? null : (
        <div className={`cutout ${numToWord(number)}`}></div>
      )}
      <div className="circle">{number}</div>
      {children}
    </div>
  );
};

const DescriptionWithIcon = ({ icon, title, children }) => {
  return (
    <div className="description">
      <div className="blue-circle">
        <img src={icon} alt="icon" />
      </div>
      <div>
        <h5>{title}</h5>
        <p>{children}</p>
      </div>
    </div>
  );
};
const FeatureDescriptionWithIcon = ({ icon, title, children }) => {
  return (
    <div className="description-col">
      <img className="icon" src={icon} alt="icon" />
      <div>
        <h5>{title}</h5>
        <p>{children}</p>
      </div>
    </div>
  );
};
const IconWithText = ({ icon, text }) => {
  return (
    <div className="icon-container">
      <div className="icon-wrapper">
        <img src={icon} alt="icon" />
      </div>
      <label>{text}</label>
    </div>
  );
};

const Body = () => {
  return (
    <section className="main">
      <div className="body checkoutpay">
        <h2>How does MontraPay Checkout Work?</h2>
        <div className="checkout-steps">
          <CardWithTriangle isFirstCard={true} number={1}>
            <h5>Add to Cart</h5>
            <p>
              Customer orders what they want and adds items into the checkout
              cart.
            </p>
            <img src={cartAdd} alt="cart add" />
          </CardWithTriangle>
          <CardWithTriangle number={2}>
            <h5>Confirm at Checkout</h5>
            <p>
              After reviewing the order, the customer confirms and clicks to
              make their payment.
            </p>
            <img src={cartSave} alt="cart save" />
          </CardWithTriangle>
          <CardWithTriangle number={3}>
            <h5>Select a Payment Option</h5>
            <p>
              Customer is redirected to MontraPay checkout link & selects one of
              the Payment Options you have set-up on MontraPay from the
              following available options:
            </p>
            <ul>
              <li>
                <img src={payNow} alt="pay now icon" />
                Pay Now
              </li>
              <li>
                <img src={pod} alt="pay on delivery icon" />
                Pay on Delivery (POD)
              </li>
              <li>
                <img src={escrowPay} alt="escrow pay icon" />
                Escrow Pay
              </li>
            </ul>
            <div className="info-1">
              <section className="info-body">
                Payment Link is sent to customer's email for
                making payment on delivery
              </section>
              <div className="info-line"></div>
            </div>
            <div className="info-2">
              <section className="info-body">
                Customer pays to Montra Escrow Account for Montra to <br /> pay
                Seller upon delivery as per terms agreed with buyer
              </section>
              <div className="info-line"></div>
            </div>
          </CardWithTriangle>
          <CardWithTriangle number={4}>
            <h5>Choose a Payment Method</h5>
            <p className="paymethod">
              Customer chooses a payment method from a variety of payment
              methods available on MontraPay:
            </p>
            <ul>
              <li>
                <img src={qrCode} alt="qr scan" />
                QR Scan
              </li>
              <li>
                <img src={pod} alt="cards" />
                New or saved cards
              </li>
              <li>
                <img src={montraIcon} alt="montra app" />
                Pay using Montra App
              </li>
              <li className="paylistgate">
                <img src={ussd} alt="ussd" />
                USSD
              </li>
            </ul>
            <div className="info-3">
              <div className="info-line-vertical"></div>
              <section className="info-body">
                Customers use their bank's USSD app to complete payment
              </section>
            </div>
            <div className="info-4">
              <div className="info-line"></div>
              <section className="info-body">
                Customers can scan QR code from any Mobile Banking App & pay
                directly from their Bank Accounts
              </section>
            </div>
            <div className="info-5">
              <div className="info-line"></div>
              <section className="info-body">
                Montra customers receives notification on their Montra App to
                complete payment using Montra Account, Linked Bank Accounts,
                Linked cards & Installments
              </section>
            </div>
          </CardWithTriangle>
          <CardWithTriangle isLastCard={true} number={5}>
            <h5>Payment Settlement</h5>
            <p>
              Once the payment is received, MontraPay will release funds to the
              seller&apos;s Montra Business Account.
            </p>
          </CardWithTriangle>
        </div>
      </div>
      <div className="sdk">
        <h2>
          Simply start collecting payments by integrating MontraPay to your
          Website &amp; Mobile App
        </h2>
        <div className="sdk-wrapper">
          <div className="sdk-card">
            <img src={sdk1} alt="sdk 1" />
            <label>Web SDK</label>
          </div>
          <div className="sdk-card">
            <img src={sdk2} alt="sdk 2" />
            <label>Mobile SDK</label>
          </div>
        </div>
        <button className="cta">Go to API Docs</button>
      </div>
      <div className="tables">
        <h3>
          Merchants can enable Pay on Delivery (POD), Pay Now , Escrow Pay to
          start accepting payments using the following payment methods on
          MontraPay Payment Gateway
        </h3>
        <div className="grid margin-bottom">
          <DescriptionWithIcon icon={gateway1} title={"New & Saved Cards"}>
            Customers can pay using a new Debit or Credit Card that has not been
            saved on Montra Platform yet or can use the Saved cards to make
            payments without having to share card details again.
          </DescriptionWithIcon>
          <DescriptionWithIcon icon={gateway2} title={"QR Scan"}>
            Customers can scan the QR code shown on the web screen using any of
            their Mobile Banking App and make payments directly in to your
            Business Account
          </DescriptionWithIcon>
          <DescriptionWithIcon icon={gateway3} title={"Pay using Montra App"}>
            Registered Montra Customers would receive notification on their
            Montra App to complete the payment using all the payment options
            available on Montra App for making payment using Montra Account,
            Linked Accounts, Linked Cards & Installments. Once payment is
            completed, customer would receive confirmation on the web page from
            where Pay ON Montra App transaction was initiated.
          </DescriptionWithIcon>

          <DescriptionWithIcon icon={gateway4} title={"USSD"}>
            Customers would be able to complete the payment from their
            respective Bank Accounts using USSD code.
          </DescriptionWithIcon>
        </div>
        <h3>
          MontraPay offers three unique Payment Acceptance Methods which
          merchants can enable or disable on Montra App for a minimum threshold
          of payment amount for which these payment options would be offered to
          customers.
        </h3>
        <div className="payment-table-1 margin-bottom">
          <table>
            <thead>
              <tr>
                <th>Payment Acceptance Methods</th>
                <th>Customers can pay using</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="col-6">
                  <DescriptionWithIcon
                    icon={gateway1}
                    title={"Pay on Delivery (POD)"}
                  >
                    Where merchants enable this payment option, Montra would
                    send a Payment Link to customer&apos;s email which can be
                    used for making the payment on delivery.
                  </DescriptionWithIcon>
                </td>
                <td rowSpan={3} className="col-6">
                  <div className="table-grid no-margin">
                    <IconWithText icon={gateway1} text={"New & Saved Cards"} />
                    <IconWithText icon={gateway2} text={"QR Scan"} />
                    <IconWithText
                      icon={gateway3}
                      text={"Pay using Montra App"}
                    />
                    <IconWithText icon={gateway4} text={"USSD"} />
                  </div>
                </td>
              </tr>
              <tr className="col-6">
                <td className="col-6">
                  <DescriptionWithIcon icon={gateway5} title={"Pay Now"}>
                    Customers can simply Pay using Montra Account, Linked Bank
                    Accounts / Cards.
                  </DescriptionWithIcon>
                </td>
              </tr>
              <tr className="col-6">
                <td className="col-6">
                  <DescriptionWithIcon icon={gateway6} title={"Escrow Pay"}>
                    Customer can agree with Terms and Conditions for delivery of
                    a Product / Service from our merchant to make the payment in
                    an ESCROW account. Funds would be refunded or paid to the
                    merchant once the Product/ Services are delivered based on
                    agreed T&C.
                  </DescriptionWithIcon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="payoffers">
          MontraPay offers easy payment options for all
        </h3>
        <div className="payment-table-1 margin-bottom">
          <table>
            <thead>
              <tr>
                <th>Customers pay using</th>
                <th>Payment Acceptance Methods</th>
                <th>Customers can pay using</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textWrap: "nowrap" }}>Any Debit or Credit Card</td>
                <td>
                  <DescriptionWithIcon
                    icon={gateway1}
                    title={"New or Saved Cards"}
                  >
                    Customer can use any new or saved Debit or Credit card.
                  </DescriptionWithIcon>
                </td>
                <td>
                  <IconWithText icon={gateway7} text={"Debit / Credit Cards"} />
                </td>
              </tr>
              <tr>
                <td>Any Mobile Banking App</td>
                <td>
                  <DescriptionWithIcon icon={gateway2} title={"QR Scan"}>
                    Customer can Scan the QR from any mobile banking app to make
                    payment directly from the bank account.
                  </DescriptionWithIcon>
                </td>
                <td>
                  <IconWithText icon={gateway8} text={"Bank Accounts"} />
                </td>
              </tr>
              <tr>
                <td>Montra App</td>
                <td>
                  <DescriptionWithIcon
                    icon={gateway3}
                    title={"Pay using Montra App"}
                  >
                    Customers registered on Montra App can complete payment on
                    Montra App using variety of payment methods.
                  </DescriptionWithIcon>
                </td>
                <td>
                  <div className="row flex-nowrap">
                    <div className="col">
                      <IconWithText icon={gateway9} text={"Montra Account"} />
                    </div>
                    <div className="col">
                      <IconWithText
                        icon={gateway10}
                        text={"Limited Bank Accounts"}
                      />
                    </div>
                    <div className="col">
                      <IconWithText icon={gateway1} text={"Linked Cards"} />
                    </div>
                    <div className="col">
                      <IconWithText icon={gateway11} text={"Instalments"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Any USSD app</td>
                <td>
                  <DescriptionWithIcon icon={gateway4} title={"USSD"}>
                    Customers would be able to complete the payment from their
                    respective Bank Accounts using USSD code.
                  </DescriptionWithIcon>
                </td>
                <td>
                  <IconWithText icon={gateway8} text={"Bank Accounts"} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="paid-instantly paid-instantly-2">
          <h1>Get paid instantly with Payment Links</h1>
          <p>
            Sell online without a website or mobile app. Create a payment link
            in easy steps & share the link with your customers—no coding
            required.
          </p>
          <div className="row paid-instantly-row">
            <div className="horizontal-line"></div>
            <div className="col">
              <img src={payStep1} alt="" />
              <div className="open-acct-count paycount1 payopenl">1</div>
              <h6>Sign in to your Montra app / merchant portal</h6>
              <p>
                New users can Download Montra app to sign-up for Montra Business
                account. If you are a registered user, sign in on your Montra
                app or Montra Merchant Portal.
              </p>
            </div>
            <div className="col">
              <img src={payStep2} alt="" />
              <div className="open-acct-count paycount">2</div>
              <h6>Create a Payment Link</h6>
              <p>
                Create a Payment Link to offer the product or service you are
                selling.
              </p>
            </div>
            <div className="col">
              <img src={payStep3} alt="" />
              <div className="open-acct-count paycount">3</div>
              <h6>Share Payment Link & get paid</h6>
              <p>
                Share the Payment Link through SMS, Email, WhatsApp etc. with
                the customer to make payment.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="features">
          <h3 className="featurespay">
            MontraPay Designed to Handle End To End Payments
          </h3>
          <div className="grid margin-bottom">
            <FeatureDescriptionWithIcon icon={feature1}>
              <div className="lineparagrapproducts">
                <h5>Multiple Payment Options</h5>
              </div>
              <p className="custpmerpara">
                {" "}
                Customers can choose from the options (pay on Delivery (POD),
                Pay Now, Escrow Pay) enabled by merchants.{" "}
              </p>
            </FeatureDescriptionWithIcon>

            <FeatureDescriptionWithIcon icon={feature2}>
              <div className="lineparagrapproducts">
                <h5>Pay on Delivery</h5>
              </div>
              <p className="custpmerpara">
                {" "}
                When Merchants enable this payment option, Montra would send a
                Payment Link to customer’s email / Montra App which can be used
                for making the payment on delivery.{" "}
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature3}>
              <div className="lineparagrapproducts">
                <h5>Escrow Pay</h5>
              </div>
              <p className="custpmerpara">
                Customer can agree with Terms and Conditions for delivery of a
                Product / Service from our merchant to make the payment in an
                ESCROW account. Funds would be refunded or paid to the merchant
                once the Product/ Services are delivered based on agreed T&C.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature4}>
              <div className="lineparagrapproducts">
                <h5> Pay in Installments on Montra</h5>
              </div>
              <p className="custpmerpara">
                Customers can simply borrow instantly on Montra to pay to the
                merchant and repay in Installments.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature5}>
              <div className="lineparagrapproducts">
                <h5>Get paid instantly with Payment Links</h5>
              </div>
              <p className="custpmerpara">
                Sell online without a website or mobile app. Create a payment
                link in easy steps & share the link with your customers—no
                coding required.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature6}>
              <div className="lineparagrapproducts">
                <h5>Checkout & Global Card Saving</h5>
              </div>
              <p className="custpmerpara">
                An easy to integrate Checkout with cards saved across businesses
                so that your customers can pay seamlessly everywhere.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature7}>
              <div className="lineparagrapproducts">
                <h5>Powerful Dashboard</h5>
              </div>
              <p className="custpmerpara">
                Get reports and detailed statistics on payments, settlements,
                refunds and much more for you to take better business decisions.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature8}>
              <div className="lineparagrapproducts">
                <h5>Go live in minutes</h5>
              </div>
              <p className="custpmerpara">
                Create a free Montra Business Account in minutes and start
                accepting payments securely today by integrating to MontraPay.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature9}>
              <div className="lineparagrapproducts">
                <h5>Secure revenue growth</h5>
              </div>
              <p className="custpmerpara">
                MontraPay offers secure payments system that protects your money
                and data from fraud as you grow revenue.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature10}>
              <div className="lineparagrapproducts">
                <h5>Robust Security</h5>
              </div>
              <p className="custpmerpara">
                PCI DSS compliant along with frequent third-party audits and a
                dedicated internal security team to make sure your data is
                always safe.
              </p>
            </FeatureDescriptionWithIcon>
          </div>
          <h3>MontraPay, a more Secure Way to Pay</h3>
          <div className="grid">
            <FeatureDescriptionWithIcon icon={feature1}>
              <div className="lineparagrapproducts">
                <h5>Secure from the start</h5>
              </div>
              <p className="custpmerpara">
                Access to your Montra App is secured with the Password that you
                set-up. You can also access the app using your Face-ID or
                Biometric-ID that you have set-up on your phone.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={built1}>
              <div className="lineparagrapproducts">
                <h5>Built in protection before you pay</h5>
              </div>
              <p className="custpmerpara">
                Every payment request is processed only upon successful
                authentication of the Montra PIN you have set-up.{" "}
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={feature3}>
              <div className="lineparagrapproducts">
                <h5>Secure with 2FA</h5>
              </div>
              <p className="custpmerpara">
                Our Fraud & Risk Assessment tool would assess the risk for every
                transaction and would ask for your Soft Token from time to time.
                Montra offers access to Free Soft Token on VERIFYED app for
                every Montra Customer.
              </p>
            </FeatureDescriptionWithIcon>
            <FeatureDescriptionWithIcon icon={built2}>
              <div className="lineparagrapproducts">
                <h5>Payment Privacy</h5>
              </div>
              <p className="custpmerpara">
                Only you and the person you are transacting with will be able to
                see the details of your payment.
              </p>
            </FeatureDescriptionWithIcon>
          </div>
        </div>
      </div>
      {/* <div className="pricing">
        <h2>Simple, Transparent, and Fair Pricing</h2>
        <p className="bestprice">
          Best prices to suit small businesses, medium, and large organizations
          through variety of innovative payment options facilitating collection
          of both upfront payments & credit-based sales.
        </p>
        <div className="tags">
          <div className="tag">Zero setup fees</div>
          <div className="tag">Zero maintenance fees</div>
          <div className="tag">No hidden charges</div>
        </div>
        <div className="prices">
          <div className="price-item">
            <div className="price-icon">
              <img src={price1} alt="price 1" />
            </div>
            <h6>
              International Card <br /> Payment Transactions
            </h6>
            <hr />
            <h1>3.8%</h1>
            <label>Per Transaction</label>
            <hr />
            <ul>
              <li>No Setup Cost</li>
              <li>Accept MasterCard, Visa, Verve card payments</li>
              <li>3.8% or ₦ 30 Fee</li>
            </ul>
          </div>
          <div className="price-item">
            <div className="price-icon">
              <img src={price2} alt="price 2" />
            </div>
            <h6>
              Local Card Payment <br />
              Transactions
            </h6>
            <hr />
            <h1>1.4%</h1>
            <label>Per Transaction</label>
            <hr />
            <ul>
              <li>No Setup Cost</li>
              <li>Accept MasterCard, Visa, Verve card payments</li>
              <li>
                1.4% or ₦ 30 Fee, <br />
                Fee is capped at ₦ 2,000
              </li>
            </ul>
          </div>
          <div className="price-item">
            <div className="price-icon">
              <img src={price3} alt="price 3" />
            </div>
            <h6>
              Local Card Payment <br />
              Transactions
            </h6>
            <hr />
            <h1>1.25%</h1>
            <label>Per Transaction</label>
            <hr />
            <ul>
              <li>No Setup Cost</li>
              <li>
                Customers get instant loans subject to eligibility from our
                Lending Partners on Montra App
              </li>
            </ul>
          </div>
          <div className="price-item">
            <div className="price-icon">
              <img src={price4} alt="price 4" />
            </div>
            <h6>Bank Account Transactions</h6>
            <hr />
            <h1>1%</h1>
            <label>Per Transaction</label>
            <hr />
            <ul>
              <li>No Setup Cost</li>
              <li>
                Customers can transfer from Bank Accounts using QR Pay or USSD
                on MontraPay payment gateway or from Linked Bank Accounts on
                Montra App
              </li>
            </ul>
          </div>
          <div className="price-item">
            <div className="price-icon">
              <img src={price5} alt="price 5" />
            </div>
            <h6>Montra Account</h6>
            <hr />
            <h1>0.75%</h1>
            <label>Per Transaction</label>
            <hr />
            <ul>
              <li>No Setup Cost</li>
              <li>
                Customers can make payment using Montra App from their Montra
                Accounts
              </li>
            </ul>
          </div>
        </div>
        <button className="cta center">Contact Sales</button>
      </div> */}
    </section>
  );
};

export default Body;
