/* React packages */
import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import CompanyProfile from "./CompanyProfile";
import Premium from "./Premium";
import PaylinkComission from "./PaylinkComission";
import MakerHeader from "./MakerHeader";
import CommonHeader from "../header/CommonHeader";

/**
* Renders profile  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      editable: false,
      buttoncolor: "",
      isCLIPremPercentlist: [],
      isAIPremPercentlist: [],
      companyDtoBordercolor: {
        companyName: "",
        companyType: "",
        country: "",
        cliPremPercent: "",
        aiPremPercent: "",
      },
      data: {
        companyDto: {
          companyName: "",
          companyId: "",
          companyType: "",
          country: "",
          currency: "",
          registrationDate: "",
          status: "",
          insPartnerForCLI: "",
          insPartnerForAI: "",
          isCompanyName: "",
          isInsPartnerForCLI: "",
          isInsPartnerForAI: "",
          isCompanyType: "",
          isCountry: "",
        },
        paylinkCommission: [
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
        ],
        collectionBuckets: [
          {
            collXdays: "",
            coll30DPD: "",
            coll60DPD: "",
            coll90DPD: "",
            coll120DPD: "",
            collBET180n360DPD: "",
            collGT360DPD: "",
          },
        ],
        insuranceCommissions: [{ premCollectedPercent: "" }],
        creditLifeAndAssetInsPremCheckers: [
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
        ],
      },
    };
  }
  /**
   * fetch data from server and set to fields
   */
  componentDidMount() {
    // Define the URL and options for the fetch request
    const url = new URL("https://api.montra.org/moneylinkweb/api/user/profile");
    url.searchParams.append("email", localStorage.getItem("email"));

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Server error");
        }
        return response.json();
      })
      .then((contents) => {
        // Update state with the fetched data
        const { Profile, editable } = contents.data;

        this.setState(
          {
            data: Profile,
            editable,
            buttoncolor: editable ? "#192859" : "#343a40",
          },
          () => {
            // Call other methods that depend on the state update
            if (this.state.editable) {
              this.setBorderColor();
            }
            this.setNvalue();
          }
        );
      })
      .catch((error) => {
        // Handle errors here
        console.error("Failed to fetch:", error);
        alert("An error occurred while fetching data.");
      });
  }
  /**
   * Set dafault for new fresh enalbe request after approval from checker
   */
  setNvalue() {
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (CheckerProfile, i) => {
        CheckerProfile.isAIPremPercent = "N";
        CheckerProfile.isCLIPremPercent = "N";
      }
    );
    this.state.data.companyDto.isInsPartnerForCLI = "N";
    this.state.data.companyDto.isInsPartnerForAI = "N";
    this.state.data.companyDto.isCompanyName = "N";
    this.state.data.companyDto.isCompanyType = "N";
    this.state.data.companyDto.isCountry = "N";
  }
  setBorderColor() {
    // Create new copies of state variables to avoid direct mutation
    const newIsAIPremPercentlist = [...this.state.isAIPremPercentlist];
    const newIsCLIPremPercentlist = [...this.state.isCLIPremPercentlist];
    const newCompanyDtoBordercolor = { ...this.state.companyDtoBordercolor };

    // Update border colors based on conditions
    this.state.data.creditLifeAndAssetInsPremCheckers.forEach(
      (checkerProfile, i) => {
        if (checkerProfile.isAIPremPercent === "A") {
          newIsAIPremPercentlist[i] = "#e90505";
        }
        if (checkerProfile.isCLIPremPercent === "A") {
          newIsCLIPremPercentlist[i] = "#e90505";
        }
      }
    );

    if (this.state.data.companyDto.isInsPartnerForCLI === "A") {
      newCompanyDtoBordercolor.cliPremPercent = "#e90505";
    }
    if (this.state.data.companyDto.isInsPartnerForAI === "A") {
      newCompanyDtoBordercolor.aiPremPercent = "#e90505";
    }
    if (this.state.data.companyDto.isCompanyName === "A") {
      newCompanyDtoBordercolor.companyName = "#e90505";
    }
    if (this.state.data.companyDto.isCompanyType === "A") {
      newCompanyDtoBordercolor.companyType = "#e90505";
    }
    if (this.state.data.companyDto.isCountry === "A") {
      newCompanyDtoBordercolor.country = "#e90505";
    }

    // Update state with new values
    this.setState({
      companyDtoBordercolor: newCompanyDtoBordercolor,
      isCLIPremPercentlist: newIsCLIPremPercentlist,
      isAIPremPercentlist: newIsAIPremPercentlist,
    });
  }
  /**
   * Capture the change made by maker,stores into data object for company details.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeCompanyProfile(e) {
    let data = this.state.data;
    if ("companyName" == e.target.name) {
      data.companyDto.companyName = e.target.value;
      this.state.data.companyDto.isCompanyName = "A";
      if ("" == data.companyDto.companyName) {
        this.setState({
          data: data,
          fieldsvalid: true,
        });
      } else {
        this.setState({
          data: data,
          fieldsvalid: false,
        });
      }
    } else if ("companyType" == e.target.name) {
      data.companyDto.companyType = e.target.value;
      this.state.data.companyDto.isCompanyType = "A";
      if ("" == data.companyDto.companyType) {
        this.setState({
          data: data,
          fieldsvalid: true,
        });
      } else {
        this.setState({
          data: data,
          fieldsvalid: false,
        });
      }
    } else if ("country" == e.target.name) {
      data.companyDto.country = e.target.value;
      this.state.data.companyDto.isCountry = "A";
      if ("" == data.companyDto.country) {
        this.setState({
          data: data,
          fieldsvalid: true,
        });
      } else {
        this.setState({
          data: data,
          fieldsvalid: false,
        });
      }
    } else if ("lifeinsurance" == e.target.name) {
      data.companyDto.insPartnerForCLI = e.target.value;
      this.state.data.companyDto.isInsPartnerForCLI = "A";
      if ("" == data.companyDto.insPartnerForCLI) {
        this.setState({
          data: data,
          fieldsvalid: true,
        });
      } else {
        this.setState({
          data: data,
          fieldsvalid: false,
        });
      }
    } else if ("assetinsurance" == e.target.name) {
      data.companyDto.insPartnerForAI = e.target.value;
      this.state.data.companyDto.isInsPartnerForAI = "A";
      if ("" == data.companyDto.insPartnerForAI) {
        this.setState({
          data: data,
          fieldsvalid: true,
        });
      } else {
        this.setState({
          data: data,
          fieldsvalid: false,
        });
      }
    }
  }
  /**
   * Capture the change made by maker,stores into data object for aiprempercent.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeaiPremPercent(e, i) {
    let data = this.state.data;
    data.creditLifeAndAssetInsPremCheckers[i].aiPremPercent = e.target.value;
    data.creditLifeAndAssetInsPremCheckers[i].isAIPremPercent = "A";
    if ("" == data.creditLifeAndAssetInsPremCheckers[i].aiPremPercent) {
      this.setState({
        data: data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data: data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for cliprempercent.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangecliPremPercent(e, i) {
    let data = this.state.data;
    data.creditLifeAndAssetInsPremCheckers[i].cliPremPercent = e.target.value;
    data.creditLifeAndAssetInsPremCheckers[i].isCLIPremPercent = "A";
    if ("" == data.creditLifeAndAssetInsPremCheckers[i].cliPremPercent) {
      this.setState({
        data: data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data: data,
        fieldsvalid: false,
      });
    }
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "MAKER" == localStorage.getItem("role").trim()
    ) {
      return (
        <div>
          {/* top-Header */}
          {/* <MakerHeader /> */}
          <CommonHeader />

          {/* header menu */}

          <Sidebar />

          {(() => {
            if (this.state.editable) {
              return (
                <div className="errorMsg">
                  {" "}
                  <p className="toperror">
                    There is Change Request yet to be approved by checker. A new
                    request can be submitted once checker approves the request
                  </p>
                </div>
              );
            }
          })()}
          <div id="maincontent" className="content">
            <section id="content-wrapper">
              <div className="backloan">
                <div className="container-fluid containermaker">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 profilediv">
                      <CompanyProfile
                        companyDtoBordercolor={this.state.companyDtoBordercolor}
                        data={this.state.data}
                        editable={this.state.editable}
                        handleChangeCompanyProfile={(e) =>
                          this.handleChangeCompanyProfile(e)
                        }
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 profilesecdiv">
                      <PaylinkComission data={this.state.data} />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <Premium
                        isAIPremPercentlist={this.state.isAIPremPercentlist}
                        isCLIPremPercentlist={this.state.isCLIPremPercentlist}
                        buttoncolor={this.state.buttoncolor}
                        editable={this.state.editable}
                        handleChangeaiPremPercent={(e, i) =>
                          this.handleChangeaiPremPercent(e, i)
                        }
                        handleChangecliPremPercent={(e, i) =>
                          this.handleChangecliPremPercent(e, i)
                        }
                        data={this.state.data}
                        // handlestatus={(statusres) => setResStatus(statusres)}
                        fieldsvalid={this.state.fieldsvalid}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    } else {
      return <p>Please Login As a AdminMaker</p>;
    }
  }
}

export default Profile;
