import React from "react";
import Moneylinklogo from "../../../assets/images/moneylink.svg";
import Moneylinkplatform from "../../../assets/images/moneylinkimg.svg";
// import Moneylinkimg from "../../../assets/images/moneylinkplatform.svg";
import "./style.css";
import HomeMoneylinkProcessContainer from "./HomeMoneylinkProcessContainer";

const Main = () => {
  return (
    <>
      <div className="home-moneylink moneylink">
        <div className="row">
          <div className="col-md-7 duo-col">
            <div className="sol-logo">
              <img className="moneylink-logo" src={Moneylinklogo} alt="image" />
            </div>
            <h1 className="money-h1">
              Modern Fintech Platform that empowers innovators
            </h1>
            <p>
              Modern Card Issuing platform, POS acquiring platform, lending &
              banking platform connecting banks, fintechs, and businesses with
              custom APIs for a wide range of financial services.
            </p>
            <a href="/Platform/Moneylink/cip">
              <button>
                Explore MoneyLink Platforms <i class="bi bi-chevron-right"></i>
              </button>
            </a>
            {/* <img className="direction-img" src={Moneylinkimg} alt="image" /> */}
            <HomeMoneylinkProcessContainer />
            {/* <div className="direction-img">
              
            </div> */}
          </div>
          <div className="col-md-5 moneylink-img-img">
            <img src={Moneylinkplatform} alt="image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
