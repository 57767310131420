/* React packages */
import React from "react";
import ReactTooltip from "react-tooltip";

/**
 * Renders company profile of partner in profile  of maker section.
 * <p>Details about partner can be edited and sent to server by maker.</p>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
 * <li> <b>select :-</b> This tag is use for element is used to create a drop-down list. </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>


 *  @Author : Seema Kumari
 *  @version :1.0
 */

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange(e) {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
    this.props.onchange(e);
  }

  render() {
    return (
      <div className="proresponsive">
        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
          Non Editable
        </ReactTooltip> */}

        {/* Form -: in this form section including dropdown and text boxes for render the data  */}
        <form method="post" name="userRegistrationForm" className="formroot">
          {/* Title */}
          <p className="signinheader">COMPANY PROFILE</p>

          <div className="form-group companyformprofile">
            <label htmlFor="lendingcompanyname" className="profile-label">
              Lending Company Name
            </label>
            <div className="input-container">
              <input
                type="tlendingcompanynameext"
                className="form-control forminputfield "
                data-tip
                data-tip-disable={!this.props.editable}
                data-for="noneditable"
                name="companyName"
                value={this.props.data.companyDto.companyName}
                onChange={this.props.handleChangeCompanyProfile.bind(this)}
                style={{
                  borderColor: this.props.companyDtoBordercolor.companyName,
                }}
              />
            </div>
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="lendingcompanyid" className="profile-label">
              {" "}
              Lending Company ID{" "}
            </label>
            <input
              type="text"
              className="form-control inputfield"
              data-tip
              data-tip-disable={!this.props.editable}
              data-for="noneditable"
              name="lendingcompanyid"
              value={this.props.data.companyDto.companyId}
              disabled
            />
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="lendingcompanytype" className="profile-label">
              Lending Company Type
            </label>
            <div className="input-container">
              <select
                className="form-control forminputfield makcomp"
                data-tip
                data-tip-disable={!this.props.editable}
                data-for="noneditable"
                name="companyType"
                value={this.props.data.companyDto.companyType}
                onChange={this.props.handleChangeCompanyProfile.bind(this)}
                style={{
                  borderColor: this.props.companyDtoBordercolor.companyType,
                }}
              >
                <option value="">Select Partner Type</option>
                <option value="Bank">Bank</option>
              </select>
            </div>
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="country" className="profile-label">
              Country
            </label>
            <div className="input-container">
              <select
                className="form-control forminputfield makcomp"
                name="country"
                data-tip
                data-tip-disable={!this.props.editable}
                data-for="noneditable"
                value={this.props.data.companyDto.country}
                onChange={this.props.handleChangeCompanyProfile.bind(this)}
                style={{
                  borderColor: this.props.companyDtoBordercolor.country,
                }}
              >
                <option value="">Select Country</option>
                <option value="Nigeria">Nigeria</option>
              </select>
            </div>
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="currency" className="profile-label">
              Currency
            </label>
            <input
              type="text"
              className="form-control inputfield"
              name="currency"
              value={this.props.data.companyDto.currency}
              disabled
            />
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="dateofregitration" className="profile-label">
              Date of registration
            </label>
            <input
              type="text"
              className="form-control inputfield"
              name="dateregistration"
              value={this.props.data.companyDto.registrationDate}
              disabled
            />
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="status" className="profile-label">
              Status{" "}
            </label>
            <div className="input-container">
              <input
                type="text"
                className="form-control  inputfield"
                name="status"
                value={this.props.data.companyDto.status}
                disabled
              />
            </div>
          </div>

          <div className="form-group companyformprofile">
            <label
              htmlFor="insurancePartnerforCreditLifeInsurance"
              className="profile-label"
            >
              Insurance Partner for Credit Life Insurance{" "}
            </label>
            <div className="input-container">
              <input
                type="text"
                className="form-control forminputfield"
                data-tip
                data-tip-disable={!this.props.editable}
                data-for="noneditable"
                name="lifeinsurance"
                value={this.props.data.companyDto.insPartnerForCLI}
                onChange={this.props.handleChangeCompanyProfile.bind(this)}
                style={{
                  borderColor: this.props.companyDtoBordercolor.cliPremPercent,
                }}
              />
            </div>
          </div>

          <div className="form-group companyformprofile">
            <label htmlFor="assetinsurance" className="profile-label">
              Insurance Partner for Asset Insurance{" "}
            </label>
            <div className="input-container">
              <input
                type="text"
                className="form-control forminputfield"
                data-tip
                data-tip-disable={!this.props.editable}
                data-for="noneditable"
                name="assetinsurance"
                value={this.props.data.companyDto.insPartnerForAI}
                onChange={this.props.handleChangeCompanyProfile.bind(this)}
                style={{
                  borderColor: this.props.companyDtoBordercolor.aiPremPercent,
                }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CompanyProfile;
