import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
// import storehero from "../../../assets/images/storehero.png";
import storehero from "../../../assets/images/uday/storehero.webp";
import loan1 from "../../../assets/images/storeicon.svg";
import "./style.css";
import StoreProcessContainer from "./StoreProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="store-hero ">
        <div className="row personalb">
          <div className="col-md-7 store-herpo-content responsivebusiness-col">
            <div className="store-sm-btn">
              <img
                src={loan1}
                alt="dlm-sm-btn-image"
                className="dlm-sm-btn-image"
              />
              <p>Montra Store</p>
            </div>
            <p className="donthave">Don’t have a website for your business?</p>
            <h1>
              Start selling online on Montra App by setting up your{" "}
              <span>Montra Store</span>
            </h1>
            <p>
              Create a free online customized Montra store for your business
              category that helps you find customers, accept payments from
              anyone, and grow your business.
            </p>

            <StoreProcessContainer />
            <div className="app-download agencyapp">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col-md-5 store-hero2 responsivebusiness-col">
            <img src={storehero} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
