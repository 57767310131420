/* React packages  */
import React from 'react';
import ReactTooltip from "react-tooltip";


/**
* Renders part of underwritting rules  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class UndWrittingPart5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {}
    }
  };

  render() {

    return (

      <div>
        {/* Form -: in this form section included label, togglebutton and dropdown to render the data */}

        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 underfifth">

                <div className="d-flex flex-row mt-3 ">
                  <div className="p-2 headerbuttonsright">
                    <div className="input-group Multiplier2">
                      <p className="text2part">Multiplier <br /> Status</p>
                      <label className="switch"><input type="checkbox"  name="numYrsEmplyIntRateMultStatus" onChange={this.props.toggleChange.bind(this)} checked={this.props.data[0].numYrsEmplyIntRateMultStatus} /><div className="slider round" ></div></label>

                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">No. of years in employment- Interest Rate Multiplier</p>
                    <p className="textlabel">No. of Years</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar">
                  <div className="p-2 underwritepart5">
                    <div className="form-group">
                      <label className="labelpt-8 underprolab">Product</label>
                      {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled >{underWritingRules.productName}</label></div>)}
                    </div>
                  </div>


                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {"<"}1 Year</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsEmplyIntMultRateMultLess1yrlist[i] }} name="numYrsEmplyIntMultRateMultLess1yr" onChange={(e) => { this.props.handleChangenumYrsEmplyIntMultRateMultLess1yr(e, i) }} placeholder={underWritingRules.numYrsEmplyIntMultRateMultLess1yr} disabled={this.props.numYrsEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsEmplyIntMultRateMultLess1yr}>{underWritingRules.numYrsEmplyIntMultRateMultLess1yr}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>1 – 3 Years</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] }} name="numYrsEmplyIntMultRateMultBET1n3yrs" onChange={(e) => { this.props.handleChangenumYrsEmplyIntMultRateMultBET1n3yrs(e, i) }} placeholder={underWritingRules.numYrsEmplyIntMultRateMultBET1n3yrs} disabled={this.props.numYrsEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsEmplyIntMultRateMultBET1n3yrs}>{underWritingRules.numYrsEmplyIntMultRateMultBET1n3yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>3 – 5 Years</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] }} name="numYrsEmplyIntMultRateMultBET3n5yrs" onChange={(e) => { this.props.handleChangenumYrsEmplyIntMultRateMultBET3n5yrs(e, i) }} placeholder={underWritingRules.numYrsEmplyIntMultRateMultBET3n5yrs} disabled={this.props.numYrsEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsEmplyIntMultRateMultBET3n5yrs}>{underWritingRules.numYrsEmplyIntMultRateMultBET3n5yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>5 – 10 Years</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] }} name="numYrsEmplyIntMultRateMultBET5n10yrs" onChange={(e) => { this.props.handleChangenumYrsEmplyIntMultRateMultBET5n10yrs(e, i) }} placeholder={underWritingRules.numYrsEmplyIntMultRateMultBET5n10yrs} disabled={this.props.numYrsEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsEmplyIntMultRateMultBET5n10yrs}>{underWritingRules.numYrsEmplyIntMultRateMultBET5n10yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {">"} 10 Years"</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsEmplyIntRateMultGT10yrslist[i] }} name="numYrsEmplyIntRateMultGT10yrs" onChange={(e) => { this.props.handleChangenumYrsEmplyIntRateMultGT10yrs(e, i) }} placeholder={underWritingRules.numYrsEmplyIntRateMultGT10yrs} disabled={this.props.numYrsEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsEmplyIntRateMultGT10yrs}>{underWritingRules.numYrsEmplyIntRateMultGT10yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 underfifth">

                <div className="d-flex flex-row mt-3 ">

                  <div className="p-2 headerbuttons">
                    <div className="input-group Multiplier2">
                      <p className="text2part">Multiplier <br /> Status</p>
                      <label className="switch"><input type="checkbox" id="togBtn" name="deliquencyIntRateMultStatus" onChange={this.props.toggleChange.bind(this)} checked={this.props.data[0].deliquencyIntRateMultStatus} /><div className="slider round" ></div></label>
                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">Delinquency Status - Interest Rate Multiplier</p>
                    <p className="textlabel">Days Past Due (DPD)</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar ">
                  <div className="p-2 underwritepart5">

                    <div className="form-group">
                      <label className="labelpt-8 underprolab">Product</label>
                      {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled >{underWritingRules.productName}</label></div>)}
                    </div>
                  </div>


                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>Current</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isDeliquencyIntRateMultCurrentlist[i] }} name="deliquencyIntRateMultCurrent" onChange={(e) => { this.props.handleChangedeliquencyIntRateMultCurrent(e, i) }} placeholder={underWritingRules.deliquencyIntRateMultCurrent} disabled={this.props.deliquencyIntRateMultStatusdisable}>
                          <option value={underWritingRules.deliquencyIntRateMultCurrent}>{underWritingRules.deliquencyIntRateMultCurrent}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>X - Days</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isDeliquencyIntRateMultXDayslist[i] }} name="deliquencyIntRateMultXDays" onChange={(e) => { this.props.handleChangedeliquencyIntRateMultXDays(e, i) }} placeholder={underWritingRules.deliquencyIntRateMultXDays} disabled={this.props.deliquencyIntRateMultStatusdisable}>
                          <option value={underWritingRules.deliquencyIntRateMultXDays}>{underWritingRules.deliquencyIntRateMultXDays}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>30 DPD</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isDeliquencyIntRateMult30DPDlist[i] }} name="deliquencyIntRateMult30DPD" onChange={(e) => { this.props.handleChangedeliquencyIntRateMult30DPD(e, i) }} placeholder={underWritingRules.deliquencyIntRateMultXDays} disabled={this.props.deliquencyIntRateMultStatusdisable}>
                          <option value={underWritingRules.deliquencyIntRateMult30DPD}>{underWritingRules.deliquencyIntRateMult30DPD}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled>60 DPD</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isDeliquencyIntRateMult60DPDlist[i] }} name="deliquencyIntRateMult60DPD" onChange={(e) => { this.props.handleChangedeliquencyIntRateMult60DPD(e, i) }} placeholder={underWritingRules.deliquencyIntRateMult60DPD} disabled={this.props.deliquencyIntRateMultStatusdisable}>
                          <option value={underWritingRules.deliquencyIntRateMult60DPD}>{underWritingRules.deliquencyIntRateMult60DPD}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}

                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {">"}90 DPD</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isDeliquencyIntRateMultGT90DPDlist[i] }} name="deliquencyIntRateMultGT90DPD" onChange={(e) => { this.props.handleChangedeliquencyIntRateMultGT90DPD(e, i) }} placeholder={underWritingRules.deliquencyIntRateMultGT90DPD} disabled={this.props.deliquencyIntRateMultStatusdisable}>
                          <option value={underWritingRules.deliquencyIntRateMultGT90DPD}>{underWritingRules.deliquencyIntRateMultGT90DPD}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>   <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                     </ReactTooltip>

                      </div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </form>



      </div>
    )

  }


}

export default UndWrittingPart5;
