import React from "react";
import YU7 from "../../../assets/svg/yu7.jsx";
import YU8 from "../../../assets/svg/yu8.jsx";
import YU9 from "../../../assets/svg/yu9.jsx";
import YU10 from "../../../assets/svg/yu10.jsx";
import YU11 from "../../../assets/svg/yu11.jsx";
import YU12 from "../../../assets/svg/yu12.jsx";
import YU13 from "../../../assets/svg/yu13.jsx";
import "./style.css";
import MontraCustomers from "../../../assets/svg/montra-customers.jsx";
import MontraMerchants from "../../../assets/images/uday/svg/merchantBlue.jsx";
import MontraAgent from "../../../assets/svg/montra-agent.jsx";

import QRScan from "../../../assets/svg/qr-scan.jsx";
import TapAndPay from "../../../assets/svg/tap-and-pay.jsx";
import POS from "../../../assets/svg/pos.jsx";
import DebitOrCredit from "../../../assets/svg/debit-or-credit.jsx";
import BankTransfer from "../../../assets/svg/bank-transfer.jsx";
import MobileOrContact from "../../../assets/svg/mobile-or-contact.jsx";
import CashWithdrawal from "../../../assets/svg/cash-withdrawal.jsx";
import CashDeposit from "../../../assets/svg/cash-deposit.jsx";
import CashPayment from "../../../assets/svg/cash-payment.jsx";
import BillPayment from "../../../assets/svg/bill-payment.jsx";
import MontraAccount from "../../../assets/svg/montra-account.jsx";

import PGSKEBICON1 from "../../../assets/images/uday/montra-PG/savedcardicon.jsx";
import PGSKEBICON2 from "../../../assets/images/uday/montra-PG/qrscanicon.jsx";
import PGSKEBICON3 from "../../../assets/images/uday/montra-PG/montrappicon.jsx";
import PGSKEBICON4 from "../../../assets/images/uday/montra-PG/ussdicon.jsx";
import PG1 from "../../../assets/svg/PG1.jsx";
import PG4 from "../../../assets/svg/PG4.jsx";
import PG3 from "../../../assets/svg/PG3.jsx";
import PG2 from "../../../assets/svg/PG2.jsx";
import Biller from "../../../assets/svg/billerIcon.jsx";
import AnyBankAccount from "../../../assets/svg/anyBankAccountIcon.jsx";
// import PgMobileDesktop from "../../../assets/images/uday/payment-gateway-home.svg";
import PgMobileDesktop from "../../../assets/images/uday/New-Images/image/payment_gateway.webp";
import YU19 from "../../../assets/svg/yu19.jsx";
import YU20 from "../../../assets/svg/yu20.jsx";
import YU21 from "../../../assets/svg/yu21.jsx";
import YU19b from "../../../assets/svg/yu19b.jsx";

export const Animation1 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-92 yu-absolute ${className}`}>
      <div className="yu-mr-3 z-1">
        <div
          className="ske-b mt24"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MontraCustomers />
          <p className="anim-p">Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt24">
          <QRScan />
          <p className="anim-p">QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt-2">
          <MontraMerchants />
          <p className="anim-p">Montra Merchant</p>
        </div>
      </div>
      <div className="yu-absolute yu-mt-x ml60">
        <YU7 />
      </div>
    </div>
  );
};

export const Animation2 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-92 yu-absolute ${className}`}>
      <div className="yu-mr-3 z-1">
        <div
          className="ske-b mt215"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DebitOrCredit />
          <p className="anim-p">Any Debit Or Credit Card</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt120">
        <div className="ske-b mt30">
          <TapAndPay />
          <p className="anim-p">Tap & Pay</p>
        </div>
        <div className="ske-b mt38">
          <POS />
          <p className="anim-p">POS</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt190">
        <div className="ske-b mt30">
          <MontraMerchants />
          <p className="anim-p">Montra Merchant</p>
        </div>
      </div>
      <div className="yu-absolute mt195 ml96">
        <YU8 />
      </div>
    </div>
  );
};

export const Animation3 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-92 yu-absolute ${className}`}>
      <div className="yu-mr-3 z-1">
        <div
          className="ske-b mt475"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MobileOrContact />
          <p className="anim-p">Any Mobile Banking App</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1 mt40">
        <div className="ske-b mt370">
          <QRScan />
          <p className="anim-p">QR Scan</p>
        </div>
        <div className="ske-b mt38">
          <BankTransfer />
          <p className="anim-p">Bank Transfer</p>
        </div>
      </div>
      <div className="yu-mr-3 z-1">
        <div className="ske-b mt-2">
          <MontraMerchants />
          <p className="anim-p">Montra Merchant</p>
        </div>
      </div>

      <div className="yu-absolute mt280 ml95">
        <YU9 />
      </div>
    </div>
  );
};

export const Animation4 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div
          className="ske-b"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MontraCustomers />
          <p className="anim-p">Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <CashDeposit />
          <p className="anim-p">Cash Withdrawal</p>
        </div>
        {/* <div className="ske-b mt8">
          <CashDeposit />
          <p className="anim-p">Cash Withdrawal</p>
        </div> */}
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <QRScan />
          <p className="anim-p">QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraAgent />
          <p className="anim-p">Montra Agents</p>
        </div>
      </div>
      <div className="yu-absolute mt35 ml96">
        <YU10 />
      </div>
    </div>
  );
};
export const Animation4a = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div
          className="ske-b"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MontraCustomers />
          <p className="anim-p">Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        {/* <div className="ske-b">
          <CashWithdrawal />
          <p className="anim-p">Cash Deposit</p>
        </div> */}
        <div className="ske-b mt105">
          {/* <CashDeposit />
          <p className="anim-p">Cash Withdrawal</p> */}
          <CashWithdrawal />
          <p className="anim-p">Cash Deposit</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <QRScan />
          <p className="anim-p">QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraAgent />
          <p className="anim-p">Montra Agents</p>
        </div>
      </div>
      <div className="yu-absolute mt100 ml40">
        <YU20 />
      </div>
    </div>
  );
};

export const Animation5 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div
          className="ske-b mt168"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DebitOrCredit />
          <p className="anim-p">Any Debit or Credit Card</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt220">
          <CashDeposit />
          <p className="anim-p">Cash Withdrawal</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt116">
          <TapAndPay />
          <p className="anim-p">Tap & Pay</p>
        </div>
        <div className="ske-b mt8">
          <POS />
          <p className="anim-p">POS</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraAgent />
          <p className="anim-p">Montra Agents</p>
        </div>
      </div>
      <div className="yu-absolute mt215 ml96">
        <YU11 />
      </div>
    </div>
  );
};

export const Animation6 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div
          className="ske-b mt336"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MobileOrContact />
          <p className="anim-p">Any Mobile Banking App</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt336">
          <CashDeposit />
          <p className="anim-p">Cash Withdrawal</p>
        </div>
        {/* <div className="ske-b mt8">
          <CashWithdrawal />
          <p className="anim-p">Cash Deposit</p>
        </div> */}
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt336">
          <QRScan />
          <p className="anim-p">QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraAgent />
          <p className="anim-p">Montra Agents</p>
        </div>
      </div>

      <div className="yu-absolute mt375 ml45">
        <YU12 />
      </div>
    </div>
  );
};
export const Animation6a = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div
          className="ske-b mt336"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MobileOrContact />
          <p className="anim-p">Any Mobile Banking App</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        {/* <div className="ske-b mt336">
        <CashDeposit />
          <p className="anim-p">Cash Withdrawal</p>
        </div> */}
        <div className="ske-b mt440">
          <CashWithdrawal />
          <p className="anim-p">Cash Deposit</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b mt336">
          <QRScan />
          <p className="anim-p">QR Scan</p>
        </div>
      </div>
      <div className="yu-mr-4 z-1">
        <div className="ske-b">
          <MontraAgent />
          <p className="anim-p">Montra Agents</p>
        </div>
      </div>

      <div className="yu-absolute mt427 ml40">
        <YU21 />
      </div>
    </div>
  );
};

export const Animation7 = ({
  className,
  onMouseEnter,
  onMouseLeave,
  anim2,
  path,
  classname,
}) => {
  return (
    <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
      <div className="yu-mr-4 z-1">
        <div
          className="ske-b mt500"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <CashPayment />
          <p className="anim-p">Cash Payments</p>
        </div>
      </div>
      <div
        className={
          anim2 ? "yu-mr-4 z-1 animation7-montraagent" : '"yu-mr-4 z-1'
        }
      >
        <div
          className={
            classname === classname ? `ske-b ml337 ${classname}` : "ske-b ml337"
          }
        >
          <MontraAgent />
          <p className="anim-p">Montra Agents</p>
        </div>
      </div>
      <div className="yu-absolute mt370 ml96">
        <YU13 path={path} />
      </div>
    </div>
  );
};
export const Animation7a = ({
  className,
  onMouseEnter,
  onMouseLeave,
  anim2,
  path,
}) => {
  let classname = "animation7a_in_7a";
  return (
    <>
      <Animation7 path={0} classname={classname} />
      <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
        <div className="yu-mr-4 z-1">
          <div className="ske-b mt620">
            <Biller />
            <p className="anim-p">Biller</p>
          </div>
          <div className="ske-b mt24">
            <AnyBankAccount />
            <p className="anim-p">Any Bank Account</p>
          </div>
        </div>
        <div className="yu-mr-4 z-1">
          <div className="ske-b mt680">
            <BillPayment />
            <p className="anim-p">Recharge & Bills Payment</p>
          </div>
          <div className="ske-b mt20">
            <BankTransfer />
            <p className="anim-p">Bank Transfers</p>
          </div>
        </div>
        <div className="yu-mr-4 z-1">
          <div className="ske-b mt680">
            <MontraAccount />
            <p className="anim-p">Montra Agent Account</p>
          </div>
        </div>
        <div
          className={
            anim2 ? "yu-mr-4 z-1 animation7-montraagent" : "yu-mr-4 z-1"
          }
        >
          <div className="ske-b">
            <MontraAgent />
            <p className="anim-p">Montra Agents</p>
          </div>
        </div>
        <div className="yu-absolut mt427 ml-285">
          <YU19 path={path} />
        </div>
      </div>
    </>
  );
};
export const Animation7b = ({
  className,
  onMouseEnter,
  onMouseLeave,
  anim2,
}) => {
  return (
    <>
      <Animation7a path={0} />
      <div className={`yu-flex gap-72 yu-absolute mt24 ${className}`}>
        <div className="yu-mr-4 z-1">
          <div
            className="ske-b mt620"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Biller />
            <p className="anim-p">Biller</p>
          </div>
          <div
            className="ske-b mt24"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <AnyBankAccount />
            <p className="anim-p">Any Bank Account</p>
          </div>
        </div>
        <div className="yu-mr-4 z-1">
          <div className="ske-b mt680">
            <BillPayment />
            <p className="anim-p">Recharge & Bills Payment</p>
          </div>
          <div className="ske-b mt20">
            <BankTransfer />
            <p className="anim-p">Bank Transfers</p>
          </div>
        </div>
        <div className="yu-mr-4 z-1">
          <div className="ske-b mt680">
            <MontraAccount />
            <p className="anim-p">Montra Agent Account</p>
          </div>
        </div>
        <div
          className={
            anim2 ? "yu-mr-4 z-1 animation7-montraagent" : "yu-mr-4 z-1"
          }
        >
          <div className="ske-b">
            <MontraAgent />
            <p className="anim-p">Montra Agents</p>
          </div>
        </div>
        <div className="yu-absolute mt725 ml96">
          <YU19b />
        </div>
      </div>
    </>
  );
};

export const Animation8 = ({
  className,
  onMouseEnter,
  onMouseLeave,
  desktopWidth,
}) => {
  return (
    <div
      className={`yu-flex gap-72 mt24 PaymentGatewayAnimations ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="pg-flex">
        <div className="yu-mr-4 z-1 pg-animation-items">
          <div
            className={
              desktopWidth
                ? "ske-b-animation mt-37 ml-200 pg-animation"
                : "ske-b-animation mt-52 ml-200 pg-animation"
            }
          >
            <PGSKEBICON1 />
          </div>
        </div>
        {/* <div className="pg-animation-rightimage">
          <div className="pg-animation-ske">
            <img
              src={PgMobileDesktop}
              alt="pg-mobile-desktop"
              className="pg-animation-right-image"
            />
          </div>
        </div> */}
      </div>
      <div className="yu-absolute pg1-animation">
        <PG1 />
      </div>
    </div>
  );
};
export const Animation9 = ({
  className,
  onMouseEnter,
  onMouseLeave,
  desktopWidth,
}) => {
  return (
    <div
      className={`yu-flex gap-72  mt24 PaymentGatewayAnimations ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="pg-flex">
        <div className="yu-mr-4 z-1 pg-animation-items">
          <div
            className={
              desktopWidth
                ? "pg-animation  ml-200 mt60 ske-b-animation"
                : "pg-animation  ml-200 mt45 ske-b-animation"
            }
          >
            <PGSKEBICON2 />
          </div>
        </div>
        {/* <div className="pg-animation-rightimage">
          <div className="pg-animation-ske">
            <img
              src={PgMobileDesktop}
              alt="pg-mobile-desktop"
              className="pg-animation-right-image"
            />
          </div>
        </div> */}
      </div>
      <div
        className={
          desktopWidth
            ? "yu-absolute mt160  ml-177"
            : "yu-absolute mt145 ml-177"
        }
      >
        <PG2 />
      </div>
    </div>
  );
};

export const Animation10 = ({
  className,
  onMouseEnter,
  onMouseLeave,
  desktopWidth,
}) => {
  return (
    <div
      className={`yu-flex gap-72  mt24 PaymentGatewayAnimations ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="pg-flex">
        <div className="yu-mr-4 z-1 pg-animation-items">
          <div
            className={
              desktopWidth
                ? "pg-animation  mt235 ml-200 ske-b-animation"
                : "pg-animation  mt218 ml-200 ske-b-animation"
            }
          >
            <PGSKEBICON3 />
          </div>
        </div>
        {/* <div className="pg-animation-rightimage">
          <div className="pg-animation-ske">
            <img
              src={PgMobileDesktop}
              alt="pg-mobile-desktop"
              className="pg-animation-right-image"
            />
          </div>
        </div> */}
      </div>
      <div
        className={
          desktopWidth ? "yu-absolute mt222 ml-175" : "yu-absolute mt205 ml-175"
        }
      >
        <PG3 />
      </div>
    </div>
  );
};
export const Animation11 = ({
  className,
  onMouseEnter,
  onMouseLeave,
  desktopWidth,
}) => {
  return (
    <div
      className={`yu-flex gap-72  mt24 PaymentGatewayAnimations ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="pg-flex">
        <div className="yu-mr-4 z-1 pg-animation-items">
          <div
            className={
              desktopWidth
                ? "pg-animation ml-200 mt330 ske-b-animation"
                : "pg-animation ml-200 mt315 ske-b-animation"
            }
          >
            <PGSKEBICON4 />
          </div>
        </div>
        {/* <div className="pg-animation-rightimage">
          <div className="pg-animation-ske">
            <img
              src={PgMobileDesktop}
              alt="pg-mobile-desktop"
              className="pg-animation-right-image"
            />
          </div>
        </div> */}
      </div>
      <div
        className={
          desktopWidth ? "yu-absolute mt255 ml-165" : "yu-absolute mt240 ml-165"
        }
      >
        <PG4 />
      </div>
    </div>
  );
};
