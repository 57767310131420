import React from "react";
import shophero2 from "../../../assets/images/shophero2.svg";
import shophero1 from "../../../assets/images/shophero1.webp";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import { NavLink } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import ShopProcessContainer from "./ShopProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="shop-hero">
        <div className="row">
          <div className="col shopmain">
            <h1>Connect to sellers on Montra app for exciting deals </h1>
            <p>
              Montra shoppers can find what they are looking for online and in
              person. From delivering fresh produce to their doorstep to
              creating and distributing movies, music, and more, we are always
              finding new ways to delight our customers.
            </p>
            {/* <div className="process-flow">
              <p className="top-description">Product & Services Categories</p>
              <div className="top-step">
                <div className="down-arrow">
                  <svg width="50" height="50">
                    <line
                      x1="25"
                      y1="0"
                      x2="25"
                      y2="50"
                      style={{ stroke: "#20336B", strokeWidth: 1 }}
                    />
                    <polygon
                      points="20,40 25,50 30,40"
                      style={{ fill: "#20336B" }}
                    />
                  </svg>
                </div>
              </div>

              <div className="process-steps">
                <div className="step-container">
                  <div className="step">
                    <div className="circleimg">
                      <img src={Shop1} alt="Loan offers" />
                    </div>
                    <p className="down-description twoline">
                      Delivery/ Takeout/ Rentals
                    </p>
                  </div>
                </div>

                <div className="step-container">
                  <div className="step">
                    <div className="circleimg">
                      <img src={Shop2} alt="Instant Loan Disbursement" />
                    </div>
                    <p className="down-description">Dine-in</p>
                  </div>
                  <div className="arrow">
                    <svg width="50" height="50">
                      <line
                        x1="0"
                        y1="25"
                        x2="50"
                        y2="25"
                        style={{ stroke: "#20336B", strokeWidth: 1 }}
                      />
                      <polygon
                        points="40,20 50,25 40,30"
                        style={{ fill: "#20336B" }}
                      />
                    </svg>
                  </div>
                </div>

                <div className="step-container">
                  <div className="step">
                    <div className="circleimg">
                      <img src={Shop3} alt="Loan Repayments" />
                    </div>
                    <p className="down-description">Home Services</p>
                  </div>
                  <div className="arrow">
                    <svg width="50" height="50">
                      <line
                        x1="0"
                        y1="25"
                        x2="50"
                        y2="25"
                        style={{ stroke: "#20336B", strokeWidth: 1 }}
                      />
                      <polygon
                        points="40,20 50,25 40,30"
                        style={{ fill: "#20336B" }}
                      />
                    </svg>
                  </div>
                </div>

                <div className="step-container">
                  <div className="step">
                    <div className="circleimg">
                      <img src={Shop4} alt="Loan Account Statement" />
                    </div>
                    <p className="down-description">Loan Account Statement</p>
                  </div>
                  <div className="arrow">
                    <svg width="50" height="50">
                      <line
                        x1="0"
                        y1="25"
                        x2="50"
                        y2="25"
                        style={{ stroke: "#20336B", strokeWidth: 1 }}
                      />
                      <polygon
                        points="40,20 50,25 40,30"
                        style={{ fill: "#20336B" }}
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <img src={shophero2} alt="" /> */}
            <ShopProcessContainer />
            <div className="app-download shop-app-download">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>

          <div className="col shop-hero-right personal-shop-hero-right">
            <img src={shophero1} alt="shop image" className="shopimg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
