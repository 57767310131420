import React from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dwp3 from "../../../../assets/images/dwp3.webp";
import "./style.css";

const Body = () => {
  return (
    <>
      <div className="dcp-body dlm-body">
        <div className="row dwp-row">
          <div className="col-md-6 dts-frame ">
            <div className="dts-frame-img">
              <img src={dts1} alt="" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Issuers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list ">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DLM helps Issuers manage risk in the most effective way as
                    the application seamlessly gathers all income, account
                    balance, debt exposure, delinquency data and further gather
                    credit bureau data to under-write the customers based on the
                    approved Risk Acceptance Criteria for this product program
                    every month.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Rule based decisions are implemented to block risky
                    customers.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Similarly, Credit Limits are reduced where DLM finds the
                    same has reduced based on revised parameters gathered.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Where Credit Limit needs to be increased, the same is queued
                    to Risk user on DUO Web Portal for approval. Credit Limit is
                    increased for such approved customers.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DLM therefore helps issuers to make decisions every month
                    for those onboarded on DUO Platform. Only those who continue
                    to be ELIGIBLE each months can Activate the service. DUO
                    Credit Cards for Active customers may be blocked or unlocked
                    digitally based on revised under-writing status every month.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 dwp-img">
            <img src={dwp3} alt="dwp3" className="dwpimgall" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
