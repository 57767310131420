import React from "react";
import co1 from "../../../../assets/images/co1.webp";
import co2 from "../../../../assets/images/co2.svg";
import "./style.css";

const Hero = () => {
  return (
    <>
      <div className="dts-hero dco-hero">
        <div className="row personalb dcb-hero-row  ">
          <div className="col-md-7 dco-hero-content-col colonboarding">
            <div className="dwp-sm-btn">
              <img
                src={co2}
                alt="duo customer onboarding hero image"
                className="dco-hero-small-image"
              />
              <p>Onboarding</p>
            </div>
            <h1 className="customer-onboarding-text">
              <span className="customer-onboarding-text-span">
                Fast, Cost-Effective Seamless Customer Onboarding
              </span>
            </h1>

            <div className="duo-list-container dco-list-container">
              <div className="duo-lists">
                <div className="duo-list onboarding">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Our consulting team works with Issuer’s Risk & Product
                      Management teams to help them finalize the underwriting
                      parameters / Risk Acceptance Criteria for the DUO Credit
                      Card program based on Issuer’s risk appetite.
                    </p>
                  </div>
                </div>
                <div className="duo-list onboarding">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Further our Onboarding Application digitize the onboarding
                      process such that Issuer uploads the customers for scoring
                      them.
                    </p>
                  </div>
                </div>
                <div className="duo-list onboarding">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Our Onboarding application gathers all the data from
                      Issuer’s system and credit Bureaus to under-write these
                      customers.
                    </p>
                  </div>
                </div>
                <div className="duo-list onboarding">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      For those customers who are pre-approved are onboarded
                      digitally on DUO platform for issuance of a Virtual DUO
                      Credit Card (DTS) or Physical DUO card (DCP).
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col-md-5 dco-hero-image-col">
            <div className="onboardingimg">
              <img src={co1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
