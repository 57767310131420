/* React packages and components */
import React, { Component } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Header2 from "./Header2";
import FooterAdmin from "../Footer/FooterAdmin";
import "./MoneylinkBusinessStyle.css";
// import bsCustomFileInput from "../../node_modules/bs-custom-file-input/dist/bs-custom-file-input";
import bsCustomFileInput from "bs-custom-file-input";

/**
   * Portfolio component will render entire portfolio section of bank for Business section.
   * <h6>There are eight types of Products will be there and also user can download the reports:</h6>
   * <li>paydayloan LOAN</li>
   * <li>Salary Advance</li>
   * <li>STPL</li>
   * <li>CD Loan</li>
   * <li>Personal Loan</li>
   * <li>Auto Loan</li>
   * <li>Home Loan</li>
   * <li>LAP</li>
   <br>
   * <h6>Below Html elemensts are created to use for  UI</h6>
   * <li> <b> Section :- </b>it is used for represents a standalone section</li>
   * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
   * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
   * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
   <br>
   * <h6>Below Bootstrap class is use for ui responsive.</h6>
   * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
   * <li> <b>row :-</b> This class to create a structure.</li>
   * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>


   * @Author : Seema Kumari
   * @version :1.0
 */

class Portfolio extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      status: "",
      data: [],
      message: "",
      errorMsg: "",
      backerrors: [],
    };
  }

  /**
   * Captures the boolean value for submit the request to server and download the loanprotfolio in xlsx format.
   *
   * @param {Event} e  event to call this method
   * @param {data[]} data index of data array
   */
  downloadfileBussiness1 = (e, i) => {
    e.preventDefault();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/loanportfolio/download?email=" +
        localStorage.getItem("email") +
        "&filename=" +
        this.state.data[i].productName
    );
    fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          alert("server error");
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "paylink-loanportfolio.xlsx";
        a.click();
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  };

  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    bsCustomFileInput.init();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/loanportfolio/portfolio?email=" +
        localStorage.getItem("email")
    );
    fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert("server error");
        }
      })
      .then((contents) => {
        if (200 == contents.status && "SUCCESS" == contents.message) {
          this.setState({
            data: contents.data,
            status: contents.status,
            message: contents.message,
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message,
          });
          this.state.backerrors.map((key) => {
            this.setState({
              errorMsg: key.message,
            });
          });
        }
      });
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "BUSINESS" !== localStorage.getItem("role")
    ) {
      return <p>Please Login AS BUSINESS</p>;
    } else {
      return (
        <div>
          {/* top-Header */}
          <Header2 />
          <div id="wrapper">
            {/* header menu */}
            <Sidebar />
            <div id="maincontent" className="content">
              <section id="content-wrapper">
                <div className="backloan">
                  <div className="container-fluid undercontainer">
                    <form className="businessheader">
                      {(() => {
                        switch (this.state.message) {
                          case "UNSUCCESS":
                            return (
                              <div className="errorMsg">
                                {" "}
                                <p>{this.state.errorMsg}</p>
                              </div>
                            );
                          default:
                            return <p></p>;
                        }
                      })()}
                      <div className="row ">
                        {this.state.data.map((business, i) => (
                          <div
                            key={i}
                            className="col-sm-12 col-md-12 col-xl-4 portfoliocols"
                          >
                            <div className={"business" + i}>
                              <p className="paydayloan">
                                {business.productName}
                              </p>
                              <div className="download">
                                <button
                                  className="downloadtext"
                                  onClick={(e) => {
                                    this.downloadfileBussiness1(e, i);
                                  }}
                                >
                                  {" "}
                                  <span>
                                    <i className="fas fa-arrow-down"></i>
                                    <br />
                                  </span>
                                  Download <br />
                                  Report
                                </button>
                              </div>
                              <div className="row businessrow">
                                <div className="col-md-4 businesscoloums">
                                  <div className="form-group">
                                    <label className="businesslablename">
                                      Customers #
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.numberOfcustomers}
                                      disabled
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label className="businesslablename1">
                                      MTD Fee Income
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.mtdFeeIncome}
                                      disabled
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="businesslablename1">
                                      YTD Fee Income
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.ytdFeeIncome}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="col-md-4 businesscoloums">
                                  <div className="form-group">
                                    <label className="lablename outstand">
                                      Loan Outstanding
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.loanOutStanding}
                                      disabled
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="businesslablename2">
                                      MTD Interest Income
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.mtdInterestIncome}
                                      disabled
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="businesslablename2">
                                      YTD Interest Income
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.ytdInterestIncome}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="col-md-4 businesscoloums1">
                                  <div className="form-group">
                                    <label className="businesslablename3">
                                      MTD Other Income
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.mtdOtherIncome}
                                      disabled
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="businesslablename4">
                                      YTD Other Income
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lendingcompanyname"
                                      placeholder={business.ytdOtherIncome}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="portfolio-business-report ">
                                <div className=" businessflex2 ">
                                  <p className="businesstext current">
                                    Current
                                  </p>
                                  <p className="businesstext">X Days</p>
                                  <p className="businesstext">30 DPD</p>
                                  <p className="businesstext">60 DPD</p>
                                  <p className="businesstext">90 DPD</p>
                                </div>

                                <div className=" businessflex">
                                  <div className="form-group ">
                                    <label className="lablename cust">
                                      Customers <br />#{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.numberOfCurrentCustomers
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.numberOfxDaysCustomers
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.numberOfDpd30Customers
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.numberOfDpd60Customers
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.numberOfgt90dpdCustomers
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className=" businessflex">
                                  <div className="form-group">
                                    <label className="lablename cust">
                                      % <br /> Delinquency
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.currentDeliquencyPercent1
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.xDaysDeliquencyPercent1
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.dpd30DeliquencyPercent1
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.dpd60DeliquencyPercent1
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.gt90dpdDeliquencyPercent1
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className=" businessflex">
                                  <div className="form-group">
                                    <label className="lablename cust">
                                      Loan <br /> Outstanding
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.currentLoanOutStanding
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.xDaysLoanOutStanding
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.dpd30LoanOutStanding
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.dpd60LoanOutStanding
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.gt90dpdLoanOutStanding
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className=" businessflex">
                                  <div className="form-group">
                                    <label className="lablename cust">
                                      % <br /> Delinquency
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.currentDeliquencyPercent2
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.xDaysDeliquencyPercent2
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.dpd30DeliquencyPercent2
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.dpd60DeliquencyPercent2
                                      }
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      className="form-control businessgroup"
                                      name="lendingcompanyname"
                                      placeholder={
                                        business.gt90dpdDeliquencyPercent2
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* Footer */}
          {/* <FooterAdmin /> */}
        </div>
      );
    }
  }
}

export default Portfolio;
