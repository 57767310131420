import React from "react";
// import financehero1 from "../../../assets/images/financehero1.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
// import webpImage from '../../../assets/images/financesvg.svg';
import webpImage from "../../../assets/images/uday/financehero.webp";
import "./style.css";
import FinanceProcessContainer from "./FinanceProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="finance-hero">
        <div className="row personalb">
          <div className="col-md-6 finance-hero-left">
            <h1>Financial products for every step of your journey</h1>
            {/* <img
              className="finance-hero-img"
              src={financehero1}
              alt="financehero1"
            /> */}
            <FinanceProcessContainer />
            <div className="app-download financedownload">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col-md-6 finance-hero-right">
            <img src={webpImage} alt="webpImage" className="financeimg1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
