
/* React packages  */
import React from 'react';
import { Link } from 'react-router-dom';
import { TooltipDefinition, TooltipIcon, Tooltip } from "carbon-components-react";
import "carbon-components/css/carbon-components.min.css";
import ReactTooltip from "react-tooltip";
import NumberFormat from 'react-number-format';


/**
* Renders part of underwritting rules  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class UndWrittingPart1 extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         fields: {}
      }
   };

   render() {

      return (

         <div>

            {/* Form -: in this form section included label, togglebutton and dropdown to render the data */}
            <form method="post" name="userRegistrationForm" className="formroot" >

               <div className="unwrite">
                  <div className="d-flex flex-row mt-3 ">

                     <div className="p-2 underwriteone">
                        <div className="form-group">
                           <label className="labelpt-7">Product</label>
                           {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled >{underWritingRules.productName}</label></div>)}
                        </div>
                     </div>

                     <div className="p-2 underwrite">

                        <div className="toolinfopro" >
                           <p className="productheadercuwmaker a4">Minimum Age</p>
                           <Tooltip className="toolt" direction="right" align="center" >
                              Years
                           </Tooltip>
                        </div>


                        <div className="form-group">
                           {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                              <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isMinAgelist[i] }} name="minAge" onChange={(e) => { this.props.handleChangeminAge(e, i) }} placeholder={underWritingRules.minAge}>
                                 <option value={underWritingRules.minAge}>{underWritingRules.minAge}</option>
                                 <option value="18">18</option>
                                 <option value="19">19</option>
                                 <option value="20">20</option>
                                 <option value="21">21</option>
                              </select>
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}
                        </div>
                     </div>
                     <div className="p-2 underwrite">

                        <div className="toolinfopro" >
                           <p className="productheadercuwmaker a3">Maximum Age</p>
                           <Tooltip className="toolt" direction="right" align="center" >
                              Years
                           </Tooltip>
                        </div>

                        <div className="form-group ">

                           {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                              <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isMaxAgelist[i] }} name="maxAge" onChange={(e) => { this.props.handleChangemaxAge(e, i) }} placeholder={underWritingRules.maxAge}>
                                 <option value={underWritingRules.maxAge}>{underWritingRules.maxAge}</option>
                                 <option value="60">60</option>
                                 <option value="70">70</option>
                                 <option value="80">80</option>
                                 <option value="90">90</option>
                              </select>
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}
                        </div>
                     </div>
                     <div className="p-2 underwrite">
                        <div className="toolinfopro" >
                           <p className="productheadercuwmaker">Debt Burden Allowed</p>
                           <Tooltip className="toolt" direction="top" align="center" >
                              % of Average Monthly Salary / Income
                           </Tooltip>
                        </div>
                        <div className="form-group">
                           {this.props.data.map((underWritingRules, i) => <div key={i} className="inner-addon right-addon">
                              <NumberFormat className="form-control formproduct margn"
                                 data-tip
                                 data-tip-disable={!this.props.editable} data-for="noneditable"
                                 style={{ borderColor: this.props.isDebtBurdenAllowPercentlist[i] }}
                                 name="debtBurdenAllowPercent"
                                 onChange={(e) => { this.props.handleChangedebtBurdenAllowPercent(e, i) }}
                                 value={underWritingRules.debtBurdenAllowPercent} suffix={'%'} />
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}
                        </div>
                     </div>




                     <div className="p-2 underwrite">
                        <div className="toolinfopro" >
                           <p className="productheadercuwmaker a2">Min. Loan Amount</p>
                           <Tooltip className="toolt" direction="left" align="center" >
                              Amount
                           </Tooltip>
                        </div>
                        <div className="form-group ">

                           {this.props.data.map((underWritingRules, i) => <div key={i} className="inner-addon right-addon">
                              <input type="number" className="form-control formproduct margn" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isMinLoanAmtlist[i] }} name="minLoanAmt" onChange={(e) => { this.props.handleChangeminLoanAmt(e, i) }} value={underWritingRules.minLoanAmt} />
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}
                        </div>
                     </div>


                     <div className="p-2 underwrite">
                        <div className="toolinfopro" >
                           <p className="productheadercuwmaker">Max Loan Amount  Cap</p>
                           <Tooltip className="toolt" direction="left" align="center" >
                              Amount
                           </Tooltip>
                        </div>

                        <div className="form-group">
                           {this.props.data.map((underWritingRules, i) => <div key={i} className="inner-addon right-addon">
                              <input type="number" className="form-control formproduct margn" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isMaxLoanAmtCaplist[i] }} name="maxLoanAmtCap" onChange={(e) => { this.props.handleChangemaxLoanAmtCap(e, i) }} value={underWritingRules.maxLoanAmtCap} />
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}
                        </div>
                     </div>


                     <div className="p-2 underwrite">

                        <div className="toolinfopro" >
                           <p className="makerunderwritting">Instant Loan Disbursement Cap</p>
                           <Tooltip className="toolt" direction="left" align="center" >
                              Amount allowed for digital disbursement
                           </Tooltip>
                        </div>

                        <div className="form-group">
                           {this.props.data.map((underWritingRules, i) => <div key={i} className="inner-addon right-addon">
                              <input type="text" className="form-control formproduct margn" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isInstantloanDisCaplist[i] }} name="instantloanDisCap" onChange={(e) => { this.props.handleChangeinstantloanDisCap(e, i) }} value={underWritingRules.instantloanDisCap} />
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}
                        </div>
                     </div>

                     <div className="p-2 underwrite">

                        <div className="toolinfopro" >
                           <p className="productheadercuwmaker a1">Last Salary / Inflow</p>
                           <Tooltip className="toolt" direction="left" align="center" >
                              Last Salary / Inflow must be there in last number of months
                           </Tooltip>
                        </div>

                        <div className="form-group">
                           {this.props.data.map((underWritingRules, i) => <div key={i} className="inner-addon right-addon">
                              <input type="number" className="form-control formproduct margn" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isLastSalarylist[i] }} name="lastSalary" onChange={(e) => { this.props.handleChangelastSalary(e, i) }} value={underWritingRules.lastSalary} />
                              <ReactTooltip id="noneditable" place="top" effect="solid">
                                 Non Editable
                              </ReactTooltip>
                           </div>)}

                        </div>
                     </div>
                  </div>
               </div>
            </form>

         </div>
      )

   }


}

export default UndWrittingPart1;
