import React from "react";
import arrow1 from "../../../assets/images/arrow1.svg";
import star from "../../../assets/images/p-privacyp.svg";
import secure2 from "../../../assets/images/secure2p.svg";
import twofa from "../../../assets/images/twofap.svg";
import reward from "../../../assets/images/builtp.svg";
import trolley from "../../../assets/images/trolley.svg";
import delivery from "../../../assets/images/delivery.svg";
import appointment from "../../../assets/images/appointment.svg";
import store from "../../../assets/images/store.svg";
import checklist from "../../../assets/images/checklist.svg";
import crossselling from "../../../assets/images/crossselling.svg";
import marketplace from "../../../assets/images/marketplace.svg";
import global from "../../../assets/images/global.svg";
import AcceptPayments from "../../../assets/images/AcceptPayments.svg";
import chat from "../../../assets/images/chat.svg";
import business from "../../../assets/images/business.svg";
import processingsettlement from "../../../assets/images/processingsettlement.svg";
import b2b from "../../../assets/images/b2b.svg";
import publicservice from "../../../assets/images/publicservice.svg";
import time from "../../../assets/images/time.svg";
import Paymentlink from "../../../assets/images/Paymentlink.svg";
import gettingstarted from "../../../assets/images/gettingstarted.svg";
import dinin from "../../../assets/images/dinin.svg";

const Body = () => {
  return (
    <>
      <div className="home-business business-store-body business-payment-body">
        <div className="arrow">
          <img src={arrow1} />
        </div>

        <div className=" business-store-body-1 business-payment-body-1 montrastoreoffered">
          <h1 className="business-store-body-1-h1">
            Making E-commerce Better for Everyone
          </h1>
          <div className="row personalb ">
            <div className="col-md-6 col-sm-6 col-xl-3">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={delivery} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Delivery/ Takeout / Rentals</h6>
                  </div>
                  <p>
                    You can sell your products on Montra Stores and let us know
                    your business model. Activate Rentals setting for those you
                    sell only on rental basis. Similarly activate Delivery for
                    those products you are willing to get delivered at
                    customer’s address & Takeout for those you would like
                    customer to pick-up from the address you provide.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-3">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={trolley} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Home Services</h6>
                  </div>
                  <p>
                    Businesses offering Home Services are those you can deliver
                    at customer’s address. Some of the popular Home Services
                    include Home Cleaning & Pest Control, Electrician Plumbing &
                    Carpenter, Salon & Spa services, Painting & Waterproofing,
                    AC & Appliance repair, Healthcare, Home Decorating etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-3">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={dinin} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Dine-in</h6>
                  </div>
                  <p>
                    Restaurant businesses can set-up Montra Store for the
                    customers to Book a table for Dine-in. customers can access
                    your Menu for taking a decision on booking table for Dine-in
                    as well as for placing orders for Food Delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-3">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={appointment} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Appointments</h6>
                  </div>
                  <p>
                    Businesses offering professional services at their offices
                    or Virtually can let customers book an appointment. Some of
                    these services are offered by Doctors, Salon & Spa services,
                    AC & Appliance repair, Healthcare, Home Decorating,
                    Consulting etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="business-store-body-1 business-payment-body-1 montrastoreoffered2">
          <h1 className="business-store-body-1-h1">
            Discover why entrepreneurs chose Montra to build their business
          </h1>
          <div className="row personalb montralb">
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={store} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Build your store</h6>
                  </div>
                  <p>
                    Bring your idea to life with Montra Store & start selling.
                    You can set-up your store using Montra App & Montra Store
                    Web Portal & scale your business faster with built-in,
                    reliable data—no setup required.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={checklist} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>The best checkout with Montra Pay & Montra App</h6>
                  </div>
                  <p>
                    Make checkout your own using customizable features that fit
                    your unique business needs. You can enable or disable from
                    the available options of accepting Payment on Delivery
                    (POD), Pay Now & Escrow Pay.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={global} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Sell across borders</h6>
                  </div>
                  <p>
                    Scale without limits. Montra can help you open Stores in
                    countries Montra is acquiring. Montra can also help
                    businesses who have a valid company registration, accept
                    global payments from any customer anywhere in the world.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row personalb montralb">
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={AcceptPayments} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Accept payment through variety of payment methods</h6>
                  </div>
                  <p>
                    Customers can make payments using their Montra Account,
                    Debit or Credit Cards, Linked Bank Accounts as well as in
                    Installments and you would collect this payment instantly in
                    your Montra Business Accounts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={chat} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Montra Chat, Voice & Video calls</h6>
                  </div>
                  <p>
                    Customers can massage the Sellers on Montra App besides
                    making a Voice & Video call and even make payment while on
                    chat.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={business} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>
                      All the commerce insights you need to grow your business
                    </h6>
                  </div>
                  <p>
                    View Dashboards for key metrics briefly and dig deeper for
                    more insights to see the big picture more clearly.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row personalb montralb">
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={processingsettlement} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Escrow Pay </h6>
                  </div>
                  <p>
                    Customer can agree with Terms and Conditions for delivery of
                    a Product / Service from our merchant to make the payment in
                    an ESCROW account. Funds would be refunded or paid to the
                    merchant once the Product/ Services are delivered based on
                    agreed T&C.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={crossselling} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Sell everywhere</h6>
                  </div>
                  <p>
                    One platform that lets you sell wherever your customers are
                    Montra App, Montra website, and even in person.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={marketplace} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>
                      Sell your Products both on Montra & other top Marketplaces
                      in one place{" "}
                    </h6>
                  </div>
                  <p>
                    Use Montra Store Connect to sync your product catalog to the
                    leading online marketplaces. On Montra Store, you can easily
                    Manage inventory with real-time syncing, fulfill orders, and
                    measure channel performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row personalb montralb">
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={b2b} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>B2B and DTC, in one place</h6>
                  </div>
                  <p>
                    You can sell to other Businesses (B2B) as well as directly
                    to customers (D2C) both on Montra App as well as through
                    Montra website.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={publicservice} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Market and sell where your customers are</h6>
                  </div>
                  <p>
                    Grow your business with Montra App posts & feeds as well as
                    market Your Montra Store on Facebook & Instagram.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={time} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>
                      Simplify fulfillment with centralized order management
                    </h6>
                  </div>
                  <p>
                    Get your products where they need to be with streamlined
                    inventory, orders, fulfillment, Returns and refunds.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row personalb montralb">
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method montrastore-method">
                <div className="store-icon">
                  <img src={Paymentlink} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Pay on Delivery</h6>
                  </div>
                  <p>
                    When Merchants enable this payment option, Montra would send
                    a Payment Link to customer’s email / Montra App which can be
                    used for making the payment on delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="business-payment-method businessmontrastore-method">
                <div className="store-icon">
                  <img src={gettingstarted} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Pay in Installments on Montra</h6>
                  </div>
                  <p>
                    Customers can simply borrow instantly on Montra to pay to
                    the merchant and repay in Installments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="business-payment-body-1 agencyinventory montraagent">
          <h1>Montra, a more secure way to pay</h1>
          <div className="row personalb">
            <div className="col-md-6 col-sm-6">
              <div className="business-payment-method business-payment-reward securewaybusiness">
                <div className="svgimg">
                  <img src={secure2} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Secure from the start</h6>
                  </div>
                  <p>
                    Access to your Montra App is secured with the Password that you set-up.
                    You can also access the app using your Face-ID or Biometric-ID that you have set-up on your phone.
                  </p>
                </div>
              </div>
              <div className="business-payment-method business-payment-reward securewaybusiness">
                <div className="svgimg">
                  <img src={twofa} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Secure with 2FA</h6>
                  </div>
                  <p>
                    Our Fraud & Risk Assessment tool would assess the risk for every transaction and would
                    ask for your Soft Token from time to time. Montra offers access to Free Soft Token on
                    VERIFYED app for every Montra Customer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="business-payment-method business-payment-reward securewaybusiness">
                <div className="svgimg">
                  <img src={reward} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Built-in protection before you pay</h6>
                  </div>
                  <p>
                    Every payment request is processed only upon successful
                    authentication of the Montra PIN you have set-up.
                  </p>
                </div>
              </div>
              <div className="business-payment-method business-payment-reward securewaybusiness">
                <div className="svgimg">
                  <img src={star} alt="" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Payment Privacy</h6>
                  </div>
                  <p>
                    Only you and the person you are transacting with will be able to see the details of your payment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
