import React from 'react';
import { TooltipDefinition, TooltipIcon, Tooltip } from "carbon-components-react";
import "carbon-components/css/carbon-components.min.css";

/**
 *  CollectionCheckerLoan component is render upper part of ProductMix  section of bank for checker section.
 *  @Author : Thrilok Kandra
 *  @version :1.0
  */

class CollectionCheckerLoan extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         fields: {},
         errors: {},
         isDisabled: false,
         message: '',
         status: '',
         backerrors: [],
         errorMsg: '',
         errors:[]
      };
      this.handleChange = this.handleChange.bind(this);
   }
   handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
         fields
      });

   }




   render() {

      return (

         <div className="productmixcollectionpage">

            <form method="post" name="userRegistrationForm" className="formroot">

               <div className="row">
                  <div className="col-sm-12 col-md-12 col-xl-4 reduce">

                     <div className="d-flex flex-row mt-3 ">

                        <div className="p-2 collform">
                           <div className="form-group">
                              <label className="productcodeloanch">Product</label>
                              {this.props.loaddata.map((productmix, i) => <div key={i}><label  className="form-control loanlabelcheck" name="paydayloan"  disabled > {productmix.prodName} </label></div>)}

                           </div>
                        </div>

                        <div className="p-2 collform">
                           <div className="toolinfopro" >
                             <p className="productheader well">Collection for internal loans</p>
                             <Tooltip className="toolt"  direction="top" align="center" >
                             Origination OR Origination + Anniversary
                             </Tooltip>
                          </div>
                           <div className="form-group">
                              {this.props.loaddata.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isCollectionForInternLoansfieldclasslist[i] }} >
                                 <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)}  disabled > {productmix.collectionForInternLoans} </label>
                                 <label className="switchtoggle"><input type="checkbox"  name="isCollectionForInternLoanssa" onChange={(e) => { this.props.toggleChangeIsCollectionForInternLoans(e, i) }} checked={productmix.isCollectionForInternLoans} disabled={this.props.isCollectionForInternLoanslist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isCollectionForInternLoansclasslist[i] }}></div></label>
                              </div>)}
                           </div>
                        </div>



                        <div className="p-2 collform">
                           <div className="toolinfopro" >
                             <p className="productheader baseinterest">Base Interest Rate</p>
                             <Tooltip className="toolt"  direction="top" align="center" >
                             Loans being managed on Bank / FC’s LMS
                             </Tooltip>
                          </div>
                           <div className="form-group">
                              <label className="collectionmix2"></label>
                              {this.props.loaddata.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isBaseInterestRatefieldclasslist[i] }} >
                                 <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)}  disabled> {productmix.baseInterestRate + "%"} </label>
                                 <label className="switchtoggle"><input type="checkbox"  name="isBaseInterestRatesa" onChange={(e) => { this.props.toggleChangeIsBaseInterestRate(e, i) }} checked={productmix.isBaseInterestRate} disabled={this.props.isBaseInterestRatelist[i]} /><div className="checkerslider round"style={{ backgroundColor: this.props.isBaseInterestRateclasslist[i] }} ></div></label>
                              </div>)}
                           </div>
                        </div>



                        <div className="p-2 collform">
                           <div className="toolinfopro" >
                             <p className="productheader baseten">Base Tenors</p>
                             <Tooltip className="toolt"  direction="left" align="center" >
                             Annualized Rate
                             </Tooltip>
                          </div>
                           <div className="form-group">
                              {this.props.loaddata.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isBaseTenorsfieldclasslist[i] }} >
                                 <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)}  disabled> {productmix.baseTenors + "%"} </label>
                                 <label className="switchtoggle"><input type="checkbox"  name="isBaseTenorssa" onChange={(e) => { this.props.toggleChangeIsBaseTenors(e, i) }} checked={productmix.isBaseTenors} disabled={this.props.isBaseTenorslist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isBaseTenorsclasslist[i] }}></div></label>
                              </div>)}

                           </div>
                        </div>
                     </div>

                     <div className="productbuttonch1">
                        <input type="submit" className=" approval-button" value="Changes requested for approval" disabled />
                     </div>

                  </div>



                  <div className="col-sm-12 col-md-12 col-xl-4 reduce">
                     <div className="p-2  collectionptchecker">
                        <div className="collectioncardchecker">


                              <div className="form-group ">
                                 <label>Type Your User PIN </label>
                                 <input type="password" className="form-control cardinputfield" name="pin"  onChange={this.props.handleChange} placeholder="xxxxx " />
                                 <div className="sign">
                                    <input type="submit" className="btn btn-warning cardbutton" style={{ backgroundColor: this.props.buttoncolor }} onClick={this.props.handleSubmit} value="Submit changes for approval" disabled={this.props.isDisabled || this.props.editable} />
                                 </div>
                              </div>


                           <div className="errorMsg">{this.props.errors.pin}</div>
                           {(() => {
                              switch (this.props.message) {
                                 case "SUCCESS": return <p className="submitted">Submitted Succesfully</p>
                                 case "UNSUCCESS": return <div className="errorMsg"> <p className="invalidpin">{this.props.errorMsg}</p></div>;
                                 default: return <p></p>
                              }
                           })()}

                        </div>


                        <div className="">
                           <div className="form-group card1">
                              <label className="cardtext">Approved </label><br />
                              <img className="toogle-img" src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle1.png?alt=media&token=a825c329-274e-4a1c-831d-554cd94b2c88" alt="image" />
                           </div>
                           <div className="form-group card1">
                              <label className="cardtext">Rejected </label><br />
                              <img className="toogle-img" src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle2.png?alt=media&token=2a0da633-8a16-4e7b-9140-71afbb43e86e" alt="image" />
                           </div>

                           <div className="form-group  card1">
                              <label className="cardtext">No changes requested for approval </label><br />
                              <div className="img-toole">
                                 <img className="toogle-img2" src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle3.png?alt=media&token=0fe3a799-1b79-4d79-87bb-68f4459f2b70" alt="image" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </form>
         </div>


      );
   }

}


export default CollectionCheckerLoan;
