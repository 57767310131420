import React from "react";
import verified3 from "../../../../assets/images/verified3.svg";
// import verified4 from "../../../../assets/images/verified4.png";
import verified5 from "../../../../assets/images/verified5.svg";
import verified6 from "../../../../assets/images/verified6.webp";
import verified4 from "../../../../assets/images/uday/verified/verified-authenticator-subsection.webp";
import verifiedicon1 from "../../../../assets/images/verifiedicon1.svg";
import verifiedicon2 from "../../../../assets/images/verifiedicon2.svg";
import verifiedicon3 from "../../../../assets/images/verifiedicon3.svg";
import verifiedicon4 from "../../../../assets/images/verifiedicon4.svg";
import veri1 from "../../../../assets/images/veri1.svg";
import veri2 from "../../../../assets/images/veri2.svg";
import veri3 from "../../../../assets/images/veri3.svg";
import veri4 from "../../../../assets/images/veri4.svg";
import veri5 from "../../../../assets/images/veri5.svg";

const Body = () => {
  return (
    <>
      <div className="auth-app-body">
        <div className="auth-app-body-1">
          <h1>
            Key trusted identities solutions that provide a truly secure and
            frictionless omni-channel experience
          </h1>
          <div className="row auth-app-body-1-inner">
            <div className="col">
              <div className="veri-part">
                <div className="col ">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon1} alt="image" />
                      </div>

                      <div className="setupauth">
                        <p>Setup</p>
                        <p><span className="authapp-list-span">
                          Easy & self-service onboarding to setup 2FA for any
                          account.
                        </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon2} alt="image" />
                      </div>

                      <div className="setupauth">
                        <p>Secure</p>
                        <p><span className="authapp-list-span">
                          Face-ID, Touch-ID, Encrypted Backups and more to keep
                          you secure.
                        </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="veri-part">
                <div className="col ">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon3} alt="image" />
                      </div>

                      <div className="setupauth">
                        <p>Backup</p>
                        <p> <span className="authapp-list-span">
                          Prevents account from getting locked when you lose
                          your phone.
                        </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="verified-part">
                    <div className="verified-part-text verified-part-text-text">
                      <div>
                        <img src={verifiedicon4} alt="image" />
                      </div>

                      <div className="setupauth">
                        <p>Multiple Devices</p>
                        <p>  <span className="authapp-list-span">
                          Sync Verifyed 2FA across mobile, tablet and desktop.
                        </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col auth-app-img-2">
              <img
                src={verified4}
                alt="verified4-image"
                className="verified4-image"
              />
            </div>
          </div>
        </div>

        <div className="auth-app-body-2-2">
          <h1>
            Two-factor authentication from{" "}
            <span className="authapp-list-span">Verifyed</span> is the simplest
            & most effective way to verify the user's identity
          </h1>
          <div className="auth-app-body-2">
            <div className="row">
              <div className="col">
                <div className="veri-part-2">
                  <div className="col ">
                    <div className="verified-part auth-auth-body3">
                      <div className="verified-part-text vf fa">
                        <img src={veri1} alt="image" />
                        <div>
                          <p>Self Service & Instant onboarding</p>
                          <span className="authapp-body3-list-span">
                            Seamless self-service digital onboarding based on
                            multiple authentications.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="verified-part auth-auth-body3">
                      <div className="verified-part-text vf fa">
                        <img src={veri2} alt="image" />
                        <div>
                          <p>Offline Authentication</p>
                          <span className="authapp-body3-list-span">
                            Offline mode enables users to use Verifyed even when
                            the device is not connected to the internet.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="veri-part-2">
                  <div className="col ">
                    <div className="verified-part auth-auth-body3 fa">
                      <div className="verified-part-text vf">
                        <img src={veri3} alt="image" />
                        <div>
                          <p>Fraud Protection</p>
                          <span className="authapp-body3-list-span">
                            Your log-in access or payment transaction can be
                            authenticated using Verifyed Soft Token.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="verified-part auth-auth-body3 fa">
                      <div className="verified-part-text vf">
                        <img src={veri4} alt="image" />
                        <div>
                          <p>Omnichannel Solution</p>
                          <span className="authapp-body3-list-span">
                            Verifyed Soft Token account can be used to secure
                            multiple consumer-facing applications and access
                            systems in varying locations or environments.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="verified-part auth-auth-body3 fa">
                      <div className="verified-part-text vf">
                        <img src={veri5} alt="image" />
                        <div>
                          <p>Multiple Device linking</p>
                          <span className="authapp-body3-list-span">
                            Customers who carry more than one phone & prefer the
                            availability of Soft Token on multiple devices, can
                            access their Verifyed Soft Token accounts on
                            multiple devices.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 auth-app-body-2-img">
                <img src={verified6} alt="verified6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
