/* React packages and components */
import React from "react";
import Footer from "../Footer/MontraFooter";
import { Navigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import MoneyLinklogo from "../../assets/paylink-images/montra/newml.svg";

/**
 *  Reset the by entering new password.
 * <p> User can enter new password and reset his password.</p>
 *  @Author : Seema Kumari
 * @version :1.0 */

class Forgotpassword2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      status: "",
      message: "",
      backerrors: [],
      isActive: false,
      errormsg: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleShow = () => {
    this.setState({
      isActive: true,
    });
  };
  /**
   *capyures the new password to server entered by user.
   * @param {Event} e event which calls this method.
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   *Submit the new password to server entered by user.
   * @param {Event} e event occurs on clicking the submit button.
   */
  handle2Submit = (e) => {
    e.preventDefault();
    let fields = this.state.fields;
    let proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = "https://api.montra.org/moneylinkweb/api/user/updatepassword?";
    fetch(
      url +
        "email=" +
        localStorage.getItem("email") +
        "&password=" +
        this.state.fields.password,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else return "server error";
      })
      .then((contents) => {
        if (200 == contents.status && "SUCCESS" == contents.message) {
          this.setState({
            status: contents.status,
            message: contents.message,
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message,
          });
          this.state.backerrors.map((key) => {
            this.setState({
              errormsg: key.message,
            });
          });
        }
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  };
  render() {
    if (this.state.status == 200 && "SUCCESS" == this.state.message) {
      return <Navigate to="Partner" />;
    } else {
      return (
        <div>
          <div className="ml-background moneybgnew">
            <div className="main-header">
              <div className="sticky-top sticky-head">
                <Navbar
                  className="navbar navbar-expand-lg navbar-light bg-light"
                  bg="light"
                  expand="lg"
                >
                  <Container className='="containerheader'>
                    <div className="toplogoml">
                      <Link to="/MoneyLink">
                        <img
                          className="toplogo"
                          src={MoneyLinklogo}
                          alt="logo"
                        />{" "}
                      </Link>
                    </div>
                    <Nav className="navdiv">
                      <div className="menu-item loginitemmenu ">
                        <Link to="" className="productmenuhover">
                          <div className="signbuttonpage">
                            Partner Sign in{" "}
                            <i className="fa fa-angle-right"></i>
                          </div>
                        </Link>
                        <div className="drop-down-menu signinmenu">
                          <ul className="partnerlistlogin">
                            <li className="loginpartnerlist">
                              {" "}
                              <a
                                href="https://www.duopay.montra.org/PartnerLogin"
                                className="loginmenu"
                              >
                                <i class="fas fa-lock"></i>DUO Login{" "}
                                <i className="fa fa-angle-right loginangle"></i>
                              </a>
                            </li>
                            <li className="loginpartnerlist">
                              {" "}
                              <NavLink
                                as={NavLink}
                                to="PartnerLogin"
                                className="loginmenu"
                              >
                                <i class="fas fa-lock"></i>MoneyLink Login{" "}
                                <i className="fa fa-angle-right loginangle"></i>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Nav>
                  </Container>
                </Navbar>
              </div>
            </div>

            <div className="forgotpasswordchange">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <h5 className="headerpass"> Forgot your password </h5>
                    <form>
                      <div className="form-group">
                        <label htmlFor="password">New Password </label>
                        <input
                          type="password"
                          name="password"
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password"> Confirm Password </label>
                        <input
                          type="password"
                          name="confirmpassword"
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                      {(() => {
                        switch (this.state.message) {
                          case "UNSUCCESS":
                            return (
                              <p className="errorMsg">{this.state.errormsg}</p>
                            );
                          default:
                            return <p></p>;
                        }
                      })()}
                      <div className="sign">
                        <input
                          type="submit"
                          className="btn btn-warning partnerbutton"
                          value="Submit"
                          onClick={this.handle2Submit}
                        />
                      </div>
                    </form>
                  </div>

                  <div className="col-md-4"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      );
    }
  }
}

export default Forgotpassword2;
