import React from 'react';

import { Link } from 'react-router-dom';

/**
 CheckerManageUserPart1(checker) component  render's manage user(normal users) for checker section.
 @Author : Thrilok Kandra
 @version :1.0
 */
class CheckerManageUserPart1 extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         fields: {},
      }
   };

   render() {

      return (

         <div>

            <form method="post" name="userRegistrationForm" className="formroot" >


               <div className="d-flex flex-row checkmakuse">
                  <div className="p-2 managech">
                     <div className="">
                        <label className="serial-header">S. No.</label>
                     </div>

                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="heightch">
                           <p className="serialch">{++i}</p>
                        </div>
                        )}
                     </div>
                  </div>
                  <div className="p-2 managech1">
                     <div className="">
                        <label className="usertheaderch userman">User Name</label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isUserNamefieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="username" onChange={this.props.handleChange.bind(this)} disabled> {manageUser.userName} </label>
                           <label className="switchtoggle"><input type="checkbox" name="isUserName" onChange={(e) => { this.props.toggleChangeIsUserName(e, i) }} checked={manageUser.isUserName} disabled={this.props.isUserNamelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isUserNameclasslist[i] }}></div></label>
                        </div>)}
                     </div>
                  </div>
                  <div className="p-2 managech1">
                     <div className="">
                        <label className="usertheaderch designationch">User Designation</label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isDesignationfieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="userdesgn1" onChange={this.props.handleChange.bind(this)} disabled> {manageUser.designation} </label>
                           <label className="switchtoggle"><input type="checkbox" name="isDesignation" onChange={(e) => { this.props.toggleChangeIsDesignation(e, i) }} checked={manageUser.isDesignation} disabled={this.props.isDesignationlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isDesignationclasslist[i] }}></div></label>
                        </div>)}
                     </div>
                  </div>
                  <div className="p-2 managech1">
                     <div className="">
                        <label className="usertheaderch userch">User Type</label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isUserTypefieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="usertype1" onChange={this.props.handleChange.bind(this)} disabled>{manageUser.userType} </label>
                           <label className="switchtoggle"><input type="checkbox" name="isUserType" onChange={(e) => { this.props.toggleChangeIsUserType(e, i) }} checked={manageUser.isUserType} disabled={this.props.isUserTypelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isUserTypeclasslist[i] }}></div></label>
                        </div>)}
                     </div>
                  </div>
                  <div className="p-2 managech1">
                     <div className="">
                        <label className="usertheaderch emailch">User Official Email ID</label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isEmailfieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="email1" onChange={this.props.handleChange.bind(this)} disabled> {manageUser.officialEmail} </label>
                           <label className="switchtoggle"><input type="checkbox" name="isEmail" onChange={(e) => { this.props.toggleChangeIsEmail(e, i) }} checked={manageUser.isEmail} disabled={this.props.isEmaillist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isEmailclasslist[i] }}></div></label>
                        </div>)}
                     </div>
                  </div>
                  <div className="p-2 managech1">
                     <div className="">
                        <label className="usertheaderch designationch mobno">User Mobile Number </label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isMobilefieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="mobile1" onChange={this.props.handleChange.bind(this)} disabled> {manageUser.mobile} </label>
                           <label className="switchtoggle"><input type="checkbox" name="isMobile" onChange={(e) => { this.props.toggleChangeIsMobile(e, i) }} checked={manageUser.isMobile} disabled={this.props.isMobilelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isMobileclasslist[i] }}></div></label>
                        </div>)}
                     </div>
                  </div>
                  <div className="p-2 managech">
                     <div className="">
                        <label className="usertheaderch actmanage">Activation </label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isActivefieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="mobile1" onChange={this.props.handleChange.bind(this)} disabled> {manageUser.active} </label>
                           <label className="switchtoggle"><input type="checkbox" name="active" onChange={(e) => { this.props.toggleChangeActive(e, i) }} checked={manageUser.isActive} disabled={this.props.isActivelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isActiveclasslist[i] }} ></div></label>
                        </div>
                        )}
                     </div>
                  </div>
                  <div className="p-2 managech">
                     <div className="">
                        <label className="usertheaderch actmanage">Removal </label>
                     </div>
                     <div className="form-group">
                        {this.props.data.map((manageUser, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isRemovedfieldclasslist[i] }} >
                           <label type="text" className="form-control formpprofilefield" name="mobile1" onChange={this.props.handleChange.bind(this)} disabled> {this.props.isRemovedyesorno[i]} </label>
                           <label className="switchtoggle"><input type="checkbox" name="active" onChange={(e) => { this.props.toggleChangeremoval(e, i) }} checked={manageUser.isRemoved} disabled={this.props.isRemovedlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isRemovedclasslist[i] }}></div></label>
                        </div>
                        )}
                     </div>
                  </div>



               </div>


            </form>

         </div>
      )

   }


}

export default CheckerManageUserPart1;
