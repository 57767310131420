
/* React packages and components */
import React from 'react';
import Sidebar from '../Sidebar';
import { Link } from 'react-router-dom';
import UndWrittingPart1 from './UndWrittingPart1';
import UndWrittingPart2 from './UndWrittingPart2';
import UndWrittingPart3 from './UndWrittingPart3';
import UndWrittingPart4 from './UndWrittingPart4';
import UndWrittingPart5 from './UndWrittingPart5';
import UndWrittingPart6 from './UndWrittingPart6';
import MakerHeader from '../MakerHeader';
import "./UnderWrittingRules.css";



/**
* Renders UnderwrittingRules  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class UnderWrittingRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      fieldsvalid: false,
      buttoncolor: '',
      isAvgSalIntRateMultBET200000n500000list: [], isAvgSalIntRateMultBET500001n1000000list: [], isAvgSalIntRateMultBET1000001n2000000list: [], isAvgSalIntRateMultBankstafflist: [], isAvgSalIntRateMultGT2000000list: [], isAvgSalIntRateMultLT200000list: [], isAvgSalIntRateMultStatuslist: [], isAvgSalTenorBankStaffvaluelist: [], isAvgSalTenorMultiplierStatuslist: [], isAvgSalaryMultBET200000n500000list: [],
      isAvgSalaryMultBET500001n1000000list: [], isAvgSalaryMultBET1000001n2000000list: [], isAvgSalaryMultGT2000000list: [], isAvgSalaryMultLT200000list: [], isBureauScoreIntRateMultBET600N700list: [], isBureauScoreIntRateMultBET700n800list: [], isBureauScoreIntRateMultBET800n900list: [], isBureauScoreIntRateMultGT900list: [], isBureauScoreIntRateMultlt600list: [], isCatMultiStatuslist: [], isCategoryAlist: [],
      isCategoryBlist: [], isCategoryClist: [], isCategoryDlist: [], isCategoryElist: [], isDebtBurdenAllowPercentlist: [], isDeliquencyIntRateMult30DPDlist: [], isDeliquencyIntRateMult60DPDlist: [], isDeliquencyIntRateMultCurrentlist: [], isDeliquencyIntRateMultGT90DPDlist: [], isDeliquencyIntRateMultXDayslist: [], isInstantloanDisCaplist: [], isLastSalarylist: [], isMaxAgelist: [], isMaxLoanAmtCaplist: [], isMinAgelist: [], isMinLoanAmtlist: [],
      isNumYrsCurCurEmplyIntRateMultBET1n3yrslist: [], isNumYrsCurEmplyIntRateMultBET3n5yrslist: [], isNumYrsCurEmplyIntRateMultBET5n10yrslist: [], isNumYrsCurEmplyIntRateMultGT10yrslist: [], isNumYrsCurEmplyIntRateMultLess1yrlist: [], isNumYrsEmplyIntMultRateMultBET1n3yrslist: [], isNumYrsEmplyIntMultRateMultBET3n5yrslist: [], isNumYrsEmplyIntMultRateMultBET5n10yrslist: [], isNumYrsEmplyIntMultRateMultLess1yrlist: [], isNumYrsEmplyIntRateMultGT10yrslist: [],
      data: [
        {
          productName: '', minAge: '', maxAge: '', debtBurdenAllowPercent: '', minLoanAmt: '', maxLoanAmtCap: '', instantloanDisCap: '', lastSalary: '', categoryA: '', categoryB: '', categoryC: '', categoryD: '', categoryE: '', catMultiStatus: '', avgSalaryMultLT200000: '',
          avgSalaryMultBET200000n500000: '', avgSalaryMultBET500001n1000000: '', avgSalaryMultBET1000001n2000000: '', avgSalaryMultGT2000000: '', avgSalTenorMultiplierStatus: '', avgSalTenorBankStaffvalue: '', avgSalIntRateMultStatus: '',
          avgSalIntRateMultLT200000: '', avgSalIntRateMultBET200000n500000: '', avgSalIntRateMultBET500001n1000000: '', avgSalIntRateMultBET1000001n2000000: '', avgSalIntRateMultGT2000000: '', numYrsEmplyIntMultRateMultLess1yr: '', numYrsEmplyIntMultRateMultBET1n3yrs: '', numYrsEmplyIntMultRateMultBET3n5yrs: '',
          numYrsEmplyIntMultRateMultBET5n10yrs: '', numYrsEmplyIntRateMultGT10yrs: '', numYrsCurEmplyIntRateMultLess1yr: '', numYrsCurCurEmplyIntRateMultBET1n3yrs: '', numYrsCurEmplyIntRateMultBET3n5yrs: '', numYrsCurEmplyIntRateMultBET5n10yrs: '', numYrsCurEmplyIntRateMultGT10yrs: '', deliquencyIntRateMultCurrent: '', deliquencyIntRateMultXDays: '',
          deliquencyIntRateMult30DPD: '', numYrsCurEmplyIntRateMultStatus: '', deliquencyIntRateMult60DPD: '', numYrsEmplyIntRateMultStatus: '', deliquencyIntRateMultStatus: '', deliquencyIntRateMultGT90DPD: '', bureauScoreIntRateMultltStatus: '', bureauScoreIntRateMultGT900: '', bureauScoreIntRateMultBET800n900: '', bureauScoreIntRateMultBET700n800: '', bureauScoreIntRateMultBET600N700: '', bureauScoreIntRateMultlt600: '',
        },
      ],
      status: '',
      avgSalIntRateMultStatusdisable: false,
      avgSalTenorMultiplierStatusdisable: false,
      bureauScoreIntRateMultltStatusdisable: false,
      catMultiStatusdisable: false,
      deliquencyIntRateMultStatusdisable: false,
      numYrsCurEmplyIntRateMultStatusdisable: false,
      numYrsEmplyIntRateMultStatusdisable: false,
      editable: false
    };

  }

  /**
  *captures the pin entred by user.
  * @param {Event} e event which calls this method.
  */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields
    })

  }
  /**
     * For fresh editable request we set all to default values.
     */
  setNvalue() {
    this.state.data.map((underwritingrules, i) => {
      underwritingrules.isAvgSalIntRateMultBET1000001n2000000 = "N"
      underwritingrules.isAvgSalIntRateMultBET200000n500000 = "N"
      underwritingrules.isAvgSalIntRateMultBET500001n1000000 = "N"
      underwritingrules.isAvgSalIntRateMultGT2000000 = "N"
      underwritingrules.isAvgSalIntRateMultLT200000 = "N"
      underwritingrules.isAvgSalTenorBankStaffvalue = "N"
      underwritingrules.isAvgSalaryMultBET1000001n2000000 = "N"
      underwritingrules.isAvgSalaryMultBET200000n500000 = "N"
      underwritingrules.isAvgSalaryMultBET500001n1000000 = "N"
      underwritingrules.isAvgSalaryMultGT2000000 = "N"
      underwritingrules.isAvgSalaryMultLT200000 = "N"
      underwritingrules.isBureauScoreIntRateMultBET600N700 = "N"
      underwritingrules.isBureauScoreIntRateMultBET700n800 = "N"
      underwritingrules.isBureauScoreIntRateMultBET800n900 = "N"
      underwritingrules.isBureauScoreIntRateMultGT900 = "N"
      underwritingrules.isBureauScoreIntRateMultlt600 = "N"
      underwritingrules.isCategoryA = "N"
      underwritingrules.isCategoryB = "N"
      underwritingrules.isCategoryC = "N"
      underwritingrules.isCategoryD = "N"
      underwritingrules.isCategoryE = "N"
      underwritingrules.isDebtBurdenAllowPercent = "N"
      underwritingrules.isDeliquencyIntRateMult30DPD = "N"
      underwritingrules.isDeliquencyIntRateMult60DPD = "N"
      underwritingrules.isDeliquencyIntRateMultCurrent = "N"
      underwritingrules.isDeliquencyIntRateMultGT90DPD = "N"
      underwritingrules.isDeliquencyIntRateMultXDays = "N"
      underwritingrules.isInstantloanDisCap = "N"
      underwritingrules.isLastSalary = "N"
      underwritingrules.isMaxAge = "N"
      underwritingrules.isMaxLoanAmtCap = "N"
      underwritingrules.isMinAge = "N"
      underwritingrules.isMinLoanAmt = "N"
      underwritingrules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = "N"
      underwritingrules.isNumYrsCurEmplyIntRateMultBET3n5yrs = "N"
      underwritingrules.isNumYrsCurEmplyIntRateMultGT10yrs = "N"
      underwritingrules.isNumYrsCurEmplyIntRateMultLess1yr = "N"
      underwritingrules.isNumYrsEmplyIntMultRateMultBET1n3yrs = "N"
      underwritingrules.isNumYrsEmplyIntMultRateMultBET3n5yrs = "N"
      underwritingrules.isNumYrsEmplyIntMultRateMultBET5n10yrs = "N"
      underwritingrules.isNumYrsEmplyIntMultRateMultLess1yr = "N"
      underwritingrules.isNumYrsEmplyIntRateMultGT10yrs = "N"
    })
  }
  /**
  *Set border color for already sent request from maker.
  */
  setBorderColor() {
    let isAvgSalIntRateMultBET200000n500000list = this.state.isAvgSalIntRateMultBET200000n500000list
    let isAvgSalIntRateMultBET500001n1000000list = this.state.isAvgSalIntRateMultBET500001n1000000list
    let isAvgSalIntRateMultBET1000001n2000000list = this.state.isAvgSalIntRateMultBET1000001n2000000list
    let isAvgSalIntRateMultBankstafflist = this.state.isAvgSalIntRateMultBankstafflist
    let isAvgSalIntRateMultGT2000000list = this.state.isAvgSalIntRateMultGT2000000list
    let isAvgSalIntRateMultLT200000list = this.state.isAvgSalIntRateMultLT200000list
    let isAvgSalIntRateMultStatuslist = this.state.isAvgSalIntRateMultStatuslist
    let isAvgSalTenorBankStaffvaluelist = this.state.isAvgSalTenorBankStaffvaluelist
    let isAvgSalTenorMultiplierStatuslist = this.state.isAvgSalTenorMultiplierStatuslist
    let isAvgSalaryMultBET200000n500000list = this.state.isAvgSalaryMultBET200000n500000list
    let isAvgSalaryMultBET500001n1000000list = this.state.isAvgSalaryMultBET500001n1000000list
    let isAvgSalaryMultBET1000001n2000000list = this.state.isAvgSalaryMultBET1000001n2000000list
    let isAvgSalaryMultGT2000000list = this.state.isAvgSalaryMultGT2000000list
    let isAvgSalaryMultLT200000list = this.state.isAvgSalaryMultLT200000list
    let isBureauScoreIntRateMultBET600N700list = this.state.isBureauScoreIntRateMultBET600N700list
    let isBureauScoreIntRateMultBET700n800list = this.state.isBureauScoreIntRateMultBET700n800list
    let isBureauScoreIntRateMultBET800n900list = this.state.isBureauScoreIntRateMultBET800n900list
    let isBureauScoreIntRateMultGT900list = this.state.isBureauScoreIntRateMultGT900list
    let isBureauScoreIntRateMultlt600list = this.state.isBureauScoreIntRateMultlt600list
    let isCategoryAlist = this.state.isCategoryAlist
    let isCategoryBlist = this.state.isCategoryBlist
    let isCategoryClist = this.state.isCategoryClist
    let isCategoryDlist = this.state.isCategoryDlist
    let isCategoryElist = this.state.isCategoryElist
    let isDebtBurdenAllowPercentlist = this.state.isDebtBurdenAllowPercentlist
    let isDeliquencyIntRateMult30DPDlist = this.state.isDeliquencyIntRateMult30DPDlist
    let isDeliquencyIntRateMult60DPDlist = this.state.isDeliquencyIntRateMult60DPDlist
    let isDeliquencyIntRateMultCurrentlist = this.state.isDeliquencyIntRateMultCurrentlist
    let isDeliquencyIntRateMultGT90DPDlist = this.state.isDeliquencyIntRateMultGT90DPDlist
    let isDeliquencyIntRateMultXDayslist = this.state.isDeliquencyIntRateMultXDayslist
    let isInstantloanDisCaplist = this.state.isInstantloanDisCaplist
    let isLastSalarylist = this.state.isLastSalarylist
    let isMaxAgelist = this.state.isMaxAgelist
    let isMaxLoanAmtCaplist = this.state.isMaxLoanAmtCaplist
    let isMinAgelist = this.state.isMinAgelist
    let isMinLoanAmtlist = this.state.isMinLoanAmtlist
    let isNumYrsCurCurEmplyIntRateMultBET1n3yrslist = this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist
    let isNumYrsCurEmplyIntRateMultBET3n5yrslist = this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist
    let isNumYrsCurEmplyIntRateMultBET5n10yrslist = this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist
    let isNumYrsCurEmplyIntRateMultGT10yrslist = this.state.isNumYrsCurEmplyIntRateMultGT10yrslist
    let isNumYrsCurEmplyIntRateMultLess1yrlist = this.state.isNumYrsCurEmplyIntRateMultLess1yrlist
    let isNumYrsEmplyIntMultRateMultBET1n3yrslist = this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist
    let isNumYrsEmplyIntMultRateMultBET3n5yrslist = this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist
    let isNumYrsEmplyIntMultRateMultBET5n10yrslist = this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist
    let isNumYrsEmplyIntMultRateMultLess1yrlist = this.state.isNumYrsEmplyIntMultRateMultLess1yrlist
    let isNumYrsEmplyIntRateMultGT10yrslist = this.state.isNumYrsEmplyIntRateMultGT10yrslist
    this.state.data.map((underwritingrules, i) => {
      if (underwritingrules.isAvgSalIntRateMultBET1000001n2000000 == "A") { this.state.isAvgSalIntRateMultBET1000001n2000000list[i] = "#e90505" }
      if (underwritingrules.isAvgSalIntRateMultBET200000n500000 == "A") { this.state.isAvgSalIntRateMultBET200000n500000listlist[i] = "#e90505" }
      if (underwritingrules.isAvgSalIntRateMultBET500001n1000000 == "A") { this.state.isAvgSalIntRateMultBET500001n1000000listlist[i] = "#e90505" }
      if (underwritingrules.isAvgSalIntRateMultGT2000000 == "A") { this.state.isAvgSalIntRateMultGT2000000listlist[i] = "#e90505" }
      if (underwritingrules.isAvgSalIntRateMultLT200000 == "A") { this.state.isAvgSalIntRateMultLT200000listlist[i] = "#e90505" }
      if (underwritingrules.isAvgSalTenorBankStaffvalue == "A") { this.state.isAvgSalTenorBankStaffvaluelist[i] = "#e90505" }
      if (underwritingrules.isAvgSalaryMultBET1000001n2000000 == "A") { this.state.isAvgSalaryMultBET1000001n2000000list[i] = "#e90505" }
      if (underwritingrules.isAvgSalaryMultBET200000n500000 == "A") { this.state.isAvgSalaryMultBET200000n500000list[i] = "#e90505" }
      if (underwritingrules.isAvgSalaryMultBET500001n1000000 == "A") { this.state.isAvgSalaryMultBET500001n1000000list[i] = "#e90505" }
      if (underwritingrules.isAvgSalaryMultGT2000000 == "A") { this.state.isAvgSalaryMultGT2000000list[i] = "#e90505" }
      if (underwritingrules.isAvgSalaryMultLT200000 == "A") { this.state.isAvgSalaryMultLT200000list[i] = "#e90505" }
      if (underwritingrules.isBureauScoreIntRateMultBET600N700 == "A") { this.state.isBureauScoreIntRateMultBET600N700list[i] = "#e90505" }
      if (underwritingrules.isBureauScoreIntRateMultBET700n800 == "A") { this.state.isBureauScoreIntRateMultBET700n800list[i] = "#e90505" }
      if (underwritingrules.isBureauScoreIntRateMultBET800n900 == "A") { this.state.isBureauScoreIntRateMultBET800n900list[i] = "#e90505" }
      if (underwritingrules.isBureauScoreIntRateMultGT900 == "A") { this.state.isBureauScoreIntRateMultGT900list[i] = "#e90505" }
      if (underwritingrules.isBureauScoreIntRateMultlt600 == "A") { this.state.isBureauScoreIntRateMultlt600list[i] = "#e90505" }
      if (underwritingrules.isCategoryA == "A") { this.state.isCategoryAlist[i] = "#e90505" }
      if (underwritingrules.isCategoryB == "A") { this.state.isCategoryBlist[i] = "#e90505" }
      if (underwritingrules.isCategoryC == "A") { this.state.isCategoryClist[i] = "#e90505" }
      if (underwritingrules.isCategoryD == "A") { this.state.isCategoryDlist[i] = "#e90505" }
      if (underwritingrules.isCategoryE == "A") { this.state.isCategoryElist[i] = "#e90505" }
      if (underwritingrules.isDebtBurdenAllowPercent == "A") { this.state.isDebtBurdenAllowPercentlist[i] = "#e90505" }
      if (underwritingrules.isDeliquencyIntRateMult30DPD == "A") { this.state.isDeliquencyIntRateMult30DPDlist[i] = "#e90505" }
      if (underwritingrules.isDeliquencyIntRateMult60DPD == "A") { this.state.isDeliquencyIntRateMult60DPDlist[i] = "#e90505" }
      if (underwritingrules.isDeliquencyIntRateMultCurrent == "A") { this.state.isDeliquencyIntRateMultCurrentlist[i] = "#e90505" }
      if (underwritingrules.isDeliquencyIntRateMultGT90DPD == "A") { this.state.isDeliquencyIntRateMultGT90DPDlist[i] = "#e90505" }
      if (underwritingrules.isDeliquencyIntRateMultXDays == "A") { this.state.isDeliquencyIntRateMultXDayslist[i] = "#e90505" }
      if (underwritingrules.isInstantloanDisCap == "A") { this.state.isInstantloanDisCaplist[i] = "#e90505" }
      if (underwritingrules.isLastSalary == "A") { this.state.isLastSalarylist[i] = "#e90505" }
      if (underwritingrules.isMaxAge == "A") { this.state.isMaxAgelist[i] = "#e90505" }
      if (underwritingrules.isMaxLoanAmtCap == "A") { this.state.isMaxLoanAmtCaplist[i] = "#e90505" }
      if (underwritingrules.isMinAge == "A") { this.state.isMinAgelist[i] = "#e90505" }
      if (underwritingrules.isMinLoanAmt == "A") { this.state.isMinLoanAmtlist[i] = "#e90505" }
      if (underwritingrules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs == "A") { this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] = "#e90505" }
      if (underwritingrules.isNumYrsCurEmplyIntRateMultBET3n5yrs == "A") { this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] = "#e90505" }
      if (underwritingrules.isNumYrsCurEmplyIntRateMultGT10yrs == "A") { this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i] = "#e90505" }
      if (underwritingrules.isNumYrsCurEmplyIntRateMultLess1yr == "A") { this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i] = "#e90505" }
      if (underwritingrules.isNumYrsEmplyIntMultRateMultBET1n3yrs == "A") { this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] = "#e90505" }
      if (underwritingrules.isNumYrsEmplyIntMultRateMultBET3n5yrs == "A") { this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] = "#e90505" }
      if (underwritingrules.isNumYrsEmplyIntMultRateMultBET5n10yrs == "A") { this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] = "#e90505" }
      if (underwritingrules.isNumYrsEmplyIntMultRateMultLess1yr == "A") { this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i] = "#e90505" }
      if (underwritingrules.isNumYrsEmplyIntRateMultGT10yrs == "A") { this.state.isNumYrsEmplyIntRateMultGT10yrslist[i] = "#e90505" }
    })
    this.setState({
      isAvgSalIntRateMultBET200000n500000list, isAvgSalIntRateMultBET500001n1000000list, isAvgSalIntRateMultBET1000001n2000000list, isAvgSalIntRateMultBankstafflist, isAvgSalIntRateMultGT2000000list, isAvgSalIntRateMultLT200000list, isAvgSalIntRateMultStatuslist, isAvgSalTenorBankStaffvaluelist, isAvgSalTenorMultiplierStatuslist, isAvgSalaryMultBET200000n500000list,
      isAvgSalaryMultBET500001n1000000list, isAvgSalaryMultBET1000001n2000000list, isAvgSalaryMultGT2000000list, isAvgSalaryMultLT200000list, isBureauScoreIntRateMultBET600N700list, isBureauScoreIntRateMultBET700n800list, isBureauScoreIntRateMultBET800n900list, isBureauScoreIntRateMultGT900list, isBureauScoreIntRateMultlt600list, isCategoryAlist,
      isCategoryBlist, isCategoryClist, isCategoryDlist, isCategoryElist, isDebtBurdenAllowPercentlist, isDeliquencyIntRateMult30DPDlist, isDeliquencyIntRateMult60DPDlist, isDeliquencyIntRateMultCurrentlist, isDeliquencyIntRateMultGT90DPDlist, isDeliquencyIntRateMultXDayslist, isInstantloanDisCaplist, isLastSalarylist, isMaxAgelist, isMaxLoanAmtCaplist, isMinAgelist, isMinLoanAmtlist,
      isNumYrsCurCurEmplyIntRateMultBET1n3yrslist, isNumYrsCurEmplyIntRateMultBET3n5yrslist, isNumYrsCurEmplyIntRateMultBET5n10yrslist, isNumYrsCurEmplyIntRateMultGT10yrslist, isNumYrsCurEmplyIntRateMultLess1yrlist, isNumYrsEmplyIntMultRateMultBET1n3yrslist, isNumYrsEmplyIntMultRateMultBET3n5yrslist, isNumYrsEmplyIntMultRateMultBET5n10yrslist, isNumYrsEmplyIntMultRateMultLess1yrlist, isNumYrsEmplyIntRateMultGT10yrslist,

    })
  }
  /**
   * Set Boolean values for "ON and OFF" for GL's required
   */
  setonandoffstatusvalue() {
    this.state.data.map((underwritingrules, i) => {
      if ("ON" == underwritingrules.avgSalIntRateMultStatus) {
        underwritingrules.avgSalIntRateMultStatus = true
        this.state.avgSalIntRateMultStatusdisable = false
      } else {
        underwritingrules.avgSalIntRateMultStatus = false
        this.state.avgSalIntRateMultStatusdisable = true
      }
      if ("ON" == underwritingrules.avgSalTenorMultiplierStatus) {
        underwritingrules.avgSalTenorMultiplierStatus = true
        this.state.avgSalTenorMultiplierStatusdisable = false
      } else {
        underwritingrules.avgSalTenorMultiplierStatus = false
        this.state.avgSalTenorMultiplierStatusdisable = true
      }
      if ("ON" == underwritingrules.bureauScoreIntRateMultltStatus) {
        underwritingrules.bureauScoreIntRateMultltStatus = true
        this.state.bureauScoreIntRateMultltStatusdisable = false
      } else {
        underwritingrules.bureauScoreIntRateMultltStatus = false
        this.state.bureauScoreIntRateMultltStatusdisable = true
      }
      if ("ON" == underwritingrules.catMultiStatus) {
        underwritingrules.catMultiStatus = true
        this.state.catMultiStatusdisable = false
      } else {
        underwritingrules.catMultiStatus = false
        this.state.catMultiStatusdisable = true
      }
      if ("ON" == underwritingrules.deliquencyIntRateMultStatus) {
        underwritingrules.deliquencyIntRateMultStatus = true
        this.state.deliquencyIntRateMultStatusdisable = false
      } else {
        underwritingrules.deliquencyIntRateMultStatus = false
        this.state.deliquencyIntRateMultStatusdisable = true
      }
      if ("ON" == underwritingrules.numYrsCurEmplyIntRateMultStatus) {
        underwritingrules.numYrsCurEmplyIntRateMultStatus = true
        this.state.numYrsCurEmplyIntRateMultStatusdisable = false
      } else {
        underwritingrules.numYrsCurEmplyIntRateMultStatus = false
        this.state.numYrsCurEmplyIntRateMultStatusdisable = true
      }
      if ("ON" == underwritingrules.numYrsEmplyIntRateMultStatus) {
        underwritingrules.numYrsEmplyIntRateMultStatus = true
        this.state.numYrsEmplyIntRateMultStatusdisable = false
      } else {
        underwritingrules.numYrsEmplyIntRateMultStatus = false
        this.state.numYrsEmplyIntRateMultStatusdisable = true
      }

    })
  }

  /**
   * Fecthes data from server and renders it to fields
   */
  componentDidMount() {
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL('https://api.montra.org/moneylinkweb/api/product/uwrules?email=' + localStorage.getItem("email"))
    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json;charset=utf-8', 'Access-Control-Allow-Origin': '*' },
    })
      .then((response) => {
        if (response.ok) {

          return response.json();
        }
        else {
          alert('server error')
        }
      })
      .then(contents => {

        if (200 == contents.status && 'SUCCESS' == contents.message) {
          this.setState({
            data: contents.data.UnderWritungRules,
            status: contents.status,
            message: contents.message,
            editable: contents.data.editable
          });
          this.setonandoffstatusvalue();
          if (this.state.editable) {
            this.setState({
              editable: false,
              buttoncolor: "#192859"
            })
          }
          else {
            this.setState({
              editable: true,
              buttoncolor: "#343a40"
            })
          }
          if (this.state.editable) {
            this.setBorderColor();
          } else {
            this.setNvalue();
          }
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message
          });
          this.state.backerrors.map(key => {
            this.setState({
              errorMsg: key.message
            })
          })
        }
      }
      )
  }
  setStatus = (statusres) => {
    this.setState({ status: statusres })

  }
  /**
    * @typedef {underwritingrulesdata} underwritingrulesdata
    * @property {string} minAge indicates minAge of loan product type
    * @property {string} maxAge indicates maxAge of loan product type
    * @property {string} debtBurdenAllowPercent indicates debtBurdenAllowPercent of loan product type
    * @property {string} minLoanAmt indicates minLoanAmt of loan product type
    * @property {string} maxLoanAmtCap indicates maxLoanAmtCap of loan product type
    * @property {string} instantloanDisCap indicates instantloanDisCap of loan product type
    * @property {string} lastSalary indicates lastSalary of loan product type
    * @property {string} categoryA indicates categoryA of loan product type
    * @property {string} categoryB indicates categoryB of loan product type
    * @property {string} categoryC indicates categoryC of loan product type
    * @property {string} categoryD indicates categoryD of loan product type
    * @property {string} categoryE indicates categoryE of loan product type
    * @property {string} catMultiStatus indicates catMultiStatus of loan product type
    * @property {string} avgSalaryMultLT200000 indicates avgSalaryMultLT200000 of loan product type
    * @property {string} avgSalaryMultBET500001n1000000 indicates avgSalaryMultBET500001n1000000 of loan product type
    * @property {string} avgSalaryMultBET1000001n2000000 indicates avgSalaryMultBET1000001n2000000 of loan product type
    * @property {string} avgSalaryMultGT2000000 indicates avgSalaryMultGT2000000 of loan product type
    * @property {string} avgSalTenorMultiplierStatus indicates avgSalTenorMultiplierStatus of loan product type
    * @property {string} avgSalTenorBankStaffvalue indicates avgSalTenorBankStaffvalue of loan product type
    * @property {string} avgSalIntRateMultStatus indicates avgSalIntRateMultStatus of loan product type
    * @property {string} avgSalIntRateMultBET200000n500000 indicates avgSalIntRateMultBET200000n500000 of loan product type
    * @property {string} avgSalIntRateMultBET500001n1000000 indicates avgSalIntRateMultBET500001n1000000 of loan product type
    * @property {string} avgSalIntRateMultBET1000001n2000000 indicates avgSalIntRateMultBET1000001n2000000 of loan product type
    * @property {string} avgSalIntRateMultGT2000000 indicates avgSalIntRateMultGT2000000 of loan product type
    * @property {string} numYrsEmplyIntMultRateMultLess1yr indicates numYrsEmplyIntMultRateMultLess1yr of loan product type
    * @property {string} numYrsEmplyIntMultRateMultBET1n3yrs indicates numYrsEmplyIntMultRateMultBET1n3yrs of loan product type
    * @property {string} numYrsEmplyIntMultRateMultBET3n5yrs indicates numYrsEmplyIntMultRateMultBET3n5yrs of loan product type
    * @property {string} numYrsEmplyIntRateMultGT10yrs indicates numYrsEmplyIntRateMultGT10yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultLess1yr indicates numYrsCurEmplyIntRateMultLess1yr of loan product type
    * @property {string} numYrsCurCurEmplyIntRateMultBET1n3yrs indicates numYrsCurCurEmplyIntRateMultBET1n3yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultBET3n5yrs indicates numYrsCurEmplyIntRateMultBET3n5yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultBET5n10yrs indicates numYrsCurEmplyIntRateMultBET5n10yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultGT10yrs indicates numYrsCurEmplyIntRateMultGT10yrs of loan product type
    * @property {string} deliquencyIntRateMultCurrent indicates deliquencyIntRateMultCurrent of loan product type
    * @property {string} deliquencyIntRateMultXDays indicates deliquencyIntRateMultXDays of loan product type
    * @property {string} deliquencyIntRateMult60DPD indicates deliquencyIntRateMult60DPD of loan product type
    * @property {string} deliquencyIntRateMultGT90DPD indicates deliquencyIntRateMultGT90DPD of loan product type
    * @property {string} bureauScoreIntRateMultltStatus indicates bureauScoreIntRateMultltStatus of loan product type
    * @property {string} bureauScoreIntRateMultGT900 indicates bureauScoreIntRateMultGT900 of loan product type
    * @property {string} bureauScoreIntRateMultBET800n900 indicates bureauScoreIntRateMultBET800n900 of loan product type
    * @property {string} bureauScoreIntRateMultBET700n800 indicates bureauScoreIntRateMultBET700n800 of loan product type
    * @property {string} bureauScoreIntRateMultBET600N700 indicates bureauScoreIntRateMultBET600N700 of loan product type
    * @property {string} bureauScoreIntRateMultlt600 indicates bureauScoreIntRateMultlt600 of loan product type


    */
  /**
  * Capture the change made by maker,stores into data object for valueXXXXXX.
  *
  * @param {Event} e event captures the changed value.
  * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
  */
  handleChangeminAge(e, i) {
    let data = this.state.data;
    this.state.data[i].minAge = e.target.value;
    this.state.data[i].isMinAge = "A";
    this.setState({
      data
    });
  }
  /**
 * Capture the change made by maker,stores into data object for valueXXXXXX.
 *
 * @param {Event} e event captures the changed value.
 * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
 */
  handleChangemaxAge(e, i) {
    let data = this.state.data;
    this.state.data[i].maxAge = e.target.value;
    this.state.data[i].isMaxAge = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangedebtBurdenAllowPercent(e, i) {
    let data = this.state.data;
    this.state.data[i].debtBurdenAllowPercent = e.target.value;
    this.state.data[i].isDebtBurdenAllowPercent = "A";
    if ("" == this.state.data[i].debtBurdenAllowPercent) {
      this.setState({
        data,
        fieldsvalid: true
      });
    }
    else {
      this.setState({
        data,
        fieldsvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeminLoanAmt(e, i) {
    let data = this.state.data;
    this.state.data[i].minLoanAmt = e.target.value;
    this.state.data[i].isMinLoanAmt = "A";
    if ("" == this.state.data[i].minLoanAmt) {
      this.setState({
        data,
        fieldsvalid: true
      });
    }
    else {
      this.setState({
        data,
        fieldsvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangemaxLoanAmtCap(e, i) {
    let data = this.state.data;
    this.state.data[i].maxLoanAmtCap = e.target.value;
    this.state.data[i].isMaxLoanAmtCap = "A";
    if ("" == this.state.data[i].maxLoanAmtCap) {
      this.setState({
        data,
        fieldsvalid: true
      });
    }
    else {
      this.setState({
        data,
        fieldsvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeinstantloanDisCap(e, i) {
    let data = this.state.data;
    this.state.data[i].instantloanDisCap = e.target.value;
    this.state.data[i].isInstantloanDisCap = "A";
    if ("" == this.state.data[i].instantloanDisCap) {
      this.setState({
        data,
        fieldsvalid: true
      });
    }
    else {
      this.setState({
        data,
        fieldsvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangelastSalary(e, i) {
    let data = this.state.data;
    this.state.data[i].lastSalary = e.target.value;
    this.state.data[i].isLastSalary = "A";
    if ("" == this.state.data[i].lastSalary) {
      this.setState({
        data,
        fieldsvalid: true
      });
    }
    else {
      this.setState({
        data,
        fieldsvalid: false
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangecategoryA(e, i) {
    let data = this.state.data;
    this.state.data[i].categoryA = e.target.value;
    this.state.data[i].isCategoryA = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangecategoryB(e, i) {
    let data = this.state.data;
    this.state.data[i].categoryB = e.target.value;
    this.state.data[i].isCategoryB = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangecategoryC(e, i) {
    let data = this.state.data;
    this.state.data[i].categoryC = e.target.value;
    this.state.data[i].isCategoryC = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangecategoryD(e, i) {
    let data = this.state.data;
    this.state.data[i].categoryD = e.target.value;
    this.state.data[i].isCategoryD = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangecategoryE(e, i) {
    let data = this.state.data;
    this.state.data[i].categoryE = e.target.value;
    this.state.data[i].isCategoryE = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalaryMultLT200000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalaryMultLT200000 = e.target.value;
    this.state.data[i].isAvgSalaryMultLT200000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalaryMultBET200000n500000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalaryMultBET200000n500000 = e.target.value;
    this.state.data[i].isAvgSalaryMultBET200000n500000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalaryMultBET500001n1000000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalaryMultBET500001n1000000 = e.target.value;
    this.state.data[i].isAvgSalaryMultBET500001n1000000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalaryMultBET1000001n2000000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalaryMultBET1000001n2000000 = e.target.value;
    this.state.data[i].isAvgSalaryMultBET1000001n2000000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalaryMultGT2000000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalaryMultGT2000000 = e.target.value;
    this.state.data[i].isAvgSalaryMultGT2000000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalTenorBankStaffvalue(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalTenorBankStaffvalue = e.target.value;
    this.state.data[i].isAvgSalTenorBankStaffvalue = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalIntRateMultLT200000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalIntRateMultLT200000 = e.target.value;
    this.state.data[i].isAvgSalIntRateMultLT200000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalIntRateMultBET200000n500000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalIntRateMultBET200000n500000 = e.target.value;
    this.state.data[i].isAvgSalIntRateMultBET200000n500000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalIntRateMultBET500001n1000000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalIntRateMultBET500001n1000000 = e.target.value;
    this.state.data[i].isAvgSalIntRateMultBET500001n1000000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalIntRateMultBET1000001n2000000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalIntRateMultBET1000001n2000000 = e.target.value;
    this.state.data[i].isAvgSalIntRateMultBET1000001n2000000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalIntRateMultGT2000000(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalIntRateMultGT2000000 = e.target.value;
    this.state.data[i].isAvgSalIntRateMultGT2000000 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangeavgSalTenorBankStaffvalue(e, i) {
    let data = this.state.data;
    this.state.data[i].avgSalTenorBankStaffvalue = e.target.value;
    this.state.data[i].isAvgSalTenorBankStaffvalue = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsEmplyIntMultRateMultLess1yr(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsEmplyIntMultRateMultLess1yr = e.target.value;
    this.state.data[i].isNumYrsEmplyIntMultRateMultLess1yr = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsEmplyIntMultRateMultBET1n3yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsEmplyIntMultRateMultBET1n3yrs = e.target.value;
    this.state.data[i].isNumYrsEmplyIntMultRateMultBET1n3yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsEmplyIntMultRateMultBET3n5yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsEmplyIntMultRateMultBET3n5yrs = e.target.value;
    this.state.data[i].isNumYrsEmplyIntMultRateMultBET3n5yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsEmplyIntMultRateMultBET5n10yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsEmplyIntMultRateMultBET5n10yrs = e.target.value;
    this.state.data[i].isNumYrsEmplyIntMultRateMultBET5n10yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsEmplyIntRateMultGT10yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsEmplyIntRateMultGT10yrs = e.target.value;
    this.state.data[i].isNumYrsEmplyIntRateMultGT10yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangedeliquencyIntRateMultCurrent(e, i) {
    let data = this.state.data;
    this.state.data[i].deliquencyIntRateMultCurrent = e.target.value;
    this.state.data[i].isDeliquencyIntRateMultCurrent = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangedeliquencyIntRateMultXDays(e, i) {
    let data = this.state.data;
    this.state.data[i].deliquencyIntRateMultXDays = e.target.value;
    this.state.data[i].isDeliquencyIntRateMultXDays = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangedeliquencyIntRateMult30DPD(e, i) {
    let data = this.state.data;
    this.state.data[i].deliquencyIntRateMult30DPD = e.target.value;
    this.state.data[i].isDeliquencyIntRateMult30DPD = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangedeliquencyIntRateMult60DPD(e, i) {
    let data = this.state.data;
    this.state.data[i].deliquencyIntRateMult60DPD = e.target.value;
    this.state.data[i].isDeliquencyIntRateMult60DPD = "A";
    this.setState({
      data
    });
  }

  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangedeliquencyIntRateMultGT90DPD(e, i) {
    let data = this.state.data;
    this.state.data[i].deliquencyIntRateMultGT90DPD = e.target.value;
    this.state.data[i].isDeliquencyIntRateMultGT90DPD = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsCurEmplyIntRateMultLess1yr(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsCurEmplyIntRateMultLess1yr = e.target.value;
    this.state.data[i].isNumYrsCurEmplyIntRateMultLess1yr = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsCurCurEmplyIntRateMultBET1n3yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsCurCurEmplyIntRateMultBET1n3yrs = e.target.value;
    this.state.data[i].isNumYrsCurCurEmplyIntRateMultBET1n3yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsCurEmplyIntRateMultBET3n5yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsCurEmplyIntRateMultBET3n5yrs = e.target.value;
    this.state.data[i].isNumYrsCurEmplyIntRateMultBET3n5yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangenumYrsCurEmplyIntRateMultGT10yrs(e, i) {
    let data = this.state.data;
    this.state.data[i].numYrsCurEmplyIntRateMultGT10yrs = e.target.value;
    this.state.data[i].isNumYrsCurEmplyIntRateMultGT10yrs = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangebureauScoreIntRateMultGT900(e, i) {
    let data = this.state.data;
    this.state.data[i].bureauScoreIntRateMultGT900 = e.target.value;
    this.state.data[i].isBureauScoreIntRateMultGT900 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangebureauScoreIntRateMultBET800n900(e, i) {
    let data = this.state.data;
    this.state.data[i].bureauScoreIntRateMultBET800n900 = e.target.value;
    this.state.data[i].isBureauScoreIntRateMultBET800n900 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangebureauScoreIntRateMultBET700n800(e, i) {
    let data = this.state.data;
    this.state.data[i].bureauScoreIntRateMultBET700n800 = e.target.value;
    this.state.data[i].isBureauScoreIntRateMultBET700n800 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangebureauScoreIntRateMultBET600N700(e, i) {
    let data = this.state.data;
    this.state.data[i].bureauScoreIntRateMultBET600N700 = e.target.value;
    this.state.data[i].isBureauScoreIntRateMultBET600N700 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  handleChangebureauScoreIntRateMultlt600(e, i) {
    let data = this.state.data;
    this.state.data[i].bureauScoreIntRateMultlt600 = e.target.value;
    this.state.data[i].isBureauScoreIntRateMultlt600 = "A";
    this.setState({
      data
    });
  }
  /**
   * Capture the change made by maker,stores into data object for valueXXXXXX.
   *
   * @param {Event} e event captures the changed value.
   * @param {underwritingrulesdata} underwritingrulesdata  stores it into index underwritingrulesdata
   */
  toggleChange(e) {


    let data = this.state.data;
    if ('catMultiStatus' == e.target.name) {
      this.state.data[0].catMultiStatus = !this.state.data[0].catMultiStatus
      if (this.state.data[0].catMultiStatus) {
        this.state.catMultiStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isCatMultiStatus = "A"
          underwritingrules.catMultiStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isCatMultiStatus = "A"
          underwritingrules.catMultiStatus = false
        })
        this.state.catMultiStatusdisable = true
      }
      this.setState({
        data
      })
    } else if ('avgSalTenorMultiplierStatus' == e.target.name) {
      this.state.data[0].avgSalTenorMultiplierStatus = !this.state.data[0].avgSalTenorMultiplierStatus
      if (this.state.data[0].avgSalTenorMultiplierStatus) {
        this.state.avgSalTenorMultiplierStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isAvgSalTenorMultiplierStatus = "A"
          underwritingrules.avgSalTenorMultiplierStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isAvgSalTenorMultiplierStatus = "A"
          underwritingrules.avgSalTenorMultiplierStatus = false
        })
        this.state.avgSalTenorMultiplierStatusdisable = true
      }
      this.setState({
        data
      })
    }
    else if ('avgSalIntRateMultStatus' == e.target.name) {
      this.state.data[0].avgSalIntRateMultStatus = !this.state.data[0].avgSalIntRateMultStatus
      if (this.state.data[0].avgSalIntRateMultStatus) {
        this.state.avgSalIntRateMultStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isAvgSalIntRateMultStatus = "A"
          underwritingrules.avgSalIntRateMultStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isAvgSalIntRateMultStatus = "A"
          underwritingrules.avgSalIntRateMultStatus = false
        })
        this.state.avgSalIntRateMultStatusdisable = true
      }
      this.setState({
        data
      })
    }
    else if ('bureauScoreIntRateMultltStatus' == e.target.name) {
      this.state.data[0].bureauScoreIntRateMultltStatus = !this.state.data[0].bureauScoreIntRateMultltStatus
      if (this.state.data[0].bureauScoreIntRateMultltStatus) {
        this.state.bureauScoreIntRateMultltStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isBureauScoreIntRateMultltStatus = "A"
          underwritingrules.bureauScoreIntRateMultltStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isBureauScoreIntRateMultltStatus = "A"
          underwritingrules.bureauScoreIntRateMultltStatus = false
        })
        this.state.bureauScoreIntRateMultltStatusdisable = true
      }
      this.setState({
        data
      })
    }
    else if ('deliquencyIntRateMultStatus' == e.target.name) {
      this.state.data[0].deliquencyIntRateMultStatus = !this.state.data[0].deliquencyIntRateMultStatus
      if (this.state.data[0].deliquencyIntRateMultStatus) {
        this.state.deliquencyIntRateMultStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isDeliquencyIntRateMultStatus = "A"
          underwritingrules.deliquencyIntRateMultStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isDeliquencyIntRateMultStatus = "A"
          underwritingrules.deliquencyIntRateMultStatus = false
        })
        this.state.deliquencyIntRateMultStatusdisable = true
      }
      this.setState({
        data
      })
    }
    else if ('numYrsCurEmplyIntRateMultStatus' == e.target.name) {
      this.state.data[0].numYrsCurEmplyIntRateMultStatus = !this.state.data[0].numYrsCurEmplyIntRateMultStatus
      if (this.state.data[0].numYrsCurEmplyIntRateMultStatus) {
        this.state.numYrsCurEmplyIntRateMultStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isNumYrsCurEmplyIntRateMultStatus = "A"
          underwritingrules.numYrsCurEmplyIntRateMultStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isNumYrsCurEmplyIntRateMultStatus = "A"
          underwritingrules.numYrsCurEmplyIntRateMultStatus = false
        })
        this.state.numYrsCurEmplyIntRateMultStatusdisable = true
      }
      this.setState({
        data
      })
    }
    else if ('numYrsEmplyIntRateMultStatus' == e.target.name) {
      this.state.data[0].numYrsEmplyIntRateMultStatus = !this.state.data[0].numYrsEmplyIntRateMultStatus
      if (this.state.data[0].numYrsEmplyIntRateMultStatus) {
        this.state.numYrsEmplyIntRateMultStatusdisable = false
        this.state.data.map(underwritingrules => {
          underwritingrules.isNumYrsEmplyIntRateMultStatus = "A"
          underwritingrules.numYrsEmplyIntRateMultStatus = true
        })
      } else {
        this.state.data.map(underwritingrules => {
          underwritingrules.isNumYrsEmplyIntRateMultStatus = "A"
          underwritingrules.numYrsEmplyIntRateMultStatus = false
        })
        this.state.numYrsEmplyIntRateMultStatusdisable = true
      }
      this.setState({
        data
      })
    }
  }

  render() {

    if (localStorage.getItem("isLoggedIn") && "MAKER" == localStorage.getItem("role")) {
      if (this.state.data.length > 0) {
        <p></p>
      } else {
        <p>No Data Found</p>
      }
      return (

        <div>
          { /* top-Header */}
          <MakerHeader />

          <div id="wrapper">
            { /* header menu */}
            <Sidebar />
            {(() => {
              if (this.state.editable) {
                return <div className="errorMsg"> <p className="toperror">There is Change Request yet to be approved by checker. A new request can be submitted once checker approves the request</p></div>
              }
            })()}
            <div id="maincontent" className="content">
              <section id="content-wrapper">
                <div className="backloan">
                  <div className="container-fluid undercontainer">
                    <p className="signinheader">UNDER-WRITING RULES</p>



                    <div className="row">
                      <div className="col-md-12">

                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 underone">

                            <UndWrittingPart1 isDebtBurdenAllowPercentlist={this.state.isDebtBurdenAllowPercentlist} isInstantloanDisCaplist={this.state.isInstantloanDisCaplist} isLastSalarylist={this.state.isLastSalarylist} isMaxAgelist={this.state.isMaxAgelist} isMinLoanAmtlist={this.state.isMinLoanAmtlist} isMinAgelist={this.state.isMinAgelist} isMaxLoanAmtCaplist={this.state.isMaxLoanAmtCaplist}
                              editable={this.state.editable} handleChangelastSalary={(e, i) => this.handleChangelastSalary(e, i)} handleChangeinstantloanDisCap={(e, i) => this.handleChangeinstantloanDisCap(e, i)} handleChangemaxLoanAmtCap={(e, i) => this.handleChangemaxLoanAmtCap(e, i)} handleChangeminLoanAmt={(e, i) => this.handleChangeminLoanAmt(e, i)} handleChangedebtBurdenAllowPercent={(e, i) => this.handleChangedebtBurdenAllowPercent(e, i)} handleChangeminAge={(e, i) => this.handleChangeminAge(e, i)} handleChangemaxAge={(e, i) => this.handleChangemaxAge(e, i)} handleChange={(e) => this.handleChange(e)} data={this.state.data} />

                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 undertwo">
                            <UndWrittingPart2 isCategoryElist={this.state.isCategoryElist} isCategoryDlist={this.state.isCategoryDlist} isCategoryClist={this.state.isCategoryClist} isCategoryBlist={this.state.isCategoryBlist} isCategoryAlist={this.state.isCategoryAlist}
                              catMultiStatusdisable={this.state.catMultiStatusdisable} editable={this.state.editable} handleChangecategoryA={(e, i) => this.handleChangecategoryA(e, i)} handleChangecategoryB={(e, i) => this.handleChangecategoryB(e, i)} handleChangecategoryC={(e, i) => this.handleChangecategoryC(e, i)} handleChangecategoryD={(e, i) => this.handleChangecategoryD(e, i)} handleChangecategoryE={(e, i) => this.handleChangecategoryE(e, i)} handleChange={(e) => this.handleChange(e)} data={this.state.data} toggleChange={(e) => this.toggleChange(e)} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 underthree">

                            <UndWrittingPart3 isAvgSalTenorBankStaffvaluelist={this.state.isAvgSalTenorBankStaffvaluelist} isAvgSalaryMultBET200000n500000list={this.state.isAvgSalaryMultBET200000n500000list} isAvgSalaryMultBET500001n1000000list={this.state.isAvgSalaryMultBET500001n1000000list} isAvgSalaryMultBET1000001n2000000list={this.state.isAvgSalaryMultBET1000001n2000000list} isAvgSalaryMultGT2000000list={this.state.isAvgSalaryMultGT2000000list} isAvgSalaryMultLT200000list={this.state.isAvgSalaryMultLT200000list}
                              avgSalTenorMultiplierStatusdisable={this.state.avgSalTenorMultiplierStatusdisable} editable={this.state.editable} handleChangeavgSalTenorBankStaffvalue={(e, i) => this.handleChangeavgSalTenorBankStaffvalue(e, i)} handleChangeavgSalaryMultGT2000000={(e, i) => this.handleChangeavgSalaryMultGT2000000(e, i)} handleChangeavgSalaryMultBET1000001n2000000={(e, i) => this.handleChangeavgSalaryMultBET1000001n2000000(e, i)} handleChangeavgSalaryMultBET500001n1000000={(e, i) => this.handleChangeavgSalaryMultBET500001n1000000(e, i)} handleChangeavgSalaryMultLT200000={(e, i) => this.handleChangeavgSalaryMultLT200000(e, i)} handleChangeavgSalaryMultBET200000n500000={(e, i) => this.handleChangeavgSalaryMultBET200000n500000(e, i)} handleChange={(e) => this.handleChange(e)} data={this.state.data} toggleChange={(e) => this.toggleChange(e)} />
                          </div>

                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 underfour">
                            <UndWrittingPart4 isAvgSalIntRateMultBET500001n1000000list={this.state.isAvgSalIntRateMultBET500001n1000000list} isAvgSalIntRateMultBET1000001n2000000list={this.state.isAvgSalIntRateMultBET1000001n2000000list} isAvgSalIntRateMultBankstafflist={this.state.isAvgSalIntRateMultBankstafflist} isAvgSalIntRateMultGT2000000list={this.state.isAvgSalIntRateMultGT2000000list} isAvgSalIntRateMultLT200000list={this.state.isAvgSalIntRateMultLT200000list} isAvgSalIntRateMultBET200000n500000list={this.state.isAvgSalIntRateMultBET200000n500000list}
                              avgSalIntRateMultStatusdisable={this.state.avgSalIntRateMultStatusdisable} editable={this.state.editable} handleChangeavgSalTenorBankStaffvalue={(e, i) => this.handleChangeavgSalTenorBankStaffvalue(e, i)} handleChangeavgSalIntRateMultGT2000000={(e, i) => this.handleChangeavgSalIntRateMultGT2000000(e, i)} handleChangeavgSalIntRateMultBET1000001n2000000={(e, i) => this.handleChangeavgSalIntRateMultBET1000001n2000000(e, i)} handleChangeavgSalIntRateMultBET500001n1000000={(e, i) => this.handleChangeavgSalIntRateMultBET500001n1000000(e, i)} handleChangeavgSalIntRateMultBET200000n500000={(e, i) => this.handleChangeavgSalIntRateMultBET200000n500000(e, i)} handleChangeavgSalIntRateMultLT200000={(e, i) => this.handleChangeavgSalIntRateMultLT200000(e, i)} handleChange={(e) => this.handleChange(e)} data={this.state.data} toggleChange={(e) => this.toggleChange(e)} />


                          </div>

                        </div>

                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <UndWrittingPart5 isDeliquencyIntRateMult30DPDlist={this.state.isDeliquencyIntRateMult30DPDlist} isDeliquencyIntRateMultGT90DPDlist={this.state.isDeliquencyIntRateMultGT90DPDlist} isDeliquencyIntRateMultCurrentlist={this.state.isDeliquencyIntRateMultCurrentlist} isDeliquencyIntRateMult60DPDlist={this.state.isDeliquencyIntRateMult60DPDlist} isDeliquencyIntRateMultXDayslist={this.state.isDeliquencyIntRateMultXDayslist} isNumYrsEmplyIntMultRateMultBET1n3yrslist={this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist}
                          isNumYrsEmplyIntMultRateMultBET5n10yrslist={this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist} isNumYrsEmplyIntMultRateMultBET3n5yrslist={this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist} isNumYrsEmplyIntMultRateMultLess1yrlist={this.state.isNumYrsEmplyIntMultRateMultLess1yrlist} isNumYrsEmplyIntRateMultGT10yrslist={this.state.isNumYrsEmplyIntRateMultGT10yrslist}
                          numYrsEmplyIntRateMultStatusdisable={this.state.numYrsEmplyIntRateMultStatusdisable} deliquencyIntRateMultStatusdisable={this.state.deliquencyIntRateMultStatusdisable} editable={this.state.editable} handleChangenumYrsEmplyIntMultRateMultBET5n10yrs={(e, i) => this.handleChangenumYrsEmplyIntMultRateMultBET5n10yrs(e, i)} handleChangenumYrsEmplyIntMultRateMultBET3n5yrs={(e, i) => this.handleChangenumYrsEmplyIntMultRateMultBET3n5yrs(e, i)} handleChangenumYrsEmplyIntMultRateMultBET1n3yrs={(e, i) => this.handleChangenumYrsEmplyIntMultRateMultBET1n3yrs(e, i)} handleChangenumYrsEmplyIntMultRateMultLess1yr={(e, i) => this.handleChangenumYrsEmplyIntMultRateMultLess1yr(e, i)} handleChange={(e) => this.handleChange(e)} data={this.state.data} toggleChange={(e) => this.toggleChange(e)}
                          handleChangenumYrsEmplyIntRateMultGT10yrs={(e, i) => this.handleChangenumYrsEmplyIntRateMultGT10yrs(e, i)} handleChangedeliquencyIntRateMultGT90DPD={(e, i) => this.handleChangedeliquencyIntRateMultGT90DPD(e, i)} handleChangedeliquencyIntRateMult60DPD={(e, i) => this.handleChangedeliquencyIntRateMult60DPD(e, i)} handleChangedeliquencyIntRateMult30DPD={(e, i) => this.handleChangedeliquencyIntRateMult30DPD(e, i)} handleChangedeliquencyIntRateMultXDays={(e, i) => this.handleChangedeliquencyIntRateMultXDays(e, i)} handleChangedeliquencyIntRateMultCurrent={(e, i) => this.handleChangedeliquencyIntRateMultCurrent(e, i)} />
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <UndWrittingPart6 isBureauScoreIntRateMultBET600N700list={this.state.isBureauScoreIntRateMultBET600N700list} isBureauScoreIntRateMultBET700n800list={this.state.isBureauScoreIntRateMultBET700n800list} isBureauScoreIntRateMultBET800n900list={this.state.isBureauScoreIntRateMultBET800n900list} isBureauScoreIntRateMultGT900list={this.state.isBureauScoreIntRateMultGT900list} isBureauScoreIntRateMultlt600list={this.state.isBureauScoreIntRateMultlt600list}
                          isNumYrsCurCurEmplyIntRateMultBET1n3yrslist={this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist} isNumYrsCurEmplyIntRateMultBET3n5yrslist={this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist} isNumYrsCurEmplyIntRateMultBET5n10yrslist={this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist} isNumYrsCurEmplyIntRateMultGT10yrslist={this.state.isNumYrsCurEmplyIntRateMultGT10yrslist} isNumYrsCurEmplyIntRateMultLess1yrlist={this.state.isNumYrsCurEmplyIntRateMultLess1yrlist}
                          bureauScoreIntRateMultltStatusdisable={this.state.bureauScoreIntRateMultltStatusdisable} numYrsCurEmplyIntRateMultStatusdisable={this.state.numYrsCurEmplyIntRateMultStatusdisable} buttoncolor={this.state.buttoncolor} editable={this.state.editable} fieldsvalid={this.state.fieldsvalid} handleChangenumYrsCurEmplyIntRateMultGT10yrs={(e, i) => this.handleChangenumYrsCurEmplyIntRateMultGT10yrs(e, i)} handleChangenumYrsCurEmplyIntRateMultBET3n5yrs={(e, i) => this.handleChangenumYrsCurEmplyIntRateMultBET3n5yrs(e, i)} handleChangenumYrsCurCurEmplyIntRateMultBET1n3yrs={(e, i) => this.handleChangenumYrsCurCurEmplyIntRateMultBET1n3yrs(e, i)} handleChangenumYrsCurEmplyIntRateMultLess1yr={(e, i) => this.handleChangenumYrsCurEmplyIntRateMultLess1yr(e, i)} toggleChange={(e) => this.toggleChange(e)} data={this.state.data} handlestatus={(status) => this.setStatus(status)}
                          handleChangebureauScoreIntRateMultlt600={(e, i) => this.handleChangebureauScoreIntRateMultlt600(e, i)} handleChangebureauScoreIntRateMultBET600N700={(e, i) => this.handleChangebureauScoreIntRateMultBET600N700(e, i)} handleChangebureauScoreIntRateMultBET700n800={(e, i) => this.handleChangebureauScoreIntRateMultBET700n800(e, i)} handleChangebureauScoreIntRateMultBET800n900={(e, i) => this.handleChangebureauScoreIntRateMultBET800n900(e, i)} handleChangebureauScoreIntRateMultGT900={(e, i) => this.handleChangebureauScoreIntRateMultGT900(e, i)} />
                      </div>
                    </div>

                    {/* user can recognise the fields/columns/rows functionality based on the label information */}
                    <div className="productglbutton">
                      <input type="submit" className=" editable-button" value="Editable" disabled />
                      <input type="submit" className=" noteditable-button" value="Not Editable" disabled />
                      <input type="submit" className=" suggested-button" value="Suggested but Editable" disabled />

                    </div>

                  </div>
                </div>
              </section>
            </div>
          </div>

        </div>
      )
    }
    else {
      return (<p>Please Login</p>)

    }
  }

}

export default UnderWrittingRules;
