import React, { useEffect, useState } from "react";
import ProcessSteps from "../../../../utilities/ProcessSteps";

import Payments from "../../../../assets/images/uday/supplychain-icons/payments.svg";
import Collections from "../../../../assets/images/uday/supplychain-icons/collections.svg";
import SalesPromotions from "../../../../assets/images/uday/supplychain-icons/salesPromotions.svg";
import orderFullfilment from "../../../../assets/images/uday/supplychain-icons/orderFulfilment.svg";
import Analytics from "../../../../assets/images/uday/supplychain-icons/analytics.svg";
import supplychainimg from "../../../../assets/images/supplychainimg.svg";

const steps = [
  {
    title: "Montra supply chain solution",
    image: supplychainimg,
    className: "supplychain-lastimg",
  },
  { image: Payments, description: "Payments" },
  { image: Collections, description: "Collections" },
  { image: SalesPromotions, description: "Sales Promotions" },
  { image: orderFullfilment, description: "Order Fulfillment" },
  { image: Analytics, description: "Distribution Analytics" },
];

const SupplyProcessContainer = ({ hometopDescription }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 1025;

  const title = steps[0].title;
  const mobileImage = steps[0].image;
  const className = steps[0].className;
  const processSteps = steps.slice(1);

  let arrowColor = "#20336B";
  let circleBorderColor = "#20336B";
  let textColor = "#20336B";

  return (
    <div>
      {/* {!isMobile ? (
        <div className="process-flow">
          <p className="top-description supply-top-description">{title}</p>
          <div className="top-step">
            <div className="util-down-arrow supply-util-down-arrow">
              <svg width="50" height="50">
                <line
                  x1="25"
                  y1="0"
                  x2="25"
                  y2="50"
                  style={{ stroke: arrowColor, strokeWidth: 1 }}
                />
                <polygon
                  points="20,40 25,50 30,40"
                  style={{ fill: arrowColor }}
                />
              </svg>
            </div>
          </div>
          <div className="util-process-steps">
            {processSteps.map((step, index) => (
              <div className="step-container" key={index}>
                <div
                  className="util-step"
                  style={
                    step.description === "Collections"
                      ? { marginLeft: "30px", marginRight: "25px" }
                      : step.description === "Sales Promotions"
                      ? { margin: "0 10px" }
                      : step.description === "Distribution Analytics"
                      ? { marginLeft: "15px" }
                      : {}
                  }
                >
                  <div
                    className="circleimg"
                    style={{ border: `2px solid ${circleBorderColor} ` }}
                  >
                    <img src={step.image} alt={step.description} />
                  </div>
                  <p
                    className="down-description supply-down-description"
                    style={{ color: textColor }}
                  >
                    {step.description}
                  </p>
                </div>
                {index > 0 && (
                  <div className="arrow supply-arrow">
                    <svg width="50" height="50">
                      <line
                        x1="0"
                        y1="25"
                        x2="50"
                        y2="25"
                        style={{ stroke: arrowColor, strokeWidth: 1 }}
                      />
                      <polygon
                        points="40,20 50,25 40,30"
                        style={{ fill: arrowColor }}
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <img
          src={mobileImage}
          alt="hero2"
          className={className ? className : ""}
        />
      )} */}

      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="supply-topdescription"
        downDescriptionClass="supply-bottomdescription"
      />
    </div>
  );
};

export default SupplyProcessContainer;
