import React from "react";
import businesshero from "../../../assets/images/uday/businesshero.webp";
// import businesshero2 from "../../../assets/images/businesshero2.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import "./style.css";
import BusinessOverviewProcessContainer from "./OverviewProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="personal-hero bbussineshero ">
        <div className="row">
          <div className="col-md-6 personal-hero-left businessleft responsivebusiness-col">
            <h1 className="business-hero-h1">
              <span className="business-hero-h1-span">
                Accelerate business growth with
              </span>{" "}
              Payment acceptance & business management solutions
            </h1>
            <p>We will help you achieve your business goals.</p>
            {/* <img
              src={businesshero2}
              alt="businesshero2"
              className="businesshero2-image"
            /> */}
            <BusinessOverviewProcessContainer />
            <div className="app-download business-hero-app-download-image">
              <img src={appstore} alt="image" />
              <img src={playstore} alt="image" />
            </div>
          </div>
          <div className="col-md-6 personal-hero-right busineslheroimg responsivebusiness-col">
            <img className="PersonalBannerImg" src={businesshero} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
