import React from "react";
import cap1 from "../../../../assets/images/cap1.svg";
import cap2 from "../../../../assets/images/cap2.svg";
import cap3 from "../../../../assets/images/cap3.svg";
import cap4 from "../../../../assets/images/cap4.svg";
import cap5 from "../../../../assets/images/cap5.svg";
// import cap6 from "../../../../assets/images/cap6.png";
import cap6 from "../../../../assets/images/uday/New-Images/image/supply-chain.svg";
import cap7 from "../../../../assets/images/uday/supplychain/buy-inventory.webp";
import cap8 from "../../../../assets/images/uday/supplychain/cap8.webp";
import cap9 from "../../../../assets/images/uday/supplychain/cap9.svg";
import cap10 from "../../../../assets/images/uday/New-Images/image/placeorder.svg";

const Body = () => {
  return (
    <>
      <div className="supply-body">
        <h1>
          Unlock powerful supply chain payment capabilities on a fully digital
          platform for your Distribution Business with
          <br />
          <span>Montra Supply chain SDK </span>
        </h1>

        <div className="row supplychain-row">
          <div className="col">
            <div className="capabilities supplychain-capabilities">
              <div className="supplychainicon">
                <img src={cap1} alt="cap1" />
              </div>
              <div class="lineparagrapproducts">
                <h6>B2B Payments</h6>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Seamless delivery of Invoices to your Distributor & the
                    Outlets under Distributor’s direct coverage.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Distributors & Outlets can simply pay using their Montra
                    Business Accounts or Linked Bank Accounts.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Dashboard to track payments against Invoices shared through
                    Montra Supply chain SDK.
                  </p>
                </div>
              </div>
            </div>

            <div className="capabilities supplychain-capabilities">
              <div className="supplychainicon">
                <img src={cap2} alt="cap2" />
              </div>
              <div class="lineparagrapproducts">
                <h6>Collections against Credit based Sales</h6>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Offer credit period on the Invoice that you send to
                    Distributor or Outlets under Distributor’s direct coverage.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Distributors & Outlets can simply pay during the Credit
                    Period or on the last day using their Montra Business
                    Accounts or Linked Bank Accounts.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Montra provides Dashboard to both Distribution Company /
                    Distributor & Outlet owner to view all payments done against
                    a Credit based invoice.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Montra would start collecting payments after the last date
                    of the Credit Period for the overdue amount.
                  </p>
                </div>
              </div>
            </div>

            <div className="capabilities supplychain-capabilities">
              <div className="supplychainicon">
                <img src={cap3} alt="cap3" />
              </div>
              <div class="lineparagrapproducts">
                <h6>Sales Promotions</h6>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Distributors can create Sales Promotions for Primary,
                    Secondary & Tertiary sales using Scratch Coupon, Spin Lucky
                    Wheel, Raffle based execution.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Montra SDK enables the Distribution companies to launch and
                    manage such promotions digitally.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Distributors, Outlets, Consumers get the benefits instantly
                    in their Montra Accounts.
                  </p>
                </div>
              </div>
            </div>

            <div className="capabilities supplychain-capabilities">
              <div className="supplychainicon">
                <img src={cap4} alt="cap4" />
              </div>
              <div class="lineparagrapproducts">
                <h6>Order Fulfillment</h6>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Outlets under direct coverage can place orders to their
                    Distributors at SKU level.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Distributors can easily make invoices against the Orders
                    received from the Outlets.
                  </p>
                </div>
              </div>
            </div>

            <div className="capabilities supplychain-capabilities">
              <div className="supplychainicon">
                <img src={cap5} alt="cap5" />
              </div>
              <div class="lineparagrapproducts">
                <h6>Distribution analytics</h6>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Montra tracks the inventories at the SKU level for the
                    outlets under direct coverage.
                  </p>
                </div>
              </div>
              <div className="sup-point ">
                <div className="square-point"></div>
                <div>
                  <p>
                    Montra also tracks inventories at SKU level for the outlets
                    under indirect coverage.{" "}
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    Our Distribution Analytics dashboard provides insights on
                    the length, breadth & depth of distribution besides giving
                    insights on the SKU level stock that these outlets have.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="supplychain-body-row row ">
              <div className="col phone1  supplychain-phone-col">
                <img src={cap6} alt="cap6" className="cap6-image" />
                <img src={cap7} alt="cap7" className="cap6-image" />
                <img src={cap8} alt="cap8" className="cap6-image" />
              </div>
              <div className="col phone2  supplychain-phone-col">
                <img src={cap9} alt="cap9" className="cap6-image" />
                <img src={cap10} alt="cap10" className="cap6-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
