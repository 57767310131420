import React, { useEffect, useState } from "react";
import Animation from "../Animation/Main";
import salesImage1 from "../../../../assets/images/uday/supplychain/buy-inventory.webp";
import salesGif from "../../../../assets/images/uday/gifs/sales.gif";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;

  return (
    <>
      <div className="sup-payment-hero sales-hero">
        <div className="row supplychain-row">
          <div className="col">
            <h1>
              Launch Sales Promotions for Primary, Secondary or Tertiary sales{" "}
              <span>to increase your business :</span>
            </h1>

            <div className="capabilities">
              <div className="sup-point subparagraph">
                <div className="square-point"></div>
                <div>
                  <p>
                    <span>Scratch Coupons & Lucky Wheel</span> provide instant
                    discounts and cashbacks for Distributors, Outlets, and
                    Consumers under both Direct and Indirect coverage.
                  </p>
                </div>
              </div>
              <div className="sup-point subparagraph">
                <div className="square-point"></div>
                <div>
                  <p>
                    <span>Spin Lucky Wheel</span> offering chance based instant
                    discounts and cashbacks to your Distributors, Outlets under
                    Direct & Indirect coverage and even to Consumers.
                  </p>
                </div>
              </div>

              <div className="sup-point subparagraph">
                <div className="square-point"></div>
                <div>
                  <p>
                    <span> Raffle Schemes </span> to increase primary, secondary
                    or tertiary sales.
                  </p>
                </div>
              </div>
            </div>
            <button>Contact Sales</button>
          </div>
          {!isMobile ? (
            <>
              <div className="col right-payment-col">
                <div className="animation-class sales-animation-class">
                  <Animation index={4} />
                </div>

                <div className="center-image-sales">
                  <div className="center-image-bottom">
                    <img src={salesImage1} alt="" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="gif-layout supplychain-gif-layout">
              <img
                src={salesGif}
                alt="business-section gif"
                className="business-section1-gif"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
