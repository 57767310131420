import React from "react";
import duo2 from "../../../assets/images/duo2.webp";
import duo3 from "../../../assets/images/duo3.svg";
import duo4 from "../../../assets/images/duologo.svg";
import DuoProcessContainer from "../../Solutions/Duo/DuoProcessContainer";
import "./styles.css";

const Main = () => {
  return (
    <>
      <div className="home-duo duo">
        <div className="row">
          <div className="col duo-col">
            <div className="sol-logo">
              <img src={duo4} alt="" className="sol-duo-logo " />
            </div>
            <h3 className="home-duo-title">
              Enjoy DUO card experience with both
              <span> Credit & Debit offering in a single card</span>
            </h3>
            <p>
              DUO offers end to end solutions for Issuers to launch, manage &
              scale Credit Card issuance, activations, usage & collections
              towards enabling them create a profitable portfolio.
            </p>
            <a href="/Solutions/Duo/overview">
              <button className="exploreduobutton">
                Explore DUO <i class="bi bi-chevron-right"></i>
              </button>
            </a>
          </div>
          <div className="col">
            <img className="duo-card" src={duo2} alt="duo-card" />
          </div>
        </div>
        <div className="home-duo-process-flow">
          <DuoProcessContainer />
        </div>
      </div>
    </>
  );
};

export default Main;
