import React, { useEffect, useState } from "react";
import Animation from "../Animation/Main";
import responsiveImage from "../../../../assets/images/uday/supply-chain-green-responsive-image.png";
import distributionImage1 from "../../../../assets/images/uday/supplychain/cap8.webp";
import "./style.css";
import distributionGif from "../../../../assets/images/uday/gifs/distribution.gif";
import duoapp2 from "../../../../assets/images/sup1.svg";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;
  return (
    <>
      <div className="sup-payment-hero distribution-hero">
        <div className="row supplychain-row">
          <div className="col distribution">
            <div className="sup-sm-btn distanalytics">
              <img
                src={duoapp2}
                alt="duo small mobile image"
                className="duo-small-image"
              />
              <p>Distribution Analytics</p>
            </div>
            <h1>
              Access SKU-level stock insights for both outlets under direct and
              indirect distribution:
            </h1>
            {/* <p>
              Unlock powerful supply chain payment capabilities on a fully
              digital platform that enables
            </p>
            <ul>
              <li>Collection of B2B payments digitally</li>
              <li>Collection of Credit based sales</li>
              <li>Tracking of Due & Collected payments</li>
              <li>
                Launching & managing digital sales promotions for Primary,
                Secondary as well as Tertiary sales
              </li>
              <li>
                Distribution analytics thereby helping in assessing SKU level
                stock availability in the outlets under direct distribution as
                well those where Montra App is being used for selling the SKUs
                to outlets under indirect distribution
              </li>
            </ul> */}
            <div className="capabilities">
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    <span>SKU level reach mapped for the region</span> the user
                    is responsible for both outlets under Direct & Indirect
                    Distribution.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    <span>
                      Facilitate decisions for running Sales Promotions
                    </span>{" "}
                    & assessment of sales promotions run on Montra platform.
                  </p>
                </div>
              </div>
              <div className="sup-point">
                <div className="square-point"></div>
                <div>
                  <p>
                    <span>Effective stock production</span> forecast based on
                    the SKU level stock that exists in the market.
                  </p>
                </div>
              </div>
            </div>
            <button>Contact Sales</button>
          </div>
          {!isMobile ? (
            <>
              <div className="col right-payment-col">
                <div className="left-image-payment distribution-left-payment">
                  <div className="left-image-top">
                    <img src={distributionImage1} alt="" />
                  </div>
                </div>

                <div className="animation-class">
                  <Animation index={3} />
                </div>
              </div>
            </>
          ) : (
            <div className="gif-layout supplychain-gif-layout">
              <img
                src={distributionGif}
                alt="business-section gif"
                className="business-section1-gif"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
