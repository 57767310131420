import React, { useEffect, useState } from "react";
import Animation from "../Animation/Main";
import orderImage1 from "../../../../assets/images/uday/supplychain/buy-inventory.webp";
import "./style.css";
import orderGif from "../../../../assets/images/uday/gifs/order.gif";
import duoapp2 from "../../../../assets/images/sup.svg";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;
  return (
    <>
      <div className="sup-payment-hero order-hero">
        <div className="row supplychain-row">
          <div className="col">
            <div className="sup-sm-btn">
              <img
                src={duoapp2}
                alt="duo small mobile image"
                className="duo-small-image sup-small-image"
              />
              <p>Order Fulfilment</p>
            </div>
            <h1 className="title" style={{ marginBottom: "20px" }}>
              Your outlets can now place direct orders with your distributor
              through the Montra App{" "}
              {/* <b>enabling seamless invoicing</b> via the Montra Supply Chain SDK */}
            </h1>
            <button>Contact Sales</button>
          </div>
          {!isMobile ? (
            <>
              <div className="col right-payment-col">
                <div className="animation-class order-animation-class">
                  <Animation index={2} />
                </div>

                <div className="center-image-order">
                  <div className="center-image-bottom">
                    <img src={orderImage1} alt="orderImage1" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="gif-layout supplychain-gif-layout">
              <img
                src={orderGif}
                alt="business-section gif"
                className="business-section1-gif"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
