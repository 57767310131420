/* React packages and components */
import React, { Component } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Header2 from "./Header2";

import FooterAdmin from "../Footer/FooterAdmin";
import "./operationStyle.css";
import bsCustomFileInput from "bs-custom-file-input";
import CommonHeader from "../header/CommonHeader";

/**
   * OperationReport component will render entire Operation Report section of bank for Operation section.
   * <p>There are one Data will be there and also user can download the reports:</p>
   * <p>HOLD ACCOUNT 2</p>

   * <br>
   * <h6>Below Html elemensts are created to use for  UI</h6>
   * <li> <b> Section :- </b>it is used for represents a standalone section</li>
   * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
   * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
   * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
   <br>
   * <h6>Below Bootstrap class is use for ui responsive.</h6>
   * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
   * <li> <b>row :-</b> This class to create a structure.</li>
   * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>


   * @Author : Seema Kumari
   * @version :1.0

 */

class OperationReport extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      data: {
        holdAccountTwo: {
          totalAmountMovedToHoldAcct2FromHoldAcct1: "",
          openingBalance: "",
          closingBalance: "",
          companyId: "",
        },
      },
    };
  }

  /**
   *
   * @param {Event} e captures the value for submit the request to server and download the fileholdaccount2 in xlsx format.
   */
  downloadfile = (e) => {
    e.preventDefault();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/collection/operationreport?email=" +
        localStorage.getItem("email")
    );
    fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          alert("server error");
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "paylink-loanportfolio.xlsx";
        a.click();
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  };
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    bsCustomFileInput.init();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/collection/operationreport?email="
    );
    fetch(url + localStorage.getItem("email"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert("server error");
        }
      })
      .then((contents) => {
        this.setState({ data: contents.data });
      });
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "OPERATIONS" !== localStorage.getItem("role")
    ) {
      return <p>Please Login AS OPERATION </p>;
    } else {
      return (
        <div>
          <CommonHeader />
          <div id="wrapper">
            <Sidebar />
            <div id="maincontent" className="content operation-content">
              <section id="content-wrapper">
                <div className="backloan">
                  <div className="container-fluid undercontainer">
                    <div className="listreport">
                      <p className="reportcols">
                        Collection report <br />
                        <br />
                      </p>
                      <ul className="reportlist">
                        <li>Month Till Date</li>
                        <li>Year Till Date</li>
                        <li>Choose dates</li>
                      </ul>
                    </div>

                    <form className="collectionheadertext">
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-xl-4 portfoliocols"></div>

                        <div className="col-sm-12 col-md-12 col-xl-4 portfoliocols">
                          <div className="collectionsec2">
                            <p className="collactiontext1">HOLD ACCOUNT 1 </p>
                            <p className="collactiontext2">
                              Collections made in Hold Account 1 and moved to
                              Hold Account 2 against the loans managed by Bank /
                              Finance Company on their LMS{" "}
                            </p>

                            <div className="row holdsec">
                              <div className="col-md-6 "></div>

                              <div className="col-md-6 collectioncols">
                                <div className="form-group">
                                  <label className="lablename">
                                    Total amount moved to Hold Account 2 from
                                    Hold Account 1
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="lendingcompanyname"
                                    placeholder={
                                      this.state.data.holdAccountTwo
                                        .totalAmountMovedToHoldAcct2FromHoldAcct1
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row holdsec2">
                              <div className="col-md-6 collectioncols">
                                <div className="form-group">
                                  <label className="lablename">
                                    Opening Balance
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="lendingcompanyname"
                                    placeholder={
                                      this.state.data.holdAccountTwo
                                        .openingBalance
                                    }
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 collectioncols">
                                <div className="form-group">
                                  <label className="lablename">
                                    Closing Balance
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="lendingcompanyname"
                                    placeholder={
                                      this.state.data.holdAccountTwo
                                        .closingBalance
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="downloadreport">
                              <button
                                className="downloadtext collectiondown"
                                onClick={this.downloadfile}
                              >
                                {" "}
                                <span>
                                  <i className="fas fa-arrow-down"></i>
                                </span>
                                Download Report
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-xl-4 portfoliocols"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* <FooterAdmin /> */}
        </div>
      );
    }
  }
}

export default OperationReport;
