import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import verifiedlogo from "../../assets/images/verifiedlogo.svg";
import "./verified.css";

const Verified = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>
      {screenWidth > 920 ? (
        <div className="second-navbar navbar verified-navbar">
          <div className="solutions-logo">
            <img src={verifiedlogo} alt="" />
          </div>

          <div className="nav-link ver-nav-link">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  to="/Solution/Verified/Authapp"
                  className="nav-link verified"
                  activeClassName="sol-active"
                >
                  Authenticator App
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Solution/Verified/Faceauth"
                  className="nav-link verified"
                  activeClassName="sol-active"
                >
                  Face Authentication
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="horizontal-navbar">
          <div className="second-navbar navbar verified-navbar">
            <div className="flexdiv-parent">
              <div className=" flexdiv-menu">
                <div className="flexdiv-menu-items">
                  <div className="solutions-logo">
                    <img src={verifiedlogo} alt="verifiedlogo" />
                  </div>
                </div>
              </div>
              <div className="horizontal-flow">
                <div className="horizontal-flow-track">
                  <div className="nav-link horizontal-flow-items">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <NavLink
                          to="/Solution/Verified/Authapp"
                          className="nav-link verified"
                          activeClassName="sol-active"
                        >
                          Authenticator App
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solution/Verified/Faceauth"
                          className="nav-link verified"
                          activeClassName="sol-active"
                        >
                          Face Authentication
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Verified;
