/* React packages and components */
import React from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Header2 from "./Header2";
import FooterAdmin from "../Footer/FooterAdmin";
import "./CollectionStyle.css";
import CommonHeader from "../header/CommonHeader";

/**
  * This Nibss Collection component is render home page for Nibss Collection section,There are no methods in this class.
  * <h6>Below Html elemensts are created to use for  UI</h6>
  * <li> <b> Section :- </b>it is used for represents a standalone section</li>
  * <li> <b>img :- </b> This tags must have a defined src attribute. This defines the image to be displayed. </li>

  <br>
  * <h6>Below Bootstrap class is use for ui responsive.</h6>
  * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
  * <li> <b>row :-</b> This class to create a structure.</li>
  @Author : Seema Kumari
  @version :1.0
 */

const NibssCollection = () => {
  if (
    localStorage.getItem("isLoggedIn") &&
    "NIBSS-COLLECTION" !== localStorage.getItem("role")
  ) {
    return <p>Please Login AS NIBSS-COLLECTION</p>;
  } else {
    return (
      <div>
        {/* top-Header */}
        {/* <Header2 /> */}
        <CommonHeader />
        <div id="wrapper">
          {/* header menu */}
          <Sidebar />
          <div id="maincontent" className="content">
            <section id="content-wrapper">
              <div className="backloan">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="profile-content-div">
                        <img
                          className="profile-i"
                          src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Fprofile-logo.png?alt=media&token=492f980b-86c1-4363-94b8-8664b2cdea1a"
                          alt="image"
                        />

                        <p className="profile-content">
                          Name :{" "}
                          <span style={{ color: "#5bb7e7" }}>
                            {" "}
                            {localStorage.getItem("name")}{" "}
                          </span>{" "}
                          <br /> User Type :{" "}
                          <span style={{ color: "#5bb7e7" }}>
                            {localStorage.getItem("role")}
                          </span>
                        </p>

                        <p className="profile-content1">
                          Please click the left menu to upload / access
                          information based on the user rights. If the menu is
                          in grey instead of black font, it means that you do
                          not have rights to access /operate the functionality.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* footer */}
        {/* <FooterAdmin /> */}
      </div>
    );
  }
};

export default NibssCollection;
