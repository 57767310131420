import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import moneylinklogo from "../../assets/images/moneylinklogo.svg";
import icon1 from "../../assets/images/responsive1.svg";
import "./platform.css";

const Solution = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const [isMoreActive, setMoreActive] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [disableLink, setDisableLink] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const timeoutIdRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > 920) {
        setSubMenuVisible(false);
        setMoreActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
    setMoreActive(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
    setMoreActive(true);
    setSubMenuVisible(false);
  };

  const handleMouseLeave = () => {
    timeoutIdRef.current = setTimeout(() => {
      setSubMenuVisible(false);
      // setMoreActive(false);
    }, 1000);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutIdRef.current);
    setSubMenuVisible(true);
  };

  const handleSubLinkActivation = (link) => {
    setMoreActive(true);
    setActiveLink(link);
    setDisableLink(true);
  };

  const handleLinkClick = () => {
    setMoreActive(false);
  };

  useEffect(() => {
    const subLinks = [
      "/Platform/Moneylink/Loanmanagement",
      "/Platform/Moneylink/Debtcollections",
    ];
    if (subLinks.includes(location.pathname)) {
      setMoreActive(true);
      setDisableLink(true);
    } else {
      setMoreActive(false);
      setDisableLink(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>
      {screenWidth > 920 ? (
        <div className="second-navbar navbar platform-navbar">
          <div className="solutions-logo">
            <img src={moneylinklogo} alt="" />
          </div>

          <div className="nav-link plat-nav-link">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  to="/Platform/Moneylink/cip"
                  className="nav-link platform"
                  activeClassName="plat-active"
                >
                  Card Issuing & Processing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Platform/Moneylink/merchant"
                  className="nav-link platform"
                  activeClassName="plat-active"
                >
                  Merchant Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Platform/Moneylink/Accountmanagement"
                  className="nav-link platform"
                  activeClassName="plat-active"
                >
                  Account Management System
                </NavLink>
              </li>
              <li className="nav-item">
                <button
                  onClick={(e) => {
                    if (disableLink) {
                      e.preventDefault();
                    } else {
                      handleButtonClick();
                    }
                  }}
                  aria-current="false"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  // style={{ cursor: disableLink ? "not-allowed" : "pointer" }}
                  className={`nav-link platform more ${
                    isMoreActive ? "active" : " "
                  } `}
                  activeClassName="active"
                  end
                >
                  More
                </button>
                {isSubMenuVisible && (
                  <div
                    className="submenu"
                    // style={{ position: "absolute" }}
                    onClick={handleSubMenuClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* Your submenu content goes here */}
                    <NavLink
                      to="/Platform/Moneylink/Loanmanagement"
                      className="nav-link platform"
                      activeClassName="plat-active"
                    >
                      Loan Management System
                    </NavLink>
                    <NavLink
                      to="/Platform/Moneylink/Debtcollections"
                      className="nav-link platform"
                      activeClassName="plat-active"
                    >
                      Debt Collection
                    </NavLink>
                  </div>
                )}
                {/* <NavLink to="/Products/Finance" className="nav-link" activeClassName="plat-active">
          More
          </NavLink> */}
              </li>

              <NavLink to="/MoneyLink/PartnerLogin" target="_blank">
                <li className="nav-item">
                  <button className="plat-button">
                    Partner Sign in <i className="bi bi-chevron-right"></i>
                  </button>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      ) : (
        <div className="horizontal-navbar">
          <div className="second-navbar navbar platform-navbar ">
            <div className="flexdiv-parent">
              <div className=" flexdiv-menu">
                <div className="flexdiv-menu-items">
                  <div className="solutions-logo">
                    <img src={moneylinklogo} alt="" />
                  </div>
                </div>
              </div>
              <div className="horizontal-flow">
                <div className="horizontal-flow-track">
                  <div className="nav-link  plat-nav-link horizontal-flow-items">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <NavLink
                          to="/Platform/Moneylink/cip"
                          className="nav-link platform"
                          activeClassName="plat-active"
                        >
                          Card Issuing & Processing
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Platform/Moneylink/merchant"
                          className="nav-link platform"
                          activeClassName="plat-active"
                        >
                          Merchant Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Platform/Moneylink/Accountmanagement"
                          className="nav-link platform"
                          activeClassName="plat-active"
                        >
                          Account Management System
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Platform/Moneylink/Loanmanagement"
                          className="nav-link platform"
                          activeClassName="plat-active"
                        >
                          Loan Management System
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to="/Platform/Moneylink/Debtcollections"
                          className="nav-link platform"
                          activeClassName="plat-active"
                        >
                          Debt Collection
                        </NavLink>
                      </li>

                      <NavLink
                        to="https://www.montra.org/PartnerLogin"
                        target="_blank"
                      >
                        <li className="nav-item">
                          <button className="plat-button">
                            Partner Sign in{" "}
                            <i className="bi bi-chevron-right"></i>
                          </button>
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Solution;
