import { useState } from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dts2 from "../../../../assets/images/dts2.svg";
import dtsyoutube from "../../../../assets/images/dtsyoutube.svg";

const Body = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleImageClick = () => {
    setIsVideoPlaying(true);
  };
  return (
    <>
      <div className="dts-body">
        <div className="row personalb">
          <div className="col-md-6 dts-frame">
            <div className="dts-frame-img">
              <img src={dts1} alt="" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Customers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Instant issuance & Activation of a Virtual Credit Card in
                    few seconds. Don’t have to wait for weeks for card delivery.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Don’t have to fill application forms or submit documents for
                    credit evaluation.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Don’t have to go to branch to pick-up the Credit Card or for
                    the card Activation.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Transact within the country using the Virtual DUO Credit
                    Card through your existing Debit Cards issued by the
                    bank/issuer.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Make even online transactions using the Virtual Credit Card
                    through Duo App.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Don’t have to carry multiple cards. Your Debit Card can be
                    now used as a Credit Card.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 dts-frame dts-frame-1">
            <div className="dts-frame-img">
              <img src={dts2} alt="" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Issuers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Offer Instant issuance & Activation of Virtual Credit Card
                    in few seconds. Eliminates requirements of Credit
                    under-writing & need of Sales Channels.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Saving of cost & time that it takes otherwise to issue a
                    physical credit card.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Simpler & instant digital activations for Virtual DUO Credit
                    Card.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Target large number of credit worthy Debit Card customers
                    through pre-approved digital & instant DTS activation
                    program.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Market DUO offering to pre-approved customers offering Debit
                    & Credit Card usage through their existing Debit Cards.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p className="dts-body-list-p">
                    Increase income by creating a profitable credit card
                    portfolio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row dts-body2">
        <div className="col ">
          {/* <img src={dtsyoutube} alt="" /> */}
          <div className="dts-content">
            {/* Image */}
            {!isVideoPlaying && (
              <img
                src={dtsyoutube} // Replace with the actual path to your image
                alt="Description of the image"
                className="video-image"
                onClick={handleImageClick}
              />
            )}

            {/* YouTube Video */}
            {isVideoPlaying && (
              <iframe
                title="YouTube Video"
                width="463px"
                height="299px"
                src="//www.youtube.com/embed/iUUEmaMutM0?rel=0&autoplay=1&mute=1" // Muted
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                style={{
                  position: "relative",
                  zIndex: 0,
                  borderRadius: "20px",
                }}
              />
            )}
          </div>
        </div>
        <div className="col">
          <div className="dts-content">
            <div className="dtsvideocontent">
              <h6>How to use DTS & DCP?</h6>
            </div>
            <p>
              Get to know how you can start using Dual Transaction Service
              (DTS).
            </p>
            <a
              href="https://www.youtube.com/embed/iUUEmaMutM0?rel=0&autoplay=1&mute=1"
              target="_blank"
            >
              <button>Watch Video</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
