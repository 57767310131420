import React from 'react';
import Sidebar from '../Sidebar';
import CollectionCheckerLoan from '../CollectionCheckerLoan';
import Header2 from '../CheckerHeader';
import { Tooltip } from "carbon-components-react";
import "carbon-components/css/carbon-components.min.css";
import "./CheckerProductmix.css";

/**
 *  CheckerProductMix component is render upper part of ProductMix  section of bank for checker section.
 * <p>Allows checker to approve or reject the changes from maker in Productmix for ecah product type</p>
 * <p> After changes checker allowed to enter the pin submit changes</p>
 *
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */


class CheckerProductMix extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         errorMsg: '',
         message: '',
         editable: false,
         status: '',
         backerrors: [],
         isDisabled: false,
         errors: [],
         fields: {},
         isFeeline1list: [],
         isActivelist: [],
         isFeeline1list: [],
         isFeeline1Freqlist: [],
         isFeeline2list: [],
         isFeeline2classlist: [],
         isFeeline2Freqlist: [],
         isInterestRateTypelist: [],
         isLossPoolContrPercentlist: [],
         isPenalChanrgesPercentlist: [],
         isLoanAmortizationTypelist: [],
         isSalriedSegmentlist: [],
         isSelfEmployedsegmentlist: [],
         isLoansToExternCustlist: [],
         isDisbursalInInternAcctlist: [],
         isDisbursalInExternAcctlist: [],
         isCollectionForInternLoanslist: [],
         isBaseInterestRatelist: [],
         isBaseTenorslist: [],
         isFeeline1classlist: [],
         isActiveclasslist: [],
         isFeeline1classlist: [],
         isFeeline1Freqclasslist: [],
         isFeeline2Freqclasslist: [],
         isInterestRateTypeclasslist: [],
         isLossPoolContrPercentclasslist: [],
         isPenalChanrgesPercentclasslist: [],
         isLoanAmortizationTypeclasslist: [],
         isSalriedSegmentclasslist: [],
         isSelfEmployedsegmentclasslist: [],
         isLoansToExternCustclasslist: [],
         isDisbursalInInternAcctclasslist: [],
         isDisbursalInExternAcctclasslist: [],
         isCollectionForInternLoansclasslist: [],
         isBaseInterestRateclasslist: [],
         isBaseTenorsclasslist: [],
         isActivefieldclasslist: [],
         isFeeline1fieldclasslist: [],
         isFeeline1Freqfieldclasslist: [],
         isFeeline2fieldclasslist: [],
         isFeeline2Freqfieldclasslist: [],
         isInterestRateTypefieldclasslist: [],
         isLossPoolContrPercentfieldclasslist: [],
         isPenalChanrgesPercentfieldclasslist: [],
         isLoanAmortizationTypefieldclasslist: [],
         isSalriedSegmentfieldclasslist: [],
         isSelfEmployedsegmentfieldclasslist: [],
         isLoansToExternCustfieldclasslist: [],
         isDisbursalInInternAcctfieldclasslist: [],
         isDisbursalInExternAcctfieldclasslist: [],
         isCollectionForInternLoansfieldclasslist: [],
         isBaseInterestRatefieldclasslist: [],
         isBaseTenorsfieldclasslist: [],


         data: [
            {
               prodName: '', code: '', activeProds: '', isActiveProds: '', feeline1: '', feeline1Freq: '', feeline2: '', feeline2Freq: '', interestRateType: '', lossPoolContrPercent: '', penalChanrgesPercent: '',
               loanAmortizationType: '', salriedSegment: '', selfEmployedsegment: '', loansToExternCust: '', disbursalInInternAcct: '', disbursalInExternAcct: '', collectionForInternLoans: '', baseInterestRate: '', baseTenors: '',
               isActive: '', isFeeline1: '', isFeeline1Freq: '', isFeeline2: '', isFeeline2Freq: '', isInterestRateType: '', isLossPoolContrPercent: '', isPenalChanrgesPercent: '', isLoanAmortizationType: '',
               isSalriedSegment: '', isSelfEmployedsegment: '', isLoansToExternCust: '', isDisbursalInInternAcct: '', isDisbursalInExternAcct: '', isCollectionForInternLoans: '', isBaseInterestRate: '', isBaseTenors: ''
            },
         ],
         tempdata: [],
         status: '',
         backerrors: '',
         message: ''
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }
   /**
      * This will validate the pin.
      * No Parameters for this method.
      */
   handleSubmitvalidateForm() {
      let errors = this.state.errors;
      let formIsValid = true;
      if (this.state.fields.pin == '' || this.state.fields.pin == null) {
         formIsValid = false;
         errors['pin'] = '*Please enter pin.';
      }
      this.setState({
         errors: errors
      });
      return formIsValid;
   }
   /**
  *Submit the changes to server.
  * @param {Event} e event occurs on clicking the submit button.
  */
   handleSubmit = async (e) => {
      e.preventDefault();

      // Validate form
      if (this.handleSubmitvalidateForm()) {
         // Clear previous errors
         this.setState({ errors: [] });

         // Update state based on various conditions
         this.setOnAndOffChangeisActive();
         this.setOnAndOffChangeisFeeline1();
         this.setOnAndOffChangeisFeeline1Freq();
         this.setOnAndOffChangeisFeeline2();
         this.setOnAndOffChangeisFeeline2Freq();
         this.setOnAndOffChangeisInterestRateType();
         this.setOnAndOffChangeisLossPoolContrPercent();
         this.setOnAndOffChangeisPenalChanrgesPercent();
         this.setOnAndOffChangeisLoanAmortizationType();
         this.setOnAndOffChangeisSalriedSegment();
         this.setOnAndOffChangeisSelfEmployedsegment();
         this.setOnAndOffChangeisLoansToExternCust();
         this.setOnAndOffChangeisDisbursalInInternAcct();
         this.setOnAndOffChangeisDisbursalInExternAcct();
         this.setOnAndOffChangeisCollectionForInternLoans();
         this.setOnAndOffChangeisBaseInterestRate();
         this.setOnAndOffChangeisBaseTenors();

         // Prepare request data
         const url = `https://api.montra.org/moneylinkweb/api/product/saveprodmix?email=${localStorage.getItem("email")}&pin=${this.state.fields.pin}`;

         const requestData = JSON.stringify(this.state.data);

         try {
            // Send the request
            const response = await fetch(url, {
               method: 'POST',
               body: requestData,
               headers: {
                  'Content-Type': 'application/json;charset=utf-8',
               },
            });

            // Check if the response is ok
            if (!response.ok) {
               throw new Error('Network response was not ok.');
            }

            // Parse the response
            const contents = await response.json();

            // Handle the response based on status
            if (contents.status === 200 && contents.message === 'SUCCESS') {
               this.setState({
                  isDisabled: true,
                  status: contents.status,
                  message: contents.message,
               });

               // Update state based on response
               this.onSubmitChangeisFeeline1();
               this.onSubmitChangeisFeeline2();
               this.onSubmitChangeisFeeline1Freq();
               this.onSubmitChangeisFeeline2Freq();
               this.onSubmitChangeisInterestRateType();
               this.onSubmitChangeisLoanAmortizationType();
               this.onSubmitChangeisLoansToExternCust();
               this.onSubmitChangeisLossPoolContrPercent();
               this.onSubmitChangeisPenalChanrgesPercent();
               this.onSubmitChangeisSalriedSegment();
               this.onSubmitChangeisSelfEmployedsegment();
               this.onSubmitChangeisBaseInterestRate();
               this.onSubmitChangeisBaseTenors();
               this.onSubmitChangeisCollectionForInternLoans();
               this.onSubmitChangeisDisbursalInExternAcct();
               this.onSubmitChangeisDisbursalInInternAcct();
            } else {
               this.setState({
                  backerrors: contents.errors,
                  status: contents.status,
                  message: contents.message,
               });

               // Handle errors
               if (contents.errors) {
                  contents.errors.forEach(error => {
                     this.setState({ errorMsg: error.message });
                  });
               }
            }
         } catch (error) {
            // Handle fetch or network errors
            console.error('There was a problem with the fetch operation:', error);
            alert('There was an error processing your request. Please try again later.');
         }
      }
   };
   /**
  * Captures the changes on event is raised.
  * @param {Event} e captures the value for pin on onChange event
  */
   handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
         fields
      });

   }
   setStatus = (statusres) => {
      this.setState({ status: statusres })

   }
   /**
    * Sets the boolean value for feeline2 toggle button,disable buttons and background color after submitting data to server.
    */
   onSubmitChangeisFeeline2() {
      let data = this.state.data;
      let isFeeline2list = this.state.isFeeline2list;
      let isFeeline2classlist = this.state.isFeeline2classlist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline2) {
            CheckerProductMix.isFeeline2 = true
            this.state.isFeeline2list[i] = true
            this.state.isFeeline2classlist[i] = "#bcbcbb"
            this.setState({
               data,
               isFeeline2list,
               isFeeline2classlist
            });
         } else {
            if ("A" == CheckerProductMix.isFeeline2) {
               CheckerProductMix.isFeeline2 = true
               this.state.isFeeline2list[i] = false
               this.state.isFeeline2classlist[i] = "#2ab934"
               this.setState({
                  data,
                  isFeeline2list,
                  isFeeline2classlist
               });
            }
            else {
               CheckerProductMix.isFeeline2 = false
               this.state.isFeeline2list[i] = false
               this.state.isFeeline2classlist[i] = "#6c757d"
               this.setState({
                  data,
                  isFeeline2list,
                  isFeeline2classlist
               });
            }
         }

      })
   }
   /**
      * Sets the boolean value for feeline1 toggle button,disable buttons and background color after submitting data to server.
      */
   onSubmitChangeisFeeline1() {
      let data = this.state.data;
      let isFeeline1list = this.state.isFeeline1list;
      let isFeeline1classlist = this.state.isFeeline1classlist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline1) {
            CheckerProductMix.isFeeline1 = true
            this.state.isFeeline1list[i] = true
            this.state.isFeeline1classlist[i] = "#bcbcbb"
            this.setState({
               data,
               isFeeline1list,
               isFeeline1classlist
            });
         } else {
            if ("A" == CheckerProductMix.isFeeline1) {
               CheckerProductMix.isFeeline1 = true
               this.state.isFeeline1list[i] = false
               this.state.isFeeline1classlist[i] = "#2ab934"
               this.setState({
                  data,
                  isFeeline1list,
                  isFeeline1classlist
               });
            }
            else {
               CheckerProductMix.isFeeline1 = false
               this.state.isFeeline1list[i] = false
               this.state.isFeeline1classlist[i] = "#6c757d"
               this.setState({
                  data,
                  isFeeline1list,
                  isFeeline1classlist
               });
            }
         }

      })
   }
   /**
      * Sets the boolean value for feeline1freq toggle button,disable buttons and background color after submitting data to server.
      */
   onSubmitChangeisFeeline1Freq() {
      let data = this.state.data;
      let isFeeline1Freqlist = this.state.isFeeline1Freqlist;
      let isFeeline1Freqclasslist = this.state.isFeeline1Freqclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline1Freq) {
            CheckerProductMix.isFeeline1Freq = true
            this.state.isFeeline1Freqlist[i] = true
            this.state.isFeeline1Freqclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isFeeline1Freqlist,
               isFeeline1Freqclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isFeeline1Freq) {
               CheckerProductMix.isFeeline1Freq = true
               this.state.isFeeline1Freqlist[i] = false
               this.state.isFeeline1Freqclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isFeeline1Freqlist,
                  isFeeline1Freqclasslist
               });
            }
            else {
               CheckerProductMix.isFeeline1Freq = false
               this.state.isFeeline1Freqlist[i] = false
               this.state.isFeeline1Freqclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isFeeline1Freqlist,
                  isFeeline1Freqclasslist
               });
            }
         }

      })
   } /**
   * Sets the boolean value for feeline2freq toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisFeeline2Freq() {
      let data = this.state.data;
      let isFeeline2Freqlist = this.state.isFeeline2Freqlist;
      let isFeeline2Freqclasslist = this.state.isFeeline2Freqclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline2Freq) {
            CheckerProductMix.isFeeline2Freq = true
            this.state.isFeeline2Freqlist[i] = true
            this.state.isFeeline2Freqclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isFeeline2Freqlist,
               isFeeline2Freqclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isFeeline2Freq) {
               CheckerProductMix.isFeeline2Freq = true
               this.state.isFeeline2Freqlist[i] = false
               this.state.isFeeline2Freqclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isFeeline2Freqlist,
                  isFeeline2Freqclasslist
               });
            }
            else {
               CheckerProductMix.isFeeline2Freq = false
               this.state.isFeeline2Freqlist[i] = false
               this.state.isFeeline2Freqclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isFeeline2Freqlist,
                  isFeeline2Freqclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for interestratetype toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisInterestRateType() {
      let data = this.state.data;
      let isInterestRateTypelist = this.state.isInterestRateTypelist;
      let isInterestRateTypeclasslist = this.state.isInterestRateTypeclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isInterestRateType) {
            CheckerProductMix.isInterestRateType = true
            this.state.isInterestRateTypelist[i] = true
            this.state.isInterestRateTypeclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isInterestRateTypelist,
               isInterestRateTypeclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isInterestRateType) {
               CheckerProductMix.isInterestRateType = true
               this.state.isInterestRateTypelist[i] = false
               this.state.isInterestRateTypeclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isInterestRateTypelist,
                  isInterestRateTypeclasslist
               });
            }
            else {
               CheckerProductMix.isInterestRateType = false
               this.state.isInterestRateTypelist[i] = false
               this.state.isInterestRateTypeclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isInterestRateTypelist,
                  isInterestRateTypeclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for losspoolpercent toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisLossPoolContrPercent() {
      let data = this.state.data;
      let isLossPoolContrPercentlist = this.state.isLossPoolContrPercentlist;
      let isLossPoolContrPercentclasslist = this.state.isLossPoolContrPercentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isLossPoolContrPercent) {
            CheckerProductMix.isLossPoolContrPercent = true
            this.state.isLossPoolContrPercentlist[i] = true
            this.state.isLossPoolContrPercentclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isLossPoolContrPercentlist,
               isLossPoolContrPercentclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isLossPoolContrPercent) {
               CheckerProductMix.isLossPoolContrPercent = true
               this.state.isLossPoolContrPercentlist[i] = false
               this.state.isLossPoolContrPercentclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isLossPoolContrPercentlist,
                  isLossPoolContrPercentclasslist
               });
            }
            else {
               CheckerProductMix.isLossPoolContrPercent = false
               this.state.isLossPoolContrPercentlist[i] = false
               this.state.isLossPoolContrPercentclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isLossPoolContrPercentlist,
                  isLossPoolContrPercentclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for penalchargespercent toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisPenalChanrgesPercent() {
      let data = this.state.data;
      let isPenalChanrgesPercentlist = this.state.isPenalChanrgesPercentlist;
      let isPenalChanrgesPercentclasslist = this.state.isPenalChanrgesPercentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isPenalChanrgesPercent) {
            CheckerProductMix.isPenalChanrgesPercent = true
            this.state.isPenalChanrgesPercentlist[i] = true
            this.state.isPenalChanrgesPercentclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isPenalChanrgesPercentlist,
               isPenalChanrgesPercentclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isPenalChanrgesPercent) {
               CheckerProductMix.isPenalChanrgesPercent = true
               this.state.isPenalChanrgesPercentlist[i] = false
               this.state.isPenalChanrgesPercentclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isPenalChanrgesPercentlist,
                  isPenalChanrgesPercentclasslist
               });
            }
            else {
               CheckerProductMix.isPenalChanrgesPercent = false
               this.state.isPenalChanrgesPercentlist[i] = false
               this.state.isPenalChanrgesPercentclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isPenalChanrgesPercentlist,
                  isPenalChanrgesPercentclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for loanamortizationtype toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisLoanAmortizationType() {
      let data = this.state.data;
      let isLoanAmortizationTypelist = this.state.isLoanAmortizationTypelist;
      let isLoanAmortizationTypeclasslist = this.state.isLoanAmortizationTypeclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isLoanAmortizationType) {
            CheckerProductMix.isLoanAmortizationType = true
            this.state.isLoanAmortizationTypelist[i] = true
            this.state.isLoanAmortizationTypeclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isLoanAmortizationTypelist,
               isLoanAmortizationTypeclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isLoanAmortizationType) {
               CheckerProductMix.isLoanAmortizationType = true
               this.state.isLoanAmortizationTypelist[i] = false
               this.state.isLoanAmortizationTypeclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isLoanAmortizationTypelist,
                  isLoanAmortizationTypeclasslist
               });
            }
            else {
               CheckerProductMix.isLoanAmortizationType = false
               this.state.isLoanAmortizationTypelist[i] = false
               this.state.isLoanAmortizationTypeclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isLoanAmortizationTypelist,
                  isLoanAmortizationTypeclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for salariedsegment toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisSalriedSegment() {
      let data = this.state.data;
      let isSalriedSegmentlist = this.state.isSalriedSegmentlist;
      let isSalriedSegmentclasslist = this.state.isSalriedSegmentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isSalriedSegment) {
            CheckerProductMix.isSalriedSegment = true
            this.state.isSalriedSegmentlist[i] = true
            this.state.isSalriedSegmentclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isSalriedSegmentlist,
               isSalriedSegmentclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isSalriedSegment) {
               CheckerProductMix.isSalriedSegment = true
               this.state.isSalriedSegmentlist[i] = false
               this.state.isSalriedSegmentclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isSalriedSegmentlist,
                  isSalriedSegmentclasslist
               });
            }
            else {
               CheckerProductMix.isSalriedSegment = false
               this.state.isSalriedSegmentlist[i] = false
               this.state.isSalriedSegmentclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isSalriedSegmentlist,
                  isSalriedSegmentclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for selfemployedsegment toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisSelfEmployedsegment() {
      let data = this.state.data;
      let isSelfEmployedsegmentlist = this.state.isSelfEmployedsegmentlist;
      let isSelfEmployedsegmentclasslist = this.state.isSelfEmployedsegmentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isSelfEmployedsegment) {
            CheckerProductMix.isSelfEmployedsegment = true
            this.state.isSelfEmployedsegmentlist[i] = true
            this.state.isSelfEmployedsegmentclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isSelfEmployedsegmentlist,
               isSelfEmployedsegmentclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isSelfEmployedsegment) {
               CheckerProductMix.isSelfEmployedsegment = true
               this.state.isSelfEmployedsegmentlist[i] = false
               this.state.isSelfEmployedsegmentclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isSelfEmployedsegmentlist,
                  isSelfEmployedsegmentclasslist
               });
            }
            else {
               CheckerProductMix.isSelfEmployedsegment = false
               this.state.isSelfEmployedsegmentlist[i] = false
               this.state.isSelfEmployedsegmentclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isSelfEmployedsegmentlist,
                  isSelfEmployedsegmentclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for loanstoexterncust toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisLoansToExternCust() {
      let data = this.state.data;
      let isLoansToExternCustlist = this.state.isLoansToExternCustlist;
      let isLoansToExternCustclasslist = this.state.isLoansToExternCustclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isLoansToExternCust) {
            CheckerProductMix.isLoansToExternCust = true
            this.state.isLoansToExternCustlist[i] = true
            this.state.isLoansToExternCustclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isLoansToExternCustlist,
               isLoansToExternCustclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isLoansToExternCust) {
               CheckerProductMix.isLoansToExternCust = true
               this.state.isLoansToExternCustlist[i] = false
               this.state.isLoansToExternCustclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isLoansToExternCustlist,
                  isLoansToExternCustclasslist
               });
            }
            else {
               CheckerProductMix.isLoansToExternCust = false
               this.state.isLoansToExternCustlist[i] = false
               this.state.isLoansToExternCustclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isLoansToExternCustlist,
                  isLoansToExternCustclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for disbursalininternacct toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisDisbursalInInternAcct() {
      let data = this.state.data;
      let isDisbursalInInternAcctlist = this.state.isDisbursalInInternAcctlist;
      let isDisbursalInInternAcctclasslist = this.state.isDisbursalInInternAcctclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isDisbursalInInternAcct) {
            CheckerProductMix.isDisbursalInInternAcct = true
            this.state.isDisbursalInInternAcctlist[i] = true
            this.state.isDisbursalInInternAcctclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isDisbursalInInternAcctlist,
               isDisbursalInInternAcctclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isDisbursalInInternAcct) {
               CheckerProductMix.isDisbursalInInternAcct = true
               this.state.isDisbursalInInternAcctlist[i] = false
               this.state.isDisbursalInInternAcctclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isDisbursalInInternAcctlist,
                  isDisbursalInInternAcctclasslist
               });
            }
            else {
               CheckerProductMix.isDisbursalInInternAcct = false
               this.state.isDisbursalInInternAcctlist[i] = false
               this.state.isDisbursalInInternAcctclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isDisbursalInInternAcctlist,
                  isDisbursalInInternAcctclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for disbursalinexternacct toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisDisbursalInExternAcct() {
      let data = this.state.data;
      let isDisbursalInExternAcctlist = this.state.isDisbursalInExternAcctlist;
      let isDisbursalInExternAcctclasslist = this.state.isDisbursalInExternAcctclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isDisbursalInExternAcct) {
            CheckerProductMix.isDisbursalInExternAcct = true
            this.state.isDisbursalInExternAcctlist[i] = true
            this.state.isDisbursalInExternAcctclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isDisbursalInExternAcctlist,
               isDisbursalInExternAcctclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isDisbursalInExternAcct) {
               CheckerProductMix.isDisbursalInExternAcct = true
               this.state.isDisbursalInExternAcctlist[i] = false
               this.state.isDisbursalInExternAcctclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isDisbursalInExternAcctlist,
                  isDisbursalInExternAcctclasslist
               });
            }
            else {
               CheckerProductMix.isDisbursalInExternAcct = false
               this.state.isDisbursalInExternAcctlist[i] = false
               this.state.isDisbursalInExternAcctclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isDisbursalInExternAcctlist,
                  isDisbursalInExternAcctclasslist
               });
            }
         }

      })
   }
   /**
   * Sets the boolean value for collectionforinternloans toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisCollectionForInternLoans() {
      let data = this.state.data;
      let isCollectionForInternLoanslist = this.state.isCollectionForInternLoanslist;
      let isCollectionForInternLoansclasslist = this.state.isCollectionForInternLoansclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isCollectionForInternLoans) {
            CheckerProductMix.isCollectionForInternLoans = true
            this.state.isCollectionForInternLoanslist[i] = true
            this.state.isCollectionForInternLoansclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isCollectionForInternLoanslist,
               isCollectionForInternLoansclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isCollectionForInternLoans) {
               CheckerProductMix.isCollectionForInternLoans = true
               this.state.isCollectionForInternLoanslist[i] = false
               this.state.isCollectionForInternLoansclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isCollectionForInternLoanslist,
                  isCollectionForInternLoansclasslist
               });
            }
            else {
               CheckerProductMix.isCollectionForInternLoans = false
               this.state.isCollectionForInternLoanslist[i] = false
               this.state.isCollectionForInternLoansclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isCollectionForInternLoanslist,
                  isCollectionForInternLoansclasslist
               });
            }
         }

      })
   }

   /**
   * Sets the boolean value for baseinterestrate toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisBaseInterestRate() {
      let data = this.state.data;
      let isBaseInterestRatelist = this.state.isBaseInterestRatelist;
      let isBaseInterestRateclasslist = this.state.isBaseInterestRateclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isBaseInterestRate) {
            CheckerProductMix.isBaseInterestRate = true
            this.state.isBaseInterestRatelist[i] = true
            this.state.isBaseInterestRateclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isBaseInterestRatelist,
               isBaseInterestRateclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isBaseInterestRate) {
               CheckerProductMix.isBaseInterestRate = true
               this.state.isBaseInterestRatelist[i] = false
               this.state.isBaseInterestRateclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isBaseInterestRatelist,
                  isBaseInterestRateclasslist
               });
            }
            else {
               CheckerProductMix.isBaseInterestRate = false
               this.state.isBaseInterestRatelist[i] = false
               this.state.isBaseInterestRateclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isBaseInterestRatelist,
                  isBaseInterestRateclasslist
               });
            }
         }

      })
   } /**
   * Sets the boolean value for basetenors toggle button,disable buttons and background color after submitting data to server.
   */
   onSubmitChangeisBaseTenors() {
      let data = this.state.data;
      let isBaseTenorslist = this.state.isBaseTenorslist;
      let isBaseTenorsclasslist = this.state.isBaseTenorsclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isBaseTenors) {
            CheckerProductMix.isBaseTenors = true
            this.state.isBaseTenorslist[i] = true
            this.state.isBaseTenorsclasslist[i] = "#bcbcbb"
            this.setState({
               data,
               isBaseTenorslist,
               isBaseTenorsclasslist
            });
         } else {
            if ("A" == CheckerProductMix.isBaseTenors) {
               CheckerProductMix.isBaseTenors = true
               this.state.isBaseTenorslist[i] = false
               this.state.isBaseTenorsclasslist[i] = "#2ab934"
               this.setState({
                  data,
                  isBaseTenorslist,
                  isBaseTenorsclasslist
               });
            }
            else {
               CheckerProductMix.isBaseTenors = false
               this.state.isBaseTenorslist[i] = false
               this.state.isBaseTenorsclasslist[i] = "#6c757d"
               this.setState({
                  data,
                  isBaseTenorslist,
                  isBaseTenorsclasslist
               });
            }
         }

      })
   }
   /**
       * Sets the boolean value for feeline2freq toggle button,disable buttons and background color.
      */
   onAndOffChangeisFeeline2Freq() {
      let data = this.state.data;
      let isFeeline2Freqlist = this.state.isFeeline2Freqlist;
      let isFeeline2Freqclasslist = this.state.isFeeline2Freqclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline2Freq) {
            CheckerProductMix.isFeeline2Freq = true
            this.state.isFeeline2Freqlist[i] = true
            this.state.isFeeline2Freqclasslist[i] = "#bcbcbb"
            this.state.isFeeline2Freqfieldclasslist[i] = "#e9ecef"

            this.setState({
               data,
               isFeeline2Freqlist,
               isFeeline2Freqclasslist
            });
         } else {
            CheckerProductMix.isFeeline2Freq = true
            this.state.isFeeline2Freqlist[i] = false
            this.state.isFeeline2Freqclasslist[i] = "#2ab934"
            this.state.isFeeline2Freqfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isFeeline2Freqlist,
               isFeeline2Freqclasslist
            });
         }

      })
   }
   /**
        * Sets the boolean value for interestratetype toggle button,disable buttons and background color.
       */

   onAndOffChangeisInterestRateType() {
      let data = this.state.data;
      let isInterestRateTypelist = this.state.isInterestRateTypelist;
      let isInterestRateTypeclasslist = this.state.isInterestRateTypeclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isInterestRateType) {
            CheckerProductMix.isInterestRateType = true
            this.state.isInterestRateTypelist[i] = true
            this.state.isInterestRateTypeclasslist[i] = "#bcbcbb"
            this.state.isInterestRateTypefieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isInterestRateTypelist,
               isInterestRateTypeclasslist
            });
         } else {
            CheckerProductMix.isInterestRateType = true
            this.state.isInterestRateTypelist[i] = false
            this.state.isInterestRateTypeclasslist[i] = "#2ab934"
            this.state.isInterestRateTypefieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isInterestRateTypelist,
               isInterestRateTypeclasslist
            });
         }

      })
   }
   /**
        * Sets the boolean value for losspoolpercent toggle button,disable buttons and background color.
       */

   onAndOffChangeisLossPoolContrPercent() {
      let data = this.state.data;
      let isLossPoolContrPercentlist = this.state.isLossPoolContrPercentlist;
      let isLossPoolContrPercentclasslist = this.state.isLossPoolContrPercentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isLossPoolContrPercent) {
            CheckerProductMix.isLossPoolContrPercent = true
            this.state.isLossPoolContrPercentlist[i] = true
            this.state.isLossPoolContrPercentclasslist[i] = "#bcbcbb"
            this.state.isLossPoolContrPercentfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isLossPoolContrPercentlist,
               isLossPoolContrPercentclasslist
            });
         } else {
            CheckerProductMix.isLossPoolContrPercent = true
            this.state.isLossPoolContrPercentlist[i] = false
            this.state.isLossPoolContrPercentclasslist[i] = "#2ab934"
            this.state.isLossPoolContrPercentfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isLossPoolContrPercentlist,
               isLossPoolContrPercentclasslist
            });
         }

      })
   }


   /**
        * Sets the boolean value for penalchargespercent toggle button,disable buttons and background color.
       */
   onAndOffChangeisPenalChanrgesPercent() {
      let data = this.state.data;
      let isPenalChanrgesPercentlist = this.state.isPenalChanrgesPercentlist;
      let isPenalChanrgesPercentclasslist = this.state.isPenalChanrgesPercentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isPenalChanrgesPercent) {
            CheckerProductMix.isPenalChanrgesPercent = true
            this.state.isPenalChanrgesPercentlist[i] = true
            this.state.isPenalChanrgesPercentclasslist[i] = "#bcbcbb"
            this.state.isPenalChanrgesPercentfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isPenalChanrgesPercentlist,
               isPenalChanrgesPercentclasslist
            });
         } else {
            CheckerProductMix.isPenalChanrgesPercent = true
            this.state.isPenalChanrgesPercentlist[i] = false
            this.state.isPenalChanrgesPercentclasslist[i] = "#2ab934"
            this.state.isPenalChanrgesPercentfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isPenalChanrgesPercentlist,
               isPenalChanrgesPercentclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for loanamortizationtype toggle button,disable buttons and background color.
       */
   onAndOffChangeisLoanAmortizationType() {
      let data = this.state.data;
      let isLoanAmortizationTypelist = this.state.isLoanAmortizationTypelist;
      let isLoanAmortizationTypeclasslist = this.state.isLoanAmortizationTypeclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isLoanAmortizationType) {
            CheckerProductMix.isLoanAmortizationType = true
            this.state.isLoanAmortizationTypelist[i] = true
            this.state.isLoanAmortizationTypeclasslist[i] = "#bcbcbb"
            this.state.isLoanAmortizationTypefieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isLoanAmortizationTypelist,
               isLoanAmortizationTypeclasslist
            });
         } else {
            CheckerProductMix.isLoanAmortizationType = true
            this.state.isLoanAmortizationTypelist[i] = false
            this.state.isLoanAmortizationTypeclasslist[i] = "#2ab934"
            this.state.isLoanAmortizationTypefieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isLoanAmortizationTypelist,
               isLoanAmortizationTypeclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for salariedsegment toggle button,disable buttons and background color.
       */
   onAndOffChangeisSalriedSegment() {
      let data = this.state.data;
      let isSalriedSegmentlist = this.state.isSalriedSegmentlist;
      let isSalriedSegmentclasslist = this.state.isSalriedSegmentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isSalriedSegment) {
            CheckerProductMix.isSalriedSegment = true
            this.state.isSalriedSegmentlist[i] = true
            this.state.isSalriedSegmentclasslist[i] = "#bcbcbb"
            this.state.isSalriedSegmentfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isSalriedSegmentlist,
               isSalriedSegmentclasslist
            });
         } else {
            CheckerProductMix.isSalriedSegment = true
            this.state.isSalriedSegmentlist[i] = false
            this.state.isSalriedSegmentclasslist[i] = "#2ab934"
            this.state.isSalriedSegmentfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isSalriedSegmentlist,
               isSalriedSegmentclasslist
            });
         }

      })
   }
   /**
        * Sets the boolean value for selfemployedsegment toggle button,disable buttons and background color.
       */

   onAndOffChangeisSelfEmployedsegment() {
      let data = this.state.data;
      let isSelfEmployedsegmentlist = this.state.isSelfEmployedsegmentlist;
      let isSelfEmployedsegmentclasslist = this.state.isSelfEmployedsegmentclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isSelfEmployedsegment) {
            CheckerProductMix.isSelfEmployedsegment = true
            this.state.isSelfEmployedsegmentlist[i] = true
            this.state.isSelfEmployedsegmentclasslist[i] = "#bcbcbb"
            this.state.isSelfEmployedsegmentfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isSelfEmployedsegmentlist,
               isSelfEmployedsegmentclasslist
            });
         } else {
            CheckerProductMix.isSelfEmployedsegment = true
            this.state.isSelfEmployedsegmentlist[i] = false
            this.state.isSelfEmployedsegmentclasslist[i] = "#2ab934"
            this.state.isSelfEmployedsegmentfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isSelfEmployedsegmentlist,
               isSelfEmployedsegmentclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for loanstoexterncust toggle button,disable buttons and background color.
       */

   onAndOffChangeisLoansToExternCust() {
      let data = this.state.data;
      let isLoansToExternCustlist = this.state.isLoansToExternCustlist;
      let isLoansToExternCustclasslist = this.state.isLoansToExternCustclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isLoansToExternCust) {
            CheckerProductMix.isLoansToExternCust = true
            this.state.isLoansToExternCustlist[i] = true
            this.state.isLoansToExternCustclasslist[i] = "#bcbcbb"
            this.state.isLoansToExternCustfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isLoansToExternCustlist,
               isLoansToExternCustclasslist
            });
         } else {
            CheckerProductMix.isLoansToExternCust = true
            this.state.isLoansToExternCustlist[i] = false
            this.state.isLoansToExternCustclasslist[i] = "#2ab934"
            this.state.isLoansToExternCustfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isLoansToExternCustlist,
               isLoansToExternCustclasslist
            });
         }

      })
   }
   /**
        * Sets the boolean value for disbursalininternacct toggle button,disable buttons and background color.
       */

   onAndOffChangeisDisbursalInInternAcct() {
      let data = this.state.data;
      let isDisbursalInInternAcctlist = this.state.isDisbursalInInternAcctlist;
      let isDisbursalInInternAcctclasslist = this.state.isDisbursalInInternAcctclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isDisbursalInInternAcct) {
            CheckerProductMix.isDisbursalInInternAcct = true
            this.state.isDisbursalInInternAcctlist[i] = true
            this.state.isDisbursalInInternAcctclasslist[i] = "#bcbcbb"
            this.state.isDisbursalInInternAcctfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isDisbursalInInternAcctlist,
               isDisbursalInInternAcctclasslist
            });
         } else {
            CheckerProductMix.isDisbursalInInternAcct = true
            this.state.isDisbursalInInternAcctlist[i] = false
            this.state.isDisbursalInInternAcctclasslist[i] = "#2ab934"
            this.state.isDisbursalInInternAcctfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isDisbursalInInternAcctlist,
               isDisbursalInInternAcctclasslist
            });
         }

      })
   }
   /**
        * Sets the boolean value for disbursalinextrenacct toggle button,disable buttons and background color.
       */

   onAndOffChangeisDisbursalInExternAcct() {
      let data = this.state.data;
      let isDisbursalInExternAcctlist = this.state.isDisbursalInExternAcctlist;
      let isDisbursalInExternAcctclasslist = this.state.isDisbursalInExternAcctclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isDisbursalInExternAcct) {
            CheckerProductMix.isDisbursalInExternAcct = true
            this.state.isDisbursalInExternAcctlist[i] = true
            this.state.isDisbursalInExternAcctclasslist[i] = "#bcbcbb"
            this.state.isDisbursalInExternAcctfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isDisbursalInExternAcctlist,
               isDisbursalInExternAcctclasslist
            });
         } else {
            CheckerProductMix.isDisbursalInExternAcct = true
            this.state.isDisbursalInExternAcctlist[i] = false
            this.state.isDisbursalInExternAcctclasslist[i] = "#2ab934"
            this.state.isDisbursalInExternAcctfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isDisbursalInExternAcctlist,
               isDisbursalInExternAcctclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for collectionforinternloans toggle button,disable buttons and background color.
       */
   onAndOffChangeisCollectionForInternLoans() {
      let data = this.state.data;
      let isCollectionForInternLoanslist = this.state.isCollectionForInternLoanslist;
      let isCollectionForInternLoansclasslist = this.state.isCollectionForInternLoansclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isCollectionForInternLoans) {
            CheckerProductMix.isCollectionForInternLoans = true
            this.state.isCollectionForInternLoanslist[i] = true
            this.state.isCollectionForInternLoansclasslist[i] = "#bcbcbb"
            this.state.isCollectionForInternLoansfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isCollectionForInternLoanslist,
               isCollectionForInternLoansclasslist
            });
         } else {
            CheckerProductMix.isCollectionForInternLoans = true
            this.state.isCollectionForInternLoanslist[i] = false
            this.state.isCollectionForInternLoansclasslist[i] = "#2ab934"
            this.state.isCollectionForInternLoansfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isCollectionForInternLoanslist,
               isCollectionForInternLoansclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for baseinterestrate toggle button,disable buttons and background color.
       */
   onAndOffChangeisBaseInterestRate() {
      let data = this.state.data;
      let isBaseInterestRatelist = this.state.isBaseInterestRatelist;
      let isBaseInterestRateclasslist = this.state.isBaseInterestRateclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isBaseInterestRate) {
            CheckerProductMix.isBaseInterestRate = true
            this.state.isBaseInterestRatelist[i] = true
            this.state.isBaseInterestRateclasslist[i] = "#bcbcbb"
            this.state.isBaseInterestRatefieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isBaseInterestRatelist,
               isBaseInterestRateclasslist
            });
         } else {
            CheckerProductMix.isBaseInterestRate = true
            this.state.isBaseInterestRatelist[i] = false
            this.state.isBaseInterestRateclasslist[i] = "#2ab934"
            this.state.isBaseInterestRatefieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isBaseInterestRatelist,
               isBaseInterestRateclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for basetenors toggle button,disable buttons and background color.
       */
   onAndOffChangeisBaseTenors() {
      let data = this.state.data;
      let isBaseTenorslist = this.state.isBaseTenorslist;
      let isBaseTenorsclasslist = this.state.isBaseTenorsclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isBaseTenors) {
            CheckerProductMix.isBaseTenors = true
            this.state.isBaseTenorslist[i] = true
            this.state.isBaseTenorsclasslist[i] = "#bcbcbb"
            this.state.isBaseTenorsfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isBaseTenorslist,
               isBaseTenorsclasslist
            });
         } else {
            CheckerProductMix.isBaseTenors = true
            this.state.isBaseTenorslist[i] = false
            this.state.isBaseTenorsclasslist[i] = "#2ab934"
            this.state.isBaseTenorsfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isBaseTenorslist,
               isBaseTenorsclasslist
            });
         }

      })
   }

   /**
        * Sets the boolean value for feeline2 toggle button,disable buttons and background color.
       */
   onAndOffChangeisFeeline2() {
      let data = this.state.data;
      let isFeeline2list = this.state.isFeeline2list;
      let isFeeline2classlist = this.state.isFeeline2classlist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline2) {
            CheckerProductMix.isFeeline2 = true
            this.state.isFeeline2list[i] = true
            this.state.isFeeline2classlist[i] = "#bcbcbb"
            this.state.isFeeline2fieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isFeeline2list,
               isFeeline2classlist
            });
         } else {
            CheckerProductMix.isFeeline2 = true
            this.state.isFeeline2list[i] = false
            this.state.isFeeline2classlist[i] = "#2ab934"
            this.state.isFeeline2fieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isFeeline2list,
               isFeeline2classlist
            });
         }

      })
   }

   /**
        * Sets the boolean value for feeline1freq toggle button,disable buttons and background color.
       */
   onAndOffChangeisFeeline1Freq() {
      let data = this.state.data;
      let isFeeline1Freqlist = this.state.isFeeline1Freqlist;
      let isFeeline1Freqclasslist = this.state.isFeeline1Freqclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline1Freq) {
            CheckerProductMix.isFeeline1Freq = true
            this.state.isFeeline1Freqlist[i] = true
            this.state.isFeeline1Freqclasslist[i] = "#bcbcbb"
            this.state.isFeeline1Freqfieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isFeeline1Freqlist,
               isFeeline1Freqclasslist
            });
         } else {
            CheckerProductMix.isFeeline1Freq = true
            this.state.isFeeline1Freqlist[i] = false
            this.state.isFeeline1Freqclasslist[i] = "#2ab934"
            this.state.isFeeline1Freqfieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isFeeline1Freqlist,
               isFeeline1Freqclasslist
            });
         }

      })
   }



   /**
        * Sets the boolean value for feeline1 toggle button,disable buttons and background color.
       */

   onAndOffChangeisFeeline1() {
      let data = this.state.data;
      let isFeeline1list = this.state.isFeeline1list;
      let isFeeline1classlist = this.state.isFeeline1classlist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isFeeline1) {
            CheckerProductMix.isFeeline1 = true
            this.state.isFeeline1list[i] = true
            this.state.isFeeline1classlist[i] = "#bcbcbb"
            this.state.isFeeline1fieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isFeeline1list,
               isFeeline1classlist
            });
         } else {
            CheckerProductMix.isFeeline1 = true
            this.state.isFeeline1list[i] = false
            this.state.isFeeline1classlist[i] = "#2ab934"
            this.state.isFeeline1fieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isFeeline1list,
               isFeeline1classlist
            });
         }

      })
   }

   /**
        * Sets the boolean value for active toggle button,disable buttons and background color.
       */
   onAndOffChangeisActive() {
      let data = this.state.data;
      let isActivelist = this.state.isActivelist;
      let isActiveclasslist = this.state.isActiveclasslist;
      this.state.data.map((CheckerProductMix, i) => {
         if ("N" == CheckerProductMix.isActiveProds
         ) {
            CheckerProductMix.isActiveProds = true
            this.state.isActivelist[i] = true
            this.state.isActiveclasslist[i] = "#bcbcbb"
            this.state.isActivefieldclasslist[i] = "#e9ecef"
            this.setState({
               data,
               isActivelist,
               isActiveclasslist
            });
         } else {
            CheckerProductMix.isActiveProds = true
            this.state.isActivelist[i] = false
            this.state.isActiveclasslist[i] = "#2ab934"
            this.state.isActivefieldclasslist[i] = "#bce394"
            this.setState({
               data,
               isActivelist,
               isActiveclasslist
            });
         }

      })
   }

   /**
             * Sets the boolean value for feeline1 toggle button,disable buttons and background color
            */

   setOnAndOffChangeisFeeline1() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isFeeline1list[i]) {
            if (true == productmix.isFeeline1) {
               productmix.isFeeline1 = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isFeeline1 = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isFeeline1 = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for feeline2 toggle button,disable buttons and background color
         */
   setOnAndOffChangeisFeeline2() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isFeeline2list[i]) {
            if (true == productmix.isFeeline2) {
               productmix.isFeeline2 = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isFeeline2 = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isFeeline2 = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for active toggle button,disable buttons and background color
         */
   setOnAndOffChangeisActive() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isActivelist[i]) {
            if (true == productmix.isActiveProds) {
               productmix.isActiveProds = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isActiveProds = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isActiveProds = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for feeline1freq toggle button,disable buttons and background color
         */
   setOnAndOffChangeisFeeline1Freq() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isFeeline1Freqlist[i]) {
            if (true == productmix.isFeeline1Freq) {
               productmix.isFeeline1Freq = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isFeeline1Freq = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isFeeline1Freq = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
             * Sets the boolean value for feeline2freq toggle button,disable buttons and background color
            */
   setOnAndOffChangeisFeeline2Freq() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isFeeline2Freqlist[i]) {
            if (true == productmix.isFeeline2Freq) {
               productmix.isFeeline2Freq = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isFeeline2Freq = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isFeeline2Freq = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for interestratetype toggle button,disable buttons and background color
         */
   setOnAndOffChangeisInterestRateType() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isInterestRateTypelist[i]) {
            if (true == productmix.isInterestRateType) {
               productmix.isInterestRateType = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isInterestRateType = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isInterestRateType = "N"
            this.setState({
               data
            });
         }
      })
   }/**
          * Sets the boolean value for losspoolpercent toggle button,disable buttons and background color
         */
   setOnAndOffChangeisLossPoolContrPercent() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isLossPoolContrPercentlist[i]) {
            if (true == productmix.isLossPoolContrPercent) {
               productmix.isLossPoolContrPercent = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isLossPoolContrPercent = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isLossPoolContrPercent = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for penalchargespercent toggle button,disable buttons and background color
         */
   setOnAndOffChangeisPenalChanrgesPercent() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isPenalChanrgesPercentlist[i]) {
            if (true == productmix.isPenalChanrgesPercent) {
               productmix.isPenalChanrgesPercent = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isPenalChanrgesPercent = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isPenalChanrgesPercent = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for loanamoritizationtype toggle button,disable buttons and background color
         */
   setOnAndOffChangeisLoanAmortizationType() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isLoanAmortizationTypelist[i]) {
            if (true == productmix.isLoanAmortizationType) {
               productmix.isLoanAmortizationType = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isLoanAmortizationType = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isLoanAmortizationType = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for salariedsegment toggle button,disable buttons and background color
         */
   setOnAndOffChangeisSalriedSegment() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isSalriedSegmentlist[i]) {
            if (true == productmix.isSalriedSegment) {
               productmix.isSalriedSegment = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isSalriedSegment = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isSalriedSegment = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for selfemployedsegment toggle button,disable buttons and background color
         */
   setOnAndOffChangeisSelfEmployedsegment() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isSelfEmployedsegmentlist[i]) {
            if (true == productmix.isSelfEmployedsegment) {
               productmix.isSelfEmployedsegment = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isSelfEmployedsegment = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isSelfEmployedsegment = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for loanstoexterncust toggle button,disable buttons and background color
         */
   setOnAndOffChangeisLoansToExternCust() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isLoansToExternCustlist[i]) {
            if (true == productmix.isLoansToExternCust) {
               productmix.isLoansToExternCust = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isLoansToExternCust = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isLoansToExternCust = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for disbursalininternacct toggle button,disable buttons and background color
         */
   setOnAndOffChangeisDisbursalInInternAcct() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isDisbursalInInternAcctlist[i]) {
            if (true == productmix.isDisbursalInInternAcct) {
               productmix.isDisbursalInInternAcct = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isDisbursalInInternAcct = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isDisbursalInInternAcct = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for disbursalinexternacct toggle button,disable buttons and background color
         */
   setOnAndOffChangeisDisbursalInExternAcct() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isDisbursalInExternAcctlist[i]) {
            if (true == productmix.isDisbursalInExternAcct) {
               productmix.isDisbursalInExternAcct = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isDisbursalInExternAcct = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isDisbursalInExternAcct = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for collectionforinternloans toggle button,disable buttons and background color
         */
   setOnAndOffChangeisCollectionForInternLoans() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isCollectionForInternLoanslist[i]) {
            if (true == productmix.isCollectionForInternLoans) {
               productmix.isCollectionForInternLoans = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isCollectionForInternLoans = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isCollectionForInternLoans = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for baseinterestrate toggle button,disable buttons and background color
         */
   setOnAndOffChangeisBaseInterestRate() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isBaseInterestRatelist[i]) {
            if (true == productmix.isBaseInterestRate) {
               productmix.isBaseInterestRate = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isBaseInterestRate = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isBaseInterestRate = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
          * Sets the boolean value for basetenors toggle button,disable buttons and background color
         */
   setOnAndOffChangeisBaseTenors() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         if (false == this.state.isBaseTenorslist[i]) {
            if (true == productmix.isBaseTenors) {
               productmix.isBaseTenors = "A"
               this.setState({
                  data
               });
            } else {
               productmix.isBaseTenors = "R"
               this.setState({
                  data
               });
            }
         }
         else {
            productmix.isBaseTenors = "N"
            this.setState({
               data
            });
         }
      })
   }
   /**
       * @typedef {ProductMixdata} ProductMixdata
       * @property {string} feeline1 indicates feeline1 of loan product type
       * @property {String} feeline1Freq  indicates feeline1Freq of loan product type
       * @property {string} feeline2 indicates feeline2 of loan product type
       * @property {String} feeline2Freq indicates feeline2Freq of loan product type
       * @property {String} interestRateType indicates interestRateType of loan product type
       * @property {String} lossPoolContrPercent indicates lossPoolContrPercent of loan product type
       * @property {String} penalChanrgesPercent indicates penalChanrgesPercent of loan product type
       * @property {String} loanAmortizationType indicates loanAmortizationType of loan product type
       * @property {String} salriedSegment indicates salriedSegment of loan product type
       * @property {String} selfEmployedsegment indicates selfEmployedsegment of loan product type
       * @property {String} loansToExternCust indicates loansToExternCust of loan product type
       * @property {String} disbursalInInternAcct indicates disbursalInInternAcct of loan product type
       * @property {String} disbursalInExternAcct indicates disbursalInExternAcct of loan product type
       * @property {String} collectionForInternLoans indicates collectionForInternLoans of loan product type
       * @property {String} baseInterestRate indicates baseInterestRate of loan product type
       * @property {String} baseTenors indicates baseTenors of loan product type
       */
   /**
    * Captures the changes in data array on event(onChange) e
    *
    * @param {Event} e Event on which the method is called.
    * @param {ProductMixdata} ProductMixdata data and index of data.
    */
   toggleChangeIsFeeline2(e, i) {
      let data = this.state.data;
      let isFeeline2classlist = this.state.isFeeline2classlist;
      this.state.data[i].isFeeline2 = !this.state.data[i].isFeeline2;
      if (true == this.state.data[i].isFeeline2) {
         this.state.isFeeline2classlist[i] = "#2ab934";
      } else {
         this.state.isFeeline2classlist[i] = "#6c757d";
      }
      this.setState({
         data,
         isFeeline2classlist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsFeeLine1(e, i) {
      let data = this.state.data;
      let isFeeline1classlist = this.state.isFeeline1classlist;
      this.state.data[i].isFeeline1 = !this.state.data[i].isFeeline1;
      if (true == this.state.data[i].isFeeline1) {
         this.state.isFeeline1classlist[i] = "#2ab934";
      } else {
         this.state.isFeeline1classlist[i] = "#6c757d";
      }
      this.setState({
         data,
         isFeeline1classlist
      });
   }
   /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {ProductMixdata} ProductMixdata data and index of data.
   */
   toggleChangeIsActiveProds(e, i) {
      let data = this.state.data;
      let isActiveProdsclasslist = this.state.isActiveProdsclasslist;
      this.state.data[i].isActiveProds = !this.state.data[i].isActiveProds;
      if (true == this.state.data[i].isActiveProds) {
         this.state.isActiveclasslist[i] = "#2ab934";
      } else {
         this.state.isActiveclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isActiveProdsclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsFeeline1Freq(e, i) {
      let data = this.state.data;
      let isFeeline1Freqclasslist = this.state.isFeeline1Freqclasslist;
      this.state.data[i].isFeeline1Freq = !this.state.data[i].isFeeline1Freq;
      if (true == this.state.data[i].isFeeline1Freq) {
         this.state.isFeeline1Freqclasslist[i] = "#2ab934";
      } else {
         this.state.isFeeline1Freqclasslist[i] = "#6c757d";

      }
      this.setState({
         data,
         isFeeline1Freqclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsFeeline2Freq(e, i) {
      let data = this.state.data;
      let isFeeline2Freqclasslist = this.state.isFeeline2Freqclasslist;
      this.state.data[i].isFeeline2Freq = !this.state.data[i].isFeeline2Freq;
      if (true == this.state.data[i].isFeeline2Freq) {
         this.state.isFeeline2Freqclasslist[i] = "#2ab934";
      } else {
         this.state.isFeeline2Freqclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isFeeline2Freqclasslist
      });
   }
   /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {ProductMixdata} ProductMixdata data and index of data.
   */
   toggleChangeIsInterestRateType(e, i) {
      let data = this.state.data;
      let isInterestRateTypeclasslist = this.state.isInterestRateTypeclasslist;
      this.state.data[i].isInterestRateType = !this.state.data[i].isInterestRateType;
      if (true == this.state.data[i].isInterestRateType) {
         this.state.isInterestRateTypeclasslist[i] = "#2ab934";
      } else {
         this.state.isInterestRateTypeclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isInterestRateTypeclasslist
      });
   }
   /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {ProductMixdata} ProductMixdata data and index of data.
   */
   toggleChangeIsLossPoolContrPercent(e, i) {
      let data = this.state.data;
      let isLossPoolContrPercentclasslist = this.state.isLossPoolContrPercentclasslist;
      this.state.data[i].isLossPoolContrPercent = !this.state.data[i].isLossPoolContrPercent;
      if (true == this.state.data[i].isLossPoolContrPercent) {
         this.state.isLossPoolContrPercentclasslist[i] = "#2ab934";
      } else {
         this.state.isLossPoolContrPercentclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isLossPoolContrPercentclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsPenalChanrgesPercent(e, i) {
      let data = this.state.data;
      let isPenalChanrgesPercentclasslist = this.state.isPenalChanrgesPercentclasslist;
      this.state.data[i].isPenalChanrgesPercent = !this.state.data[i].isPenalChanrgesPercent;
      if (true == this.state.data[i].isPenalChanrgesPercent) {
         this.state.isPenalChanrgesPercentclasslist[i] = "#2ab934";
      } else {
         this.state.isPenalChanrgesPercentclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isPenalChanrgesPercentclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsLoanAmortizationType(e, i) {
      let data = this.state.data;
      let isLoanAmortizationTypeclasslist = this.state.isLoanAmortizationTypeclasslist;
      this.state.data[i].isLoanAmortizationType = !this.state.data[i].isLoanAmortizationType;
      if (true == this.state.data[i].isLoanAmortizationType) {
         this.state.isLoanAmortizationTypeclasslist[i] = "#2ab934";
      } else {
         this.state.isLoanAmortizationTypeclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isLoanAmortizationTypeclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsSalriedSegment(e, i) {
      let data = this.state.data;
      let isSalriedSegmentclasslist = this.state.isSalriedSegmentclasslist;
      this.state.data[i].isSalriedSegment = !this.state.data[i].isSalriedSegment;
      if (true == this.state.data[i].isSalriedSegment) {
         this.state.isSalriedSegmentclasslist[i] = "#2ab934";
      } else {
         this.state.isSalriedSegmentclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isSalriedSegmentclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsSelfEmployedsegment(e, i) {
      let data = this.state.data;
      let isSelfEmployedsegmentclasslist = this.state.isSelfEmployedsegmentclasslist;
      this.state.data[i].isSelfEmployedsegment = !this.state.data[i].isSelfEmployedsegment;
      if (true == this.state.data[i].isSelfEmployedsegment) {
         this.state.isSelfEmployedsegmentclasslist[i] = "#2ab934";
      } else {
         this.state.isSelfEmployedsegmentclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isSelfEmployedsegmentclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsLoansToExternCust(e, i) {
      let data = this.state.data;
      let isLoansToExternCustclasslist = this.state.isLoansToExternCustclasslist;
      this.state.data[i].isLoansToExternCust = !this.state.data[i].isLoansToExternCust;
      if (true == this.state.data[i].isLoansToExternCust) {
         this.state.isLoansToExternCustclasslist[i] = "#2ab934";
      } else {
         this.state.isLoansToExternCustclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isLoansToExternCustclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsDisbursalInInternAcct(e, i) {
      let data = this.state.data;
      let isDisbursalInInternAcctclasslist = this.state.isDisbursalInInternAcctclasslist;
      this.state.data[i].isDisbursalInInternAcct = !this.state.data[i].isDisbursalInInternAcct;
      if (true == this.state.data[i].isDisbursalInInternAcct) {
         this.state.isDisbursalInInternAcctclasslist[i] = "#2ab934";
      } else {
         this.state.isDisbursalInInternAcctclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isDisbursalInInternAcctclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsDisbursalInExternAcct(e, i) {
      let data = this.state.data;
      let isDisbursalInExternAcctclasslist = this.state.isDisbursalInExternAcctclasslist;
      this.state.data[i].isDisbursalInExternAcct = !this.state.data[i].isDisbursalInExternAcct;
      if (true == this.state.data[i].isDisbursalInExternAcct) {
         this.state.isDisbursalInExternAcctclasslist[i] = "#2ab934";
      } else {
         this.state.isDisbursalInExternAcctclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isDisbursalInExternAcctclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsCollectionForInternLoans(e, i) {
      let data = this.state.data;
      let isCollectionForInternLoansclasslist = this.state.isCollectionForInternLoansclasslist;
      this.state.data[i].isCollectionForInternLoans = !this.state.data[i].isCollectionForInternLoans;
      if (true == this.state.data[i].isCollectionForInternLoans) {
         this.state.isCollectionForInternLoansclasslist[i] = "#2ab934";
      } else {
         this.state.isCollectionForInternLoansclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isCollectionForInternLoansclasslist
      });
   }
   /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {ProductMixdata} ProductMixdata data and index of data.
   */
   toggleChangeIsBaseInterestRate(e, i) {
      let data = this.state.data;
      let isBaseInterestRateclasslist = this.state.isBaseInterestRateclasslist;
      this.state.data[i].isBaseInterestRate = !this.state.data[i].isBaseInterestRate;
      if (true == this.state.data[i].isBaseInterestRate) {
         this.state.isBaseInterestRateclasslist[i] = "#2ab934";
      } else {
         this.state.isBaseInterestRateclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isBaseInterestRateclasslist
      });
   }
   /**
      * Captures the changes in data array on event(onChange) e
      *
      * @param {Event} e Event on which the method is called.
      * @param {ProductMixdata} ProductMixdata data and index of data.
      */
   toggleChangeIsBaseTenors(e, i) {
      let data = this.state.data;
      let isBaseTenorsclasslist = this.state.isBaseTenorsclasslist;
      this.state.data[i].isBaseTenors = !this.state.data[i].isBaseTenors;
      if (true == this.state.data[i].isBaseTenors) {
         this.state.isBaseTenorsclasslist[i] = "#2ab934";
      } else {
         this.state.isBaseTenorsclasslist[i] = "#6c757d";
      }
      this.setState({
         data,
         isBaseTenorsclasslist
      });
   }

   toggleChangestatus(e) {
      let data = this.state.data;
      this.state.data.map(productmix => {
         if ('approved' == e.target.name) {
            productmix.status = "approved";
         } else {
            productmix.status = "rejected";
         }
      })
   }
   /**
    * Fetches the data from server and render it on fields.
    */
   async componentDidMount() {
      try {
         const url = new URL('https://api.montra.org/moneylinkweb/api/product/productmix?email=' + localStorage.getItem("email"));
         const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json;charset=utf-8', 'Access-Control-Allow-Origin': '*' },
         });

         if (response.ok) {
            const contents = await response.json();

            if (contents.status === 200 && contents.message === 'SUCCESS') {
               const { ProductMix, editable } = contents.data;

               // Consolidate state updates
               this.setState({
                  data: ProductMix,
                  status: contents.status,
                  editable,
                  buttoncolor: editable ? "#192859" : "#343a40"
               }, () => {
                  // Callback after state is set
                  if (this.state.editable) {
                     this.setNforNonEditable();
                  }
                  this.onAndOffChangeisActive();
                  this.onAndOffChangeisBaseInterestRate();
                  this.onAndOffChangeisBaseTenors();
                  this.onAndOffChangeisCollectionForInternLoans();
                  this.onAndOffChangeisDisbursalInExternAcct();
                  this.onAndOffChangeisDisbursalInInternAcct();
                  this.onAndOffChangeisFeeline1();
                  this.onAndOffChangeisFeeline1Freq();
                  this.onAndOffChangeisFeeline2();
                  this.onAndOffChangeisFeeline2Freq();
                  this.onAndOffChangeisInterestRateType();
                  this.onAndOffChangeisLoanAmortizationType();
                  this.onAndOffChangeisLoansToExternCust();
                  this.onAndOffChangeisLossPoolContrPercent();
                  this.onAndOffChangeisPenalChanrgesPercent();
                  this.onAndOffChangeisSalriedSegment();
                  this.onAndOffChangeisSelfEmployedsegment();
               });
            } else {
               // Error handling for API response
               this.setState({
                  backerrors: contents.errors,
                  status: contents.status,
                  message: contents.message,
                  errorMsg: contents.errors.map(error => error.message).join(', ')
               });
            }
         } else {
            alert('Server error');
         }
      } catch (error) {
         // Handle any errors during fetch
         console.error('Fetch error:', error);
         alert('Network error');
      }
   }
   setNforNonEditable() {
      let data = this.state.data;
      this.state.data.map((productmix, i) => {
         productmix.isActive = "N";
         productmix.isBaseInterestRate = "N";
         productmix.isBaseTenors = "N";
         productmix.isCollectionForInternLoans = "N";
         productmix.isDisbursalInExternAcct = "N";
         productmix.isDisbursalInInternAcct = "N";
         productmix.isFeeline1 = "N";
         productmix.isFeeline1Freq = "N";
         productmix.isFeeline2 = "N";
         productmix.isFeeline2Freq = "N";
         productmix.isInterestRateType = "N";
         productmix.isLoanAmortizationType = "N";
         productmix.isLoansToExternCust = "N";
         productmix.isLossPoolContrPercent = "N";
         productmix.isPenalChanrgesPercent = "N";
         productmix.isSalriedSegment = "N";
         productmix.isSelfEmployedsegment = "N";
      })
      this.setState({
         data
      })
   }


   render() {
      if (localStorage.getItem("isLoggedIn") && "CHECKER" == localStorage.getItem("role")) {
         return (

            <div>

               <Header2 />

               <div id="wrapper">

                  <Sidebar />
                  {(() => {
                     if (this.state.editable) {
                        return <div className="errorMsg"> <p className="toperror">There are no Changes Request from Maker. A new request can be submitted once Maker does  the changes</p></div>
                     }
                  })()}
                  <div id="maincontent" className="content">
                     <section id="content-wrapper">
                        <div className="backloan">

                           <div className="container-fluid promix">

                              <p className="productmix">PRODUCT-MIX</p>
                              <form method="post" name="userRegistrationForm" className="formroot">

                                 <div className="row">

                                    <div className="col-sm-12 col-md-12 col-xl-5 reduce">
                                       <div className="d-flex flex-row mt-3 ">
                                          <div className="p-2 trial">
                                             <div className="form-group">
                                                <label className="productch">Product Name  </label>
                                                {this.state.data.map((productmix, i) => <div key={i}><label className="form-control loanlabelcheck" name="paydayloan" disabled> {productmix.prodName} </label></div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 trial2">
                                             <div className="form-group">
                                                <label className="procode">Code </label>
                                                {this.state.data.map((productmix, i) => <div key={i}><label className="form-control codeproduct" name="code" disabled> {productmix.code} </label></div>)}
                                             </div>
                                          </div>

                                          <div className="p-2 trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak">Active Products </p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   Products activated for Lending
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isActivefieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="activeProds" onChange={this.handleChange.bind(this)} disabled > {productmix.activeProds} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isActiveProds" onChange={(e) => { this.toggleChangeIsActiveProds(e, i) }} checked={productmix.isActiveProds} disabled={this.state.isActivelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isActiveclasslist[i] }}></div></label>
                                                </div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 trial4">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak line">Fee – Line 1 % </p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   % of Amount Disbursed
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isFeeline1fieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="feeline1" onChange={this.handleChange.bind(this)} disabled > {productmix.feeline1 + "%"} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isFeeline1" onChange={(e) => { this.toggleChangeIsFeeLine1(e, i) }} checked={productmix.isFeeline1} disabled={this.state.isFeeline1list[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isFeeline1classlist[i] }}></div></label>
                                                </div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 trial4">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak">Fee – Line 1 Freq. </p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   Origination OR Origination + Anniversary
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isFeeline1Freqfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="feeline1Freqsa" onChange={this.handleChange.bind(this)} disabled> {productmix.feeline1Freq} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isFeeline1Freq" onChange={(e) => { this.toggleChangeIsFeeline1Freq(e, i) }} checked={productmix.isFeeline1Freq} disabled={this.state.isFeeline1Freqlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isFeeline1Freqclasslist[i] }}></div></label>
                                                </div>)}

                                             </div>
                                          </div>


                                          <div className="p-2 trial4">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak line">Fee – Line 2 % </p>
                                                <Tooltip className="toolt" direction="left" align="center" >
                                                   % of Amount Disbursed
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isFeeline2fieldclasslist[i] }}  >
                                                   <label type="text" className="form-control formpprofilefield" name="feeline12cd" onChange={this.handleChange.bind(this)} disabled > {productmix.feeline2 + "%"} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isFeeline2" onChange={(e) => { this.toggleChangeIsFeeline2(e, i) }} checked={productmix.isFeeline2} disabled={this.state.isFeeline2list[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isFeeline2classlist[i] }}></div></label>
                                                </div>)}

                                             </div>
                                          </div>

                                          <div className="p-2 trial4">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak">Fee – Line 2 Freq </p>
                                                <Tooltip className="toolt" direction="left" align="center" >
                                                   Origination OR Origination + Anniversary
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isFeeline2Freqfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="feeline2Freqsa" onChange={this.handleChange.bind(this)} disabled > {productmix.feeline2Freq} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isFeeline2Freq" onChange={(e) => { this.toggleChangeIsFeeline2Freq(e, i) }} checked={productmix.isFeeline2Freq} disabled={this.state.isFeeline2Freqlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isFeeline2Freqclasslist[i] }}></div></label>
                                                </div>)}
                                             </div>
                                          </div>

                                       </div>
                                    </div>



                                    <div className="col-sm-12 col-md-12 col-xl-4 reduce">
                                       <div className="d-flex flex-row mt-3 ">

                                          <div className="p-2 md-trial">
                                             <div className="form-group">
                                                <label className="productch codecheck2">Product Name </label>
                                                {this.state.data.map((productmix, i) => <div key={i} ><label className="form-control loanlabelcheck" name="paydayloan" disabled> {productmix.prodName} </label></div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 md-trial2">
                                             <div className="form-group">
                                                <label className="procode codecheck">Code </label>
                                                {this.state.data.map((productmix, i) => <div key={i}><label className="form-control codeproduct" name="code" disabled > {productmix.code} </label></div>)}
                                             </div>
                                          </div>

                                          <div className="p-2 md-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak maks3">Interest Rate Type </p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   Flat OR Reducing
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isInterestRateTypefieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="interestRateTypesa" onChange={this.handleChange.bind(this)} disabled> {productmix.interestRateType} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isInterestRateType" onChange={(e) => { this.toggleChangeIsInterestRateType(e, i) }} checked={productmix.isInterestRateType} disabled={this.state.isInterestRateTypelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isInterestRateTypeclasslist[i] }}></div></label>
                                                </div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 md-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak">% Loss Pool Contribution </p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   % of Amount Disbursed
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isLossPoolContrPercentfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="lossPoolContrPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.lossPoolContrPercent + "%"} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isLossPoolContrPercent" onChange={(e) => { this.toggleChangeIsLossPoolContrPercent(e, i) }} checked={productmix.isLossPoolContrPercent} disabled={this.state.isLossPoolContrPercentlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isLossPoolContrPercentclasslist[i] }}></div></label>
                                                </div>)}

                                             </div>
                                          </div>

                                          <div className="p-2 md-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak maks3">Penal Charges % </p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   % of delayed repayment amount
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isPenalChanrgesPercentfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.penalChanrgesPercent + "%"} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isPenalChanrgesPercentsa" onChange={(e) => { this.toggleChangeIsPenalChanrgesPercent(e, i) }} checked={productmix.isPenalChanrgesPercent} disabled={this.state.isPenalChanrgesPercentlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isPenalChanrgesPercentclasslist[i] }}></div></label>
                                                </div>)}
                                             </div>
                                          </div>

                                          <div className="p-2 md-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak">Type of Loan Amortization </p>
                                                <Tooltip className="toolt" direction="left" align="center" >
                                                   Amortization methodology
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isLoanAmortizationTypefieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.loanAmortizationType} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isLoanAmortizationTypesa" onChange={(e) => { this.toggleChangeIsLoanAmortizationType(e, i) }} checked={productmix.isLoanAmortizationType} disabled={this.state.isLoanAmortizationTypelist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isLoanAmortizationTypeclasslist[i] }} ></div></label>
                                                </div>)}
                                             </div>
                                          </div>

                                          <div className="p-2 md-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheadermak maks3">Salaried Segment</p>
                                                <Tooltip className="toolt" direction="left" align="center" >
                                                   Segment Covered
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isSalriedSegmentfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.salariedSegment} </label>
                                                   <label className="switchtoggle" ><input type="checkbox" name="isSalriedSegment" onChange={(e) => { this.toggleChangeIsSalriedSegment(e, i) }} checked={productmix.isSalriedSegment} disabled={this.state.isSalriedSegmentlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isSalriedSegmentclasslist[i] }} ></div></label>
                                                </div>)}
                                             </div>
                                          </div>
                                       </div>
                                    </div>


                                    <div className="col-sm-12 col-md-12 col-xl-3 reducerd">
                                       <div className="d-flex flex-row mt-3 ">

                                          <div className="p-2 row-trial1">
                                             <div className="form-group">
                                                <label className="productchecker">Product Name </label>
                                                {this.state.data.map((productmix, i) => <div key={i}><label className="form-control  loanlabelcheck" name="paydayloan" disabled> {productmix.prodName} </label></div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 row-trial2">
                                             <div className="form-group">
                                                <label className="procode1">Code </label>
                                                {this.state.data.map((productmix, i) => <div key={i}><label className="form-control codeproduct" name="code" disabled > {productmix.code} </label></div>)}
                                             </div>
                                          </div>



                                          <div className="p-2 row-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheader">Self Employed Segment</p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   Segment Covered
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isSelfEmployedsegmentfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.selfEmployedsegment} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isSelfEmployedsegmentsa" onChange={(e) => { this.toggleChangeIsSelfEmployedsegment(e, i) }} checked={productmix.isSelfEmployedsegment} disabled={this.state.isSelfEmployedsegmentlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isSelfEmployedsegmentclasslist[i] }}></div></label>
                                                </div>)}

                                             </div>
                                          </div>


                                          <div className="p-2 row-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheader">Loans to external customers</p>
                                                <Tooltip className="toolt" direction="top" align="center" >
                                                   Lending to customers of other banks
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isLoansToExternCustfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled>{productmix.loansToExternCust} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isLoansToExternCustsa" onChange={(e) => { this.toggleChangeIsLoansToExternCust(e, i) }} checked={productmix.isLoansToExternCust} disabled={this.state.isLoansToExternCustlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isLoansToExternCustclasslist[i] }}></div></label>
                                                </div>)}

                                             </div>
                                          </div>


                                          <div className="p-2 row-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheader">Disbursal in internal accoun</p>
                                                <Tooltip className="toolt" direction="left" align="center" >
                                                   Disburse in account within  the bank
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isDisbursalInInternAcctfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.disbursalInInternAcct} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isDisbursalInInternAcctsa" onChange={(e) => { this.toggleChangeIsDisbursalInInternAcct(e, i) }} checked={productmix.isDisbursalInInternAcct} disabled={this.state.isDisbursalInInternAcctlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isDisbursalInInternAcctclasslist[i] }}></div></label>
                                                </div>)}
                                             </div>
                                          </div>


                                          <div className="p-2 row-trial3">
                                             <div className="toolinfopro" >
                                                <p className="productheader">Disbursal in external account</p>
                                                <Tooltip className="toolt" direction="left" align="center" >
                                                   Disburse in external bank account of customer
                                                </Tooltip>
                                             </div>
                                             <div className="form-group">
                                                {this.state.data.map((productmix, i) => <div key={i} className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.state.isDisbursalInExternAcctfieldclasslist[i] }} >
                                                   <label type="text" className="form-control formpprofilefield" name="penalChanrgesPercentsa" onChange={this.handleChange.bind(this)} disabled> {productmix.disbursalInExternAcct} </label>
                                                   <label className="switchtoggle"><input type="checkbox" name="isDisbursalInExternAcctsa" onChange={(e) => { this.toggleChangeIsDisbursalInExternAcct(e, i) }} checked={productmix.isDisbursalInExternAcct} disabled={this.state.isDisbursalInExternAcctlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.state.isDisbursalInExternAcctclasslist[i] }}></div></label>
                                                </div>)}

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>



                              </form>

                              <div className="row">
                                 <div className="col-xl-12">
                                    <CollectionCheckerLoan buttoncolor={this.state.buttoncolor} editable={this.state.editable} isCollectionForInternLoansclasslist={this.state.isCollectionForInternLoansclasslist} isBaseInterestRateclasslist={this.state.isBaseInterestRateclasslist} isBaseTenorsclasslist={this.state.isBaseTenorsclasslist}
                                       isCollectionForInternLoansfieldclasslist={this.state.isCollectionForInternLoansfieldclasslist} isBaseInterestRatefieldclasslist={this.state.isBaseInterestRatefieldclasslist} isBaseTenorsfieldclasslist={this.state.isBaseTenorsfieldclasslist}
                                       toggleChangeIsBaseTenors={(e, i) => this.toggleChangeIsBaseTenors(e, i)} data={this.state.fields} toggleChangeIsCollectionForInternLoans={(e, i) => this.toggleChangeIsCollectionForInternLoans(e, i)} toggleChangeIsBaseInterestRate={(e, i) => this.toggleChangeIsBaseInterestRate(e, i)} loaddata={this.state.data} isCollectionForInternLoanslist={this.state.isCollectionForInternLoanslist} isBaseInterestRatelist={this.state.isBaseInterestRatelist} isBaseTenorslist={this.state.isBaseTenorslist} handlestatus={status => this.setStatus(status)}
                                       toggleChangestatus={(e) => this.toggleChangestatus(e)} handleSubmit={(e) => this.handleSubmit(e)} handleChange={(e) => this.handleChange(e)} errorMsg={this.state.errorMsg} message={this.state.message} errors={this.state.errors} isDisabled={this.state.isDisabled} />


                                 </div>
                              </div>
                           </div>
                        </div>

                     </section>
                  </div>
               </div>
            </div>
         )
      }
      else {
         return (<p>Please Login AS AdminChecker</p>)
      }
   }
}

export default CheckerProductMix;
