import React from 'react';

import { Link } from 'react-router-dom';

/**
 CheckerManageUserPart2 component render's manage user(credit-uw users) for checker section.
 @Author : Thrilok Kandra
 @version :1.0
  */

class CheckerManageUserPart2 extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         fields: {}
      }
   };
   render() {
      let k = 0;
      return (

         <div>
            {this.props.data.map((manageUser, i) =>
               <form key={i} method="post" name="userRegistrationForm" className="formroot managemat">


                  <div className="d-flex flex-row usercuw">
                     <div className="p-2 managech">
                        <div className="form-group">
                           <label className="serial-header manser">S. No.</label>
                           <div className="heightch">
                              <p className="serialch">{++k}</p>
                           </div>
                        </div>
                     </div>

                     <div className="p-2 managech1">

                        <div className="form-group">

                           <label className="usertheaderch usname1">User Name</label>
                           <div className="form-group aligncenter inputgrayfield " style={{ backgroundColor: this.props.isUserNamefieldcredituwclasslist[i] }}>
                              <label type="text" className="form-control formpprofilefield" placeholder="Mahesh" name="username" disabled >{manageUser.userName}</label>
                              <label className="switchtoggle"><input type="checkbox"  name="isUserName" checked={manageUser.isUserName} disabled={this.props.isUserNamecredituwlist[i]} onChange={(e) => { this.props.toggleChangecredituwIsUserName(e, i) }} /><div className="checkerslider round" style={{ backgroundColor: this.props.isUserNamecredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>


                     <div className="p-2 managech1">

                        <div className="form-group">
                           <label className="usertheaderch desig">User Designation</label>
                           <div className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isDesignationfieldcredituwclasslist[i] }} >
                              <label type="text" className="form-control formpprofilefield" name="userdesgn1" disabled>{manageUser.designation} </label>
                              <label className="switchtoggle"><input type="checkbox"  name="isDesignation" checked={manageUser.isDesignation} onChange={(e) => { this.props.toggleChangecredituwIsDesignation(e, i) }} disabled={this.props.isDesignationcredituwlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isDesignationcredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>


                     <div className="p-2 managech1">

                        <div className="form-group">
                           <label className="usertheaderch user5">User Type</label>
                           <div className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isUserTypefieldcredituwclasslist[i] }}>
                              <label type="text" className="form-control formpprofilefield" name="usertype1" disabled>{manageUser.userType} </label>
                              <label className="switchtoggle"><input type="checkbox" name="isUserType" checked={manageUser.isUserType} disabled={this.props.isUserTypecredituwlist[i]} onChange={(e) => { this.props.toggleChangecredituwIsUserType(e, i) }} /><div className="checkerslider round" style={{ backgroundColor: this.props.isUserTypecredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>



                     <div className="p-2 managech1">

                        <div className="form-group">
                           <label className="usertheaderch user6">User Official Email ID</label>
                           <div className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isEmailfieldcredituwclasslist[i] }}>
                              <label type="text" className="form-control formpprofilefield" name="email1" disabled> {manageUser.officialEmail}</label>
                              <label className="switchtoggle"><input type="checkbox"  name="isEmail" checked={manageUser.isEmail} disabled={this.props.isEmailcredituwlist[i]} onChange={(e) => { this.props.toggleChangecredituwIsEmail(e, i) }} /><div className="checkerslider round" style={{ backgroundColor: this.props.isEmailcredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>


                     <div className="p-2 managech1">
                        <div className="form-group">
                           <label className="usertheaderch user7">User Mobile Number </label>
                           <div className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isMobilefieldcredituwclasslist[i] }}>
                              <label type="text" className="form-control formpprofilefield" name="mobile1" disabled>{manageUser.mobile} </label>
                              <label className="switchtoggle"><input type="checkbox"  name="isMobile" checked={manageUser.isMobile} disabled={this.props.isMobilecredituwlist[i]} onChange={(e) => { this.props.toggleChangecredituwIsMobile(e, i) }} /><div className="checkerslider round" style={{ backgroundColor: this.props.isMobilecredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>

                     <div className="p-2 managech1">
                        <div className="form-group">
                           <label className="usertheaderch us8">Activation </label>
                           <div className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isActivefieldcredituwclasslist[i] }}>
                              <label type="text" className="form-control formpprofilefield" name="mobile1" disabled>{manageUser.active} </label>
                              <label className="switchtoggle"><input type="checkbox"  name="active" checked={manageUser.isActive} onChange={(e) => this.props.toggleChangecredituwActive(e, i)} disabled={this.props.isActivecredituwlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isActivecredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>

                     <div className="p-2 managech1">
                        <div className="form-group">
                           <label className="usertheaderch us8">Removal </label>
                           <div className="form-group aligncenter inputgrayfield" style={{ backgroundColor: this.props.isRemovedfieldcredituwclasslist[i] }}>
                              <label type="text" className="form-control formpprofilefield" name="mobile1" disabled>{this.props.isRemovedyesornocredituw[i]} </label>
                              <label className="switchtoggle"><input type="checkbox"  name="active" checked={manageUser.isRemoved} onChange={(e) => this.props.toggleChangecredituwremoval(e, i)} disabled={this.props.isRemovedcredituwlist[i]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isRemovedcredituwclasslist[i] }} ></div></label>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="pafuc">

                     <p className="usertextch2">Products assigned for Under-writing</p>


                     <div className="d-flex flex-row portable">

                        {manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) =>
                           <div key={j} className="form-group productlabelname">
                              <label className="usersubheader">{prodsAssignedForUWRules.productName}</label>

                           </div>
                        )}

                     </div>

                     <div className="d-flex flex-row portable2">
                        {manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) =>
                           <div key={j} className="p-2 managech4">
                              <div className="form-group aligncenter inputgrayfield heightmanage" style={{ backgroundColor: this.props.isAssignedfieldclasslist[i][j] }}>
                                 <label type="text" className="form-control formpprofilefield" disabled >{prodsAssignedForUWRules.prodAssigned}</label>
                                 <label className="switchtoggle"><input type="checkbox"  name="isUserName" checked={prodsAssignedForUWRules.isAssigned} onChange={(e) => { this.props.toggleChangeprodassignedforuw(e, i, j) }} disabled={this.props.isAssignedlist[i][j]} /><div className="checkerslider round" style={{ backgroundColor: this.props.isAssignedclasslist[i][j] }} ></div></label>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>

               </form>
            )}

         </div>
      )

   }


}

export default CheckerManageUserPart2;
