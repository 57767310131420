import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import CheckerCompProfile from "./CheckerCompProfile";
import CheckerComission from "./CheckerComission";
import CheckerPremium from "./CheckerPremium";
import Header2 from "../CheckerHeader";
import "./CheckerProfile.css";
import CommonHeader from "../../header/CommonHeader";

/**
 * CheckerProfile component is render entire profile section of bank for checker section.
 * @Author : Thrilok Kandra
 * @version :1.0 .
 */

class CheckerProfile extends React.Component {
  /**
   * Creates a new checker profile instance
   * @param {props} props
   */
  constructor(props) {
    super();
    this.state = {
      fields: {},
      buttoncolor: "",
      isCLIPremPercentlist: [],
      isAIPremPercentlist: [],
      isCLIPremPercentclasslist: [],
      isAIPremPercentclasslist: [],
      isCLIPremPercentfieldclasslist: [],
      isAIPremPercentfieldclasslist: [],
      companyprofilelist: "",
      companyprofileclasslist: "",
      companytypedisable: "",
      companytypecolorclass: "",
      countrydisable: "",
      countrycolorclass: "",
      insuranceclidisable: "",
      insuranceclicolorclass: "",
      insuranceaidisable: "",
      insuranceaicolorclass: "",
      companyprofilefieldclasslist: "",
      companytypecolorfieldclass: "",
      countrycolorfieldclass: "",
      insuranceclicolorfieldclass: "",
      insuranceaicolorfieldclass: "",

      data: {
        companyDto: {
          companyName: "",
          companyId: "",
          lendingType: "",
          country: "",
          currency: "",
          registrationDate: "",
          status: "",
          insPartnerCLI: "",
          insPartnerAI: "",
          isCompanyName: "",
          isInsPartnerForCLI: "",
          errors: { pin: "" },
          errorMsg: "",
          backerrors: [],
          editable: false,
          message: "",
          errors: [],
          isInsPartnerForAI: "",
          isCompanyType: "",
          isCountry: "",
        },
        paylinkCommission: [
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
        ],
        collectionBuckets: [
          {
            collXdays: "",
            coll30DPD: "",
            coll60DPD: "",
            coll90DPD: "",
            coll120DPD: "",
            collBET180n360DPD: "",
            collGT360DPD: "",
          },
        ],
        insuranceCommissions: [{ premCollectedPercent: "" }],
        creditLifeAndAssetInsPremCheckers: [
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
        ],
      },
    };
  }
  /**
   * Sets default color disable for all toggle buttons for request non editable
   */
  setNforNonEditable() {
    let dataarray = this.state.dataarray;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        checkerProfile.isAIPremPercent = "N";
        checkerProfile.isCLIPremPercent = "N";
      }
    );
    this.state.data.companyDto.isCompanyName = "N";
    this.state.data.companyDto.isCompanyType = "N";
    this.state.data.companyDto.isCountry = "N";
    this.state.data.companyDto.isInsPartnerForAI = "N";
    this.state.data.companyDto.isInsPartnerForCLI = "N";
  }

  /**
   * Sets the boolean value for aiprempercent toggle button,disable buttons and background color.
   */
  onAndOffChangeisAIPremPercent() {
    let dataarray = this.state.dataarray;
    let isAIPremPercentlist = this.state.isAIPremPercentlist;
    let isAIPremPercentclasslist = this.state.isAIPremPercentclasslist;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        if ("N" == checkerProfile.isAIPremPercent) {
          checkerProfile.isAIPremPercent = true;
          this.state.isAIPremPercentlist[i] = true;
          this.state.isAIPremPercentclasslist[i] = "#bcbcbb";
          this.state.isAIPremPercentfieldclasslist[i] = "#e9ecef";
          this.setState({
            dataarray,
            isAIPremPercentlist,
            isAIPremPercentclasslist,
          });
        } else {
          checkerProfile.isAIPremPercent = true;
          this.state.isAIPremPercentlist[i] = false;
          this.state.isAIPremPercentclasslist[i] = "#2ab934";
          this.state.isAIPremPercentfieldclasslist[i] = "#bce394";
          this.setState({
            dataarray,
            isAIPremPercentlist,
            isAIPremPercentclasslist,
          });
        }
      }
    );
  }

  /**
   * Sets the boolean value for cliprempercent toggle button,disable buttons and background color.
   */
  onAndOffChangeisCLIPremPercent() {
    let dataarray = this.state.dataarray;
    let isCLIPremPercentlist = this.state.isCLIPremPercentlist;
    let isCLIPremPercentclasslist = this.state.isCLIPremPercentclasslist;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        if ("N" == checkerProfile.isCLIPremPercent) {
          checkerProfile.isCLIPremPercent = true;
          this.state.isCLIPremPercentlist[i] = true;
          this.state.isCLIPremPercentclasslist[i] = "#bcbcbb";
          this.state.isCLIPremPercentfieldclasslist[i] = "#e9ecef";
          this.setState({
            dataarray,
            isCLIPremPercentlist,
            isCLIPremPercentclasslist,
          });
        } else {
          checkerProfile.isCLIPremPercent = true;
          this.state.isCLIPremPercentlist[i] = false;
          this.state.isCLIPremPercentclasslist[i] = "#2ab934";
          this.state.isCLIPremPercentfieldclasslist[i] = "#bce394";
          this.setState({
            dataarray,
            isCLIPremPercentlist,
            isCLIPremPercentclasslist,
          });
        }
      }
    );
  }
  /**
   * Sets the boolean value for cliprempercent toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCLIPremPercent() {
    let data = this.state.data;
    let isCLIPremPercentlist = this.state.isCLIPremPercentlist;
    let isCLIPremPercentclasslist = this.state.isCLIPremPercentclasslist;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        if ("N" == checkerProfile.isCLIPremPercent) {
          checkerProfile.isCLIPremPercent = true;
          this.state.isCLIPremPercentlist[i] = true;
          this.state.isCLIPremPercentclasslist[i] = "#bcbcbb";
          this.setState({
            data,
            isCLIPremPercentlist,
            isCLIPremPercentclasslist,
          });
        } else {
          if ("A" == checkerProfile.isCLIPremPercent) {
            checkerProfile.isCLIPremPercent = true;
            this.state.isCLIPremPercentlist[i] = false;
            this.state.isCLIPremPercentclasslist[i] = "#2ab934";
            this.setState({
              data,
              isCLIPremPercentlist,
              isCLIPremPercentclasslist,
            });
          } else {
            checkerProfile.isCLIPremPercent = false;
            this.state.isCLIPremPercentlist[i] = false;
            this.state.isCLIPremPercentclasslist[i] = "#6c757d";
            this.setState({
              data,
              isCLIPremPercentlist,
              isCLIPremPercentclasslist,
            });
          }
        }
      }
    );
  }
  /**
   * Sets the boolean value for aiprempercent toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAIPremPercent() {
    let data = this.state.data;
    let isAIPremPercentlist = this.state.isAIPremPercentlist;
    let isAIPremPercentclasslist = this.state.isAIPremPercentclasslist;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        if ("N" == checkerProfile.isAIPremPercent) {
          checkerProfile.isAIPremPercent = true;
          this.state.isAIPremPercentlist[i] = true;
          this.state.isAIPremPercentclasslist[i] = "#bcbcbb";
          this.setState({
            data,
            isAIPremPercentlist,
            isAIPremPercentclasslist,
          });
        } else {
          if ("A" == checkerProfile.isAIPremPercent) {
            checkerProfile.isAIPremPercent = true;
            this.state.isAIPremPercentlist[i] = false;
            this.state.isAIPremPercentclasslist[i] = "#2ab934";
            this.setState({
              data,
              isAIPremPercentlist,
              isAIPremPercentclasslist,
            });
          } else {
            checkerProfile.isAIPremPercent = false;
            this.state.isAIPremPercentlist[i] = false;
            this.state.isAIPremPercentclasslist[i] = "#6c757d";
            this.setState({
              data,
              isAIPremPercentlist,
              isAIPremPercentclasslist,
            });
          }
        }
      }
    );
  }
  /**
   * Sets the boolean value for company profile of partner's toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeCompanyProfileChecker() {
    let companyprofilelist = this.state.companyprofilelist;
    let companyprofileclasslist = this.state.companyprofileclasslist;
    let companytypedisable = this.state.companytypedisable;
    let companytypecolorclass = this.state.companytypecolorclass;
    let countrydisable = this.state.countrydisable;
    let countrycolorclass = this.state.countrycolorclass;
    let insuranceclidisable = this.state.insuranceclidisable;
    let insuranceclicolorclass = this.state.insuranceclicolorclass;
    let insuranceaidisable = this.state.insuranceaidisable;
    let insuranceaicolorclass = this.state.insuranceaicolorclass;
    let data = this.state.data;
    if ("N" == this.state.data.companyDto.isCompanyName) {
      this.state.data.companyDto.isCompanyName = true;
      this.state.companyprofilelist = true;
      this.state.companyprofileclasslist = "#e9ecef";
      this.setState({
        data,
      });
    } else {
      if ("A" == this.state.data.companyDto.isCompanyName) {
        this.state.data.companyDto.isCompanyName = true;
        this.state.companyprofilelist = false;
        this.state.companyprofileclasslist = "#2ab934";
      } else {
        this.state.data.companyDto.isCompanyName = false;
        this.state.companyprofilelist = false;
        this.state.companyprofileclasslist = "#6c757d";
      }
      this.setState({
        data,
      });
    }
    if ("N" == this.state.data.companyDto.isCompanyType) {
      this.state.data.companyDto.isCompanyType = true;
      this.state.companytypedisable = true;
      this.state.companytypecolorclass = "#e9ecef";
      this.setState({
        data,
      });
    } else {
      if ("A" == this.state.data.companyDto.isCompanyType) {
        this.state.data.companyDto.isCompanyType = true;
        this.state.companytypedisable = false;
        this.state.companytypecolorclass = "#2ab934";
      } else {
        this.state.data.companyDto.isCompanyType = false;
        this.state.companytypedisable = false;
        this.state.companytypecolorclass = "#6c757d";
      }
      this.setState({
        data,
      });
    }

    if ("N" == this.state.data.companyDto.isCountry) {
      this.state.data.companyDto.isCountry = true;
      this.state.countrydisable = true;
      this.state.countrycolorclass = "#e9ecef";
      this.setState({
        data,
      });
    } else {
      if ("A" == this.state.data.companyDto.isCountry) {
        this.state.data.companyDto.isCountry = true;
        this.state.countrydisable = false;
        this.state.countrycolorclass = "#2ab934";
      } else {
        this.state.data.companyDto.isCountry = false;
        this.state.countrydisable = false;
        this.state.countrycolorclass = "#6c757d";
      }

      this.setState({
        data,
      });
    }
    if ("N" == this.state.data.companyDto.isInsPartnerForCLI) {
      this.state.data.companyDto.isInsPartnerForCLI = true;
      this.state.insuranceclidisable = true;
      this.state.insuranceclicolorclass = "#e9ecef";
      this.setState({
        data,
      });
    } else {
      if ("A" == this.state.data.companyDto.isInsPartnerForCLI) {
        this.state.data.companyDto.isInsPartnerForCLI = true;
        this.state.insuranceclidisable = false;
        this.state.insuranceclicolorclass = "#2ab934";
      } else {
        this.state.data.companyDto.isInsPartnerForCLI = false;
        this.state.insuranceclidisable = false;
        this.state.insuranceclicolorclass = "#6c757d";
      }

      this.setState({
        data,
      });
    }
    if ("N" == this.state.data.companyDto.isInsPartnerForAI) {
      this.state.data.companyDto.isInsPartnerForAI = true;
      this.state.insuranceaidisable = true;
      this.state.insuranceaicolorclass = "#e9ecef";
      this.setState({
        data,
      });
    } else {
      if ("A" == this.state.data.companyDto.isInsPartnerForAI) {
        this.state.data.companyDto.isInsPartnerForAI = true;
        this.state.insuranceaidisable = false;
        this.state.insuranceaicolorclass = "#2ab934";
      } else {
        this.state.data.companyDto.isInsPartnerForAI = false;
        this.state.insuranceaidisable = false;
        this.state.insuranceaicolorclass = "#6c757d";
      }

      this.setState({
        data,
      });
    }
  }

  /**
   * @typedef {companyDto} companyDto
   * @property {string} companyName indicates companyName of loan product type
   * @property {String} CompanyType  indicates CompanyType of loan product type
   * @property {string} country indicates country of loan product type
   * @property {String} currency indicates currency of loan product type
   * @property {String} aiprempercent indicates aiprempercent of loan product type
   * @property {String} cliprempercent indicates cliprempercent of loan product type
   */
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {companyDto} companyDto data and index of data.
   */
  toggleChangeCompanyProfile(e) {
    let data = this.state.data;
    let companyprofileclasslist = this.state.companyprofileclasslist;
    let companytypecolorclass = this.state.companytypecolorclass;
    let insuranceaicolorclass = this.state.insuranceaicolorclass;
    let insuranceclicolorclass = this.state.insuranceclicolorclass;
    let countrycolorclass = this.state.countrycolorclass;
    if ("isCompanyName" == e.target.name) {
      this.state.data.companyDto.isCompanyName =
        !this.state.data.companyDto.isCompanyName;
      if (true == this.state.data.companyDto.isCompanyName) {
        this.state.companyprofileclasslist = "#2ab934";
      } else {
        this.state.companyprofileclasslist = "#6c757d";
      }
      this.setState({
        data,
      });
    } else if ("isCompanyType" == e.target.name) {
      this.state.data.companyDto.isCompanyType =
        !this.state.data.companyDto.isCompanyType;
      if (true == this.state.data.companyDto.isCompanyType) {
        this.state.companytypecolorclass = "#2ab934";
      } else {
        this.state.companytypecolorclass = "#6c757d";
      }
      this.setState({
        data,
      });
    } else if ("isCountry" == e.target.name) {
      this.state.data.companyDto.isCountry =
        !this.state.data.companyDto.isCountry;
      if (true == this.state.data.companyDto.isCountry) {
        this.state.countrycolorclass = "#2ab934";
      } else {
        this.state.countrycolorclass = "#6c757d";
      }
      this.setState({
        data,
      });
    } else if ("isInsPartnerForCLI" == e.target.name) {
      this.state.data.companyDto.isInsPartnerForCLI =
        !this.state.data.companyDto.isInsPartnerForCLI;
      if (true == this.state.data.companyDto.isInsPartnerForCLI) {
        this.state.insuranceclicolorclass = "#2ab934";
      } else {
        this.state.insuranceclicolorclass = "#6c757d";
      }
      this.setState({
        data,
      });
    } else if ("isInsPartnerForAI" == e.target.name) {
      this.state.data.companyDto.isInsPartnerForAI =
        !this.state.data.companyDto.isInsPartnerForAI;
      if (true == this.state.data.companyDto.isInsPartnerForAI) {
        this.state.insuranceaicolorclass = "#2ab934";
      } else {
        this.state.insuranceaicolorclass = "#6c757d";
      }
      this.setState({
        data,
      });
    } else {
      this.setState({
        isChecked: !this.state.isChecked,
      });
    }
  }
  /**
   * Sets the boolean value for profile of company's toggle button,disable buttons and background color.
   */
  onAndOffChangeCompanyProfileChecker() {
    let companyprofilelist = this.state.companyprofilelist;
    let companyprofileclasslist = this.state.companyprofileclasslist;
    let companytypedisable = this.state.companytypedisable;
    let companytypecolorclass = this.state.companytypecolorclass;
    let countrydisable = this.state.countrydisable;
    let countrycolorclass = this.state.countrycolorclass;
    let insuranceclidisable = this.state.insuranceclidisable;
    let insuranceclicolorclass = this.state.insuranceclicolorclass;
    let insuranceaidisable = this.state.insuranceaidisable;
    let insuranceaicolorclass = this.state.insuranceaicolorclass;
    let data = this.state.data;
    if ("N" == this.state.data.companyDto.isCompanyName) {
      this.state.data.companyDto.isCompanyName = true;
      this.state.companyprofilelist = true;
      this.state.companyprofileclasslist = "#bcbcbb";
      this.state.companyprofilefieldclasslist = "#e9ecef";
      // this.setState({
      //   data,
      //   companyprofilelist,
      //   companyprofileclasslist
      // });
    } else {
      this.state.data.companyDto.isCompanyName = true;
      this.state.companyprofilelist = false;
      this.state.companyprofileclasslist = "#2ab934";
      this.state.companyprofilefieldclasslist = "#bce394";
      // this.setState({
      //   data,
      //   companyprofilelist,
      //   companyprofileclasslist
      // });
    }
    if ("N" == this.state.data.companyDto.isCompanyType) {
      this.state.data.companyDto.isCompanyType = true;
      this.state.companytypedisable = true;
      this.state.companytypecolorclass = "#bcbcbb";
      this.state.companytypecolorfieldclass = "#e9ecef";
      // this.setState({
      //   data,
      //   companytypedisable,
      //   companytypecolorclass
      // });
    } else {
      this.state.data.companyDto.isCompanyType = true;
      this.state.companytypedisable = false;
      this.state.companytypecolorclass = "#2ab934";
      this.state.companytypecolorfieldclass = "#bce394";
      // this.setState({
      //   data,
      //   companytypedisable,
      //   companytypecolorclass
      // });
    }

    if ("N" == this.state.data.companyDto.isCountry) {
      this.state.data.companyDto.isCountry = true;
      this.state.countrydisable = true;
      this.state.countrycolorclass = "#bcbcbb";
      this.state.countrycolorfieldclass = "#e9ecef";
      // this.setState({
      //   data,
      //   countrydisable,
      //   countrycolorclass
      // });
    } else {
      this.state.data.companyDto.isCountry = true;
      this.state.countrydisable = false;
      this.state.countrycolorclass = "#2ab934";
      this.state.countrycolorfieldclass = "#bce394";
      // this.setState({
      //   data,
      //   countrydisable,
      //   countrycolorclass
      // });
    }
    if ("N" == this.state.data.companyDto.isInsPartnerForCLI) {
      this.state.data.companyDto.isInsPartnerForCLI = true;
      this.state.insuranceclidisable = true;
      this.state.insuranceclicolorclass = "#bcbcbb";
      this.state.insuranceclicolorfieldclass = "#e9ecef";
      // this.setState({
      //   data,
      //   insuranceclidisable,
      //   insuranceclicolorclass
      // });
    } else {
      this.state.data.companyDto.isInsPartnerForCLI = true;
      this.state.insuranceclidisable = false;
      this.state.insuranceclicolorclass = "#2ab934";
      this.state.insuranceclicolorfieldclass = "#bce394";
      // this.setState({
      //   data,
      //   insuranceclidisable,
      //   insuranceclicolorclass
      // });
    }
    if ("N" == this.state.data.companyDto.isInsPartnerForAI) {
      this.state.data.companyDto.isInsPartnerForAI = true;
      this.state.insuranceaidisable = true;
      this.state.insuranceaicolorclass = "#bcbcbb";
      this.state.insuranceaicolorfieldclass = "#e9ecef";
      // this.setState({
      //   data,
      //   insuranceaidisable,
      //   insuranceaicolorclass
      // });
    } else {
      this.state.data.companyDto.isInsPartnerForAI = true;
      this.state.insuranceaidisable = false;
      this.state.insuranceaicolorclass = "#2ab934";
      this.state.insuranceaicolorfieldclass = "#bce394";
      // this.setState({
      //   data,
      //   insuranceaidisable,
      //   insuranceaicolorclass
      // });
    }
  }
  /**
   * @typedef {creditLifeAndAssetInsPremCheckers} creditLifeAndAssetInsPremCheckers
   * @property {string} aiprempercent indicates aiprempercent of loan product type
   * @property {String} cliprempercent  indicates cliprempercent of loan product type
   */
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {creditLifeAndAssetInsPremCheckers} creditLifeAndAssetInsPremCheckers data and index of data.
   */
  toggleChangeIsCLIPremPercent(e, i) {
    let data = this.state.data;
    let isCLIPremPercentclasslist = this.state.isCLIPremPercentclasslist;
    this.state.data.creditLifeAndAssetInsPremCheckers[i].isCLIPremPercent =
      !this.state.data.creditLifeAndAssetInsPremCheckers[i].isCLIPremPercent;
    if (
      true ==
      this.state.data.creditLifeAndAssetInsPremCheckers[i].isCLIPremPercent
    ) {
      this.state.isCLIPremPercentclasslist[i] = "#2ab934";
    } else {
      this.state.isCLIPremPercentclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {creditLifeAndAssetInsPremCheckers} creditLifeAndAssetInsPremCheckers data and index of data.
   */
  toggleChangeIsAIPremPercent(e, i) {
    let data = this.state.data;
    let isAIPremPercentclasslist = this.state.isAIPremPercentclasslist;
    this.state.data.creditLifeAndAssetInsPremCheckers[i].isAIPremPercent =
      !this.state.data.creditLifeAndAssetInsPremCheckers[i].isAIPremPercent;
    if (
      true ==
      this.state.data.creditLifeAndAssetInsPremCheckers[i].isAIPremPercent
    ) {
      this.state.isAIPremPercentclasslist[i] = "#2ab934";
    } else {
      this.state.isAIPremPercentclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
    });
  }
  /**
   * fetches data from and renders it on fields.
   */
  componentDidMount() {
    const url = `https://api.montra.org/moneylinkweb/api/user/profile?email=${localStorage.getItem(
      "email"
    )}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Server error");
        }
        return response.json();
      })
      .then((contents) => {
        console.log("API response:", contents); // Log the response for debugging
        if (contents.status === 200 && contents.message === "SUCCESS") {
          this.setState(
            {
              data: contents.data.Profile,
              editable: contents.data.editable,
            },
            () => {
              console.log("State updated:", this.state); // Log the updated state for debugging
              if (this.state.editable) {
                this.setState({
                  editable: false,
                  buttoncolor: "#192859",
                });
              } else {
                this.setState({
                  editable: true,
                  buttoncolor: "#343a40",
                });
              }

              if (this.state.editable) {
                this.setNforNonEditable();
              }
              this.onAndOffChangeCompanyProfileChecker();
              this.onAndOffChangeisAIPremPercent();
              this.onAndOffChangeisCLIPremPercent();
            }
          );
        } else {
          alert("Server error");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert("Error fetching data");
      });
  }
  /**
   * Sets the boolean value for cliprempercent toggle button,disable buttons and background color
   */
  setOnAndOffChangeisCLIPremPercent() {
    let data = this.state.data;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        if (false == this.state.isCLIPremPercentlist[i]) {
          if (true == checkerProfile.isCLIPremPercent) {
            checkerProfile.isCLIPremPercent = "A";
            this.setState({
              data,
            });
          } else {
            checkerProfile.isCLIPremPercent = "R";
            this.setState({
              data,
            });
          }
        } else {
          checkerProfile.isCLIPremPercent = "N";
          this.setState({
            data,
          });
        }
      }
    );
  }
  /**
   * Sets the boolean value for aiprempercent toggle button,disable buttons and background color
   */
  setOnAndOffChangeisAIPremPercent() {
    let data = this.state.data;
    this.state.data.creditLifeAndAssetInsPremCheckers.map(
      (checkerProfile, i) => {
        if (false == this.state.isAIPremPercentlist[i]) {
          if (true == checkerProfile.isAIPremPercent) {
            checkerProfile.isAIPremPercent = "A";
            this.setState({
              data,
            });
          } else {
            checkerProfile.isAIPremPercent = "R";
            this.setState({
              data,
            });
          }
        } else {
          checkerProfile.isAIPremPercent = "N";
          this.setState({
            data,
          });
        }
      }
    );
  }
  /**
   * Sets the boolean value for company profile of partner's toggle button,disable buttons and background color
   */
  setonAndOffChangeCompanyProfileChecker() {
    let data = this.state.data;
    if (false == this.state.data.companyDto.isCompanyName) {
      if (true == this.state.data.companyDto.isCompanyName) {
        this.state.data.companyDto.isCompanyName = "A";
        this.setState({
          data,
        });
      } else {
        this.state.data.companyDto.isCompanyName = "R";
        this.setState({
          data,
        });
      }
    } else {
      this.state.data.companyDto.isCompanyName = "N";
      this.setState({
        data,
      });
    }
    if (false == this.state.data.companyDto.isCompanyTypet) {
      if (true == this.state.data.companyDto.isCompanyType) {
        this.state.data.companyDto.isCompanyType = "A";
        this.setState({
          data,
        });
      } else {
        this.state.data.companyDto.isCompanyType = "R";
        this.setState({
          data,
        });
      }
    } else {
      this.state.data.companyDto.isCompanyType = "N";
      this.setState({
        data,
      });
    }
    if (false == this.state.data.companyDto.isCountry) {
      if (true == this.state.data.companyDto.isCountry) {
        this.state.data.companyDto.isCountry = "A";
        this.setState({
          data,
        });
      } else {
        this.state.data.companyDto.isCountry = "R";
        this.setState({
          data,
        });
      }
    } else {
      this.state.data.companyDto.isCountry = "N";
      this.setState({
        data,
      });
    }
    if (false == this.state.data.companyDto.isInsPartnerForCLI) {
      if (true == this.state.data.companyDto.isInsPartnerForCLI) {
        this.state.data.companyDto.isInsPartnerForCLI = "A";
        this.setState({
          data,
        });
      } else {
        this.state.data.companyDto.isInsPartnerForCLI = "R";
        this.setState({
          data,
        });
      }
    } else {
      this.state.data.companyDto.isInsPartnerForCLI = "N";
      this.setState({
        data,
      });
    }
    if (false == this.state.data.companyDto.isInsPartnerForAI) {
      if (true == this.state.data.companyDto.isInsPartnerForAI) {
        this.state.data.companyDto.isInsPartnerForAI = "A";
        this.setState({
          data,
        });
      } else {
        this.state.data.companyDto.isInsPartnerForAI = "R";
        this.setState({
          data,
        });
      }
    } else {
      this.state.data.companyDto.isInsPartnerForAI = "N";
      this.setState({
        data,
      });
    }
  }
  /**
   * Captures the changes on event is raised.
   * @param {Event} e captures the value for pin on onChange event
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   *Submit the changes to server.
   * @param {Event} e event occurs on clicking the submit button.
   */
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      let pin = this.state.pin;
      pin = "";
      this.setState({
        pin: pin,
      });
      let fields = this.state.fields;

      this.setState({
        fields: fields,
      });

      this.setonAndOffChangeCompanyProfileChecker();
      this.setOnAndOffChangeisAIPremPercent();
      this.setOnAndOffChangeisCLIPremPercent();
      let proxyurl = "https://cors-anywhere.herokuapp.com/";
      let url =
        "https://api.montra.org/moneylinkweb/api/user/createprofile?email=" +
        localStorage.getItem("email") +
        "&userpin=" +
        this.state.fields.pin;
      fetch(url, {
        method: "POST",
        body: JSON.stringify(this.state.data),
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else return "server error";
        })
        .then((contents) => {
          if (200 == contents.status && "SUCCESS" == contents.message) {
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message,
            });
            this.onSubmitChangeisAIPremPercent();
            this.onSubmitChangeisCLIPremPercent();
            this.onSubmitChangeCompanyProfileChecker();
          } else {
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message,
            });
            this.state.backerrors.map((key) => {
              this.setState({
                errorMsg: key.message,
              });
            });
          }
        });
    }
  };

  handleSubmitvalidateForm() {
    let fields = this.state.fields;
    let pin = this.state.pin;
    let formIsValid = true;
    if (this.state.fields.pin == "" || this.state.fields.pin == null) {
      formIsValid = false;
      pin = "*Please enter pin.";
    }
    this.setState({
      pin,
    });
    return formIsValid;
  }
  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "CHECKER" == localStorage.getItem("role")
    ) {
      return (
        <div>
          <CommonHeader />

          <div id="wrapper">
            <Sidebar />
            {(() => {
              if (this.state.editable) {
                return (
                  <div className="errorMsg">
                    {" "}
                    <p>
                      There are no Changes Request from Maker. A new request can
                      be submitted once Maker does the changes
                    </p>
                  </div>
                );
              }
            })()}
            <div id="maincontent" className="content">
              <section id="content-wrapper">
                <div className="backloan">
                  <div className="container-fluid undercontainer">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 profilediv1">
                        <CheckerCompProfile
                          countrydisable={this.state.countrydisable}
                          countrycolorclass={this.state.countrycolorclass}
                          insuranceclidisable={this.state.insuranceclidisable}
                          insuranceclicolorclass={
                            this.state.insuranceclicolorclass
                          }
                          insuranceaidisable={this.state.insuranceaidisable}
                          insuranceaicolorclass={
                            this.state.insuranceaicolorclass
                          }
                          companytypedisable={this.state.companytypedisable}
                          companytypecolorclass={
                            this.state.companytypecolorclass
                          }
                          companyprofilelist={this.state.companyprofilelist}
                          companyprofileclasslist={
                            this.state.companyprofileclasslist
                          }
                          toggleChangeCompanyProfile={(e) =>
                            this.toggleChangeCompanyProfile(e)
                          }
                          data={this.state.data}
                          onchange={(e) => onchange(e)}
                          countrycolorfieldclass={
                            this.state.countrycolorfieldclass
                          }
                          insuranceclicolorfieldclass={
                            this.state.insuranceclicolorfieldclass
                          }
                          insuranceaicolorfieldclass={
                            this.state.insuranceaicolorfieldclass
                          }
                          companytypecolorfieldclass={
                            this.state.companytypecolorfieldclass
                          }
                          companyprofilefieldclasslist={
                            this.state.companyprofilefieldclasslist
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 profilesecdiv">
                        <CheckerComission />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 profilethirddiv">
                        <CheckerPremium
                          buttoncolor={this.state.buttoncolor}
                          isCLIPremPercentlist={this.state.isCLIPremPercentlist}
                          isAIPremPercentlist={this.state.isAIPremPercentlist}
                          isCLIPremPercentclasslist={
                            this.state.isCLIPremPercentclasslist
                          }
                          isAIPremPercentclasslist={
                            this.state.isAIPremPercentclasslist
                          }
                          pin={this.state.pin}
                          editable={this.state.editable}
                          isDisabled={this.state.isDisabled}
                          isCLIPremPercentfieldclasslist={
                            this.state.isCLIPremPercentfieldclasslist
                          }
                          isAIPremPercentfieldclasslist={
                            this.state.isAIPremPercentfieldclasslist
                          }
                          toggleChangeIsAIPremPercent={(e, i) =>
                            this.toggleChangeIsAIPremPercent(e, i)
                          }
                          toggleChangeIsCLIPremPercent={(e, i) =>
                            this.toggleChangeIsCLIPremPercent(e, i)
                          }
                          data={this.state.data}
                          handleSubmit={(e) => this.handleSubmit(e)}
                          errorMsg={this.state.errorMsg}
                          message={this.state.message}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="Editable2">
                      <input
                        type="submit"
                        className="checker-button"
                        value="Changes requested for approval"
                        disabled
                      />
                      <input
                        type="submit"
                        className=" noteditable-button2"
                        value="Not Editable"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>Please Login AS AdminChecker</p>;
    }
  }
}
export default CheckerProfile;
