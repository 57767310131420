/* React packages */
import React from "react";
import Montralogo from "../../assets/images/Moneylink-logo.svg";
import { Link, NavLink } from "react-router-dom";
import Accesslogo from "../../assets/images/logo7.svg";

/**
 *  Header component is render top navigation in Credit-UW section.
 * <h6>Shows all sections</h6>
 * <li>Moneylink logo</li>
 * <li>User Name</li>
 * <li>User Type</li>
 * <li>Log out button</li>
 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li><b>Navbar.Brand :- </b>navbar-brand class is applied to typical Moneylink logo so we can  see in the top navigation bar.</li>
 * <li><b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li><b>NavLink:- </b> it will add styling attributes to the rendered element when it matches the current URL.</li>
 * <li><b>btn btn-outline-danger :-</b> it is used for buttons</li>
  @Author : Seema Kumari
  @version :1.0
 */

const Header = () => {
  return (
    <div className="montra-main-header">
      <div className="makerheader">
        <div className="toplogoml">
          <img className="toplogo" src={Montralogo} alt="logo" />
        </div>

        <div className="montra-logout-user">
          <div className="mr-auto montralendingheader">
            <ul className="maker-top-header">
              <li className="navbarlink">
                Name &nbsp; : &nbsp;{" "}
                <span className="titlename">
                  {localStorage.getItem("name")}
                </span>
              </li>
              <li className="navbarlink">
                User Type &nbsp; : &nbsp;{" "}
                <span className="titlename">{"Credit UW"}</span>
              </li>
              <li className="navbarlink2">
                Partner
                <img className="imagepartner" src={Accesslogo} alt="img" />
              </li>
            </ul>
          </div>
          <Link
            to="/MoneyLink/PartnerLogin"
            type=""
            className="btn btn-outline-danger logout"
          >
            Log Out
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
